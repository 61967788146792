import React from 'react'
import Dinero from 'dinero.js'

import { months } from './constants.json'

const getJWTHeaders = () => {
  const plex_user = JSON.parse(localStorage.getItem('plex_user'))

  if (plex_user && plex_user.access_token) {
    return {
      headers: {
        Authorization: `Bearer ${plex_user.access_token}`
      }
    }
  }
}

const formatTime = (time) => {
  const date = new Date(time)

  // return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}` // format MM DD, YYYY
  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}` // format DD MM YYYY
}

const format24HrTime = (timestamp) => {
  const date = new Date(timestamp)

  const z = (n) => ( (n<10 ? '0' : '') + n )
  return z(date.getUTCHours()) + ':' + z(date.getUTCMinutes())
}

const formatSgTimeFormat = (time) => {
  const schedule = new Date(time)
  return schedule.toLocaleString('en-SG', { timeZone: 'UTC' })
}

const formatSgMinsAndSecs = (time) => {
  const schedule = new Date(time)

  const options = {
     timeZone:'UTC',
     hour12 : true,
     hour: '2-digit',
     minute: '2-digit'
  }

  return schedule.toLocaleTimeString('en-SG', options)
}

const formatPrice = (d) => 'S$' + Dinero({ amount: d.amount }).toFormat('0,0.00') 

function validContact(contact) {
  return contact.match(/^[0-9-().+ ]{7,20}$/)
}

function validEmail(email) {
  return email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/)
}

const enrolAction = ({ action, prev, next }) => {
  const step_list = ['notice', 'course', 'trainee', 'payment', 'confirmation', 'complete']
  const step_action = ['continue', 'back', 'jump']
  const form_action = ['catalog', 'billing', 'coupon']

  if (step_action.indexOf(action) > -1)
    return `User ${action} - from ${step_list[prev]} to ${step_list[next]}` 

  if (form_action.indexOf(action) > -1)
      return `User provides ${action} information`

  return `No action intended`
}

const getCourseOrder = () => {
  return [
    { code: 'TM_01', order: 1 },
    { code: 'TM_02', order: 2 },
    { code: 'P010', order: 3 },
    { code: 'P010_E', order: 4 },
    { code: 'P011', order: 5 },
    { code: 'P011_E', order: 6 },
    { code: 'P020', order: 7 },
    { code: 'P020_S', order: 8 },
    { code: 'P020_E', order: 9 },
    { code: 'UAPL', order: 10 }
  ]
}

const checkStringifiedObject = (str) => {
  if( typeof( str ) !== 'string' ) { 
      return false
  }
  try {
      JSON.parse(str)
      return true
  } catch (e) {
      return false
  }
}

const renderRemarks = (remarks_input) => {
  let rendered_remarks = null
  const remarks = checkStringifiedObject(remarks_input) ? JSON.parse(remarks_input) : remarks_input
  if (typeof remarks === 'object') {
    let remark_node = []
    let i=0
    for (const remarks_title in remarks) {
      const formatted_title = remarks_title.split(' - pattern')
      const set_remark_node = [<div key={i} style={{ marginBottom: '12px' }}><div><strong>{formatted_title[0]}</strong></div><div>{remarks[remarks_title] || '-'}</div></div>]
      i=i+1
      const ignore_str = ['', '-']
      if (formatted_title && ignore_str.indexOf(remarks[remarks_title]) < 0) {
        remark_node.push(set_remark_node)
      }
    }
    rendered_remarks = (<div style={{ margin: '12px 0' }} >{remark_node}</div>)
  } else if (typeof remarks === 'string') {
    rendered_remarks = (remarks !== '' && remarks.length > 0) ? remarks : '-'
  }
  return rendered_remarks
}

const formatTimeWithZero = (num) => {
  return num < 10 && num > -1 ? `0${num}` : num
}

const Utils = {
  getJWTHeaders,
  formatTime,
  format24HrTime,
  formatSgTimeFormat,
  formatSgMinsAndSecs,
  formatPrice,
  validContact,
  validEmail,
  enrolAction,
  getCourseOrder,
  checkStringifiedObject,
  renderRemarks,
  formatTimeWithZero
}

export default Utils
