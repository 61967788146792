import React, { Component } from 'react'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import utils from '../utils'
import api_interface from '../api_interface'

// import { 
//   getTraineeGradeReports
// } from '../actions'

const status_filter = ['Available', 'Cancelled', 'Enrolled']

const styles = {
  table_cell: {
    fontFamily: 'Open Sans, helvetica, arial, sans-serif'
  }
}

class EBooklet extends Component {
  state = {
    credits: null
  }

  async componentDidMount() {
    // console.log('this.props.auth', this.props.auth)
    const credit_res_data = await api_interface.getTraineeCredits(this.props.auth._id)
    // console.log('credit_res_data', credit_res_data)
    if (!credit_res_data.success) {
      this.props.history.push('/500')
      return
    }

    const credits = credit_res_data.credits.filter( credit => status_filter.indexOf(credit.status) < 0 ).sort( (a, b) => new Date(b.course_schedule.start_time) - new Date(a.course_schedule.start_time) )
    this.setState({ credits })
  }

  componentDidUpdate = async (prevProps, prevState) => {
  }

  renderTableBody = () => {
    return this.state.credits.map( (credit, i) => {
      const count_credit_enrolled = this.state.credits.filter( tsc => tsc.course_id === credit.course_id ).length
      return (
        <TableRow key={i}>
          <TableCell style={{ ...styles.table_cell, textAlign: 'center' }} >{i+1}</TableCell>
          <TableCell style={{ ...styles.table_cell }} >{ utils.formatTime(credit.course_schedule.start_time) }</TableCell>
          <TableCell style={{ ...styles.table_cell }}>{ credit.course.name }{ count_credit_enrolled > 1 ? ` - ${credit.session_number}` : '' } </TableCell>
          <TableCell style={{ ...styles.table_cell }}>{ credit.status }</TableCell>
          <TableCell style={{ ...styles.table_cell }}>
            {
              credit.instructor ?
              <span>
                <span style={{ fontWeight: '500', display: 'block' }} >{ credit.instructor.name }</span>
                { credit.instructor.instructor_id && <span style={{ color: '#757575', fontSize: '14px' }} >{ credit.instructor.instructor_id }</span>}
              </span>
              :
              '-'
            }
          </TableCell>
        </TableRow>
      )
    })
  }

  render() {

    // console.log('this.state.credits', this.state.credits)
    
    return (
      <div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <h2 style={{ position: 'relative', marginTop: '0px', marginBottom: '1em' }} >E-booklet</h2>
        { this.state.credits === null ?
          <div style={{ textAlign: 'center', width: '100%', padding: '2em' }} >
            <CircularProgress />
          </div>
          :
          <TableContainer component={Paper}>
          <Table style={{ minWidth: '1000px' }}>
            <TableHead>
              <TableRow style={{ background: '#f5f5f5' }} >
                <TableCell style={{ ...styles.table_cell, width: 25, textAlign: 'center', fontSize: 16,
    fontWeight: 'bold', }}>S/N</TableCell>
                <TableCell style={{ ...styles.table_cell, width: 100, fontSize: 16,
    fontWeight: 'bold', }}>Date</TableCell>
                <TableCell style={{ ...styles.table_cell, width: 225, fontSize: 16,
    fontWeight: 'bold', }}>Module</TableCell>
                <TableCell style={{ ...styles.table_cell, width: 150, fontSize: 16,
    fontWeight: 'bold', }}>Grade</TableCell>
                <TableCell style={{ ...styles.table_cell, width: 150, fontSize: 16,
    fontWeight: 'bold', }}>Instructor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { this.renderTableBody() }
            </TableBody>
          </Table>
        </TableContainer>
        }
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return { auth: state.auth }
}

export default connect(mapStateToProps)(EBooklet)
