import React from 'react'
import { connect } from 'react-redux'
import { Elements,  ElementsConsumer, CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import MuiButton from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Checkbox from '@material-ui/core/Checkbox'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

import { goToNextStep, goToPreviousStep, addEnrolmentPaymentDetails, changePaymentExpandedPanel } from '../../actions'
import constants from '../../utils/constants.json'
import utils from '../../utils/index'
import paynow_qr from '../../utils/paynow_qr.png'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const CREDIT_DEBIT_CARD_METHOD = 'Credit / Debit Card'
const REQUEST_QUOTATION_OR_INVOICE_METHOD = 'Request Quotation / Invoice'
const PAYNOW_METHOD = 'PayNow'
const BANK_TRANSFER_METHOD = 'Bank Transfer'

class StepFourPayment extends React.Component {
  state = {
    payment_method: CREDIT_DEBIT_CARD_METHOD,

    toggle_quotation_instructions: false,

    toggle_same_as_invoicing_details: false,

    card_is_verifying: false,

    stripe_payment_method: {},

    billing_contact: {
      first_name: '',
      last_name: '',
      email: '',
      mobile_number: '',
      company_name: ''
    },
    card_payer_details: {
      first_name: '',
      last_name: '',
      address: '',
      city: '',
      postal_code: '',
      country: ''
    },
    invoicing_details: {
      recipient_name: '',
      email: '',
      company_name: '',
      uen: '',
      address: '',
      city: '',
      postal_code: '',
      country: ''
    },
    quotation_details: {
      recipient_name: '',
      email: '',
      company_name: '',
      uen: '',
      address: '',
      city: '',
      postal_code: '',
      country: ''
    },
  }

  componentDidMount() {
    if (this.props.billing_contact) {
      this.setState({ billing_contact: this.props.billing_contact })
      // console.log('this.state.billing_contact is: ', this.state.billing_contact)
    }
    // console.log('props 4 is: ', this.props)
    if (this.props.enrol_details.card_payer_details) {
      this.setState({ card_payer_details: this.props.enrol_details.card_payer_details })
    }
    if (this.props.enrol_details.invoicing_details) {
      this.setState({ invoicing_details: this.props.enrol_details.invoicing_details })
    }
    if (this.props.enrol_details.payment_method) {
      this.setState({ payment_method: this.props.enrol_details.payment_method })
    }
    if (this.props.enrol_details.quotation_details.recipient_name) {
      this.setState({ toggle_quotation_instructions: true , quotation_details: this.props.enrol_details.quotation_details })
    }
    if (JSON.stringify(this.props.enrol_details.invoicing_details) === JSON.stringify(this.props.enrol_details.quotation_details) && this.props.enrol_details.quotation_details.recipient_name !== '') {
      this.setState({ toggle_same_as_invoicing_details: true })
    }

    this.props.changePaymentExpandedPanel('panel1')
  }

  goToPanel2 = () => {
    // Pre-fills the card_payer_details first name and last name
    this.changePaymentMethod(this.state.payment_method)

    // Expands second panel
    // this.setState({ expanded: 'panel2' })
    this.props.changePaymentExpandedPanel('panel2')
  }

  changePaymentMethod = (payment_method) => {
    // Pre-fill card payer and invoicing details
    const { billing_contact, card_payer_details, invoicing_details } = this.state

    if (payment_method === CREDIT_DEBIT_CARD_METHOD) {
      this.setState({
        card_payer_details: {
          ...card_payer_details,
          first_name: billing_contact.first_name,
          last_name: billing_contact.last_name,
        }
      })
    } else if (payment_method === REQUEST_QUOTATION_OR_INVOICE_METHOD) {
      this.setState({
        invoicing_details: {
          ...invoicing_details,
          recipient_name: `${billing_contact.first_name} ${billing_contact.last_name}`,
          email: billing_contact.email,
          company_name: billing_contact.company_name
        }
      })
    }

    this.setState({ payment_method })
  }

  getStripePaymentMethod = async () => {
    const { elements, stripe } = this.props
    //CardCvcElement, CardExpiryElement, CardNumberElement
    const card_element = elements.getElement('cardNumber')
    // console.log('card element', card_element)
    const { error, token } = await stripe.createToken(card_element)
    
    if (error) {
      console.error('Stripe createToken error', error)
    } else {
      // console.log('token is', token)
      return token
    }
  }

  handleBillingContact = (key, value) => {
    this.setState({ 
      billing_contact: {
        ...this.state.billing_contact,
        [key]: value
      }
    })
  }

  handleCardPayerDetails = (key, value) => {
    this.setState({ 
      card_payer_details: {
        ...this.state.card_payer_details,
        [key]: value
      }
    })
  }

  handleInvoicingDetails = (key, value) => {
    this.setState({ 
      invoicing_details: {
        ...this.state.invoicing_details,
        [key]: value
      }
    })
  }

  handleQuotationDetails = (key, value) => {
    this.setState({ 
      quotation_details: {
        ...this.state.quotation_details,
        [key]: value
      }
    })
  }

  onVerifyCreditCard = async (event) => {
    // console.log('stripe credit card onchange: ', event)

    if (this.state.payment_method === CREDIT_DEBIT_CARD_METHOD && event.complete) {
      // console.log('verifying')
      let stripe_payment_method = await this.getStripePaymentMethod()
      this.setState({ stripe_payment_method })
      this.setState({ card_is_verifying: true })
    } else if (!event.complete) {
      this.setState({ stripe_payment_method: {} })
    }
  }

  onNextClick = async () => {
    const { 
      payment_method,
      billing_contact,
      card_payer_details,
      invoicing_details,
      quotation_details,
      stripe_payment_method,
    } = this.state 
    
    this.props.addEnrolmentPaymentDetails({
      payment_method,
      billing_contact,
      card_payer_details,
      invoicing_details,
      quotation_details,
      
      stripe_payment_method
    })

    this.props.goToNextStep()
  }

  goBackToTrainee() {
    const { 
      payment_method,
      billing_contact,
      card_payer_details,
      invoicing_details,
      quotation_details,
    } = this.state

    this.props.addEnrolmentPaymentDetails({
      payment_method,
      billing_contact,
      card_payer_details,
      invoicing_details,
      quotation_details,
    })

    this.props.goToPreviousStep()

  }

  renderStripeCardElement = () => {
    return (
      <Grid container>
        <Grid item md={6} >
          <Typography style={styles.stripeElementLabel}>Card Number</Typography>
          <div style={{ ...styles.stripeElementContainer, maxWidth: '85%' }}>
            <CardNumberElement
              onChange={(event) => this.onVerifyCreditCard(event)}
              options={{ style: styles.stripeElementInput }}
            />
          </div>
        </Grid>
        <Grid item md={3}>
          <Typography style={styles.stripeElementLabel}>Expiration Date</Typography>
          <div style={{ ...styles.stripeElementContainer, maxWidth: '70%' }}>
            <CardExpiryElement
              onChange={(event) => this.onVerifyCreditCard(event)}
              options={{ style: styles.stripeElementInput }}
            />
          </div>
        </Grid>
        <Grid item md={3}>
          <Typography style={styles.stripeElementLabel}>CVC</Typography>
          <div style={{ ...styles.stripeElementContainer, maxWidth: '70%' }}>
            <CardCvcElement
              onChange={(event) => this.onVerifyCreditCard(event)}
              options={{ style: styles.stripeElementInput }}
            />
          </div>
        </Grid>
        <Grid item md={1}></Grid>
      </Grid>
    )
  }

  renderPaymentMethodDetails = () => {
    const { payment_method } = this.state 
    
    if (payment_method === CREDIT_DEBIT_CARD_METHOD) {
      const { card_payer_details } = this.state 
      // console.log('card_payer_details: ', card_payer_details)

      return (
        <>
          <br/>
          <Typography><strong>Billing Address</strong></Typography>

          <br/>

          <Grid container>
            <Grid item md={6}>
              <TextField 
                label="First Name" 
                onChange={(event) => this.handleCardPayerDetails('first_name', event.target.value)} 
                value={card_payer_details.first_name} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField 
                label="Last Name" 
                onChange={(event) => this.handleCardPayerDetails('last_name', event.target.value)} 
                value={card_payer_details.last_name} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>

            <br/><br/><br/><br/>

            <Grid item md={12} style={{ marginTop: 20 }}>
              <TextField 
                label="Address" 
                onChange={(event) => this.handleCardPayerDetails('address', event.target.value)} 
                value={card_payer_details.address} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '95%' }}
              />
            </Grid>

            <br/><br/><br/><br/>

            <Grid item md={6} style={{ marginTop: 20 }}>
              <TextField 
                label="City" 
                onChange={(event) => this.handleCardPayerDetails('city', event.target.value)} 
                value={card_payer_details.city}
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>
            <Grid item md={6} style={{ marginTop: 20 }}>
              <TextField 
                label="Postal Code" 
                onChange={(event) => this.handleCardPayerDetails('postal_code', event.target.value)} 
                value={card_payer_details.postal_code}
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>

            <br/><br/><br/><br/>

            <Grid item md={6} style={{ marginTop: 20 }}>
              <InputLabel shrink>Country</InputLabel>
              <Select value={card_payer_details.country} onChange={(event) => this.handleCardPayerDetails('country', event.target.value)} style={{ minWidth: '90%', marginTop: '-12px' }}>
                {constants.countries.map(c => {
                  return <MenuItem key={c.name} value={c.name}>{c.name}</MenuItem>
                })}
              </Select>
            </Grid>
          </Grid>

          <br/><br/>

          <Typography><strong>Card Information</strong></Typography>
          <Typography>Visa, MasterCard, American Express or Discover billed in Singapore Dollars</Typography>

          <br/>

          <Grid container>
            <Grid item md={12}>
              <Typography style={{ color: 'red'}}>{this.props.error.message}</Typography>
              <br/>
              {/* <CardElement onChange={(event) => this.onVerifyCreditCard(event) } options={{hidePostalCode: true}} /> */}
              {this.renderStripeCardElement()}
            </Grid> 
          </Grid>

          {this.renderVerifyErrorMessage()}

        </>
      )
    } else if (payment_method === BANK_TRANSFER_METHOD) {
      return (
        <>
          <Typography>

          Please make payment to the following account within 72 hours and send a screenshot of the confirmation screen 
          or transaction confirmation number to <a href="mailto:finance@garuda.io">finance@garuda.io</a>.

          </Typography>

          <br/>
          
          <Paper elevation={4} style={{ minWidth: '50%' }}>
            <Container>
              <br/>
              <Grid container>
                  <Grid item md={6}>
                    <Typography><strong>Bank</strong></Typography>
                  </Grid>
                  <Grid item md={6}>OCBC</Grid>

                  <Grid item md={6}>
                    <Typography><strong>Name</strong></Typography>
                  </Grid>
                  <Grid item md={6}>Garuda Robotics Pte. Ltd.</Grid>
                  
                  <Grid item md={6}>
                    <Typography><strong>Account</strong></Typography>
                  </Grid>
                  <Grid item md={6}>686-261355-001</Grid>
              </Grid>
              <br/>
            </Container>
          </Paper>
        </>
      )
    } else if (payment_method === PAYNOW_METHOD) {

      return (
        <>
        <Typography>
          Please make payment to the following account within 72 hours and send a screenshot of the confirmation screen 
          or transaction confirmation number to <a href="mailto:finance@garuda.io">finance@garuda.io</a>.
        </Typography>

        <br/>

        <Paper elevation={4} style={{ minWidth: '50%' }}>
            <Container>
              <br/>
              <Grid container>
                <Grid item md={2}>
                  <Typography><strong>Name</strong> <br/><br/> <strong>UEN</strong></Typography>
                </Grid>
                <Grid item md={6}>GARUDA ROBOTICS PTE. LTD. <br/><br style={{ marginTop: 6 }} /> 201401225D</Grid>
                <Grid item md={4} >
                  <img alt="PayNow" src={paynow_qr} style={{ marginLeft: 20 }}/>
                </Grid>
                  
              </Grid>
              <br/>
            </Container>
          </Paper>


        <br/>

        <Typography>
          Please note that PayNow is available to retail customers of nine participating banks in Singapore - 
          Bank of China, Citibank Singapore Limited, DBS Bank/POSB, HSBC, Industrial and Commercial Bank of China Limited,
          Maybank, OCBC Bank, Standard Chartered Bank, and UOB.
        </Typography>
        </>
      )

    } else if (payment_method === REQUEST_QUOTATION_OR_INVOICE_METHOD) {
      const { invoicing_details } = this.state 
      // console.log('invoicing_details: ', invoicing_details)

      return (
        <>
          <Typography>
            Please note that this payment option is only available for corporate 
            customers who require a separate invoice for processing by their 
            Finance Department.
          </Typography>

          <br/><br/>

          <Typography><strong>Invoicing Instructions</strong></Typography>

          <br/>
          
          <Grid container>
            <Grid item md={6}>
              <TextField 
                label="Recipient's Name" 
                onChange={(event) => this.handleInvoicingDetails('recipient_name', event.target.value)} 
                value={invoicing_details.recipient_name} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField 
                label="Email" 
                onChange={(event) => this.handleInvoicingDetails('email', event.target.value)}
                error={this.checkDisplayEmailErrorInvoicing(invoicing_details)}
                helperText={this.checkDisplayEmailHelperTextInvoicing(invoicing_details) || this.checkDisplayEmailHelperTextInvoicing(invoicing_details) === 'is_empty' ? '' : 'Invalid email or unsupported Hotmail address'} 
                value={invoicing_details.email} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>
              
            <br/><br/><br/><br/>

            <Grid item md={6} style={{ marginTop: 20 }}>
              <TextField 
                label="Company Name" 
                onChange={(event) => this.handleInvoicingDetails('company_name', event.target.value)} 
                value={invoicing_details.company_name} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>
            <Grid item md={6} style={{ marginTop: 20 }}>
              <TextField 
                label="UEN" 
                onChange={(event) => this.handleInvoicingDetails('uen', event.target.value)} 
                value={invoicing_details.uen} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>

            <br/><br/><br/><br/>

            <Grid item md={12} style={{ marginTop: 20 }}>
              <TextField 
                label="Address" 
                onChange={(event) => this.handleInvoicingDetails('address', event.target.value)} 
                value={invoicing_details.address} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '95%' }}
              />
            </Grid>

            <br/><br/><br/><br/>

            <Grid item md={6}style={{ marginTop: 20 }}>
              <TextField 
                label="City" 
                onChange={(event) => this.handleInvoicingDetails('city', event.target.value)} 
                value={invoicing_details.city} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>
            <Grid item md={6} style={{ marginTop: 20 }}>
              <TextField 
                label="Postal Code" 
                onChange={(event) => this.handleInvoicingDetails('postal_code', event.target.value)} 
                value={invoicing_details.postal_code} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>

            <br/><br/><br/><br/>

            <Grid item md={6} style={{ marginTop: 20 }}>
              <InputLabel shrink>Country</InputLabel>
              <Select value={invoicing_details.country}  onChange={(event) => this.handleInvoicingDetails('country', event.target.value)} style={{ minWidth: '90%', marginTop: '-12px' }}>
                {constants.countries.map(c => {
                  return <MenuItem key={c.name} value={c.name}>{c.name}</MenuItem>
                })}
              </Select>
            </Grid>
          </Grid>

          <br/><br/><br/>

          <Typography><strong>Quotation Instructions</strong></Typography>

          <br/>

          <Grid container>
            <Grid item md={12}>
              <Checkbox
                  checked={this.state.toggle_quotation_instructions}
                  onChange={() => this.toggleQuotationInstructions()}
                  color="primary"
                />
              <Typography style={{ fontSize: '14px', marginLeft: '53px', marginTop: '-33px', cursor: 'pointer' }} onClick={() => this.toggleQuotationInstructions()}>
              I require a Quotation to generate a Purchase Order before receiving an invoice. 
              <br/>
              <span style={{ fontSize: '12px' }}>You will not receive an invoice until we have received your Purchase Order.</span>
              </Typography>
            </Grid>
          </Grid>

          {this.renderQuotationInstructions()}
        </>
      )
    }
  }

  renderVerifyErrorMessage() {
    if (this.state.card_is_verifying) {
      if (!(this.state.stripe_payment_method && this.state.stripe_payment_method.id)) {
        return (
          <span style={{ color: 'red', fontSize: 14 }}>
            Invalid credit card.
          </span>
        )
      }
    } else {
      return ''
    }
  }

  toggleQuotationInstructions() {
    this.setState({ toggle_quotation_instructions: !this.state.toggle_quotation_instructions })

    const quotation_copy = {
      recipient_name: '',
      email: '',
      company_name: '',
      uen: '',
      address: '',
      city: '',
      postal_code: '',
      country: ''
    }

    // wipes this.state.quotation_details when checkbox is deselected
    // this prevents user's quotation details from being sent in the case where the user types stuff into quotation details but deselects the quotation checkbox
    this.setState({ quotation_details: quotation_copy })

    // deselects 'use invoicing instructions' checkbox when quotation checkbox is deselected
    this.setState({ toggle_same_as_invoicing_details: false })

  }

  setQuotationDetails(quotation_details) {
/*     if (quotation_details.recipient_name === '' ||
        quotation_details.email === '' ||
        quotation_details.company_name === '' ||
        quotation_details.uen === '' ||
        quotation_details.address === '' ||
        quotation_details.city === '' ||
        quotation_details.postal_code === '' ||
        quotation_details.country === ''
    ) { */
        let invoicing_copy = { ...this.state.invoicing_details }
        this.setState({ quotation_details: invoicing_copy })
    /* } else {
      let invoicing_copy = {
        recipient_name: '',
        email: '',
        company_name: '',
        uen: '',
        address: '',
        city: '',
        postal_code: '',
        country: ''
      }
      this.setState({ quotation_details: invoicing_copy }) 
    } */

    this.setState({ toggle_same_as_invoicing_details: !this.state.toggle_same_as_invoicing_details })
  }

  renderQuotationInstructions() {
    const { quotation_details } = this.state
    if (this.state.toggle_quotation_instructions && !this.state.toggle_same_as_invoicing_details) {
      return (
        <>
          <hr/>

          {/* <FormControlLabel
            control={<Checkbox onChange={() => this.setQuotationDetails(quotation_details)} />}
            label={
              <Typography>
                <span style={{ fontSize: 14 }}>Same as invoicing instructions.</span>
              </Typography>
            }
          /> */}

          <Grid container>
            <Grid item md={12}>
              <Checkbox
                  checked={this.state.toggle_same_as_invoicing_details}
                  onChange={() => this.setQuotationDetails(quotation_details)}
                  color="primary"
                />
              <Typography style={{ fontSize: '14px', marginLeft: '53px', marginTop: '-33px', cursor: 'pointer' }} onClick={() => this.setQuotationDetails(quotation_details)}>
                <span style={{ fontSize: 14 }}>Use invoicing instructions.</span>
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={6} style={{ marginTop: 20 }}>
              <TextField 
                label="Recipient's Name" 
                onChange={(event) => this.handleQuotationDetails('recipient_name', event.target.value)} 
                value={quotation_details.recipient_name} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>
            <Grid item md={6} style={{ marginTop: 20 }}>
              <TextField 
                label="Email" 
                onChange={(event) => this.handleQuotationDetails('email', event.target.value)}
                error={this.checkDisplayEmailErrorQuotation(quotation_details)}
                helperText={this.checkDisplayEmailHelperTextQuotation(quotation_details) || this.checkDisplayEmailHelperTextQuotation(quotation_details) === 'is_empty' ? '' : 'Invalid email or unsupported Hotmail address'} 
                value={quotation_details.email} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>

            <br/><br/><br/><br/>

            <Grid item md={6} style={{ marginTop: 20 }}>
              <TextField 
                label="Company Name" 
                onChange={(event) => this.handleQuotationDetails('company_name', event.target.value)} 
                value={quotation_details.company_name} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>
            <Grid item md={6} style={{ marginTop: 20 }}>
              <TextField 
                label="UEN" 
                onChange={(event) => this.handleQuotationDetails('uen', event.target.value)} 
                value={quotation_details.uen} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>

            <Grid item md={12} style={{ marginTop: 20 }}>
              <TextField 
                label="Address" 
                onChange={(event) => this.handleQuotationDetails('address', event.target.value)} 
                value={quotation_details.address} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '95%' }}
              />
            </Grid>

            <br/><br/><br/><br/>

            <Grid item md={6}style={{ marginTop: 20 }}>
              <TextField 
                label="City" 
                onChange={(event) => this.handleQuotationDetails('city', event.target.value)} 
                value={quotation_details.city} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>
            <Grid item md={6} style={{ marginTop: 20 }}>
              <TextField 
                label="Postal Code" 
                onChange={(event) => this.handleQuotationDetails('postal_code', event.target.value)} 
                value={quotation_details.postal_code} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>

            <br/><br/><br/><br/>

            <Grid item md={6} style={{ marginTop: 20 }}>
              <InputLabel shrink>Country</InputLabel>
              <Select value={quotation_details.country}  onChange={(event) => this.handleQuotationDetails('country', event.target.value)} style={{ minWidth: '90%', marginTop: '-12px' }}>
                {constants.countries.map(c => {
                  return <MenuItem value={c.name}>{c.name}</MenuItem>
                })}
              </Select>
            </Grid>
          </Grid>
        </>
      )
    } else if (this.state.toggle_quotation_instructions && this.state.toggle_same_as_invoicing_details) {
      return (
        <>
          <hr/>

          <Grid container>
            <Grid item md={12}>
              <Checkbox
                  checked={this.state.toggle_same_as_invoicing_details}
                  onChange={() => this.setQuotationDetails(quotation_details)}
                  color="primary"
                />
              <Typography style={{ fontSize: '14px', marginLeft: '53px', marginTop: '-33px', cursor: 'pointer' }} onClick={() => this.setQuotationDetails(quotation_details)}>
                <span style={{ fontSize: 14 }}>Use invoicing instructions.</span>
                <br/>
                <span style={{ fontSize: '12px' }}>If you have made changes to the Invoicing Instructions and wish to apply the new changes to the Quotation Instructions, please re-select this checkbox.</span>
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={6} style={{ marginTop: 20 }}>
              <TextField 
                label="Recipient's Name" 
                disabled
                onChange={(event) => this.handleQuotationDetails('recipient_name', event.target.value)} 
                value={quotation_details.recipient_name} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>
            <Grid item md={6} style={{ marginTop: 20 }}>
              <TextField 
                label="Email" 
                disabled
                onChange={(event) => this.handleQuotationDetails('email', event.target.value)}
                error={this.checkDisplayEmailErrorQuotation(quotation_details)}
                helperText={this.checkDisplayEmailHelperTextQuotation(quotation_details) || this.checkDisplayEmailHelperTextQuotation(quotation_details) === 'is_empty' ? '' : 'Invalid email or unsupported Hotmail address'} 
                value={quotation_details.email} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>

            <br/><br/><br/><br/>

            <Grid item md={6} style={{ marginTop: 20 }}>
              <TextField 
                label="Company Name" 
                disabled
                onChange={(event) => this.handleQuotationDetails('company_name', event.target.value)} 
                value={quotation_details.company_name} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>
            <Grid item md={6} style={{ marginTop: 20 }}>
              <TextField 
                label="UEN" 
                disabled
                onChange={(event) => this.handleQuotationDetails('uen', event.target.value)} 
                value={quotation_details.uen} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>

            <Grid item md={12} style={{ marginTop: 20 }}>
              <TextField 
                label="Address" 
                disabled
                onChange={(event) => this.handleQuotationDetails('address', event.target.value)} 
                value={quotation_details.address} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '95%' }}
              />
            </Grid>

            <br/><br/><br/><br/>

            <Grid item md={6}style={{ marginTop: 20 }}>
              <TextField 
                label="City" 
                disabled
                onChange={(event) => this.handleQuotationDetails('city', event.target.value)} 
                value={quotation_details.city} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>
            <Grid item md={6} style={{ marginTop: 20 }}>
              <TextField 
                label="Postal Code" 
                disabled
                onChange={(event) => this.handleQuotationDetails('postal_code', event.target.value)} 
                value={quotation_details.postal_code} 
                InputLabelProps={{ shrink: true }} 
                style={{ minWidth: '90%' }}
              />
            </Grid>

            <br/><br/><br/><br/>

            <Grid item md={6} style={{ marginTop: 20 }}>
              <InputLabel shrink>Country</InputLabel>
              <Select disabled value={quotation_details.country}  onChange={(event) => this.handleQuotationDetails('country', event.target.value)} style={{ minWidth: '90%', marginTop: '-12px' }}>
                {constants.countries.map(c => {
                  return <MenuItem key={c.name} value={c.name}>{c.name}</MenuItem>
                })}
              </Select>
            </Grid>
          </Grid>
        </>
      )
    } else {
      return ''
    }
  }

  render() {
    const { billing_contact } = this.state 

    return (
      <Container>
        <ExpansionPanel expanded={this.props.payment_expanded_panel === 'panel1'}>
          <ExpansionPanelSummary onClick={() => this.props.changePaymentExpandedPanel('panel1')}>
            <span style={styles.numberCircle}>1</span> Billing Contact
          </ExpansionPanelSummary>
          
          <ExpansionPanelDetails>
            <Grid container>
              <Grid item md={6}>
                <TextField 
                  label="First Name" 
                  onChange={(event) => this.handleBillingContact('first_name', event.target.value)} 
                  value={billing_contact.first_name} 
                  InputLabelProps={{ shrink: true }} 
                  style={{ minWidth: '90%' }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField 
                  label="Last Name" 
                  onChange={(event) => this.handleBillingContact('last_name', event.target.value)} 
                  value={billing_contact.last_name} 
                  InputLabelProps={{ shrink: true }} 
                  style={{ minWidth: '90%' }}
                />
              </Grid>
              <br/>
              <br/>
              <br/>
              <br/>

              <Grid item md={6} style={{ marginTop: 20 }}>
                <TextField 
                  label="Email" 
                  onChange={(event) => this.handleBillingContact('email', event.target.value)} 
                  value={billing_contact.email}
                  error={this.checkDisplayEmailError(billing_contact)}
                  helperText={this.checkDisplayEmailHelperText(billing_contact) || this.checkDisplayEmailHelperText(billing_contact) === 'is_empty' ? '' : 'Invalid Email or unsupported Hotmail address'} 
                  InputLabelProps={{ shrink: true }} 
                  style={{ minWidth: '90%' }}
                />
              </Grid>
              <Grid item md={6} style={{ marginTop: 20 }}>
                <TextField 
                  label="Mobile Number" 
                  onChange={(event) => this.handleBillingContact('mobile_number', event.target.value)} 
                  value={billing_contact.mobile_number}
                  error={this.checkDisplayMobileError(billing_contact)}
                  helperText={this.checkDisplayMobileHelperText(billing_contact) || this.checkDisplayMobileHelperText(billing_contact) === 'is_empty' ? '' : 'Invalid Mobile Number'} 
                  InputLabelProps={{ shrink: true }} 
                  style={{ minWidth: '90%' }}
                />
              </Grid>
              <br/>
              <br/>
              <br/>
              <br/>

              <Grid item md={12} style={{ marginTop: 20 }}>
                <TextField 
                  label="Company Name" 
                  onChange={(event) => this.handleBillingContact('company_name', event.target.value)} 
                  value={billing_contact.company_name} 
                  InputLabelProps={{ shrink: true }} 
                  style={{ minWidth: '95%' }}
                />
              </Grid>

             
              <br/>
              <br/>
              <br/>
              <br/>


              {this.renderPanelOneNextButton()}

            </Grid>

          </ExpansionPanelDetails>
        </ExpansionPanel>



        <ExpansionPanel expanded={this.props.payment_expanded_panel === 'panel2'} style={{ border: '1px solid rgba(0, 0, 0, 0.125)' }}>
          <ExpansionPanelSummary>
            <span style={styles.numberCircle}>2</span> Payment Method
          </ExpansionPanelSummary>
          
          <ExpansionPanelDetails>
            <Container>
              <br/>
              <Typography><strong>Payment Method</strong></Typography>
              <br/>
              <Select value={this.state.payment_method} onChange={(event) => this.changePaymentMethod(event.target.value)} style={{  minWidth: '50%' }}>
                <MenuItem value={CREDIT_DEBIT_CARD_METHOD}>Credit / Debit Card</MenuItem>
                <MenuItem value={BANK_TRANSFER_METHOD}>Bank Transfer</MenuItem>
                <MenuItem value={PAYNOW_METHOD}>PayNow</MenuItem>
                <MenuItem value={REQUEST_QUOTATION_OR_INVOICE_METHOD}>Request Quotation / Invoice</MenuItem>
              </Select>
              <br/>
              <br/>

              {this.renderPaymentMethodDetails()}
              <br/>
              <br/>
              <br/>

              <BackButton 
                style={{ textTransform: 'none', backgroundColor: 'white', color: '#1E90FF', border: '1px solid' }} 
                onClick={() => this.props.changePaymentExpandedPanel('panel1')}
              >
                Back
              </BackButton>

              {/* {this.renderVerifyCardButton()} */}

              <br/>
              <br/>
              <br/>
            </Container>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <br/>
        <br/>
        <BackButton style={{ textTransform: 'none', backgroundColor: 'white', color: '#1E90FF', border: '1px solid' }} onClick={ () => this.goBackToTrainee()}>Back to Trainee</BackButton>
        {this.renderContinueButton()}

        <br/>
        <span style={{ fontSize: 12, float: 'right', marginTop: 10 }}>All fields are mandatory.</span>

        <br/><br/><br/><br/>
      </Container>
    )
  }

  //Checks when to display error outline and helpertext for billing contact email and mobile number
  checkDisplayEmailError(billing_contact) {
    if (billing_contact.email.length === 0) {
      return false
    } else if (billing_contact.email.length > 0 && utils.validEmail(billing_contact.email)) {
      return false
    } else {
      return true
    }
  }

  checkDisplayEmailHelperText(billing_contact) {
    if (billing_contact.email.length === 0) {
      return 'is_empty'
    } else if (billing_contact.email.toLowerCase().includes("hotmail")){
      return false
    } else if (billing_contact.email.length > 0 && utils.validEmail(billing_contact.email)) {
      return true
    } else {
      return false
    }
  }

  checkDisplayMobileError(billing_contact) {
    if (billing_contact.mobile_number.length === 0) {
      return false
    } else if (billing_contact.mobile_number.length > 0 && utils.validContact(billing_contact.mobile_number)) {
      return false
    } else {
      return true
    }
  }

  checkDisplayMobileHelperText(billing_contact) {
    if (billing_contact.mobile_number.length === 0) {
      return 'is_empty'
    } else if (billing_contact.mobile_number.length > 0 && utils.validContact(billing_contact.mobile_number)) {
      return true
    } else {
      return false
    }
  }

  //Checks when to display error outline and helpertext for invoicing details email
  checkDisplayEmailErrorInvoicing(invoicing_details) {
    if (invoicing_details.email.length === 0) {
      return false
    } else if (invoicing_details.email.length > 0 && utils.validEmail(invoicing_details.email)) {
      return false
    } else {
      return true
    }
  }

  checkDisplayEmailHelperTextInvoicing(invoicing_details) {
    if (invoicing_details.email.length === 0) {
      return 'is_empty'
    }else if (invoicing_details.email.toLowerCase().includes("hotmail")) {
      return false
    } else if (invoicing_details.email.length > 0 && utils.validEmail(invoicing_details.email)) {
      return true
    } else {
      return false
    }
  }

  //Checks when to display error outline and helpertext for quotation details email
  checkDisplayEmailErrorQuotation(quotation_details) {
    if (quotation_details.email.length === 0) {
      return false
    } else if (quotation_details.email.length > 0 && utils.validEmail(quotation_details.email)) {
      return false
    } else {
      return true
    }
  }

  checkDisplayEmailHelperTextQuotation(quotation_details) {
    if (quotation_details.email.length === 0) {
      return 'is_empty'
    } else if (quotation_details.email.toLowerCase().includes("hotmail")) {
      return false
    } else if (quotation_details.email.length > 0 && utils.validEmail(quotation_details.email)) {
      return true
    } else {
      return false
    }
  }

  renderPanelOneNextButton() {

    let can_continue = true

    const { billing_contact } = this.state

    if (!billing_contact.first_name || 
      !billing_contact.last_name || 
      !utils.validEmail(billing_contact.email) || 
      !utils.validContact(billing_contact.mobile_number) || 
      !billing_contact.company_name
      ) {
      can_continue = false
    }
  
    if (can_continue) {
      return <NextButton onClick={() => this.goToPanel2()} style={{ marginTop: 20, float: 'right', textTransform: 'none' }}>Next</NextButton>
    } else {
      return <Button disabled onClick={() => this.goToPanel2()} variant='contained' style={{ marginTop: 20, float: 'right', textTransform: 'none', cursor: 'not-allowed', pointerEvents: 'all', padding: '10px', paddingLeft: '20px', paddingRight: '20px', border: 'none' }}>Next</Button>
    }
  
  }

  renderContinueButton() {
    let can_continue = false

    const {
      payment_method,
      billing_contact,
      card_payer_details,
      invoicing_details,
      quotation_details,
      stripe_payment_method
    } = this.state

    // billing_contact is the stuff in panel 1
    // card_payer_details is the stuff for 'credit card payment method'
    // invoicing_details is the stuff for 'request invoice payment method'

    if (
      billing_contact.first_name && 
      billing_contact.last_name && 
      utils.validEmail(billing_contact.email) && 
      utils.validContact(billing_contact.mobile_number) && 
      billing_contact.company_name
      ) {
        if (payment_method === CREDIT_DEBIT_CARD_METHOD) {
          const {
            first_name,
            last_name,
            address,
            city,
            postal_code,
            country
          } = card_payer_details

          if (
            first_name &&
            last_name &&
            address &&
            city &&
            postal_code &&
            country &&
            stripe_payment_method &&
            stripe_payment_method.id
          ) {
            can_continue = true
          }
        }
        
        if (payment_method === REQUEST_QUOTATION_OR_INVOICE_METHOD && !this.state.toggle_quotation_instructions) {
          const {
            recipient_name,
            email,
            company_name,
            uen,
            address,
            city,
            postal_code,
            country
          } = invoicing_details
          
          if (
            recipient_name &&
            email &&
            company_name &&
            uen &&
            address &&
            city &&
            postal_code &&
            country &&
            utils.validEmail(email)
          ) {
            can_continue = true
          }
        }

        if (payment_method === REQUEST_QUOTATION_OR_INVOICE_METHOD && this.state.toggle_quotation_instructions) {
          const {
            recipient_name,
            email,
            company_name,
            uen,
            address,
            city,
            postal_code,
            country
          } = invoicing_details

          if (
            recipient_name &&
            email &&
            company_name &&
            uen &&
            address &&
            city &&
            postal_code &&
            country &&
            utils.validEmail(email) &&

            quotation_details.recipient_name &&
            quotation_details.email &&
            quotation_details.company_name &&
            quotation_details.uen &&
            quotation_details.address &&
            quotation_details.city &&
            quotation_details.postal_code &&
            quotation_details.country &&
            utils.validEmail(quotation_details.email)
          ) {
            can_continue = true
          }
        }

        if (payment_method === BANK_TRANSFER_METHOD || payment_method === PAYNOW_METHOD) {
          can_continue = true
        }
    }

    if (can_continue) {
      return (
        <NextButton
          onClick={() => this.onNextClick()}
          style={{ float: 'right', textTransform: 'none', }}>
          Continue
        </NextButton>
      )
    } else {
      return (
        <Button
          disabled
          variant='contained'
          style={{ float: 'right', textTransform: 'none', cursor: 'not-allowed', pointerEvents: 'all', padding: '10px', paddingLeft: '20px', paddingRight: '20px', border: 'none' }}>
          Continue
        </Button>
      )
    }
  }
}


const StripeConnectedStepFourPayment = (redux_store_props) => {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {(stripe_props) => (
          <StepFourPayment {...stripe_props} {...redux_store_props} />
        )}
      </ElementsConsumer>
    </Elements>
  )
}

const mapStateToProps = state => {
  // console.log('STEP FOUR MAPSTATETOPROPS: ', state.error)
  return {
    error: state.error,
    billing_contact: state.enrol.billing_contact,
    enrol_details: state.enrol,
    payment_expanded_panel: state.enrol.payment_expanded_panel
  }
}

const mapDispatchToProps = { 
  goToNextStep, 
  goToPreviousStep, 
  addEnrolmentPaymentDetails, 
  changePaymentExpandedPanel 
}

export default connect(mapStateToProps, mapDispatchToProps)(StripeConnectedStepFourPayment)


const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion)

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: '#EBEEF0',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    fontSize: '20px',
    color: '#373649',
    fontWeight: '500',
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(AccordionSummary)

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(AccordionDetails)

const styles = {
  numberCircle: {
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    padding: '4px',
    background: '#1E90FF',
    color: 'white',
    textAlign: 'center',
    fontSize: '12px',
    marginRight: '10px',
    marginTop: '3px'
  },
  stripeElementLabel: {
    fontSize: '14px', 
    color: '#373649',
    fontWeight: 500
  },
  // Style of the container around stripe element
  // ie. border, padding
  stripeElementContainer: {
    border: '1px solid #DDE0E6',
    borderRadius: '4px',
    paddingLeft: '12px',
    paddingRight: '12px',
    paddingTop: '8px',
    paddingBottom: '8px',

    maxWidth: '30%'
  },
  // Style of the text within the stripe element
  stripeElementInput: {
    base: { fontSize: '14px' },
    invalid: { color: '#fa755a' }
  }
}

const BackButton = withStyles({
  root: {
    backgroundColor: '#1E90FF ',
    color: 'white',
    borderRadius: '4px !important', 
    '&$disabled': {
      border: '1px solid rgb(170, 170, 170)',
      color: 'rgb(170, 170, 170) !important',
      backgroundColor: 'white !important'
    },
    '&:hover': {
      backgroundColor: '#3d94f6 !important',
      color: 'white !important',
    }
  }
})(MuiButton)

const NextButton = withStyles({
  root: {
    backgroundColor: '#1E90FF ',
    color: 'white',
    borderRadius: '4px !important', 
    '&$disabled': {
      border: '1px solid rgb(170, 170, 170)',
      color: 'rgb(170, 170, 170) !important',
      backgroundColor: 'white !important'
    },
    '&:hover': {
      backgroundColor: '#3d94f6 !important',
      color: 'white !important',
    }
  }
})(MuiButton)