import {
  Box,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  panel: {
    padding: theme.spacing(1, 2),
    border: '1px solid ' + theme.palette.primary.main,
    minWidth: '160px',
    backgroundColor: theme.palette.common.white,
  },
})

function StatPanel({ classes, overtext, maintext }) {
  return (
    <Box className={classes.panel}>
      { overtext && <Typography variant='overline'>{overtext}</Typography> }
      { maintext && <Typography variant='h4'>{maintext}</Typography> }
    </Box>
  )
}

export default withStyles(styles)(StatPanel)
