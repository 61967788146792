// import axios from 'axios'

// import utils from '../utils'

// const UATO_API_URL    = process.env.REACT_APP_UATO_API_URL
// const FLIGHTS_API_URL = process.env.REACT_APP_FLIGHTS_API_URL

export const getFlightsByPilotUserId = async (userId) => {

  // 2023-03-22: Disabling this for now until it is necessary to pull out
  //             such data in the future
  // const url = `${FLIGHTS_API_URL}/flights?pilot_id=${userId}`
  // const options = utils.getJWTHeaders()

  // const res = await axios.get(url, options)

  // console.log('getFlightsByPilotUserId: ', res.data)

  // return res.data
  return {
    success: false,
    error: 'Feature is deprecated',
  }
}

export const getFlightsByStudentId = async (studentId) => {

  // 2023-03-22: Disabling this for now until it is necessary to pull out
  //             such data in the future
  // const options = utils.getJWTHeaders()

  // Misnomer, actually searches name, email, and student ID
  // const res1 = await axios.get(`${UATO_API_URL}/search-trainee/${studentId}/skip/0`, options)
  // const trainees = res1.data

  // if (!trainees || trainees.length < 1)
  //   return []

  // For now, return the first one (in case there are duplicate Student IDs)
  // const userId = trainees[0].plex?.user_id
  // if (!userId)
  //   return []

  // const res2 = await axios.get(`${FLIGHTS_API_URL}/flights?pilot_id=${userId}`, options)
  // console.log('getFlightsByStudentId: ', res2.data)

  // return res2.data
  return {
    success: false,
    error: 'Feature is deprecated',
  }
}


export const getTraineeFlightsByInstructorPersonnelId = async (personnelId) => {

  // HOW TO DO THIS !!
  return {
    success: false,
    error: 'Not yet implemented. Need to join personnel Id, User Id, Flights',
  }
}
