import React from 'react'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import EditCreditViewer from './EditCreditViewer.js'
import api_interface from '../../api_interface'
import utils from '../../utils'

const pseudo_button_style = {
  background: 'transparent',
  border: '2px solid #1E90FF',
  color: '#1E90FF',
  borderRadius: '100px',
  padding: '3px 30px',
  fontWeight: 500,
  cursor: 'pointer',
  display: 'inline-block'
}

class CreditManagerViewer extends React.Component {
  state = {
    search_trainee: '',
    trainees: null,
    recent_trainees: null,
    selected_trainee: null,
    edit_credit: null,
    next_active: false,
    add_credit_dialog: false,

    selected_add_credit_trainee: null,
    trainee_orders: [],
    trainee_courses: [],
    new_credit_order: null,
    new_credit_course: null,
    new_session_number: 1
  }

  async componentDidMount() {
    const trainee_res_data = await api_interface.getLatestTraineeCredits()
    const trainees = trainee_res_data.trainees

    // console.log('trainees', trainees)

    this.setState({ trainees, recent_trainees: trainees })
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.add_credit_dialog !== prevState.add_credit_dialog && this.state.add_credit_dialog) {
      const order_res_data = await api_interface.getOrdersByTrainee(this.state.selected_add_credit_trainee._id)
      const trainee_orders = order_res_data.orders
      this.setState({ trainee_orders })
    }
    if (this.state.new_credit_order !== prevState.new_credit_order && this.state.new_credit_order !== 'default') {
      const courses_res_data = await api_interface.getCoursesByCatalogOrder(this.state.new_credit_order)
      const trainee_courses = courses_res_data.courses
      this.setState({ trainee_courses })
    }
  }

  mapTraineeCredits = (trainee_info) => {
    return trainee_info.trainee_credits.map((credit, index) => {
      return (
        <div key={index} style={{ display: 'flex', width: '100%', margin: '20px 0', alignItems: 'center' }}>
          <div style={{ flexBasis: '40%' }} >
            { `${credit.course.name} - ${credit.session_number}` }<br />
          </div>
          <div style={{ flexBasis: '30%' }} >{ credit.course_schedule ? utils.formatTime(credit.course_schedule.start_time) : 'n/a' }</div>
          <div style={{ flexBasis: '15%' }} >{ credit.status }</div>
          <div style={{ flexBasis: '15%', textAlign: 'right' }} ><div style={ pseudo_button_style } onClick={ (e) => this.handleEditCredit(e, trainee_info, credit) } >Edit</div></div>
        </div>
      )
    })
  }

  renderAddDialog = () => {
    const { add_credit_dialog, new_session_number, new_credit_course, new_credit_order, selected_add_credit_trainee, trainee_orders, trainee_courses } = this.state
    return (
      <Dialog open={ add_credit_dialog }
        onClose={() => this.setState({ add_credit_dialog: false })}
        style={{ width: '100%', margin: '0 auto' }}
      >
        <DialogTitle>Add Credit</DialogTitle>
        <DialogContent>
          <div style={{ minWidth: '500px' }}>
            <div style={{ margin: '10px 0' }}><strong>Trainee: { selected_add_credit_trainee ? `${selected_add_credit_trainee.first_name} ${selected_add_credit_trainee.last_name}` : '' }</strong></div>
            <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }} >Order:
              <Select style={{ minWidth: 0, display: 'inline-block', margin: '0 10px' }} value={ new_credit_order } onChange={e => this.handleChange(e, 'new_credit_order')}  >
                <MenuItem value='default' ></MenuItem>
                { trainee_orders.map( (order, index) => {
                  return (
                    <MenuItem key={index} value={order._id} >{utils.formatTime(order.ordered_on)} - {utils.format24HrTime(order.ordered_on)} </MenuItem>
                  )                
                } ) }
              </Select>
            </div>
            {
              trainee_courses && trainee_courses.length > 0 &&
              <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>Course:
                <Select style={{ minWidth: 0, display: 'inline-block', margin: '0 10px' }} value={ new_credit_course } onChange={e => this.handleChange(e, 'new_credit_course')} >
                  { trainee_courses.map( (course, index) => {
                    return (
                      <MenuItem value={course._id} key={index} >{course.name}</MenuItem>
                    )
                  } ) }
                </Select>
              </div>
            }
            <div style={{ margin: '10px 0' }} >Session Number: <input type="number" style={{ padding: '12px', maxWidth: '50px' }} value={new_session_number} onChange={(e) => this.setState({ new_session_number: e.target.value})} /></div>
          </div>
        </DialogContent>
        <DialogActions style={{ borderTop: '1px solid #e0e0e0' }}>
          <Button onClick={() => this.setState({ add_credit_dialog: false })} style={{ backgroundColor: 'white', color: '#1E90FF', textTransform: 'none' }}>
            <strong>Cancel</strong>
          </Button>
          <Button disabled={ !(selected_add_credit_trainee && new_credit_course && new_credit_order && new_session_number) } onClick={(e) => this.handleAddCredit(e)} style={{ textTransform: 'none' }}>
            <strong>Submit</strong>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderNull = () => {
    return (
      <div style={{ textAlign: 'center', width: '100%', padding: '2em' }} >
        <br/><br/><br/><br/><br />
        <CircularProgress />
      </div>
    )
  }

  handleSearch = async (e) => {
    e.preventDefault()
    if (this.state.search_trainee.length > 2) {
      this.setState({ trainees: null })
      const trainee_res_data = await api_interface.searchTraineeByNameOrEmail(this.state.search_trainee)
      const trainees = trainee_res_data.trainees

      console.log('trainees', trainees)

      this.setState({ trainees })
    }
  }

  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleBlurSearch = (e) => {
    if (e.target.value.length < 3) {
      this.setState({
        trainees: this.state.recent_trainees
      })
    }
  }

  handleNextTrainees = async (e) => {
    e.preventDefault()
    this.setState({ next_active: true })
    const trainee_res_data = this.state.search_trainee.length > 2 ? await api_interface.searchTraineeByNameOrEmail(this.state.search_trainee, this.state.trainees.length) : await api_interface.getLatestTraineeCredits(this.state.trainees.length)
    const next_trainees = trainee_res_data.trainees

    this.setState({ next_active: false, trainees: [ ...this.state.trainees, ...next_trainees ], recent_trainees: this.state.search_trainee.length > 2 ? this.state.recent_trainees : [ ...this.state.recent_trainees, ...next_trainees ] })
  }

  handleEditCredit = (e, trainee, credit) => {
    e.preventDefault()
    this.setState({
      edit_credit: credit,
      selected_trainee: trainee
    })
  }

  handleBack = async () => {
    this.setState({ trainees: null })
    const trainee_res_data = await api_interface.getLatestTraineeCredits()
    const trainees = trainee_res_data.trainees
    this.setState({
      trainees,
      search_trainee: '',
      edit_credit: null,
      selected_trainee: null
    })
  }

  handleAddCredit = async (e) => {
    const payload = {
      trainee: this.state.selected_add_credit_trainee._id,
      course: this.state.new_credit_course,
      order: this.state.new_credit_order,
      session_number: this.state.new_session_number
    }
    const create_new_credit = await api_interface.addCredit(payload)
    if (create_new_credit.success) {
      this.setState({
        add_credit_dialog: false
      })
      this.handleBack()
    } else {
      alert('Error creating new credit')
    }
  }

  handleDialogCredit = (e, trainee) => {
    this.setState({
      add_credit_dialog: true,
      selected_add_credit_trainee: trainee
    })
  }

  render() {

    const { trainees, selected_trainee, next_active, edit_credit } = this.state
    
    if (trainees === null) {
      return this.renderNull()
    }

    return(
      <div style={{ margin: '120px 0 30px', textAlign: 'center' }} >
        <EditCreditViewer trainee={ selected_trainee } edit_credit={ edit_credit } handleBack={ this.handleBack } formatTime={ utils.formatTime } pseudo_button_style={ pseudo_button_style } />
        {
          !(edit_credit && selected_trainee) &&
          <div>
            <h2>Credit Manager</h2>
            <div style={{ padding: '20px 0' }} >
              <form onSubmit={(e) => this.handleSearch(e)} >
                <TextField
                  style={{ margin: 0, width: '80%', marginRight: '5px' }}
                  id="standard-full-width"
                  placeholder="Search Trainee Here (Name, Email, or Student ID)"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name='trainee-search'
                  value={this.state.search_trainee}
                  onChange={(event) => this.handleChange(event, 'search_trainee')}
                  onBlur={(event) => this.handleBlurSearch(event)}
                />
                <Button type="submit" style={{ padding: '8px 20px' }}>Search</Button>
              </form>
            </div>
            <Divider />
            {
              trainees.length === 0 ?
              <h1 style={{ fontStyle: 'italic', fontWeight: '300', color: '#757575' }} >Not found</h1>
              :
              <div style={{ padding: '20px 0', lineHeight: '1.618' }} >
                <Grid>
                  <Grid item style={{ minWidth: '100%', marginBottom: '3em' }}>
                    { trainees.map( (trainee, i) => {
                      return (
                        <Paper key={i} style={{ textAlign: 'left', margin: '20px 0', padding: '20px' }} >
                          <div style={{ display: 'flex' }} >
                            <div style={{ flexBasis: '80%' }} >
                              <div><strong>Name</strong>: { `${ trainee.first_name } ${ trainee.last_name }` }</div>
                              <div style={{ marginBottom: '20px' }} ><strong>Email</strong>: { trainee.email }</div>
                            </div>
                            <div style={{ flexBasis: '20%', textAlign: 'right' }} >
                              <Button onClick={ (e) => this.handleDialogCredit(e, trainee) } >+Credit</Button>
                            </div>
                          </div>
                          <Divider />
                          { this.mapTraineeCredits(trainee) }
                        </Paper>
                      )
                    } ) }
                  </Grid>
                </Grid>
              </div>
            }
            { next_active && <div style={{ marginTop: '-150px' }} >{this.renderNull()}</div> }
            { trainees.length > 0 && this.state.trainees.length%10 === 0 && !next_active && <div style={ pseudo_button_style } onClick={ (e) => this.handleNextTrainees(e) } >Next</div> }
            { this.renderAddDialog() }
          </div>
        }
      </div>
    )
  }
}

export default CreditManagerViewer


