export const goToNextStep = () => dispatch => {
  dispatch({ type: 'NEXT_STEP' })
}

export const goToPreviousStep = () => dispatch => {
  dispatch({ type: 'PREVIOUS_STEP' })
}

export const addOneTrainee = () => dispatch => {
  dispatch({ type: 'ADD_ONE_TRAINEE' })
}

export const removeOneTrainee = () => dispatch => {
  dispatch({ type: 'REMOVE_ONE_TRAINEE' })
}

export const goToStep = (step) => dispatch => {
  dispatch({ 
    type: 'GO_TO_STEP',
    payload: step
  })
}

export const changePaymentExpandedPanel = (panel) => dispatch => {
  dispatch({
    type: 'CHANGE_PAYMENT_EXPANDED_PANEL',
    payload: panel
  })
}

export const handleSelectCatalogItem = (catalog_item) => dispatch => {
  // console.log('action catalog item is ', catalog_item)
  dispatch({ type: 'SELECTED_CATALOG_ITEM', payload: catalog_item })
}

export const handleAllTraineesInfo = (all_trainees_info) => dispatch => {
  // console.log('action all_trainees_info is: ', all_trainees_info)
  dispatch({ type: 'ALL_TRAINEES_INFO', payload: all_trainees_info })
}

export const setBillingContact = (billing_contact) => dispatch => {
  dispatch({ type: 'SET_BILLING_CONTACT_INFO', payload: billing_contact })
}

export const addEnrolmentPaymentDetails = (payment_details) => dispatch => {
  dispatch({
    type: 'ADD_ENROLMENT_PAYMENT_DETAILS',
    payload: payment_details
  })
}

export const addDiscount = (coupon_code) => dispatch => {
  dispatch({
    type: 'ADD_COUPON_CODE',
    payload: coupon_code
  })
}

export const togglePlaceOrderCheckboxFirst = () => dispatch => {
  dispatch({ type: 'PLACE_ORDER_CHECKBOX_FIRST' })
}

export const togglePlaceOrderCheckboxSecond = () => dispatch => {
  dispatch({ type: 'PLACE_ORDER_CHECKBOX_SECOND' })
}

export const setBillingContactIndexCheckbox = (index) => dispatch => {
  dispatch({ type: 'SET_BILLING_CONTACT_INDEX_CHECKBOX', payload: index })
}

export const clearEnrolReducer = () => dispatch => {
  dispatch({ type: 'CLEAR_ENROL_REDUCER' })
}
