import React from 'react'

import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '100px 0',
    maxWidth: theme.breakpoints.values.md,
    margin: '0 auto'
  }
})

const SUPPORT_MAIL_CONTACT = process.env.REACT_APP_SUPPORT_MAIL_CONTACT
if (!SUPPORT_MAIL_CONTACT)
  throw Error('Environment Variable REACT_APP_SUPPORT_MAIL_CONTACT is required')

function ServerError({ classes }) {

  return (
    <div className={classes.root}>
      <div style={{ flexBasis: '100%', height: '100%', lineHeight: '1.618' }}>
        <Typography variant='h3'>We're Sorry</Typography>
        <br />
        <Typography variant='body1'>
          The server has encountered an error and is unable to complete your request. Please
          try again later. If the problem persists, report this error to
          our customer service via <a href={`mailto:${SUPPORT_MAIL_CONTACT}`}>email</a>, 
          stating this error message and the actions that caused it.
        </Typography>
        <br />
        <img src={process.env.PUBLIC_URL + '/ccpix-builtin.com.jpg'} alt='Delivery Drones' />
      </div>
    </div>
  )
}

export default withStyles(styles)(ServerError)
