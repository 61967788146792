import axios from 'axios'

import utils from '../utils'

const API_URL = process.env.REACT_APP_UATO_API_URL

export const topupCredits = async (topup) => {
  try {
    const options = utils.getJWTHeaders()
    const res = await axios.post(`${API_URL}/topup`, topup, options)
    // console.log('res.data is: ', res.data)
    return res.data
  } catch (e) {
    console.error('Error topup credits:', e)
    return e.response.data
  }
}

export const getTopupOrder = async (trainee_email) => {
  try {
    const options = utils.getJWTHeaders()
    const res = await axios.get(`${API_URL}/get-topup-by-email/${trainee_email}`, options)
    // console.log('res.data: ', res.data)
    return res.data.topup_order
  } catch (e) {
    console.error('Error get topup order:', e)
    return e.response.data
  }
}

export const getTopupOrders = async (query) => {
  const options = utils.getJWTHeaders()

  const res = await axios.get(`${API_URL}/topup-orders?${ query }`, options)
  return res.data
}

export const putTopupStatus = async (topup, status) => {
  // console.log(`Change ${topup._id} to ${status}`)
  const url = API_URL + `/topup/${topup._id}/status`
  const options = utils.getJWTHeaders()

  const res = await axios.put(url, { status }, options)

  return res.data
}

export const putTopupAdditionalNotes = async (order_id, additional_notes) => {
  const url = API_URL + `/topup/${order_id}/additional_notes`
  const options = utils.getJWTHeaders()

  const res = await axios.put(url, { additional_notes }, options)

  // console.log('res.data is: ', res.data)
  return res.data
}

