import ReactGA from 'react-ga'
import utils from '../utils/index'

const INITIAL_STATE = {
  step: 0,
  selected_catalog_item: {
    name: '', 
    description: '',
    sku: '',
    type: '',
    catalog_price: {
      currency: 'SGD',
      amount: 0,
    },
    product_ids: '',
    deleted_on: ''
  },

  trainees: [{
    first_name: '',
    last_name: '',
    email: '',
    mobile_number: '',
  }],

  payment_method: '',

  billing_contact: {
    first_name: '',
    last_name: '',
    email: '',
    mobile_number: '',
    company_name: ''
  },
  card_payer_details: {
    first_name: '',
    last_name: '',
    address: '',
    city: '',
    postal_code: '',
    country: ''
  },
  invoicing_details: {
    recipient_name: '',
    email: '',
    company_name: '',
    uen: '',
    address: '',
    city: '',
    postal_code: '',
    country: ''
  },
  quotation_details: {
    recipient_name: '',
    email: '',
    company_name: '',
    uen: '',
    address: '',
    city: '',
    postal_code: '',
    country: ''
  },

  place_order_checkbox_first_confirmation: false,

  place_order_checkbox_second_confirmation: false,

  set_billing_contact_index_checkbox: -1,

  coupon: '',

  payment_expanded_panel: 'panel1'
}

const EnrolReducer = (state = INITIAL_STATE, action) => {
  let action_status
  switch (action.type) {
    case 'NEXT_STEP':
      action_status = utils.enrolAction({ action: 'continue', prev: state.step, next: state.step + 1 })
      ReactGA.event({
        category: 'enrol-step',
        action: action_status
      })
      return { ...state, step: state.step + 1 }

    case 'PREVIOUS_STEP':
      action_status = utils.enrolAction({ action: 'back', prev: state.step, next: state.step - 1 })
      ReactGA.event({
        category: 'enrol-step',
        action: action_status
      })
      return { ...state, step: state.step - 1 }

    case 'GO_TO_STEP':
      action_status = utils.enrolAction({ action: 'jump', prev: state.step, next: action.payload })
      ReactGA.event({
        category: 'enrol-step',
        action: action_status
      })
      return { ...state, step: action.payload }

    case 'ADD_ONE_TRAINEE':
      return { 
        ...state, 
        trainees: [ 
          ...state.trainees, 
          {
            first_name: '',
            last_name: '',
            email: '',
            mobile_number: '',
          } 
        ]
      }

    case 'REMOVE_ONE_TRAINEE':
      return { ...state, trainees: state.trainees.filter(( _ , idx ) => idx !== state.trainees.length - 1)}

    case 'SELECTED_CATALOG_ITEM':
      action_status = utils.enrolAction({ action: 'catalog', prev: state.step, next: action.payload })
      ReactGA.event({
        category: 'enrol-form',
        action: action_status
      })
      return { ...state, selected_catalog_item: action.payload }

    case 'ALL_TRAINEES_INFO':
      return { ...state, trainees: action.payload }

    case 'SET_BILLING_CONTACT_INFO':
      action_status = utils.enrolAction({ action: 'billing', prev: state.step, next: action.payload })
      ReactGA.event({
        category: 'enrol-form',
        action: action_status
      })
      return { ...state, billing_contact: action.payload }

    case 'ADD_ENROLMENT_PAYMENT_DETAILS':
      action_status = utils.enrolAction({ action: 'billing' })
      ReactGA.event({
        category: 'enrol-form',
        action: action_status
      })
      return { ...state, ...action.payload }

    case 'ADD_COUPON_CODE':
      action_status = utils.enrolAction({ action: 'coupon' })
      ReactGA.event({
        category: 'enrol-form',
        action: action_status
      })
      return { ...state, coupon: action.payload }

    case 'PLACE_ORDER_CHECKBOX_FIRST':
      return { ...state, place_order_checkbox_first_confirmation: !state.place_order_checkbox_first_confirmation }

    case 'PLACE_ORDER_CHECKBOX_SECOND':
      return { ...state, place_order_checkbox_second_confirmation: !state.place_order_checkbox_second_confirmation }

    case 'SET_BILLING_CONTACT_INDEX_CHECKBOX':
      return { ...state, set_billing_contact_index_checkbox: action.payload }

    case 'CHANGE_PAYMENT_EXPANDED_PANEL': 
      return { ...state, payment_expanded_panel: action.payload }

    case 'CLEAR_ENROL_REDUCER':
      return INITIAL_STATE

    default:
      return state
  }
}

export default EnrolReducer
  