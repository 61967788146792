import axios from 'axios'

import utils from '../utils'

const API_URL = process.env.REACT_APP_UATO_API_URL


export const getEntireCourseSchedule = async () => {
  const options = utils.getJWTHeaders()
  
  const res = await axios.get(API_URL + '/schedule', options)
  
  return res.data
}

export const getEntireEnrolledSchedules = async (query) => {
  const options = utils.getJWTHeaders()
  const res = await axios.get(`${API_URL}/enrolled_schedule?${query}`, options)
  return res.data
}

export const getMonthlyEnrolledSchedules = async (query) => {
  const options = utils.getJWTHeaders()
  const res = await axios.get(`${API_URL}/monthly-schedule${query || ''}`, options)
  return res.data
}

export const getCourseScheduleForTrainee = async (trainee_id) => {
  const url = `${API_URL}/trainee/${trainee_id}/schedule`
  const options = utils.getJWTHeaders()

  const res = await axios.get(url, options)

  return res.data
}

export const getScheduledCourseTrainees = async (schedule_id) => {
  const url = `${API_URL}/schedule/${schedule_id}/trainees`
  const options = utils.getJWTHeaders()
  
  const res = await axios.get(url, options)

  return res.data
}

export const getSchedulesForCourse = async (course_id) => {
  const url = `${API_URL}/schedule/${course_id}/course`
  const options = utils.getJWTHeaders()
  
  const res = await axios.get(url, options)

  return res.data
}

export const getCourseScheduleManager = async (skip, start_at, end_by) => {
  const url = `${API_URL}/schedule/${skip}/start-at/${start_at}/end-by/${end_by}`
  const options = utils.getJWTHeaders()
  
  const res = await axios.get(url, options)

  return res.data
}

export const getFullCourses = async () => {
  const url = `${API_URL}/courses-by-schedule`
  const options = utils.getJWTHeaders()
  
  const res = await axios.get(url, options)

  return res.data
}

export const addSchedule = async (payload) => {
  if (!payload.course)
    return { success: false, message: 'Cannot add empty course id' }

  const url = `${API_URL}/schedule`
  const options = utils.getJWTHeaders()

  const res = await axios.post(url, payload, options)

  // console.log('res.data: ', res.data)

  return res.data
}

export const updateSchedule = async (payload) => {
  if (!payload.schedule_id)
    return { success: false, message: 'Cannot update empty schedule id' }
  
  const url = `${API_URL}/schedule/${payload.schedule_id}`
  const options = utils.getJWTHeaders()

  const res = await axios.put(url, payload, options)

  // console.log('res.data: ', res.data)

  return res.data
}

export const customReminder = async (credit) => {
  const url = `${API_URL}/resend-reminder-schedule`
  const options = utils.getJWTHeaders()

  const res = await axios.post(url, {credit}, options)

  // console.log('res.data: ', res.data)

  return res.data 
}

export const customNotification = async (credit) => {
  const url = `${API_URL}/resend-notification-schedule`
  const options = utils.getJWTHeaders()

  const res = await axios.post(url, {credit}, options)

  // console.log('res.data: ', res.data)

  return res.data 
}

