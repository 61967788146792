const TraineeReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_TRAINEE_SUCCESS':
      return { ...state, ...action.payload }

    case 'GET_TRAINEE_FAIL':
      return { error: action.payload }

    case 'UPDATE_TRAINEE_PLEX_DETAILS': 
      return { ...state, ...action.payload }

    case 'UPDATE_TRAINEE_ADDITIONAL_DETAILS_SUCCESS':
      return { ...state, ...action.payload }

    default:
      return state
   }
}

export default TraineeReducer