import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { OidcProvider, processSilentRenew } from 'redux-oidc'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store'
import userManager from './utils/userManager'

import './index.css'
import App from './App'

if (window.location.pathname === '/silent-renew') {
	processSilentRenew()
} else {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <OidcProvider store={store} userManager={userManager}>
          <App />
        </OidcProvider>
      </PersistGate>
    </Provider>
    , 
    document.getElementById('root')
  )
}