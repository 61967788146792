import React from 'react'
import { connect } from 'react-redux'
import { SignoutCallbackComponent } from 'redux-oidc'
// import { push } from 'connected-react-router'

// import { setAuthUser } from '../actions'
import userManager from '../utils/userManager'
import { setAuthUser, removeAuthUser } from '../actions'

const app_client_id = process.env.REACT_APP_OIDC_CLIENT_ID

class LogoutCallback extends React.Component {

  successCallback = async () => {
    // console.log('success callback')
    const query_params = this.props.location.search
    const urlParams = new URLSearchParams(query_params)
    const url_client_id = urlParams.get('client_id')
    
    const plex_user = localStorage.getItem('plex_user')

    if (url_client_id === app_client_id && plex_user) {
      // console.log('still logged in')
      await this.props.setAuthUser(JSON.parse(plex_user))
      userManager.signinRedirect()
    } else {
      if (this.props.auth && this.props.oidc && this.props.oidc.user) {
        userManager.signinRedirect()
      } else {
        // console.log('remove auth storage')
        localStorage.removeItem('plex_user')
        await this.props.removeAuthUser()
        this.props.history.push('/enrol')
      }
    }
  }
  
  render() {
    // just redirect to '/' in both cases
    return (
      <SignoutCallbackComponent
        userManager={userManager}
        successCallback={this.successCallback}
        errorCallback={() => { console.error('Signout Error') }}
      >
          <div><br /><br /><br /><br /><br />Redirecting...</div>
      </SignoutCallbackComponent>
    )
  }
}

const mapStateToProps = state => {
  return {
    oidc_state: state.oidc_state,
    oidc: state.oidc,
    auth: state.auth
  }
}

export default connect(mapStateToProps, { setAuthUser, removeAuthUser })(LogoutCallback)
