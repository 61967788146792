import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Snackbar from '@material-ui/core/Snackbar'
import { styled } from '@material-ui/core/styles'

import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import utils from '../utils'
import api_interface from '../api_interface'

const failed_status = 'Failed'
const top_up_status = [ 'Conditional Failed', failed_status ]
const pass_and_enrol = [ 'Passed', 'Conditional Passed', 'Enrolled' ]
// const used_credit_status = [ ...top_up_status, ...pass_and_enrol ]
const available_credit_status = [ 'Available', 'Cancelled' ]
const non_top_up_practical_status = [ ...pass_and_enrol, ...available_credit_status ]
// const practical_session_name = [
//   {name: 'Practical Session'},
//   {name: 'Mock Evaluation'}
// ]

const SubmitTopupButton = styled(Button)({    
  float: 'right',
  background: '#fff',
  color: '#1E90FF !important',
  border: '1px solid #1E90FF',
  textTransform: 'none',
  padding: '5px 20px',
  '&:disabled': {
    color: '#757575 !important'
  },
  '&:hover': {
    background: '#fff',
    color: '#1E90FF !important'
  },
  
})

class TopupCreditSection extends Component {
  state = {
    coupon_code: '',
    coupon_error: false,
    applied_coupon: null,
    open_snackbar: false,
    topup_credit_balance: [],
    available_credits: [],
    topup_form_active: false
  }

  // async componentDidMount() {
  //   const { auth_object } = this.props
  //   console.log('auth_object', auth_object)
  //   const trainee_credit_status = await api_interface.getTraineeCreditStatus(this.props.auth_object._id)
  //   console.log('trainee_credit_status', trainee_credit_status)
  // }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.credits !== prevProps.credits) {

      // Available credits are:
      // - credit_status is inside available_credit_status Array.
      // - credit.hold === false
      // - credit.session_number <= total sessions on course (refer to the credits registered on course/product DB). IMPORTANT, this part enables to calculate credits properly based on initial condition (check the initial courses for credits on catalog db).
      const available_credits = this.props.credits.length > 0 ? this.props.credits.filter((credit) => available_credit_status.indexOf(credit.status) > -1 && !credit.hold && credit.session_number <= credit.course.number_of_sessions ) : []

      // const topup_credit_with_available = this.mapTopupCreditBalance(this.props.credits)
      // console.log('topup_credit_with_available', topup_credit_with_available)

      // const all_topup_credits = this.props.credits.length > 0 ? available_credits.length <= topup_credit_with_available.length ? topup_credit_with_available.concat(this.props.credits.filter(c => c.hold)) : topup_credit_with_available : []

      const failed_credits = this.props.credits.filter( c => c.status === failed_status )
      let additional_credits_needed = []
      
      if (this.props.auth) {
        for (let i=failed_credits.length-1; i>-1; i--) {
          const course_on_catalog = this.props.auth.active_catalog.product_ids.filter( ac => ac === failed_credits[i].course_id )
          const course_success_and_open = this.props.auth.credits.filter(acredit => acredit.course_id === failed_credits[i].course_id && non_top_up_practical_status.indexOf(acredit.status) > -1)
          const added_credit_amount = course_on_catalog.length - course_success_and_open.length
          if (course_success_and_open.length < course_on_catalog.length && (added_credit_amount - i > 0)) {
            additional_credits_needed.push(failed_credits[i])
          }
        }
      }
      
      this.setState({
        topup_credit_balance: additional_credits_needed,
        topup_form_active: additional_credits_needed.length > 0,
        available_credits
      })
    }
  }

  mapTopupCreditBalance(all_credits) {
    let topup_credit_balance = []
    let temp_enrolled_credit_map = []
    for (const credit of all_credits ) {
      const number_of_sessions = credit.course.number_of_sessions
      let course_credit_update_count = 0

      const temp_enrolled_index = temp_enrolled_credit_map.length > 0 ? temp_enrolled_credit_map.findIndex(tecm => tecm.course_id === credit.course_id) : -1

      if (top_up_status.indexOf(credit.status) > -1) {
        if(temp_enrolled_index > -1) {
          temp_enrolled_credit_map[temp_enrolled_index].count = temp_enrolled_credit_map[temp_enrolled_index].count+1
          course_credit_update_count = temp_enrolled_credit_map[temp_enrolled_index].count
        } else {
          temp_enrolled_credit_map.push({ course_id: credit.course_id, count: 1 })
        }
      }

      const check_course_available_sessions = all_credits.filter( ac => ac.course_id === credit.course_id && top_up_status.indexOf(ac.status) < 0 ).length
      const remaining_course_topup = number_of_sessions - check_course_available_sessions
      
      // add top up credit balance if:
      // 1. calculated credits for top up (remaining_course_topup) > 0
      // 2. credit update <= calculated credits for top up (ensure that updated credits is lower than or equal to calculated top-up credits). Important to prevent extra credits in case of multiple failed credit results.
      // 3. credit status (from current iteration) is top-up 
      if (remaining_course_topup > 0 && course_credit_update_count <= remaining_course_topup && top_up_status.indexOf(credit.status) > -1) {
        topup_credit_balance.push(credit)
      }
    }
    return topup_credit_balance
  }

  formatCreditBalance = (credits) => {
    let new_credit_balance = []
    for (const credit of credits) {
      let copy_credit = {
        catalog_item: credit.catalog_item,
        course: credit.course,
        course_id: credit.course_id,
        order_id: credit.order_id,
        trainee: credit.trainee,
        trainee_id: credit.trainee_id,
        count: 1
      }

      const index_credit_balance = new_credit_balance.findIndex(nbc => nbc.course_id === credit.course_id)
      if (new_credit_balance.length > 0 && index_credit_balance > -1) {
        new_credit_balance[index_credit_balance].count = new_credit_balance[index_credit_balance].count + 1
      } else {
        new_credit_balance.push(copy_credit)
      }
    }
    return new_credit_balance
  }

  renderCreditTable = (credit_balance) => {
    const reformat_credit_balance = this.formatCreditBalance(credit_balance)
    const credit_balance_amount = credit_balance.map(cb => cb.course.price.amount)
    
    return (
      <TableContainer style={{ border: 'none' }} >
        <Table style={{ border: '1px solid rgba(0, 0, 0, 0.125)' }}>
          <TableHead style={{ backgroundColor: '#ebebeb' }}>
            {
              !this.state.topup_form_active ?
              <TableRow>
                <TableCell style={{ width: '65%', textAlign: 'left', padding: '8px 12px' }} ><strong>Class</strong></TableCell>
                <TableCell style={{ width: '35%', textAlign: 'center', padding: '8px 12px' }} ><strong>Credit(s)</strong></TableCell>
              </TableRow>
              :
              <TableRow>
                <TableCell style={{ width: '50%', textAlign: 'left', padding: '8px 12px' }} ><strong>Class</strong></TableCell>
                <TableCell style={{ width: '10%', textAlign: 'center', padding: '8px 12px' }} ><strong>Credit(s)</strong></TableCell>
                <TableCell style={{ width: '20%', textAlign: 'center', padding: '8px 12px' }} ><strong>Unit Price</strong></TableCell>
                <TableCell style={{ width: '20%', textAlign: 'right', padding: '8px 12px' }} ><strong>Total Price</strong></TableCell>
              </TableRow>
            }
          </TableHead>

          <TableBody>
            {
              reformat_credit_balance.map( (credit, index) => {
                return !this.state.topup_form_active ?
                  (
                    <TableRow key={index} >
                      <TableCell style={{ textAlign: 'left', padding: '8px 12px' }} >{ credit.course.name }</TableCell>
                      <TableCell style={{ textAlign: 'center', padding: '8px 12px' }} >{ credit.count }</TableCell>
                    </TableRow>
                  )
                  :
                  (
                    <TableRow key={index} >
                      <TableCell style={{ textAlign: 'left', padding: '8px 12px' }} >{ credit.course.name }</TableCell>
                      <TableCell style={{ textAlign: 'center', padding: '8px 12px' }} >{ credit.count }</TableCell>
                      <TableCell style={{ textAlign: 'center', padding: '8px 12px' }} >{ utils.formatPrice(credit.course.price) }</TableCell>
                      <TableCell style={{ textAlign: 'right', padding: '8px 12px' }} >{ utils.formatPrice({ currency: credit.course.price.currency, amount: credit.course.price.amount*credit.count }) }</TableCell>
                    </TableRow>
                  )
              })
            }
          </TableBody>
        </Table>
        { this.state.applied_coupon && 
          <Table style={{ border: 'none' }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: '50%', textAlign: 'left', padding: '8px 12px', border: 'none' }} ></TableCell>
                <TableCell style={{ width: '10%', textAlign: 'center', padding: '8px 12px', border: 'none' }} ></TableCell>
                <TableCell style={{ width: '20%', textAlign: 'center', padding: '8px 12px', border: 'none' }} ><strong>Discount</strong></TableCell>
                <TableCell style={{ width: '20%', textAlign: 'right', padding: '8px 12px', border: 'none' }} ><strong>{ utils.formatPrice({ currency: 'SGD', amount: 3000 }) }</strong></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        }
        {
          this.state.topup_form_active &&
          <Table style={{ border: 'none' }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: '50%', textAlign: 'left', padding: '8px 12px', border: 'none' }} ></TableCell>
                <TableCell style={{ width: '10%', textAlign: 'center', padding: '8px 12px', border: 'none' }} ></TableCell>
                <TableCell style={{ width: '20%', textAlign: 'center', padding: '8px 12px', border: 'none' }} ><strong>Total</strong></TableCell>
                <TableCell style={{ width: '20%', textAlign: 'right', padding: '8px 12px', border: 'none' }} ><strong>{ utils.formatPrice({ currency: 'SGD', amount: credit_balance_amount.length > 0 ? credit_balance_amount.reduce( (a,b) => a+b ) : 0 }) }</strong></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        }
      </TableContainer>
    )
  }

  renderCouponForm = () => {
    return (
      <div>
        <div style={{ margin: '1em 0' }} >
          <div>Enter a coupon code for discounted pricing on this purchase order.</div>
          <div style={{ fontSize: '14px' }}>The grand total for your purchase order will be written in the invoice.</div>
        </div>
        <div style={{ display: 'flex' }} >
          <div style={{ flexBasis: '60%' }} >
            <TextField
              onChange={(event) => this.handleChange(event, 'coupon_code')} 
              value={this.state.coupon_code} 
              InputLabelProps={{ shrink: true }}
              style={{ minWidth: '100%' }}
              placeholder='Enter coupon code (optional)'
            />
          </div>
          <div>
            <Button 
              style={{ textTransform: 'none', border: '1px solid', height: '40px', padding: '6px 24px', marginLeft: '12px' }} 
              onClick={(e) => this.insertCouponCode(e)}
              disabled={ this.state.coupon_code === '' }
            >
              Apply
            </Button>
          </div>
        </div>
        { this.state.coupon_error &&
          <Typography style={{ color: 'red',fontSize: '14px' }}>{this.state.coupon_error}</Typography>
        }
      </div>
    )
  }

  // reformatWithPracticalCredits = (credit_balance) => {
  //   return {
  //     practical: credit_balance.length === 0 ? [] : this.props.credits.filter(credit => practical_session_name.findIndex(psn => credit.course.name.includes(psn.name) ) > -1 ),
  //     non_practical: credit_balance.length === 0 ? [] : this.props.credits.filter(credit => practical_session_name.findIndex(psn => credit.course.name.includes(psn.name) ) < 0 )
  //   }
  // }

  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleClose = (name) => {
    this.setState({
      [name]: false
    })
  }

  insertCouponCode = (e) => {
    e.preventDefault()
  }

  processTopup = async (e) => {
    e.preventDefault()
    
    const topup_order = {
      trainee: this.props.auth,
      trainee_id: this.props.auth._id,
      courses: this.state.topup_credit_balance,
      coupon_code: this.state.coupon_code
    }
    this.props.change_topup_status(true)
    this.props.convertCredits(e, false)
    
    const topup_credit_result = await api_interface.topupCredits(topup_order)

    this.props.change_topup_status(false)
    if (topup_credit_result.success) {
      this.setState({
        open_snackbar: true
      }) 
    } else {
      alert(`Top-up credits couldn't be completed. Please try again later.`)
    }
  }

  render() {
    
    // const render_credit_balance = this.reformatWithPracticalCredits(this.props.credits)
    // console.log('this.state topup credit section', this.state)
    // console.log('this.props topup credit section', this.props)
    
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 'calc(100% - 70px)', padding: '12px 20px', border: '1px solid #e0e0e0', borderRadius: '5px', background: '#fff', margin: '0 30px' }} >
          <label>{ this.state.topup_credit_balance.length } additional credit(s) needed to complete the course</label>
          <SubmitTopupButton disabled={ !this.props.auth.topup_enabled } onClick={(e) => this.props.convertCredits(e, true)}>
            Top-up Credit
          </SubmitTopupButton>
        </div>
        <Dialog
          open={ this.props.open_topup_dialog }
          onClose={ (e) => this.props.convertCredits(e, false) }
          style={{ minWidth: '600px' }}
        >
          <DialogTitle style={{ minWidth: '552px' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
              <div>{ this.state.topup_form_active ? 'Top-up Your Class Credit' : 'Add Classes' } </div>
              <div>
                <IconButton style={{ backgroundColor: 'transparent', color: '#616161', textTransform: 'none', fontWeight: 'bold', padding: '0' }} onClick={ (e) => this.props.convertCredits(e, false) }>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            {
              this.props.topup_order.length > 0 ?
              <div>
                <p>We have sent your request to the relevant team for processing. Turnaround time will be around 2 - 5 working days.</p>
              </div>
              :
              !this.state.topup_form_active ?
              <div style={{ color: '#212121' }} >
                {
                  this.state.topup_credit_balance.length > 0 ?
                  <div>
                    <p>You have { this.state.available_credits.length } credit(s) in your balance. Use your Class Credits to add the following classes to your course.</p>
                    { this.renderCreditTable(this.state.topup_credit_balance) }
                  </div>
                  :
                  <div>
                    <p>You still have remaining credits to enrol the class.</p>
                  </div>
                }
                <br />
              </div>
              :
              <div style={{ color: '#212121' }} >
                <p >You'll need additional <strong>{this.state.topup_credit_balance.length}</strong> class credit(s) to complete this course. To receive additional class credit(s), submit a request by clicking the Place Order button.</p>
                { this.renderCreditTable(this.state.topup_credit_balance) }
                { !this.state.applied_coupon && this.renderCouponForm() }
              </div>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => this.props.convertCredits(e, false)} style={{ backgroundColor: 'white', color: '#1E90FF', textTransform: 'none' }}>
              <strong>Close</strong>
            </Button>
            {
              this.state.topup_credit_balance.length > 0 && this.props.topup_order.length === 0 &&
              <Button onClick={(e) => this.processTopup(e)} style={{ textTransform: 'none' }}>
                <strong>{ this.state.available_credits.length > 0 ? `${ this.state.topup_credit_balance.length } Credit${ this.state.topup_credit_balance.length > 1 ? 's' : ''}` : 'Place Order' }</strong>
              </Button> 
            }
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={ this.state.open_snackbar }
          autoHideDuration={ 3000 } 
          onClose={ e => this.handleClose('open_snackbar') }
        >
          <div style={{
            background: '#fff',
            display: 'flex',
            padding: '12px 20px',
            borderRadius: '4px',
            boxShadow: '0 2px 2px rgba(0,0,0,.3)',
            borderLeft: '6px solid #5aa700',
            fontSize: '14px',
            alignItems: 'center'
          }} >
            <div style={{ height: '30px', display: 'flex', alignItems: 'center' }} ><CheckCircleIcon style={{ color: '#5aa700' }} /></div>
            <div style={{ height: '30px', lineHeight: '30px', width: '400px', margin: '0 15px' }}>Your request has been sent! We will get back to you soon</div>
            <div style={{ height: '30px', display: 'flex', alignItems: 'center' }}>
              <IconButton size="small" aria-label="close" color="inherit" onClick={ e => this.handleClose('open_snackbar') }>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        </Snackbar>
      </div>
    )
  }

}

export default TopupCreditSection
