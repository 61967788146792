import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import {
  AppBar,
  Button,
  Container,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { withStyles } from '@material-ui/core/styles'

import userManager from '../utils/userManager'
import { removeAuthUser } from '../actions'

const StyledAppBar = withStyles({
  root: {
    backgroundColor: '#FFFFFF !important',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, .125)',
  }
})(AppBar)

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    fontSize: '14px'
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontFamily: 'Open Sans, helvetica, arial, sans-serif',
    color: '#5a5a5a',
    backgroundColor: '#ffffff',
    fontSize: '14px',
    fontWeight: '500',
    '&:hover': {
      color: '#ffffff !important',
      fontWeight: 'bold',
      backgroundColor: '#246ff1'
    }
  }
}))(MenuItem);

const styles = {
  menuToolbar : {
    display: 'flex',
    fontSize: '16px',
    width: '100%',
    justifyContent: 'space-between'
  },
  textMenuToolbar: {
    padding: '4px 10px',
    cursor: 'pointer',
    fontWeight: '500',
    fontSize: '16px',
    color: '#5a5a5a',
    margin: '0 4px'
  },
  menuActive: {
    color: '#151515',
    fontWeight: 'bold'
  },
  menuOthers: {
    color: '#5a5a5a',
    fontWeight: 'normal'
  },
  menuIconStyle: {
    height: '20px'
  }
}

class Navbar extends Component  {

  constructor (props) {
    super(props)
    this.state = {
      order_anchor_dropdown: null,
      grading_anchor_dropdown: null,
      oidc: this.props.oidc,
      auth: this.props.auth
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.oidc !== prevProps.oidc) {
      this.setState({ oidc: this.props.oidc })
    }
    if (this.props.auth !== prevProps.auth) {
      this.setState({ auth: this.props.auth })
    }
  }

  onLoginButtonClick = async (event) => {
    event.preventDefault()
    await userManager.signinRedirect()
  }

  onLogoutButtonClick = (event) => {
    event.preventDefault()
    
    // removes the user data from sessionStorage
    // and redirects to user manager's post_logout_redirect_uri
    // make sure post_logout_redirect_uri is registered on 
    // identity server
    
    // localStorage.removeItem('plex_user')
    
    // await this.props.removeAuthUser()

    const { oidc } = this.state

    // console.log('oidc', oidc)

    userManager.signoutRedirect({ id_token_hint: oidc.user.id_token })
  }

  renderLoginOrLogoutButton = () => {
    if (this.props.router.location.pathname === '/enrol')
      return null

    const { oidc } = this.state

    // console.log('oidc', oidc)

    if (oidc && oidc.user) {
      return (<span>
        <Typography variant='caption'>{oidc.user.profile.email}</Typography>
        <Button onClick={this.onLogoutButtonClick}>Logout</Button>
      </span>)
    } else {
      return (
        <Button onClick={this.onLoginButtonClick}>Login</Button>
      )
    }
  }

  handleCloseDropdown = () => {
    this.setState({
      order_anchor_dropdown: null,
      grading_anchor_dropdown: null,
      report_anchor_dropdown: null
    })
  }

  handleDropdownMenu = (url_target) => {
    this.props.push(`${url_target}`)
    this.setState({
      order_anchor_dropdown: null,
      grading_anchor_dropdown: null,
      report_anchor_dropdown: null
    })
  }

  handleClickDropdown = (e, name) => {
    this.setState({
      [name]: e.currentTarget
    })
  }

  renderNavbarLinks() {
    const { auth, oidc } = this.props

    const detect_orders_menu = window.location.href.includes('/admin/orders') || window.location.href.includes('/admin/topup'),
      // detect_service_menu = window.location.href.includes('/admin/course-schedule') || window.location.href.includes('/admin/schedule-manager'),
      detect_grading_menu = window.location.href.includes('/admin/module-grading') || window.location.href.includes('/admin/practical-flight-grading'),
      detect_report_menu = window.location.href.includes('/flight-report') || window.location.href.includes('/e-booklet')

    if (oidc.user && oidc.user.profile.sub) {
      if (auth.uato_personnel) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }} >
            <div onClick={() => this.props.push('/admin/instructor-dashboard')} style={ window.location.href.includes('/admin/instructor-dashboard') ? { ...styles.textMenuToolbar, ...styles.menuActive } : styles.textMenuToolbar }>
              Dashboard
            </div>
            <div onClick={(e) => this.handleClickDropdown(e, 'order_anchor_dropdown')} style={ detect_orders_menu ? { ...styles.textMenuToolbar, ...styles.menuActive, display: 'flex' } : {...styles.textMenuToolbar, display: 'flex' } }>
              Orders <ArrowDropDownIcon style={ styles.menuIconStyle } />
            </div>
            <StyledMenu
              id="orders-menu"
              anchorEl={this.state.order_anchor_dropdown}
              keepMounted
              open={Boolean(this.state.order_anchor_dropdown)}
              onClose={() => this.handleCloseDropdown()}
              style={{ alignItems: 'flex-end' }}
            >
              <StyledMenuItem style={ window.location.href.includes('/admin/orders') ? styles.menuActive : styles.menuOthers } onClick={() => this.handleDropdownMenu('/admin/orders')}>Course Catalog</StyledMenuItem>
              <StyledMenuItem style={ window.location.href.includes('/admin/topup') ? styles.menuActive : styles.menuOthers } onClick={() => this.handleDropdownMenu('/admin/topup')}>Top-up Sessions</StyledMenuItem>
            </StyledMenu>
            <div onClick={() => this.props.push('/admin/trainees')} style={ window.location.href.includes('/admin/trainees') ? { ...styles.textMenuToolbar, ...styles.menuActive } : styles.textMenuToolbar }>
              Trainees
            </div>
            <div onClick={(e) => this.handleClickDropdown(e, 'grading_anchor_dropdown')} style={ detect_grading_menu ? { ...styles.textMenuToolbar, ...styles.menuActive, display: 'flex' } : {...styles.textMenuToolbar, display: 'flex' } }>
              Grading <ArrowDropDownIcon style={ styles.menuIconStyle } />
            </div>
            <StyledMenu
              id="grading-menu"
              anchorEl={this.state.grading_anchor_dropdown}
              keepMounted
              open={Boolean(this.state.grading_anchor_dropdown)}
              onClose={() => this.handleCloseDropdown()}
              style={{ alignItems: 'flex-end' }}
            >
              <StyledMenuItem style={ window.location.href.includes('/admin/module-grading') ? styles.menuActive : styles.menuOthers } onClick={() => this.handleDropdownMenu('/admin/module-grading')}>Module Grading</StyledMenuItem>
              <StyledMenuItem style={ window.location.href.includes('/admin/practical-flight-grading') ? styles.menuActive : styles.menuOthers } onClick={() => this.handleDropdownMenu('/admin/practical-flight-grading')}>Practical Flight Grading</StyledMenuItem>
            </StyledMenu>
            <div onClick={() => this.props.push('/admin/schedule-manager')} style={ window.location.href.includes('/admin/schedule-manager') ? { ...styles.textMenuToolbar, ...styles.menuActive } : styles.textMenuToolbar }>
              Schedule
            </div>
          </div>
        )
      } else {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div onClick={() => this.props.push('/dashboard')} style={ window.location.href.includes('/dashboard') ? { ...styles.textMenuToolbar, ...styles.menuActive } : styles.textMenuToolbar }>
              Dashboard
            </div>
            <div onClick={() => this.props.push('/schedule')} style={ window.location.href.includes('/schedule') ? { ...styles.textMenuToolbar, ...styles.menuActive } : styles.textMenuToolbar }>
              Class Schedule
            </div>
            <div onClick={(e) => this.handleClickDropdown(e, 'report_anchor_dropdown')} style={ detect_report_menu ? { ...styles.textMenuToolbar, ...styles.menuActive, display: 'flex' } : {...styles.textMenuToolbar, display: 'flex' } }>
              Grade Reports <ArrowDropDownIcon style={ styles.menuIconStyle } />
            </div>
            <StyledMenu
              id="report-menu"
              anchorEl={this.state.report_anchor_dropdown}
              keepMounted
              open={Boolean(this.state.report_anchor_dropdown)}
              onClose={() => this.handleCloseDropdown()}
              style={{ alignItems: 'flex-end' }}
            >
              <StyledMenuItem style={ window.location.href.includes('/flight-report') ? styles.menuActive : styles.menuOthers } onClick={() => this.handleDropdownMenu('/flight-report')}>Flight Report</StyledMenuItem>
              <StyledMenuItem style={ window.location.href.includes('/e-booklet') ? styles.menuActive : styles.menuOthers } onClick={() => this.handleDropdownMenu('/e-booklet')}>E-booklet</StyledMenuItem>
            </StyledMenu>
          </div>
        )
      }      
    } else {
      return (
        <div></div>
      )
    }
  }

  render() {
    return (
      <StyledAppBar>
        <Container>
          <Toolbar position="static">
            <div style={{ cursor: 'pointer' }} onClick={() => this.props.push('/dashboard')}>
              <img src={process.env.PUBLIC_URL + '/2020-uato-logo.png'} alt="" style={{ height: '50px', width: 'auto', backgroundColor: 'white !important'  }} />
            </div>
            <div style={styles.menuToolbar}> 
              {this.renderNavbarLinks()}
              {/* <IconButton onClick={() => this.props.push('/enrol')}>
                Enrol
              </IconButton>
              <IconButton onClick={() => this.props.push('/user/details')}>
                User Details
              </IconButton> */}
              {this.renderLoginOrLogoutButton()}
            </div>
          </Toolbar>
        </Container>
      </StyledAppBar>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps, { removeAuthUser, push })(Navbar)
