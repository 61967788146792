import { useState, useEffect } from 'react'

import api_interface from '../../api_interface'

import FilterDrawer    from './FilterDrawer'
import AboutInstructor from './AboutInstructor'
import AboutStudents   from './AboutStudents'
import {
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  ChevronRight,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    display: 'flex',
  },
  openDrawer: {
    position: 'absolute',
    top: theme.spacing(12),
    left: theme.spacing(1),
  },
})

function InstructorDashboard({ classes }) {
  const [ products,    setProducts    ] = useState([])
  const [ instructors, setInstructors ] = useState([])

  const [ open, setOpen ] = useState(true)
  const [ selectIndex, setSelectIndex ] = useState(-1)
  const [ filter, setFilter ] = useState({
    studentResults: 'all', // all, pass, fail, none
    flightIncident: 'all', // all, only, exclude
    startDate: null,       // null, Date() object
    endDate: null,         // null, Date() object
    courses: []            // list of SKUs (default all)
  })

  useEffect(() => {
    async function fetchProducts() {
      const data = await api_interface.getProducts()
      console.log('data', data)
      if (!data.success) {
        console.log('Failed to get products', data.error)
        setProducts([])
      } else {
        const sortedProducts = [ ...data.products ]
        sortedProducts.sort((a, b) => (a.code < b.code ? -1 : a.code > b.code ? 1 : 0))
        setProducts(sortedProducts)
        setFilter(prevFilter => ({
          ...prevFilter,
          courses: sortedProducts.map(p => p.code) // default filter all
        }))
      }
    } // Effect callbacks are synchronous to prevent race conditions. Put the async function inside.
    fetchProducts()

    async function fetchInstructors() {
      const data = await api_interface.getAllPersonnel()
      console.log('data', data)
      if (!data.success) {
        console.log('Failed to get all personnel', data.error)
        setInstructors([])
      } else {
        setInstructors(data.personnel)
      }
    }
    fetchInstructors()
  }, [])

  // console.table(filter)

  // Clipped Drawer https://v4.mui.com/components/drawers/#drawer
  return <div className={classes.root}>
    <div style={{ display: 'inline-block' }}>
      <IconButton className={classes.openDrawer}
        onClick={() => setOpen(true)}>
        <ChevronRight />
      </IconButton>
      <br />
      <br />
      <br />
      <br />
      <br />
      {
        selectIndex < 0 ?
          <Typography variant='h5'>Select a Personnel</Typography>
        : <>
            <AboutInstructor
              instructor={instructors[selectIndex]}
              filter={filter} />
            <br />
            <Divider />
            <br />
            <AboutStudents
              instructor={instructors[selectIndex]}
              filter={filter} />
          </>
      }
    </div>
    <FilterDrawer
      open={open}
      onClose={() => setOpen(false)}
      instructors={instructors}
      selectIndex={selectIndex}
      setSelectIndex={setSelectIndex}
      products={products}
      filter={filter}
      setFilter={setFilter}
    />
  </div>
}

export default withStyles(styles)(InstructorDashboard)
