import axios from 'axios'

import utils from '../utils'

const API_URL = process.env.REACT_APP_UATO_API_URL

//upload single caas file
export const addTheoryResult = async (payload, trainee_id) => {
  const url = `${API_URL}/upload/caas?trainee_id=${trainee_id}`
  const options = utils.getJWTHeaders()

  const res = await axios.post(url, payload, options)

  if (res.data && !res.data.success)
    console.error('addTheoryResult error', res.data.message)

  return res.data
}

//upload single lecture file
export const uploadLectureFile = async (payload) => {
  const url = `${API_URL}/upload/lecture`
  const options = utils.getJWTHeaders()

  const res = await axios.post(url, payload, options)

  if (res.data && !res.data.success)
    console.error('uploadLectureFile error', res.data.message)

  return res.data
}

//get single caas file
export const getCaasFile = async (file_name, trainee_id) => {
  const url = `${API_URL}/upload/caas/${file_name}?trainee_id=${trainee_id}`
  const options = { headers: utils.getJWTHeaders().headers, responseType: 'arraybuffer' }

  const res = await axios.get(url, options)

  if (res.data && !res.data.success)
    console.error('getCaasFile error', res.data.message)

  return res.data
}

//get single lecture file
export const getLectureFile = async (file_name, trainee_id) => {
  const url = `${API_URL}/upload/lecture/${file_name}?trainee_id=${trainee_id}`
  const options = { headers: utils.getJWTHeaders().headers, responseType: 'arraybuffer' }

  const res = await axios.get(url, options)

  if (res.data && !res.data.success)
    console.error('getLectureFile error', res.data.message)

  return res.data
}

//get all lecture file
export const getLectureFiles = async (trainee_id) => {
  const url = `${API_URL}/upload/lectures?trainee_id=${trainee_id}&category=lecture`
  const options = utils.getJWTHeaders()

  const res = await axios.get(url, options)

  if (res.data && !res.data.success)
    console.error('getLectureFiles error', res.data.message)

  return res.data
}
