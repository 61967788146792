import axios from 'axios'

import utils from '../utils'

const API_URL = process.env.REACT_APP_UATO_API_URL

export const getPersonnelByEmail = async (email) => {
  const url = `${API_URL}/personnel/${email}`
  const options = utils.getJWTHeaders()

  const res = await axios.get(url, options)

  // console.log('getPersonnelByEmail: ', res.data)

  return res.data
}

export const getAllPersonnel = async () => {
  const url = `${API_URL}/personnel`
  const options = utils.getJWTHeaders()

  const res = await axios.get(url, options)

  console.log('getAllPersonnel: ', res.data)

  return res.data
}

