import React from 'react'

import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
// import Slide from '@material-ui/core/Slide'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import MailOutlineIcon from '@material-ui/icons/MailOutline'

import api_interface from '../../api_interface'

const credit_status = [ 'Passed', 'Conditional Passed', 'Failed', 'Conditional Failed', 'Cancelled', 'Enrolled', 'Available' ]

class EditCreditViewer extends React.Component {
  state = {
    course_schedules: null,
    selected_session: 1,
    selected_status: null,
    selected_schedule: null,
    selected_hold: null,

    open_mail_dialog: false,

    open_update_result: false,
    err_update: false
  }

  componentDidUpdate = async (prevProps) => {
    if (this.props.edit_credit !== prevProps.edit_credit && this.props.edit_credit) {
      const schedule_res_data = await api_interface.getSchedulesForCourse(this.props.edit_credit.course_id)
      const course_schedules = schedule_res_data.course_schedules

      // console.log('course_schedules', course_schedules)
      // console.log('this.props.edit_credit', this.props.edit_credit)

      this.setState({
        course_schedules: course_schedules,
        selected_status: this.props.edit_credit.status || 'default',
        selected_schedule: this.props.edit_credit.course_schedule_id || 'default',
        selected_hold: this.props.edit_credit.hold || false,
        selected_session: this.props.edit_credit.session_number || 1
      })
    }
  }

  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleBlurNumber = (e, name) => {
    this.setState({
      [name]: parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 1
    })
  }

  handleSubmitEdit = async (e) => {
    e.preventDefault()
    const { selected_status, selected_schedule, selected_hold, selected_session } = this.state

    if (selected_schedule === 'default' && selected_status !== 'Available') {
      alert('Please complete the schedule and status')
      return false
    }

    const new_credit_object = {
      credit_id: this.props.edit_credit._id,
      status: selected_status,
      course_schedule: selected_schedule,
      session_number: selected_session,
      hold: selected_hold
    }

    const submit_credit_change = await api_interface.putCreditChange(new_credit_object)

    if (submit_credit_change.success) {
      this.setState({
        open_update_result: true
      })
    } else {
      this.setState({
        open_update_result: true,
        err_update: true
      })
    }
  }

  handleNotification = async (e, credit) => {
    const generate_email_notification = await api_interface.customNotification(credit)
    if (generate_email_notification.success) {
      alert('Sending notification email successfully')
    } else {
      alert('Failed to send notification email')
    }
  }

  handleReminderEmail = async (e, credit) => {
    const generate_email_reminder = await api_interface.customReminder(credit)
    if (generate_email_reminder.success) {
      alert('sending reminder email successfully')
    } else {
      alert('Failed to send reminder email')
    }
  }

  renderNull = () => {
    return (
      <div style={{ textAlign: 'center', width: '100%' }} >
        <CircularProgress />
      </div>
    )
  }

  render() {
    const {
      edit_credit,
      trainee,
      handleBack,
      formatTime
    } = this.props
    const {
      course_schedules,
      selected_status
    } = this.state

    return (
      <div style={{ maxWidth: '900px', margin: '0 auto' }} >
        { edit_credit && trainee &&
          <div style={{ background: 'transparent', cursor: 'pointer', margin: '20px 0', display: 'flex', color: '#757575' }} onClick={ () => handleBack() }>
            <KeyboardBackspaceIcon style={{ marginRight: '10px' }} />Back
          </div>
        }
        {
          edit_credit && trainee &&
          <div>
            <h2 style={{ lineHeight: '48px', textAlign: 'left' }} >Edit Credit</h2>
            <Paper style={{ textAlign: 'left', padding: '12px 20px' }} >
              <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '12px' }} >
                <div>
                  <div style={{ lineHeight: '1.618' }} >Name: { `${ trainee.first_name } ${ trainee.last_name }` }</div>
                  <div style={{ lineHeight: '1.618' }} >Email: { trainee.email }</div>
                </div>
                <div>
                  <IconButton style={{ background: '#eee' }} onClick={ () => this.setState({ open_mail_dialog: true }) } >
                    <MailOutlineIcon />
                  </IconButton>
                </div>
              </div>
              <Divider />
              <Dialog
                open={ this.state.open_mail_dialog }
                onClose={ () => this.setState({ open_mail_dialog: false }) }
                >
                <DialogTitle>Send email to the trainee</DialogTitle>
                <DialogContent style={{ minWidth: '400px', marginBottom: '1em' }} >
                  <MenuList style={{ width: '100%', padding: 0, border: '1px solid #e0e0e0' }} >
                    <MenuItem style={{ borderBottom: '1px solid #e0e0e0', padding: '12px 20px', justifyContent: 'center', fontSize: '.875em' }} onClick={e => this.handleNotification(e, edit_credit)}><AnnouncementIcon style={{ marginRight: '.3em', fontStyle: '1em' }} />Send Notification Email</MenuItem>
                    <MenuItem style={{ borderBottom: '1px solid #e0e0e0', padding: '12px 20px', justifyContent: 'center', fontSize: '.875em' }} onClick={e => this.handleReminderEmail(e, edit_credit)}><NotificationImportantIcon style={{ marginRight: '.3em', fontStyle: '1em' }} />Send Reminder Email</MenuItem>
                  </MenuList>
                </DialogContent>
              </Dialog>
              {
                <div style={{ margin: '12px 0' }} >
                  <div style={{ display: 'flex', margin: '12px 0' }} >
                    <div style={{ flexBasis: '33.3333%' }}>
                      <strong>Course</strong><br />
                      { this.props.edit_credit ? this.props.edit_credit.catalog_item.sku : '-' }
                    </div>
                    <div style={{ flexBasis: '33.3333%' }}>
                      <strong>Class</strong><br />
                      { this.props.edit_credit && this.props.edit_credit.course.name }
                    </div>
                    <div style={{ flexBasis: '33.3333%' }}>
                      <strong>Session Number</strong>
                      <TextField
                        style={{ margin: 0, marginRight: '5px', width: '100%', paddingRight: '40px' }}
                        id="standard-full-width"
                        margin="normal"
                        type="number"
                        value={ this.state.selected_session }
                        name='trainee-search'
                        onChange={(event) => this.handleChange(event, 'selected_session')}
                        onBlur={(e) => this.handleBlurNumber(e, 'selected_session')}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', margin: '12px 0' }} >

                    <div style={{ flexBasis: '33.3333%' }}>
                      <strong>Status</strong><br />
                      <div style={{ marginRight: '20px' }}>
                        <Select value={selected_status || 'Available'} onChange={e => this.handleChange(e, 'selected_status')} >
                          { credit_status.map( (cs, i) => {
                            return (
                              <MenuItem value={cs} key={i}>{cs}</MenuItem>
                            )
                          } ) }
                        </Select>
                      </div>
                    </div>

                    <div style={{ flexBasis: '33.3333%' }}>
                      <strong>Schedule</strong><br />
                      { !course_schedules ?
                        this.renderNull()
                        :
                        <div style={{ marginRight: '20px' }}>
                          <Select value={this.state.selected_schedule} onChange={e => this.handleChange(e, 'selected_schedule')}>
                            <MenuItem value='default'></MenuItem>
                            {this.state.course_schedules.map( (cs, i) => {
                              return (
                                <MenuItem key={i} value={cs._id} >{formatTime(cs.start_time)}</MenuItem>
                              )
                            } )}
                          </Select>
                        </div>
                      }
                    </div>

                    <div style={{ flexBasis: '33.3333%' }}>
                      <strong>Accessible</strong><br />
                      <div>
                        <Select value={!this.state.selected_hold} onChange={e => this.setState({ selected_hold: !e.target.value })}>
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', margin: '24px 0 12px' }}>
                    <Button disabled={ !(this.state.selected_schedule && this.state.selected_status) } onClick={ e => this.handleSubmitEdit(e) } >Save</Button>
                  </div>
                </div>
              }
            </Paper>
          </div>
        }
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={ this.state.open_update_result }
          autoHideDuration={6000}
          onClose={ () => this.setState({ open_update_result: false }) }
        >
          {
            this.state.err_update ?
            <Alert onClose={ () => this.setState({ open_update_result: false }) } severity="error">
              <div>Cannot save the credit update</div>
            </Alert>
            :
            <Alert onClose={ () => this.setState({ open_update_result: false }) } severity="success">
              <div>You have successfully updated the credit</div>
            </Alert>
          }
        </Snackbar>
      </div>
    )
  }
}

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// })

export default EditCreditViewer


