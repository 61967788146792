import React, { Component } from 'react'
import { pdfjs, Document, Page } from 'react-pdf';
import InfiniteScroll from "react-infinite-scroll-component";
import api_interface from '../api_interface'
import { connect } from 'react-redux'

import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import CloseIcon from '@material-ui/icons/Close'

class LecturenoteViewer extends Component {
  constructor(props) {
    super(props)
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    this.state = {
      url: '',
      page: 1,
      has_more: true,
      pdf_id: this.props.match.params.pdf_id,
      error_dialog: false
    }
  }

  async componentDidMount() {
    try {
      const res = await api_interface.getLectureFile(this.props.match.params.pdf_id, this.props.auth._id)

      const blob = new Blob([res], { type: 'application/pdf' })
      const url = URL.createObjectURL(blob)
      this.setState({ url: url })
    }
    catch (error) {
      if (error.response.status===401)
        this.handleDialogOpen()
    }
  }

  handleDialogOpen = () => {
    this.setState({
      error_dialog: true,
    })
  }

  renderErrorDialog = () => {
    return (
      <Dialog
        open={this.state.error_dialog}
        onClose={() => this.setState({ error_dialog: false })}
        style={{ minWidth: '600px' }}
      >
        <DialogTitle style={{ minWidth: '552px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
            <div>Error</div>
            <div>
              <IconButton style={{ backgroundColor: 'transparent', color: '#616161', textTransform: 'none', fontWeight: 'bold', padding: '0' }} onClick={() => this.setState({ error_dialog: false })}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          Cannot open document due to invalid request or unauthorized access.
      </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    )
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages })
  }

  loadMore = () => {
    if (this.state.page >= this.state.numPages) {
      this.setState({ has_more: false })
      return
    }
    this.setState({
      page: this.state.page + 5,
    })
  }

  render() {
    const { url, pdf_id, page, has_more } = this.state

    if (!pdf_id) return (
      < div >
        <br />
        <br />
        <br />
        <h3>PDF Not Found</h3>
      </div >
    )

    return (
      <div >
        <br />
        <br />
        <br />
        <br />
        <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', minHeight: '100vh', left: '0', right: '0', margin: '0 auto', backgroundColor: '#474747' }}>
          <div className='print'>
            {/* To disable save-as pdf from print use stlye media */}
            {/* To disable right click use prevenDefault() */}
            {<style>{`@media print {.print{display: none;}}`}</style>}
            <Document
              file={url}
              onLoadSuccess={this.onDocumentLoad}
              onContextMenu={(e => e.preventDefault())}
              orientation='landscape'
            >
              <InfiniteScroll
                dataLength={page}
                next={this.loadMore}
                hasMore={has_more}
                loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ color: '#FFFFFF', textAlign: "center" }}>
                    <b>End of Document</b>
                  </p>
                }
              >
                {Array.apply(null, Array(page))
                  .map((x, i) => i + 1)
                  .map(page =>
                    <div key={page} style={{ margin: '10px 0' }}>
                      <Page
                        pageNumber={page} scale={1.3} error={false}
                        loading={<div style={{ color: '#FFFFFF' }}>Loading...</div>}
                      />
                    </div>
                  )
                }
              </InfiniteScroll>
            </Document>
          </div>
          {this.renderErrorDialog()}
        </div>
      </div >
    )
  }

}

const mapStateToProps = (state) => state

export default connect(mapStateToProps)(LecturenoteViewer)