import React from 'react'
import { connect } from 'react-redux'

import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from '@material-ui/core/Input'

import SearchIcon from '@material-ui/icons/Search'

// import grading_checklist from '../../utils/grading_checklist.json'
import api_interface from '../../api_interface'
import TraineeSidebar from './sidebar/TraineeSidebar'
import CloseIcon from '@material-ui/icons/Close'

const trainee_status = {
  current: 'plex',
  inactive: 'nonplex'
}

class TraineeDetailsViewer extends React.Component {
  state = {
    screen: 'screen1',

    trainee_currently_grading: {},

    course_reference_selector: null,

    course_checklist_selector: null,

    sortie_selector: 1,

    currrent_sortie: '1',
    
    trainees: null,
    selected_trainee: null,
    prev_grade: null,
    remarks: '',
    flight_time: { mins: 0, secs: 0 },
    checkall: false,
    save_flight: false,
    
    open_sidebar: false,
    preview_submit_flight: false,
    selected_trainee_type: 'current',
    filter_trainee: 'plex',
    
    skip: 0,
    load_next: false,
    search_trainee: '',
    search_mode: false
  }

  async componentDidMount() {
    this.refreshTraineeDetails(this.props.location.search.substr(1))
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.filter_trainee !== prevState.filter_trainee) {
      this.setState({
        trainees: null
      })
      this.refreshTraineeDetails(`?select_by=${this.state.filter_trainee}${this.state.search_trainee && this.state.search_trainee !== '' ? `&trainee=${this.state.search_trainee}` : ''}`)
    }
  }

  refreshTraineeDetails = async (query) => {
    const query_url = query || `?select_by=plex`
    const trainee_res_data = await api_interface.getPlexAuthTrainees(query_url)
    const trainees = trainee_res_data.trainees

    // console.log('trainees', trainees)

    this.setState({ trainees })
  }

  // renders table body in screen 1
  renderTableBody() {
    const { trainees } = this.state

    return trainees.map((trainee, index) => {
      return (
        <TableRow key={index}>
          <TableCell>
            <Link
              href="#"
              onClick={(e) => this.openTraineeDetail(e, trainee)}
              style={{ fontWeight: '500', color: 'rgb(30, 144, 255)', textDecoration: 'none' }}
            >
              {trainee.full_name}
            </Link>
          </TableCell>
          <TableCell>{trainee.student_id}</TableCell>
          <TableCell>{trainee.email}</TableCell>
          <TableCell>{trainee.mobile_number}</TableCell>
          <TableCell>{trainee.active_catalog.sku || '-'}</TableCell>
          <TableCell>{trainee.caas_theory.length ? trainee.caas_theory[trainee.caas_theory.length-1].result : 'Unknown' }</TableCell>
        </TableRow>
      )
    }) 
  }

  openTraineeDetail = (e, trainee) => {
    e.preventDefault()
    this.setState({
      open_sidebar: true,
      selected_trainee: trainee
    })
  }

  closeTraineeDetail = (status = null) => {
    // console.log('status', status)
    this.setState({
      open_sidebar: false
    })
  }

  handleChange = (e, name) => {
    this.setState({
      [name] : e.target.value
    })
  }

  handleChangeStatus = (e) => {
    this.setState({
      filter_trainee: trainee_status[e.target.value],
      selected_trainee_type: e.target.value
    })
  }

  handleNext = async e => {
    e.preventDefault()
    this.setState({ load_next: true })

    const query_url = `?select_by=${this.state.filter_trainee || 'plex'}${this.state.search_mode ? `` : `&skip=${this.state.skip+this.state.trainees.length}`}`
    const next_trainee_data = this.state.search_mode ? await api_interface.searchTraineeByNameOrEmail(this.state.search_trainee, this.state.trainees.length, query_url) : await api_interface.getPlexAuthTrainees(query_url)

    // console.log('next_trainee_data', next_trainee_data)

    const next_trainees = next_trainee_data.trainees

    this.setState({ trainees: [ ...this.state.trainees, ...next_trainees ], load_next: false })
  }

  handleSearch = async (e) => {
    e.preventDefault()
    if (this.state.search_trainee.length > 2) {
      this.setState({ trainees: null })
      const query_url = `?select_by=${this.state.filter_trainee || 'plex'}`
      const searchTrainee = this.state.search_trainee
        .trim()
        .replace(/\s\s+/g, ' ')
      const trainee_res_data = await api_interface.searchTraineeByNameOrEmail(searchTrainee, 0, query_url)
      const trainees = trainee_res_data.trainees

      // console.log('trainees', trainees)

      this.setState({ trainees, search_mode: true })
    }
  }

  cancelSearchMode = e => {
    e.preventDefault()
    const query = `?select_by=${this.state.filter_trainee}`

    this.refreshTraineeDetails(query)
    this.setState({
      search_mode: false,
      search_trainee: ''
    })
  }

  renderNull = () => {
    return (
      <div style={{ textAlign: 'center', width: '100%', padding: '2em' }} >
          <br/><br/><br/><br/><br />
          <CircularProgress />
        </div>
    )
  }

  renderLabel = () => {
    return (
      <div style={{ position: 'relative' }} >
        <h2 style={{ marginTop: '0', marginBottom: '2em' }} >Trainees</h2>
        <div style={{ position: 'absolute', right: 0, top: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', minWidth: 500 }} >
          <div>
            <Select value={ this.state.selected_trainee_type } onChange={ (event) => this.handleChangeStatus(event) } style={{ background: '#fff' }} >
              <MenuItem value='current' key={1} >Current</MenuItem>
              <MenuItem value='inactive' key={2} >Inactive</MenuItem>
            </Select>
          </div>
          <div style={{ flexBasis: '70%', background: '#fff' }} >
            {this.state.search_mode ?
              <div>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderRadius: '4px',
                  border: '1px solid #e0e0e0',
                  paddingLeft: '15px',
                  lineHeight: 1,
                  fontFamily: 'Roboto, sans-serif',
                  fontSize: '.875em'
                }} >
                  { this.state.search_trainee }
                  <IconButton style={{ padding: '8px' }} onClick={e => this.cancelSearchMode(e)}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              :
              <form onSubmit={(e) => this.handleSearch(e)} style={{ position: 'relative' }}  >
                <Input
                  style={{ width: '100%', paddingRight: '40px' }}
                  id="standard-full-width"
                  placeholder="Search by name, email, or student ID"
                  name='trainee-search'
                  value={this.state.search_trainee}
                  onChange={(event) => this.handleChange(event, 'search_trainee')}
                />
                <IconButton type="submit" style={{ padding: '8px', position: 'absolute', right: 0, top: 0 }}><SearchIcon /></IconButton>
              </form>
            }
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.trainees === null) {
      return (
        <div style={{ padding: '0 24px' }}>
          <br/><br/><br/><br/><br /><br />
          { this.renderLabel() }
          { this.renderNull() }
        </div>
      )
    }
    if (this.state.screen === 'screen1') {
      return (
        <div>
          <br/><br/><br/><br/><br /><br />
          { this.renderLabel() }

          {
            this.state.trainees.length > 0 ?
            <Paper>

              <TableContainer component={Paper} style={{ marginTop: 0, maxWidth: 3000, borderRadius: '0', overflowX: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow style={{ background: '#f5f5f5' }} >
                      <TableCell style={{ fontSize: 16, fontWeight: 'bold', width: 250 }}>Name</TableCell>
                      <TableCell style={{ fontSize: 16, fontWeight: 'bold', width: 100 }}>Student ID</TableCell>
                      <TableCell style={{ fontSize: 16, fontWeight: 'bold', width: 300 }}>Email</TableCell>
                      <TableCell style={{ fontSize: 16, fontWeight: 'bold', width: 130 }}>Mobile Number</TableCell>
                      <TableCell style={{ fontSize: 16, fontWeight: 'bold', width: 110 }}>Course Code</TableCell>
                      <TableCell style={{ fontSize: 16, fontWeight: 'bold', width: 100 }}>CAAS Test</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.renderTableBody()}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            :
            <h2 style={{ textAlign: 'center', fontWeight: 300, fontSize: '2.5em', color: '#757575', fontStyle: 'italic'
            }} >No Trainees Available</h2>
          }
          {
            this.state.load_next ?
              this.renderNull()
              :
              <div style={{ textAlign: 'center', marginTop: '15px' }} >
                {
                  this.state.trainees.length % 20 === 0 && this.state.trainees.length > 0 ?
                  <div onClick={ e => this.handleNext(e) } style={{ display: 'inline-block', background: '#1E90FF', color: '#fff', border: '2px solid #1E90FF', padding: '5px  50px', borderRadius: '100px', cursor: 'pointer' }} >
                    Next
                  </div>
                  :
                  ''
                }
              </div>
          }
          <br/>
          <br/>
          <br/>
          <TraineeSidebar history={this.props.history} closeTraineeDetail={this.closeTraineeDetail} open_sidebar={this.state.open_sidebar} filter_trainee={this.state.filter_trainee} search_trainee={this.state.search_trainee} selected_trainee={this.state.selected_trainee} refreshTraineeDetails={this.refreshTraineeDetails} auth={ this.props.auth } />
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps)(TraineeDetailsViewer)

