const AuthReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_AUTH_USER':
      return { ...state, ...action.payload }

    case 'REMOVE_AUTH_USER': 
      return {}
      
    case 'UPDATE_AUTH_USER_DETAILS_SUCCESS':
      return { ...state, ...action.payload }

    case 'GET_AUTH_USER_CREDITS': 
      return { ...state, credits: action.payload }

    default:
      return state
   }
}

export default AuthReducer