import React from 'react'

const styles = {
  calendarCell: {
    backgroundColor: 'white',
    border: 'none',
    flex: 1,
    height: '68px',
    cursor: 'default',
    textAlign: 'center',
    position: 'relative'
  }
}

const CalendarCell = (props) => (
  <div style={styles.calendarCell}>{props.children}</div>
)

export default CalendarCell 
