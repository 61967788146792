import React, { Component } from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import Collapse from '@material-ui/core/Collapse'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

// import utils from '../utils'
import api_interface from '../api_interface'

import FlightReportSection from './sections/FlightReportSection'

// import { 
//   getTraineeGradeReports
// } from '../actions'

// const course_order = utils.getCourseOrder()

const styles = {
  paper_wrapper: {
    display: 'flex',
    padding: '12px 20px'
  },
  sidebar_menu_list: {
    fontSize: '14px',
    padding: '8px 15px',
    margin: '8px 0',
    background: '#fff',
    cursor: 'pointer',
    border: '1px solid transparent'
  },
  menu_active: {
    background: '#eee',
    border: '1px solid #e0e0e0',
    cursor: 'default'
  },
  report_body_label: {
    color: '#757575',
    fontWeight: '500',
    lineHeight: '25px'
  }
}

const normalizedReports = (reports) => {

  let report_array = []

  for (const report of reports) {

    const course_index = report_array.findIndex(ra => ra.code === report.course.code && ra.session === report.credit.session_number )

    if ( course_index > -1) {
      report_array[course_index].grade_list.push({
        _id: report._id,
        grade_code: report.grade_code,
        sortie: report.sortie
      })
    } else {
      const session_report = {
        course_name: report.course.name,
        credit_id: report.credit._id,
        code: report.course.code,
        session: report.credit.session_number,
        grade_list: [
          {
            _id: report._id,
            grade_code: report.grade_code,
            sortie: report.sortie
          }
        ]
      }
      report_array.push(session_report)
    }

  }

  return report_array

}

const sortReportByCourse = (grade_reports) => {
  const course_code_list = ['P010', 'P010E', 'P11', 'P11E']
  let sorted_reports = []
  for (const course_code of course_code_list) {
    const filter_grade_report = grade_reports.filter( report => report.course.code === course_code ).sort( (a,b) => b.credit.session_number - a.credit.session_number || b.grade_code - a.grade_code || b.sortie - a.sortie )
    if (filter_grade_report.length > 0) {
      sorted_reports.push(...filter_grade_report)
    }
  }

  return sorted_reports
}

const setupFlightCount = (flight_reports) => flight_reports.sort( (a, b) => new Date(a.created_at) - new Date(b.created_at) ).map( ((report, index) => {
  return {
    flight_id: report._id,
    index: index+1 > 9 ? `${index+1}` : `0${index+1}`
  }
}) )

class FlightReport extends Component {
  state = {
    grade_list: null,
    grade_reports: null,
    active_credit: null,
    active_grade: null
  }

  async componentDidMount() {
    const report_res_data = await api_interface.getTraineeGradeReports(this.props.auth._id)
    // console.log('report_res_data', report_res_data)
    if (!report_res_data.success) {
      this.props.history.push('/500')
      return
    }
    if (report_res_data.success && report_res_data.grade_reports.length > 0) {
      const sorted_res_data = sortReportByCourse(report_res_data.grade_reports)
      const grade_list_by_credit = normalizedReports(sorted_res_data)
      const flight_counter = setupFlightCount([...sorted_res_data])
      this.setState({
        grade_list_by_credit,
        grade_reports: sorted_res_data,
        active_credit: sorted_res_data[0].credit._id,
        active_grade: sorted_res_data[0]._id,
        flight_counter
      })
    } else {
      this.setState({
        grade_reports: []
      })
    }
  }

  handleChangeReport = (report_id) => {
    if (this.state.active_grade !== report_id) {
      this.setState({
        active_grade: report_id
      })
    }
  }

  handleChangeCredit = (credit_id) => {
    if (this.state.active_credit !== credit_id) {
      this.setState({
        active_credit: credit_id
      })
    }
  }

  renderReportSidebar = () => {
    return (
      <Paper>
        <div style={{ padding: '5px 15px' }}>
          { this.state.grade_list_by_credit.map( (grade, i) => {
            return (
              <div key={i} >
                <div style={{ display: 'flex', height: '40px', alignItems: 'center', fontWeight: '500', cursor: this.state.active_credit === grade.credit_id ? 'default' : 'pointer' }} onClick={() => this.handleChangeCredit(grade.credit_id)} >
                  { grade.credit_id === this.state.active_credit ? <ArrowDropDownIcon /> : <ArrowRightIcon /> }
                  { grade.course_name } - { grade.session }
                </div>
                <div style={{ paddingLeft: '12px' }} >
                  <Collapse in={ grade.credit_id === this.state.active_credit }>
                    { grade.grade_list.map( (gl, j) => {
                      return (
                        <div key={j} onClick={ () => this.handleChangeReport(gl._id) } style={ this.state.active_grade === gl._id ? { ...styles.sidebar_menu_list, ...styles.menu_active } : styles.sidebar_menu_list } >{ gl.grade_code }, Sortie { gl.sortie }</div>
                      )
                    } ) }
                  </Collapse>
                </div>
              </div>
            )
          }) }
        </div>
      </Paper>
    )
  }

  renderReportCard = () => {
    const rendered_report = this.state.grade_reports.find( report => report._id === this.state.active_grade)
    const flight_counter_index = this.state.flight_counter.find( flight => flight.flight_id.toString() === this.state.active_grade.toString() )

    // console.log('rendered_report', rendered_report)

    const flight_patterns = rendered_report.grade_params.reduce((r, obj) => r.concat(obj.flight_patterns), [])
    // console.log('flight_patterns', flight_patterns)

    return (
      <Paper>
        <FlightReportSection rendered_report={rendered_report} flight_counter_index={flight_counter_index} flight_patterns={flight_patterns} />
      </Paper>
    )
  }

  render() {
    
    return (
      <div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <h2 style={{ position: 'relative', marginTop: '0px', marginBottom: '1em' }} >Flight Report</h2>
        { this.state.grade_reports === null ?
          <div style={{ textAlign: 'center', width: '100%', padding: '2em' }} >
            <CircularProgress />
          </div>
          :
          this.state.grade_reports.length > 0 ?
            <Grid container>
              <Grid item md={3}>
                { this.renderReportSidebar() }
              </Grid>
              <Grid item md={9}>
                <div style={{ marginLeft: '20px' }} >
                  { this.renderReportCard() }
                </div>
              </Grid>
            </Grid>
            :
            <Grid container>
              <div style={{ fontSize: '2em', fontWeight: '300', color: '#bdbdbd', fontStyle: 'italic', textAlign: 'center', width: '100%' }} >
                No flight report available
              </div>
            </Grid>
        }
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return { auth: state.auth }
}

export default connect(mapStateToProps)(FlightReport)
