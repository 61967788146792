import { createTheme } from '@material-ui/core/styles'

// UATO.sg's Site Theme to follow Style Guide for UATO.sg
const uatoTheme = createTheme({
  palette: {
    primary: {
      main:  '#1E90FF',
      light: '#6FC0FF',
      dark:  '#0063CB'
    },
    secondary: {
      main:  '#373649',
      light: '#616075',
      dark:  '#111021'
    },
  },
  overrides: {
    // MuiButton: {
    //   root: {
    //     backgroundColor: '#1E90FF ',
    //     color: 'white',
    //     borderRadius: '4px !important', 
    //     '&$disabled': {
    //       border: '1px solid rgb(170, 170, 170)',
    //       color: 'rgb(170, 170, 170)',
    //       backgroundColor: 'white'
    //     },
    //     '&:hover': {
    //       backgroundColor: '#1E90FF ',
    //       color: 'white',
    //     }
    //   },
    //   text: {
    //     padding: '10px',
    //     paddingLeft: '20px',
    //     paddingRight: '20px',
    //   },
    //   disabled: {}
    // },
    MuiInput: {
      root: {
        borderRadius: '4px !important',
        border: '1px solid #DDE0E6',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '4px',
        paddingBottom: '4px',
        fontSize: '14px',
        minWidth: '100%',
      },
      underline: {
        '&&&:before': {
          borderBottom: 'none'
        },
        '&&:after': {
          borderBottom: 'none'
        }
      },
    },
    MuiInputBase: {
      root: {
        '&$error': {
          border: '1px red solid !important'
        },
        '&$focused': {
          border: '1px #1E90FF solid'
        }
      }
    },
    MuiInputLabel: {
      root: {
        color: '#373649 !important',
        fontWeight: '500 !important',
        marginBottom: '12px !important',
        shrink: true
      }
    },
    MuiRadio: {
      root: {
        '&$checked': {
          color: '#1E90FF !important'
        }
      }
    },
    MuiCheckbox: {
      root: {
        '&$checked': {
          color: '#1E90FF !important'
        }
      }
    },
    MuiStepIcon: {
      root: {
        '&$active' : {
          color: '#1E90FF'
        },
        '&$completed' : {
          color: '#1E90FF'
        }
      }
    },
  },
})

export default uatoTheme
