
import React, { Component } from 'react' 

import CalendarCell from './CalendarCell'

const classOnCalendarStyle = (calendar_color) => {
  // console.log('calendar color: ', calendar_color)
  return  {
    fontSize: '2px',
    borderRadius: '4px',
    width: '50%',
    margin: '1px auto',
    backgroundColor: `${calendar_color}`,
    color: `${calendar_color}`
  }
}

class CalendarDay extends Component {
  render() {

    if (this.props.day) {
      // console.log('this.props.day', this.props.day)
      const now = new Date().getTime()
      // Only take a single schedule (the first) in array with the same schedule
      const start_time_date = this.props.scheduled && this.props.scheduled.length > 0 ? new Date(this.props.scheduled[0].start_time).getTime() : 0
      // logic to filter eligible course schedule
      // - props.scheduled is defined
      // - start_time - 1 day > now
      const eligible_date = this.props.scheduled && start_time_date-(24*3600*1000) > now
      // console.log('calendar day props: ', this.props)

      // Populate calendar color and set unique calendar color to be rendered
      const all_schedules = this.props.scheduled && Array.isArray(this.props.scheduled) ? this.props.scheduled.map(schedule => schedule.course.calendar_color) : []
      const displayed_schedules = [...new Set(all_schedules)]

      // console.log('this.props.today', this.props.today)
      
      return (
        <CalendarCell>
          <div style={{ position: 'relative', zIndex: 10 }} >
            <span style={{ color: this.props.today ? '#fff' : '#212121' }} >{this.props.day}</span>
            <br/>
            { displayed_schedules.length > 0 && eligible_date ?
              displayed_schedules.map( (sc, isc) => {
                return (
                  <div key={isc} style={sc ? classOnCalendarStyle(sc) : {}}>
                    {sc ? `You can't see me` : ``}
                  </div>
                )
              })
              :
              <span></span>
            }
          </div>
          { this.props.today && <span style={{ position: 'absolute', zIndex: 2, top: '-9px', left: 'calc(50% - 20px)', borderRadius: '50%', background: 'rgb(30, 144, 255)', display: 'inline-block', width: '40px', height: '40px' }} ></span> }
        </CalendarCell>
      )
    } else {
      return <CalendarCell></CalendarCell>
    }
  }
}

export default CalendarDay 
