import React from 'react'

import Divider from '@material-ui/core/Divider'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'

import utils from '../../utils'
import FlightReportHeader from './FlightReportHeader'

const styles = {
  paper_wrapper: {
    display: 'flex',
    position: 'relative',
    padding: '12px 20px'
  },
  report_body_label: {
    color: '#757575',
    fontWeight: '500',
    lineHeight: '25px'
  }
}

// const normalizedReports = (reports) => {

//   let report_array = []

//   for (const report of reports) {

//     const course_index = report_array.findIndex(ra => ra.code === report.course.code && ra.session === report.credit.session_number )

//     if ( course_index > -1) {
//       report_array[course_index].grade_list.push({
//         _id: report._id,
//         grade_code: report.grade_code,
//         sortie: report.sortie
//       })
//     } else {
//       const session_report = {
//         course_name: report.course.name,
//         credit_id: report.credit._id,
//         code: report.course.code,
//         session: report.credit.session_number,
//         grade_list: [
//           {
//             _id: report._id,
//             grade_code: report.grade_code,
//             sortie: report.sortie
//           }
//         ]
//       }
//       report_array.push(session_report)
//     }

//   }

//   return report_array

// }

// const sortReportByCourse = (grade_reports) => {
//   const course_code_list = ['P010', 'P010E', 'P11', 'P11E']
//   let sorted_reports = []
//   for (const course_code of course_code_list) {
//     const filter_grade_report = grade_reports.filter( report => report.course.code === course_code ).sort( (a,b) => b.credit.session_number - a.credit.session_number || b.grade_code - a.grade_code || b.sortie - a.sortie )
//     if (filter_grade_report.length > 0) {
//       sorted_reports.push(...filter_grade_report)
//     }
//   }

//   return sorted_reports
// }

// const setupFlightCount = (flight_reports) => flight_reports.sort( (a, b) => new Date(a.created_at) - new Date(b.created_at) ).map( ((report, index) => {
//   return {
//     flight_id: report._id,
//     index: index+1 > 9 ? `${index+1}` : `0${index+1}`
//   }
// }) )

class FlightReportSection extends React.Component {
  render() {
    const {
      rendered_report,
      // flight_counter_index,
      flight_patterns
      // editable
    } = this.props
    return (
      <div style={{ position: 'relative', zIndex: '9' }} >
        <FlightReportHeader { ...this.props } />
        <div style={ styles.paper_wrapper } >
          <div>
            <div style={{ marginBottom: '1.5em' }} >
              <div style={{ ...styles.report_body_label, marginBottom: '1em' }}>Patterns</div>
              {
                flight_patterns.map( (fp, i) => {
                  return (
                    <div key={i} style={{ display: 'flex', lineHeight: '1', margin: '1em 0', alignItems: 'center' }} >
                      <span style={{ marginRight: '.5em' }} >{
                        fp.grade ?
                          <CheckIcon style={{ lineHeight: '1', fontSize: '1.1em', color: 'rgb(27, 196, 125)' }} />
                        :
                          <CloseIcon style={{ lineHeight: '1', fontSize: '1.1em', color: '#B00020' }} />
                      }</span>
                      { fp.pattern }
                    </div>
                  )
                } )
              }
            </div>
            <div style={{ marginBottom: '1.5em' }} >
              <div style={ styles.report_body_label } >Remarks</div>
              <div>{ rendered_report && utils.renderRemarks(rendered_report.remarks || '-') }</div>
            </div>
            <div style={{ marginBottom: '1.5em' }} >
              <div style={ styles.report_body_label } >Time (min : sec)</div>
              <div>{ utils.formatTimeWithZero(rendered_report.flight_time.mins) } : { utils.formatTimeWithZero(rendered_report.flight_time.secs) }</div>
            </div>
            <br />
          </div>
        </div>
        <Divider />
        {
          rendered_report.credit.instructor &&
          <div style={ styles.paper_wrapper } >
            <div style={{ color: '#757575', fontSize: '14px', padding: '10px 0' }}>Instructor: {
              rendered_report.credit.instructor.name
            } {
              rendered_report.credit.instructor.instructor_id ?
                `(${rendered_report.credit.instructor.instructor_id})`
              :
                ''
            }</div>
          </div>
        }
      </div>
    )
  }
}

export default FlightReportSection

