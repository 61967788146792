import React from 'react'
import { connect } from 'react-redux'

import MuiButton from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

import { goToNextStep } from '../../actions'

class StepOneNotice extends React.Component {


  render() {
    return (
      <div>
        <h2>Important Notice</h2>

        <Paper>
          <p style={{ padding: 30 , paddingBottom: 0 }}>Please read the following information before completing your application.</p>

          <ol style={{ paddingLeft: 48 }}>
            <li style={{ paddingLeft: 10, paddingBottom: 10 }}>Your purchase is not complete until Garuda Robotics has sent you an email receipt.</li>
            <li style={{ paddingLeft: 10, paddingBottom: 10 }}>Payments made are strictly non-refundable.</li>
          </ol>

          <p style={{ paddingLeft: 30 }}>The following information is required:</p>

          <ul style={{ paddingLeft: 65 }}>
            <li style={{ paddingLeft: 10, paddingBottom: 10 }}>Contact details of UA trainee(s) to be enrolled</li>
            <li style={{ paddingLeft: 10, paddingBottom: 20 }}>Business registration number / UEN for corporates who wish to pay by invoice</li>
          </ul>


        </Paper>

        <p>By clicking "Continue to Course", you acknowledge that you have read the information above.</p>

        <br/>
        <Button style={{ float: 'right', textTransform: 'none' }} onClick={ () => this.props.goToNextStep()}>Continue to Course</Button>
      </div>
    )
  }
}



const mapStateToProps = state => {
    return {}
  }
  
  export default connect(mapStateToProps, { goToNextStep })(StepOneNotice)

  const Button = withStyles({
    root: {
      backgroundColor: '#1E90FF ',
      color: 'white',
      borderRadius: '4px !important', 
      '&$disabled': {
        border: '1px solid rgb(170, 170, 170)',
        color: 'rgb(170, 170, 170) !important',
        backgroundColor: 'white !important'
      },
      '&:hover': {
        backgroundColor: '#3d94f6 !important',
        color: 'white !important',
      }
    }
  })(MuiButton)