import api_interface from '../api_interface'

// For personnel admin uato only
export const getEntireCourseSchedule = (query) => async dispatch => {
  const entire_course_schedule = await api_interface.getEntireCourseSchedule()

  if (entire_course_schedule.success) {
    dispatch({
      type: 'GET_ENTIRE_COURSE_SCHEDULE',
      payload: entire_course_schedule.schedule
    })
  } else {
    console.error('Failed to get schedule: ', entire_course_schedule.error)
  }
}


export const getCourseScheduleForTrainee = () => async (dispatch, getState) => {
  const state = getState()
  const trainee_id = state.auth._id
  const trainee_course_schedule = await api_interface.getCourseScheduleForTrainee(trainee_id)
  // console.log('trainee_course_schedule', trainee_course_schedule)

  if (trainee_course_schedule.success) {
    dispatch({
      type: 'GET_COURSE_SCHEDULE_FOR_TRAINEE',
      payload: trainee_course_schedule.schedule 
    })
  }
}
