import React from 'react'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'

import api_interface from '../../api_interface'

import { Button } from '@material-ui/core'

class TraineeDetailsViewer extends React.Component {
  
  state = {
    raw_trainee: null,
    mobile_number: null,
    first_name: '',
    last_name: '',
    email: ''
  }
  
  async componentDidMount() {
    const trainee_id = this.props.match.params.trainee_id
    const trainee_res_data = await api_interface.getTraineeById(trainee_id)
    const trainee = trainee_res_data.trainee

    // console.log('trainee', trainee)

    this.setState({
      raw_trainee: trainee,
      email: trainee.email,
      mobile_number: trainee.mobile_number,
      first_name: trainee.first_name,
      last_name: trainee.last_name
    })
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.screen !== prevState.screen) {
      if (this.state.screen === 'screen2' && this.state.trainee_currently_grading){

        const get_trainee_grade = await api_interface.getTraineeGrades(this.state.trainee_currently_grading._id)
        const grade_patterns = this.mapGradePattern(get_trainee_grade.checklist)

        const now = new Date().getTime()
        const latest_schedule_grade = [...get_trainee_grade.grades].find( gtg => gtg.credit && gtg.credit.status === 'Enrolled' && new Date(gtg.schedule).getTime() < ( now + (1000*60*60*24) ) )

        let course_selector = { grade_code: 'P010_01', sortie: '1' }
        let render_checklist = this.renderChecklist(course_selector.grade_code, grade_patterns)

        if (get_trainee_grade && latest_schedule_grade) {
           course_selector = get_trainee_grade && latest_schedule_grade ? latest_schedule_grade.grade_code : { ...get_trainee_grade }.grades[0].grade_code
           render_checklist = { ...[...get_trainee_grade.grades].find(gc => gc.grade_code === course_selector.grade_code) }
        }

        let prev_grades = get_trainee_grade.grades_with_credit
        // console.log('prev_grades', prev_grades)
        prev_grades = prev_grades.sort( (a, b) => (b.modified_at > a.modified_at) ? 1 : ((a.modified_at > b.modified_at) ? -1 : 0) )
        // console.log('prev_grades', prev_grades)

        this.setState({
          course_checklist_selector: course_selector.grade_code,
          course_reference_selector: course_selector.grade_code,
          prev_grade: get_trainee_grade.grades_with_credit ? prev_grades[0] : null,
          sortie_selector: course_selector.sortie,
          remarks: '',
          flight_time: { mins: 0, secs: 0 },
          checkall: false,
          grade_patterns,
          render_checklist
        })
      }
    }
  }

  handleChange = (e, name) => {
    this.setState({
      [name] : e.target.value
    })
  }

  submitTraineeDetails = async (e) => {
    e.preventDefault()
    const trainee_details = {
      _id: this.state.raw_trainee._id,
      email: this.state.email,
      mobile_number: this.state.mobile_number,
      first_name: this.state.first_name,
      last_name: this.state.last_name
    }
    this.setState({ raw_trainee: null })
    const update_trainee_details = await api_interface.updateTraineeDetailsById(trainee_details)
    // console.log('update_trainee_details', update_trainee_details)
    if (update_trainee_details.success) {
      this.props.history.push('/admin/trainees')
    }
  }

  renderNull = () => {
    return (
      <div style={{ textAlign: 'center', width: '100%', padding: '2em' }} >
          <br/><br/><br/><br/><br />
          <CircularProgress />
        </div>
    )
  }

  render = () => {
    
    if (this.state.raw_trainee === null)
      return this.renderNull()
    
    return (
      <div style={{ padding: '0 24px' }}>>
        <br /><br /><br /><br /><br /><br />
        <Paper>
          <div style={{ padding: '16px' }} >
            <h2>Edit Trainee Details</h2>
            <div>Student ID: <strong>{this.state.raw_trainee.student_id}</strong></div>
            <TextField
              id="standard-full-width"
              label="First Name"
              placeholder=""
              style={{ minWidth: '45%' , marginTop: 30 }}
              InputLabelProps={{
                shrink: true,
              }}
              name='first_name'
              value={this.state.first_name}
              onChange={(event) => this.handleChange(event, 'first_name')}
            />

            <TextField
              id="date"
              label="Last Name"
              placeholder=""
              style={{ minWidth: '50%' , marginLeft: '5%' , marginTop: 30 }}
              InputLabelProps={{
                shrink: true,
              }}
              name='last_name'
              value={this.state.last_name}
              onChange={(event) => this.handleChange(event, 'last_name')}
            />

            <br/>

            <TextField
              label="Email"
              style={{ minWidth: '45%' , marginTop: 30, color: 'blue !important' }}
              placeholder=""
              margin="normal" 
              InputLabelProps={{
                shrink: true,
              }}
              name='email'
              value={this.state.email}
              onChange={(event) => this.handleChange(event, 'email')}
            />

            <TextField
              id="standard-full-width"
              label="Mobile Number"
              style={{ minWidth: '50%' , marginLeft: '5%' , marginTop: 30 }}
              placeholder=""
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              name='mobile_number'
              value={this.state.mobile_number}
              onChange={(event) => this.handleChange(event, 'mobile_number')}
            />
          </div>
          <div style={{ textAlign: 'right', padding: '16px' }} >
            <Button style={{ padding: '9px 60px', textTransform: 'none', display: 'inline-block' }} onClick={(e) => this.submitTraineeDetails(e)}>Submit</Button>
          </div>
          <br /><br />
        </Paper>
        <br /><br /><br />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps)(TraineeDetailsViewer)

