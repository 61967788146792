import axios from 'axios'

import utils from '../utils'

const API_URL = process.env.REACT_APP_UATO_API_URL

export const getUATOAuthUser = async () => {
  const options = utils.getJWTHeaders()

  const res = await axios.get(`${API_URL}/auth`, options)

  return res.data
}
