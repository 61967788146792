import api_interface from '../api_interface'

export const addTheoryResult = (payload, trainee_id) => async dispatch => {
  const theory_result = await api_interface.addTheoryResult(payload, trainee_id)

  if(!theory_result) return

  dispatch({
    type: 'ADD_THEORY_RESULT',
    payload: theory_result
  })
}

