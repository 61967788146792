import React from 'react'
import Divider from '@material-ui/core/Divider'

import utils from '../../utils'

const styles = {
  paper_wrapper: {
    display: 'flex',
    position: 'relative',
    padding: '12px 20px'
  }
}

const FlightReportHeader = (props) => {
  const { rendered_report, flight_counter_index } = props

  // console.log('rendered_report', rendered_report)

  return (
    <div>
      <div style={{ ...styles.paper_wrapper, alignItems: 'center' }} >
        {
          flight_counter_index && flight_counter_index.index && <div style={{ fontSize: '20px', fontWeight: '500', color: '#757575', background: '#eee', padding: 10, borderRadius: '4px', lineHeight: '1', marginRight: '.5em' }} >{ flight_counter_index.index || '01' }</div>
        }
        <div>
          <div style={{ fontWeight: '600' }} >{ rendered_report.module_code.replace(/_/g, '.') }, Sortie { rendered_report.sortie }</div>
          { rendered_report.schedule && <div style={{ color: '#757575', fontSize: '14px' }} >{ utils.formatSgTimeFormat(rendered_report.schedule.start_time) }</div>}
        </div>
      </div>
      <Divider />
    </div>
  )

}

export default FlightReportHeader

