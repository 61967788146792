import React from 'react'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'
import CircularProgress from '@material-ui/core/CircularProgress'

import { months } from '../../utils/constants.json'
import api_interface from '../../api_interface'

class CourseScheduleViewer extends React.Component {
  state = {
    table_info: null,
    change_status: {},
    open_dialog: false,
    active_tab: 'enrolled', // active_tab list: 'enrolled', 'cancelled'
  }

  async componentDidMount() {
    // await this.getCourseScheduleInfo()
    await this.getEnrolledSchedules()
  }

  getEnrolledSchedules = async () => {
    const all_enrolled_schedules = await api_interface.getEntireEnrolledSchedules()
    // console.log('all_enrolled_schedules', all_enrolled_schedules)
    this.setState({ table_info: all_enrolled_schedules.schedule })
  }

  // getCourseScheduleInfo = async () => {
  //   const schedule_res_data = await api_interface.getEntireCourseSchedule()
  //   const course_schedule = schedule_res_data.schedule
  //   console.log('course_schedule: ', course_schedule)

  //   let table_info = []

  //   for (let i=0; i < course_schedule.length; i++) {
  //     const schedule_id = course_schedule[i]._id
  //     const trainee_res_data = await api_interface.getScheduledCourseTrainees(schedule_id)
  //     const trainees = trainee_res_data.trainees
  //     console.log('trainees: ', trainees)

  //     for (let j=0; j < trainees.length; j++) {
  //       const trainee = trainees[j]
  //       console.log('trainee: ', trainee)

  //       let table_info_row = { 
  //         course_schedule_id: course_schedule[i]._id,
  //         date: course_schedule[i].start_time,
  //         module: course_schedule[i].course_name,

  //         trainee_credit_id: trainee.trainee_credit_id,
  //         credit_status: trainee.credit_status,

  //         name: trainee.full_name,
  //         email: trainee.email,
  //         mobile_number: trainee.mobile_number,
  //       }

  //       if (course_schedule[i].course_object.number_of_sessions > 1)
  //         table_info_row.session_number = trainee.credit_session_number

  //       table_info.push(table_info_row)
  //     }
  //   }

  //   this.setState({ table_info })
  // }

  formatDate = (date) => {
    const date_str = new Date(date)
    const year = date_str.getFullYear()
    const month = months[date_str.getMonth()]
    const month_date = date_str.getDate()

    return `${month} ${month_date}, ${year}`
  }

  changeCreditStatus = async ({ trainee_credit_id, trainee_credit_name, status }) => {
    // console.log('Changing ', trainee_credit_id, 'to', status)
    this.setState( prevState => ({
        ...prevState,
        change_status: {
          ...prevState.change_status,
          trainee_credit_id,
          trainee_credit_name,
          status
        },
        open_dialog: true
      })
    )
  }

  updateCreditStatus = async () => {
    if(this.state.change_status) {
      const { trainee_credit_id, status } = this.state.change_status
      const credit_status_changed = await api_interface.setTraineeCreditGrade(trainee_credit_id, status)
      
      // console.log('credit_status_changed', credit_status_changed)

      if (credit_status_changed.success) {
        this.setState({ open_dialog: false })
        await this.getEnrolledSchedules()
      } else {
        alert(`Failed to update credit status. ${credit_status_changed.message}`)
      }        
    }
  }

  renderTableRows() {
    const { table_info } = this.state
    return table_info.map((info, index) => {
      // console.log('info is: ', info)
      return (
        <TableRow key={index}>
          <TableCell align="center">{this.formatDate(info.date)}</TableCell>
          <TableCell align="center">{info.course_code}</TableCell>
          <TableCell align="center">{`${info.module}${info.session_number ? `-${info.session_number}` : ''}`}</TableCell>
          <TableCell align="center">{info.name}</TableCell>
          <TableCell align="center">{info.student_id}</TableCell>
          <TableCell align="center">{info.mobile_number}</TableCell>
          <TableCell align="center">
            <Select value={info.credit_status} onChange={
              (event) => this.changeCreditStatus({
                trainee_credit_id: info.trainee_credit_id,
                trainee_credit_name: info.name,
                status: event.target.value
              })
            }>
              <MenuItem value={'Enrolled'}>
                <span style={{ fontSize: '12px', color: 'transparent' }}>u cant c me</span>
              </MenuItem>
              <MenuItem value={'Passed'}>Passed</MenuItem>
              <MenuItem value={'Conditional Passed'}>Conditional Passed</MenuItem>
              <MenuItem value={'Conditional Failed'}>Conditional Failed</MenuItem>
              <MenuItem value={'Failed'}>Failed</MenuItem>
              <MenuItem value={'Cancelled'}>Cancelled</MenuItem>
            </Select>
          </TableCell>
        </TableRow>
      )
    })
  }

  handleScheduleTab = async (tab_status) => {
    this.setState({ active_tab: tab_status, table_info: null })
    // const show_status = tab_status === 'cancelled' ? ['Enrolled', 'Conditional Passed', 'Passed', 'Conditional Failed', 'Failed'] : tab_status
    const str_query =`include=${tab_status.charAt(0).toUpperCase() + tab_status.slice(1)}`
    const all_enrolled_schedules = await api_interface.getEntireEnrolledSchedules(str_query)
    // console.log('all_enrolled_schedules', all_enrolled_schedules)
    if (all_enrolled_schedules.schedule)
      this.setState({ table_info: all_enrolled_schedules.schedule })
  }

  render() {

    const list_style = { display: 'inline-block', padding: '1em 2em', borderBottom: '2px solid transparent' }
    const no_course_style = { padding: '2em', textAlign: 'center', fontSize: '2em', fontStyle: 'italic', color: '#9e9e9e', fontWeight: '200' }

    return(
      <div>
        <ul style={{ listStyle: 'none', margin: '120px 0 30px', borderBottom: '1px solid #bdbdbdbd' }} >
          <li style={{ ...list_style,
              borderColor: this.state.active_tab === 'enrolled' ? '#1E90FF' : 'transparent',
              color: this.state.active_tab === 'enrolled' ? '#1E90FF' : '#212121',
              cursor: this.state.active_tab === 'enrolled' ? 'default' : 'pointer',
              fontWeight: this.state.active_tab === 'enrolled' ? '700' : '400'
            }}
            key='enrol'>
            <div onClick={ this.state.active_tab === 'enrolled' ? null : () => this.handleScheduleTab('enrolled') } >Enrolled</div>
          </li>
          <li style={{ ...list_style,
              borderColor: this.state.active_tab === 'cancelled' ? '#1E90FF' : 'transparent',
              color: this.state.active_tab === 'cancelled' ? '#1E90FF' : '#212121',
              cursor: this.state.active_tab === 'cancelled' ? 'default' : 'pointer',
              fontWeight: this.state.active_tab === 'cancelled' ? '700' : '400'
            }} key='cancel'>
            <div onClick={ this.state.active_tab === 'cancelled' ? null : () => this.handleScheduleTab('cancelled') } >Cancelled</div>
          </li>
        </ul>
        <Grid>
          <Grid item style={{ minWidth: '100%', marginBottom: '3em' }}>
            <TableContainer component={Paper}>
              <Table style={{ minWidth: '1000px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Course Code</TableCell>
                    <TableCell align="center">Module</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Student ID</TableCell>
                    <TableCell align="center">Mobile Number</TableCell>
                    <TableCell align="center">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { this.state.table_info !== null && this.renderTableRows() }
                </TableBody>
              </Table>
            </TableContainer>
            { this.state.table_info === null &&
              <div style={{ textAlign: 'center', width: '100%', padding: '2em' }} >
                <CircularProgress />
              </div>
            }
            { this.state.table_info !== null && this.state.table_info.length === 0 && <div style={no_course_style} >No Course Schedule</div> }
            {/* CONFIRM ENROLLMENT DIALOG */}
            <Dialog
              open={ this.state.open_dialog }
              onClose={ () => this.setState({ open_dialog: false }) }
              TransitionComponent={ Transition }
            >
            <DialogTitle>Confirm Enrollment</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <div>Confirm status change for the trainee</div>
                  <div>Name: {this.state.change_status.trainee_credit_name}</div>
                  <div>Status: {this.state.change_status.status}</div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({ open_dialog: false })} style={{ backgroundColor: 'red' }}>
                  Close
                </Button>
                <Button onClick={() => this.updateCreditStatus()}>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
})

export default CourseScheduleViewer


