import { useState, useEffect } from 'react'
import api_interface from '../../api_interface'

import StatPanel from './StatPanel'
import {
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  grid: {
    width: 'lg',
  },
})

function AboutInstructor({ classes, instructor, filter }) {
  const { studentResults, flightIncident, startDate, endDate, courses } = filter || {}

  const [ flights, setFlights ] = useState([])

  useEffect(() => {
    async function fetchInstructorFlights() {
      // TODO: Two ways - painful: Merge Personnel into Accounts(User),
      //             less painful: Add User ID to Personnel.
      const data = await api_interface.getFlightsByPilotUserId(instructor.user_id)
      console.log('data', data)
      if (!data.success) {
        console.log('Failed to get flights', data.error)
        setFlights([])
      } else {
        setFlights(data.flights)
      }
    }
    if (instructor?.user_id)
      fetchInstructorFlights()
  }, [instructor.user_id])

  // Hardcoding currency requirement = 90 days until there's a way to manage this
  const lastFlightEpoch = flights.reduce((max, flight) => (
    flight.date > max ? flight.date : max
  ), 0)

  const currencyExpireInDays = lastFlightEpoch === 0 ? 'No Flights' :
    Math.ceil((new Date().getTime() - lastFlightEpoch) / (1000 * 60 * 60 * 24)).toFixed()

  const totalFlightTimeInSeconds = flights.reduce((sum, flight) => {
    const d = flight.duration
    if (!d) return sum
    return sum + d.hours * 3600 + d.minutes * 60 + d.seconds
  }, 0)

  const totalFlightTimeInHM =
    Math.floor(totalFlightTimeInSeconds / 3600) + 'hrs ' +
    Math.floor(totalFlightTimeInSeconds % 3600 / 60) + 'min'

  // Approximating this until we have a unified incident management system
  const totalIncidents = flights.reduce((sum, flight) => (
    flight.serviceable === false ? sum + 1 : sum
  ), 0)

  return <>
    <Typography variant='h6'>
      About Instructor {instructor.name}
    </Typography>
    <Typography variant='body2' gutterBottom>
      Showing {
        studentResults === 'all' ? 'all results' :
        studentResults === 'pass' ? 'only trainees who passed' :
        studentResults === 'fail' ? 'only trainees who failed' :
        'nothing'
      }, {
        flightIncident === 'all' ? 'with or without incidents' :
        flightIncident === 'include' ? 'only flights that resulted in an incident' :
        flightIncident === 'exclude' ? 'only flights that did not result in an incident' :
        'no idea whether there is an incident'
      }, {
        startDate && endDate ? `between ${startDate.toLocalDateString()} and ${endDate.toLocalDateString()}` :
        startDate ? `since ${startDate.toLocalDateString()}` :
        endDate ? `up until ${endDate.toLocalDateString()}` :
        'for the entire history for the instructor'
      }. They include these course codes: {
        courses?.length === 0 ? 'none of the courses' : courses?.join(', ')
      }.
    </Typography>
    <Grid container spacing={2} className={classes.grid}>
      <Grid item>
        <StatPanel
          overtext='Currency Expire In'
          maintext={currencyExpireInDays}
        />
      </Grid>
      <Grid item>
        <StatPanel
          overtext='Instructed Flight Time'
          maintext={totalFlightTimeInHM}
        />
      </Grid>
      <Grid item>
        <StatPanel
          overtext='Total Incidents'
          maintext={totalIncidents?.toFixed()}
        />
      </Grid>
    </Grid>
  </>
}

export default withStyles(styles)(AboutInstructor)
