import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'

class UnauthorizedAccess extends Component { 
  _isMounted = false

  componentDidMount() {
    console.error('Unauthorized access. Redirecting to /enrol')
    this._isMounted = true
    if (this._isMounted) {
      this.props.push('/enrol')
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    return <p style={{ marginTop: '200px' }}>Redirecting...</p>
  }
}

const AdminProtectedRoute = ({ children, oidc, auth, push, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() =>
        oidc.user && oidc.user.profile.sub && auth.uato_personnel ? 
          children
          : 
          <UnauthorizedAccess push={push} />
      }
    />
  )
}

const mapStateToProps = (state) => {
  const { oidc, auth } = state
  return { oidc, auth }
}

export default connect(mapStateToProps, { push })(AdminProtectedRoute)
