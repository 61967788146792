import axios from 'axios'

import utils from '../utils'

const API_URL = process.env.REACT_APP_UATO_API_URL

export const getTraineeByEmail = async (email) => {
  const url = `${API_URL}/trainee/${email}`
  const options = utils.getJWTHeaders()
  
  const res = await axios.get(url, options)
  
  return res.data
}

export const getTraineeById = async (id) => {
  const url = `${API_URL}/trainee/${id}/details`
  const options = utils.getJWTHeaders()
  
  const res = await axios.get(url, options)
  
  return res.data
}

export const getLatestTraineeCredits = async (skip) => {
  const url = `${API_URL}/trainee-credits/${skip || 0}/skip`
  const options = utils.getJWTHeaders()
  
  const res = await axios.get(url, options)
  
  return res.data
}

export const searchTraineeByNameOrEmail = async (query, skip, ext_query) => {
  const skip_param = skip || 0
  const url = `${API_URL}/search-trainee/${query}/skip/${skip_param}${ext_query}`
  const options = utils.getJWTHeaders()
  
  const res = await axios.get(url, options)
  
  return res.data
}

export const updateTraineeDetailsById = async (trainee_details) => {
  const url = `${API_URL}/trainee/${trainee_details._id}/details_id`
  const options = utils.getJWTHeaders()

  const res = await axios.put(url, { trainee_details }, options)

  return res.data
}

export const updateTraineePlexDetails = async (plex_user) => {
  const email = plex_user.profile.email
  const user_id = plex_user.profile.sub

  const url = `${API_URL}/trainee/${email}/plex`
  const options = utils.getJWTHeaders()

  const res = await axios.put(url, { user_id }, options)

  return res.data
}

export const updateTraineeAdditionalDetails = async (trainee_email, additional_details_payload) => {
  const url = `${API_URL}/trainee/${trainee_email}/details`
  const options = utils.getJWTHeaders()
  
  const res = await axios.put(url, additional_details_payload, options)

  return res.data
}

export const getTraineeCredits = async (trainee_id) => {
  try {
    const url = `${API_URL}/trainee/${trainee_id}/credits`
    const options = utils.getJWTHeaders()

    const res = await axios.get(url, {...options, timeout: 60000})

    return res.data
  } catch (e) {
    return { error: e }
  }
}

export const getTraineeGrades = async (trainee_id) => {
  const url = `${API_URL}/retrieve_grade_by_trainee/${trainee_id}`
  const options = utils.getJWTHeaders()

  const res = await axios.get(url, options)

  return res.data
}

export const getAllTrainees = async () => {
  const url = `${API_URL}/trainees`
  const options = utils.getJWTHeaders()

  const res = await axios.get(url, options)

  return res.data
}

export const getPlexAuthTrainees = async (trainee_url_str) => {
  const url = `${API_URL}/trainees${trainee_url_str}`
  const options = utils.getJWTHeaders()

  const res = await axios.get(url, options)

  // console.log('res', res)

  return res.data
}

export const setTraineeCreditGrade = async (trainee_credit_id, data) => {
  const url = `${API_URL}/credit/${trainee_credit_id}`
  const options = utils.getJWTHeaders()

  const res = await axios.put(url, data, options)

  // console.log('res.data: ', res.data)

  return res.data
}

export const putCreditChange = async (credit_update) => {
  const url = `${API_URL}/credit/${credit_update.credit_id}/update_credit`
  const options = utils.getJWTHeaders()

  const res = await axios.put(url, credit_update, options)

  // console.log('res.data: ', res.data)

  return res.data
}

export const addCredit = async (payload) => {
  const url = `${API_URL}/credit/`
  const options = utils.getJWTHeaders()

  const res = await axios.post(url, {payload}, options)

  // console.log('res.data: ', res.data)

  return res.data
}

export const toggleTopupCredit = async (trainee_id, topup_enabled) => {
  const url = `${API_URL}/toggle-trainee-topup/`
  const options = utils.getJWTHeaders()

  const res = await axios.put(url, { trainee_id, topup_enabled }, options)

  // console.log('res.data: ', res.data)

  return res.data
}
