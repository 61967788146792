import * as auth_interface from './auth'
import * as course_schedule_interface from './course_schedule' 
import * as enrollment_interface from './enrollment'
import * as flight_interface from './flights'
import * as grade_interface from './grade'
import * as order_interface from './order'
import * as personnel_interface from './personnel'
import * as products_interface from './products'
import * as topup_interface from './topup'
import * as trainee_interface from './trainee'
import * as upload_interface from './upload'

const api_interfaces = {
  ...auth_interface,
  ...course_schedule_interface,
  ...enrollment_interface,
  ...flight_interface,
  ...grade_interface,
  ...order_interface,
  ...personnel_interface,
  ...products_interface,
  ...topup_interface,
  ...trainee_interface,
  ...upload_interface,
}

export default api_interfaces