import React from 'react'
import { connect } from 'react-redux'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'

import utils from '../../utils'
import api_interface from '../../api_interface'

const styles = {
  paper_wrapper: {
    display: 'flex',
    position: 'relative',
    padding: '12px 20px'
  },
  report_body_label: {
    color: '#757575',
    fontWeight: '500',
    lineHeight: '25px'
  },
  label_optional: {
    fontWeight: '500',
    fontSize: '.875em',
    color: '#9e9e9e'
  },
  label: {
    fontWeight: '500',
    fontSize: '.875em'
  }
}

class FlightReportForm extends React.Component {

  // TODO: Remove this hack - complain from React: Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.
  _isMounted = false

  constructor(props) {
    super(props)
    const {
      rendered_report,
      flight_time,
      grade_params,
      // remarks,
      // trainee,
      session,
      selected_checklist,
      selected_sortie,
      selected_module_code
    } = this.props
    // const verified_remarks = remarks ? utils.checkStringifiedObject(remarks) : false
    this.state = {
      form_report: rendered_report || null,
      grade_params: this.setParamsWithIndex(),
      remarks: this.setRemarksObject(grade_params) || {},
      flight_time: flight_time || { mins: 0, secs: 0 },
      checkall: false,
      preview_submit_flight: false,
      open_update_result: false,
      grade_err: null,
      grading_checklist: null,
      trainee: this.props.selected_trainee,
      session, selected_checklist, selected_sortie, selected_module_code,

      modules_selection: null,
      sorties_selection: null,
      checklist_selection: null,

      is_stick: false
    }
  }

  componentDidMount = async () => {
    this._isMounted = true
    window.addEventListener('scroll', this.stickSidebar)
    if (this._isMounted && this.props.init_grading_checklist) {
      await this.initializeForm()
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.grading_checklist !== prevState.grading_checklist && this.state.grading_checklist) {
      this.setState({
        grade_params: this.setParamsWithIndex()
      })
    }
    if (this.props.selected_module_code !== prevProps.selected_module_code) {
      this.setState({
        selected_module_code: this.props.selected_module_code
      })
    }
    if (this.props.selected_sortie !== prevProps.selected_sortie) {
      this.setState({
        selected_sortie: this.props.selected_sortie
      })
    }
    if (this.props.selected_checklist !== prevProps.selected_checklist) {
      this.setState({
        selected_checklist: this.props.selected_checklist
      })
    }
    if (this.props.session !== prevProps.session) {
      this.setState({
        session: this.props.session
      })
      await this.initializeForm()
    }
    if (this.props.remarks !== prevProps.remarks) {
      // const verified_remarks = this.props.remarks ? utils.checkStringifiedObject(this.props.remarks) : false
      this.setState({
        remarks: this.setRemarksObject(this.props.grade_params) || {}
      })
    }
    if (this.props.selected_trainee !== prevProps.selected_trainee) {
      this.setState({
        trainee: this.props.selected_trainee
      })
      await this.initializeForm()
    }
    if (this.props.grade_params !== prevProps.grade_params) {
      // const {
      //   grade_params,
      //   remarks
      // } = this.props

      // const verified_remarks = remarks ? utils.checkStringifiedObject(remarks) : false

      this.setState({
        grade_params: this.setParamsWithIndex(),
        remarks: this.setRemarksObject(this.props.grade_params) || {},
        checkall: false,
        preview_submit_flight: false
      })

      if (this.state.form_report) {
        this.checkboxBulkUpdates(false)
      }
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false
    window.removeEventListener('scroll', this.stickSidebar)
  }

  stickSidebar = () => {
    this.setState({
      is_stick: window.scrollY > 93
    })
  }

  initializeForm = async () => {
    const parsed_checklist =  utils.checkStringifiedObject(this.props.selected_checklist) ? JSON.parse(this.props.selected_checklist) : null

    if (this.props.extend_form) {
      if (parsed_checklist && this.props.session.course) {
        let grading_checklist = this.state.grading_checklist
        if (!grading_checklist) {
          const grade_res_data = await api_interface.initializeGradeForm()
          grading_checklist = grade_res_data.grading_checklist
        }

        const selected_module_checklist = grading_checklist[parsed_checklist.course]

        const modules_selection = this.getModuleCodeList(grading_checklist)
        const sorties_selection = this.getSortiesSelection(selected_module_checklist, parsed_checklist.code.replace(/\./g, '_') )

        this.setState({
          modules_selection: modules_selection,
          sorties_selection: sorties_selection,
          checklist_selection: modules_selection,
          grading_checklist
        })
      } else {
        alert('Cannot initialize flight grade form')
      }
    }
  }

  componentWillUnmount = () => {
    if (this._isMounted) {
      this._isMounted = false
    }
  }

  setParamsWithIndex = () => {
    const {
      grade_params,
      // selected_checklist
    } = this.props
    return grade_params && grade_params.length > 0 ? grade_params.map((gp, i) => {
      return {
        ...gp,
        i,
        flight_patterns: gp.flight_patterns.map( (gpfp, j) => {
          return {
            ...gpfp,
            j
          }
        } )
      }
    }) : null
  }

  setRemarksObject = (grade_params) => {

    const training_phases = grade_params && grade_params.length > 0 ? grade_params.map( (gp, i) => `${gp.training_phase} - pattern${i}` ) : []

    const remarks = training_phases.reduce((accumulator, current, i) => {
      accumulator[current] = this.state && this.state.remarks && this.state.remarks[current] ? this.state.remarks[current] || '' : this.props.remarks && utils.checkStringifiedObject(this.props.remarks) ? JSON.parse(this.props.remarks)[current] || '' : ''
      return accumulator
    }, {})

    return remarks
  }

  getModuleCodeList = (grading_checklist) => {
    let code_list = []

    for (const grade in grading_checklist) {
      code_list.push( ...grading_checklist[grade].map(gc => { return { course: grade, code: gc.code } } ))
    }

    return code_list
  }

  getSortiesSelection = (modules_list, module_code) => {
    const selected_module_index = modules_list ? modules_list.findIndex( mod_list => mod_list.code === module_code ) : -1
    return selected_module_index > -1 ? modules_list[selected_module_index].sorties.map( s => s.sortie ) : null
  }

  checkboxBulkUpdates = (grade_status) => {
    this.setState({
      grade_params: this.state.grade_params.map( gp => {
        return {
          ...gp,
          flight_patterns: gp.flight_patterns.map( fp => {
            return {
              ...fp,
              grade: grade_status
            }
          })
        }
      })
    })
  }

  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleChangeObject = (e, state_name, key_name) => {
    const {trainee, session} = this.state
    if (!(trainee && session && trainee._id && session._id)) {
      alert('Please select trainee and session first')
      return false
    }
    this.setState({
      [state_name]: {
        ...this.state[state_name],
        [key_name]: e.target.value
      }
    })
  }

  handleChangeCheckbox = ({ phase, sortie, fp_object, i, j }) => {
    const {trainee, session} = this.state
    if (!(trainee && session && trainee._id && session._id)) {
      alert('Please select trainee and session first')
      return false
    }

    const { pattern, grade } = fp_object
    const grade_params = [ ...this.state.grade_params ]

    const phase_index = grade_params.findIndex(gp => gp.training_phase === phase && gp.i === i && gp.flight_patterns.findIndex( gpfp => gpfp.j === j) > -1 )
    const pattern_index = grade_params[phase_index].flight_patterns.findIndex(fp => fp.pattern === pattern)
    
    // Please consider react immutability issue when updating state
    this.setState(({ grade_params }) => ({
      grade_params: [
        ...grade_params.slice(0, phase_index),
        {
          ...grade_params[phase_index],
          flight_patterns: [
            ...grade_params[phase_index].flight_patterns.slice(0, pattern_index),
            {
              ...grade_params[phase_index].flight_patterns[pattern_index],
              grade: !grade
            },
            ...grade_params[phase_index].flight_patterns.slice(pattern_index+1)
          ]
        },
        ...grade_params.slice(phase_index+1)
      ]
    }))
  }

  handleCheckAllCheckbox = (e) => {
    const {trainee, session} = this.state
    if (!(trainee && session && trainee._id && session._id)) {
      alert('Please select trainee and session first')
      return false
    }
    this.setState({
      checkall: !this.state.checkall
    })
    this.checkboxBulkUpdates(e.target.checked)
  }

  handleChangePlusSortie = (e) => {
    const confirmation = !(this.state.trainee && this.state.session) ? alert('Please select trainee and session first') : this.state.checklist_selection !== e.target.value ? window.confirm("Checklist form will be re-initialized. Are you sure?") : true
    if (confirmation) {
      const parsed_target_value = JSON.parse(e.target.value)
      
      const selected_module_checklist = this.state.grading_checklist[parsed_target_value.course]
      const sorties_selection = this.getSortiesSelection(selected_module_checklist, parsed_target_value.code)

      const selected_module_pattern = selected_module_checklist.find( mod_list => mod_list.code === parsed_target_value.code )

      const flight_patterns = this.renderChecklist(selected_module_pattern)

      this.setState({
        selected_checklist: e.target.value,
        selected_sortie: 1,
        sorties_selection,
        ...flight_patterns
      })
    }
  }

  handleChangeTime = (e, name) => {
    const { value } = e.target
    const time_value = parseInt(value) > 59 ? 59 : parseInt(value) < 0 ? 0 : parseInt(value)
    this.setState((state) => ({
      flight_time: {
        ...state.flight_time,
        [name]: time_value
      }
    }))
  }

  renderChecklist = (module_pattern, refresh = false) => {
    const grade_params_aggr = module_pattern.sorties.map( s => s.content.map( c => {
      return {
        sortie: s.sortie,
        flight_patterns: c.flight_patterns.map( fp => { return { pattern: fp, grade: false} } ),
        training_phase: c.training_phase
      }
    }) )

    const grade_params = [].concat.apply([], grade_params_aggr)

    return {
      grade_code: module_pattern.code,
      grade_params
    }
  }

  cancelFlight = (e) => {
    e.preventDefault()
    this.props.exitFormReport(false)
  }

  previewFlight = (e) => {
    e.preventDefault()
    if (this.state.flight_time.mins < 0 || (this.state.flight_time.mins === 0 && this.state.flight_time.secs === 0)) {
      alert('Please insert flight time before submitting flight')
      return false
    }
    this.setState({
      preview_submit_flight: true
    })
  }

  submitFlight = async (e) => {
    const { grade_params, session, trainee, selected_checklist, selected_sortie, selected_module_code, remarks,flight_time } = this.state

    const grade_code = utils.checkStringifiedObject(selected_checklist) ? JSON.parse(selected_checklist).code : null
    const sortie = selected_sortie
    const module_code = utils.checkStringifiedObject(selected_module_code) ? JSON.parse(selected_module_code).code : null

    const validated_flight_submission = session && session.course_id && this.props.auth && this.props.auth.uato_personnel && grade_code && sortie && module_code

    if (validated_flight_submission) {
      const flight_grade_json = {
        _id: this.props.form_id || null,
        credit: session,
        schedule: session.course_schedule_id,
        trainee: trainee,
        personnel: this.props.auth._id,
        grade_params,
        grade_code,
        sortie,
        module_code,
        remarks,
        flight_time
      }

      this.setState({ send_grade: true })

      const save_flight_grade_res_data = await api_interface.submitFlightGradeForm(flight_grade_json)
      if (save_flight_grade_res_data.success){
        // const flight_grade = save_flight_grade_res_data.flight_grade
        this.setState({
          grade_err: null,
          send_grade: false,
          preview_submit_flight: false,
          open_update_result: true,
          success_update: true
        })
      } else {
        const error = save_flight_grade_res_data.error
        this.setState({
          send_grade: false,
          preview_submit_flight: false,
          grade_err: error,
          open_update_result: true,
          success_update: true
        })
      }

      if (this.props.extend_form) {
        this.props.exitFormReport(true)
      }

    } else {
      const errorReason = () => {
        switch(true) {
          case !session:
            return 'Credit is not available'
          case !session.course_id:
            return 'Class is not being enrolled yet'
          case !this.props.auth:
            return 'Not authenticated'
          // Unreachable code - maybe to go before previous check?
          // case !this.props.auth.uato_personnel:
          //   return 'Not UATO personnel'
          default:
            return 'Cannot create or update flight grade'
        }
      }
      alert('The flight grade is not valid.\nReason: ' + errorReason() + '.\nPlease check again')
      return false
    }
    
  }

  renderNull = () => {
    return (
      <div style={{ textAlign: 'center', width: 'calc(100% - 4em)', padding: '2em' }} >
        <CircularProgress />
      </div>
    )
  }

  renderPreviewDialog = () => {
    const {
      grade_params,
      trainee,
      session,
      selected_checklist,
      selected_sortie,
      selected_module_code,
      // remarks,
      send_grade
    } = this.state
    const parsed_checklist = utils.checkStringifiedObject(selected_checklist) && JSON.parse(selected_checklist)
    const parsed_module = utils.checkStringifiedObject(selected_checklist) && JSON.parse(selected_module_code)
    if (parsed_checklist && parsed_module) {
      return (
        <Dialog open={ this.state.preview_submit_flight }
          onClose={() => this.setState({ preview_submit_flight: false })}
          style={{ margin: '0 auto' }}
        >
          <DialogTitle>Flight Report Preview</DialogTitle>
          <DialogContent>
            <div style={{ maxWidth: '100%', minWidth: '500px' }}>
              <div style={{ margin: '1em 0' }}>
                <div><strong>Trainee:</strong> { trainee.full_name}</div>
                <div><strong>Student ID:</strong> { trainee.student_id }</div>
                <div><strong>Class:</strong> { session.course.name } - { session.session_number }</div>
                <div>{parsed_module.code.replace(/_/g, '.') } { parsed_checklist.code.replace(/_/g, '.') }, Sortie { selected_sortie }</div>
              </div>
              <div style={{ margin: '1em 0' }} >
                <span style={styles.label_optional}>Patterns</span>
                { grade_params.map( (params) => params.flight_patterns.map( (fp, i) => {
                  return (
                    <div key={i} style={{ display: 'flex', lineHeight: '1.5', margin: '.5em 0', alignItems: 'center' }} ><span style={{ marginRight: '.5em' }} >{ fp.grade ? <CheckIcon style={{ fontSize: '1em', color: '#03DAC6' }} /> : <CloseIcon style={{ fontSize: '1em', color: '#B00020' }} /> }</span> {fp.pattern}</div>
                  )
                }) ) }
              </div>
              <div style={{ margin: '1em 0' }}>
                <span style={styles.label_optional}>Remarks</span>
                <div>{ utils.renderRemarks(this.state.remarks) }</div>
              </div>
              <div style={{ margin: '1em 0' }}>
                <span style={styles.label_optional}>Time (min: secs)</span>
                <div>{ utils.formatTimeWithZero(this.state.flight_time.mins) }:{ utils.formatTimeWithZero(this.state.flight_time.secs) }</div>
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ borderTop: '1px solid #e0e0e0' }}>
            {
              send_grade ?
              this.renderNull()
              :
              <div>
                <Button onClick={() => this.setState({ preview_submit_flight: false })} style={{ backgroundColor: 'white', color: '#1E90FF', textTransform: 'none' }}>
                  <strong>Close</strong>
                </Button>
                <Button disabled={ send_grade } onClick={(e) => this.submitFlight(e)} style={{ textTransform: 'none' }}>
                  {
                    this.props.extend_form ?
                    <strong>Update Flight Record</strong>
                    :
                    <strong>Create Flight Record</strong>
                  }
                </Button>
              </div>
            }
          </DialogActions>
        </Dialog>
      )      
    }
  }

  renderChecklistSelection = () => {
    const { modules_selection, sorties_selection, checklist_selection } = this.state
    if (!(modules_selection && sorties_selection && checklist_selection)) {
      return this.renderNull()
    }
    return (
      <div style={{ width: '100%' }} >
        <div style={{ width: '100%', margin: '1em 0' }} >
          <div style={{ margin: '1em 0' }} >
            <div style={{ fontWeight: '700' }}><span style={styles.label}>Module Code</span></div>
            <Select value={ this.state.selected_module_code } onChange={ (event) => this.handleChange(event, 'selected_module_code') }>
              {
                modules_selection.map( (ms, i) => {
                  return (
                    <MenuItem value={JSON.stringify(ms)} key={i}>{ ms.code.replace(/_/g, '.') }</MenuItem>
                  )
                })
              }
            </Select>
          </div>
        </div>
        <div style={{ width: '100%', margin: '1em 0 0', display: 'flex' }} >
          <div style={{ flexBasis: '50%', paddingRight: '1em' }} >
            <div style={{ fontWeight: '700' }}><span style={styles.label}>Sortie</span></div>
            <Select value={ this.state.selected_sortie } onChange={ (event) => this.handleChange(event, 'selected_sortie') }>
              {
                sorties_selection.map( (ss, i) => {
                  return (
                    <MenuItem value={ss} key={i}>{ ss }</MenuItem>
                  )
                })
              }
            </Select>
          </div>
          <div style={{ flexBasis: '50%', paddingLeft: '1em' }} >
            <div style={{ fontWeight: '700' }}><span style={styles.label}>Checklist</span></div>
            <Select value={ this.state.selected_checklist } onChange={ (event) => this.handleChangePlusSortie(event, 'selected_checklist') }>
              {
                checklist_selection.map( (cs, i) => {
                  return (
                    <MenuItem value={JSON.stringify(cs)} key={i}>{ cs.code.replace(/_/g, '.') }</MenuItem>
                  )
                })
              }
            </Select>
          </div>
        </div>
      </div>
    )
  }

  renderInSinglePage(disabled_submit) {
    const { renderFormSidebar } = this.props
    const { is_stick } = this.state

    const sidebar_style = {
      position: 'sticky',
      top: is_stick ? '94px' : -window.scrollY,
      minWidth: '370px',
      WebkitTransition: 'all 200ms linear',
      MozTransition: 'all 200ms linear',
      OTransition: 'all 200ms linear',
      transition: 'all 200ms linear',
    }

    return (
      <Grid container>
        <Grid item md={4}>
          <Paper style={ sidebar_style } >
            { renderFormSidebar() }
            <div style={{ padding: '0 20px 12px 20px' }} >
              <Button style={{ width: '100%', textAlign: 'center' }} disabled={ disabled_submit } onClick={ (e) => this.previewFlight(e) }>Submit Flight</Button>
            </div>
          </Paper>
        </Grid>
        <Grid item md={8} >
          <div style={{ marginLeft: '15px' }} >
            <Paper>
              { this.renderMainContent(disabled_submit) }
            </Paper>
          </div>
        </Grid>
      </Grid>
    )
  }

  renderMainContent(disabled_submit) {
    const {
      extend_form,
      // top_submit_flight
    } = this.props
    const {
      grade_params,
      // remarks,
      open_update_result,
      trainee,
      session,
      // selected_checklist,
      // selected_sortie,
      selected_module_code,
      grade_err
      // grading_checklist
    } = this.state

    return (
      <div style={{ position: 'relative', zIndex: '9' }} >
        { extend_form &&
          <div style={ styles.paper_wrapper }>
            { this.renderChecklistSelection() }
          </div>
        }
        { grade_params && grade_params.length > 0 ?
          <div>
            <div style={ styles.paper_wrapper } >
              <div>
                <h3>Patterns</h3>
                <div style={{ margin: '24px 0' }} >
                  <FormControlLabel control={<Checkbox name='Check All' checked={this.state.checkall} onChange={(e) => this.handleCheckAllCheckbox(e)} />} label={'Check All'} />
                </div>
                { grade_params.map( (gp, i) => {
                  return (
                    <div key={i} style={{ margin: '1em 0' }}>
                      <span style={styles.label_optional}>{ gp.training_phase }</span>
                      { gp.flight_patterns.map( (fp, j) => {
                        return (
                          <div key={j}>
                            <FormControlLabel control={<Checkbox name={fp.pattern} checked={fp.grade} onChange={() => this.handleChangeCheckbox({ phase: gp.training_phase, sortie: gp.sortie, fp_object: fp, i, j })} />} label={fp.pattern} />
                          </div>
                        )
                      } ) }
                      <div style={{ margin: '12px 0 36px' }}>
                        <span style={styles.label}>Remarks</span> <span style={styles.label_optional}>(optional)</span>
                        <br />
                        <TextField
                          placeholder=""
                          style={{ marginTop: 6, width: '100%' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name={gp.training_phase}
                          value={ this.state.remarks[`${gp.training_phase} - pattern${i}`] || '' }
                          onChange={(event) => this.handleChangeObject(event, 'remarks', `${gp.training_phase} - pattern${i}`)}
                        />
                      </div>
                    </div>
                  )
                } ) }
              </div>
            </div>
            <Divider />
            <div style={ styles.paper_wrapper } >
              <div style={{ margin: '24px 0 0' }}>
                <span style={styles.label}>Flight Time</span>
                <br />
                <TextField style={{ width: '100px' }} type="number" disabled={ !(trainee && session && trainee._id && session._id) } placeholder="--" value={ this.state.flight_time.mins } max={60} onChange={e => this.handleChangeTime(e, 'mins')} />
                <TextField style={{ width: '100px', margin: '0 20px' }} type="number" disabled={ !(trainee && session && trainee._id && session._id) } placeholder="--" value={ this.state.flight_time.secs } max={60} onChange={e => this.handleChangeTime(e, 'secs')} />
              </div>
            </div>
            {
              extend_form ?
              <div style={{ ...styles.paper_wrapper, justifyContent: 'flex-end' }} >
                <Button style={{ margin: '20px', background: 'transparent', color: '#212121' }} onClick={ (e) => this.cancelFlight(e) } >Close</Button>
                <Button disabled={ disabled_submit } style={{ margin: '20px 0' }} onClick={ (e) => this.previewFlight(e) }>Save</Button>
              </div>
              :
              <div style={{ ...styles.paper_wrapper, justifyContent: 'flex-end' }} >
                <Button disabled={ disabled_submit } style={{ margin: '20px 0' }} onClick={ (e) => this.previewFlight(e) }>Submit Flight</Button>
              </div>
            }
             { !disabled_submit && selected_module_code && this.renderPreviewDialog() } 
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={ open_update_result }
              autoHideDuration={6000}
              onClose={ () => this.setState({ open_update_result: false }) }
            >
              {
                grade_err ?
                <Alert onClose={ () => this.setState({ open_update_result: false }) } severity="error">
                  <div>Cannot submit flight report for { trainee && trainee.full_name } - { trainee && trainee.student_id }.</div>
                  <div>Reason: { JSON.stringify(grade_err) } </div> 
                </Alert>
                :
                <Alert onClose={ () => this.setState({ open_update_result: false }) } severity="success">
                  <div>You have successfully submitted a flight report for:</div>
                  <div>{ trainee && trainee.full_name } - { trainee && trainee.student_id }</div> 
                </Alert>
              }
            </Snackbar>
          </div>
          :
          this.renderNull()
        }
      </div>
    )
  }

  render() {
    const { extend_form } = this.props
    const {
      // grade_params,
      // remarks,
      // open_update_result,
      trainee,
      session,
      selected_checklist,
      selected_sortie
      // selected_module_code,
      // grade_err,
      // grading_checklist
    } = this.state

    const disabled_submit = !(trainee && trainee._id && session && session._id && selected_checklist && selected_sortie )

    return (
      <div>
        {
          extend_form ?
          this.renderMainContent(disabled_submit)
          :
          this.renderInSinglePage(disabled_submit)
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps)(FlightReportForm)

