const PersonnelReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_PERSONNEL_SUCCESS':
      return { ...state, ...action.payload }
    
    case 'GET_PERSONNEL_FAIL':
      return { error: action.payload }

    default:
      return state
   }
}

export default PersonnelReducer