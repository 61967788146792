import React from 'react'
import { connect } from 'react-redux'
import { CallbackComponent } from 'redux-oidc'
import { push } from 'connected-react-router'

import { setAuthUser, removeAuthUser } from '../actions'
import userManager from '../utils/userManager'


class LoginCallback extends React.Component {

  successCallback = async (plex_user) => {
    // console.log('plex_user: ', plex_user)
    
    if (plex_user && plex_user.access_token && plex_user.profile.sub) {      
      const {
        success,
        uato_personnel,
        update_trainee_details
      } = await this.props.setAuthUser(plex_user)

      // console.log('success', success)
      // console.log('uato_personnel', uato_personnel)

      if (success) {
        if (uato_personnel)
          this.props.push('/admin/orders')
        else {
          if (update_trainee_details) 
            this.props.push('/user/details')
          else 
            this.props.push('/schedule')
        }
      } else {
        this.props.push('/500')
      }
    }
  }

  errorCallback = async (error) => {
    console.log('Login error callback: ', error)
    
    // We sign in redirect again for cases where user
    // signs in post verification email

    if (!this.props.oidc.user) {
      this.props.removeAuthUser()
      await userManager.signinRedirect()
    } else {
      this.props.push('/dashboard')
    }
  }
  
  render() {
    // just redirect to '/' in both cases
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={this.successCallback}
        errorCallback={this.errorCallback}
      >
          <div>Redirecting...</div>
      </CallbackComponent>
    )
  }
}

const mapStateToProps = state => {
  return {
    trainee: state.trainee,
    oidc: state.oidc
  }
}

export default connect(mapStateToProps, { push, setAuthUser, removeAuthUser })(LoginCallback)
