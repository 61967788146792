import React from 'react'

import Paper from '@material-ui/core/Paper'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Button } from '@material-ui/core'

const requested_payment = 'Requested Payment'
const engaging = 'Engaging'
const payment_expected = 'Payment Expected'
const paid_and_confirmed = 'Paid and Confirmed'
const no_response = 'No Response'
const cancelled = 'Cancelled'

const array_status = [ requested_payment, engaging, payment_expected, paid_and_confirmed, no_response, cancelled ]
const now_copy1 = new Date(), now_copy2 = new Date(), now_copy3 = new Date()
const min_one = new Date(now_copy1.setDate(now_copy1.getDate() - 1))
const min_seven = new Date(now_copy2.setDate(now_copy2.getDate() - 7))
const min_month = new Date(now_copy3.setMonth(now_copy3.getMonth() - 1))
const array_date = [
  {value: new Date(Date.UTC(min_one.getFullYear(), min_one.getMonth(), min_one.getDate())).toISOString(), label: 'Last Day'},
  {value: new Date(Date.UTC(min_seven.getFullYear(), min_seven.getMonth(), min_seven.getDate())).toISOString(), label: 'Last Week'},
  {value: new Date(Date.UTC(min_month.getFullYear(), min_month.getMonth(), min_month.getDate())).toISOString(), label: 'Last Month'},
  {value: '-1', label: 'Anytime'}
]
const array_payment = [ 'stripe', 'bank', 'paynow', 'invoice' ]
const array_course = [ 'UT01', 'UT02', 'UT03A', 'UT03B', 'UT04', 'UT05', 'UA01', 'UA02', 'UA03', 'UA04', 'UA05' ]

class OrderFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date_filter: '',
      status_filter: [],
      payment_filter: [],
      course_filter: [],
      panel_date: true,
      panel_status: true,
      panel_payment: true,
      panel_course: true,
    }
  }

  componentDidMount = () => {
    this.transferProps()
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.filters !== prevProps.filters) {
      this.transferProps()
    }
  }

  applyFilter = async (e, name) => {
    this.setState({
      [name]: e.target.checked
    })
  }

  clearFilter = (e) => {
    e.preventDefault()
    this.resetFilter()
  }

  resetFilter = () => {
    this.setState({
      date_filter: '',
      status_filter: [],
      payment_filter: [],
      course_filter: []
    })
  }

  transferProps = () => {
    this.setState({
      date_filter: this.props.filters.date_param[0] || '',
      status_filter: this.props.filters.status_param || [],
      payment_filter: this.props.filters.payment_param || [],
      course_filter: this.props.filters.course_filter || []
    })
  }

  toggleAccordion = (name) => {
    this.setState({
      [name]: !this.state[name]
    })
  }

  handleChangeCheckbox = (e, filter_group, value) => {
    const targeted_filter = this.state[filter_group]
    const filter_update = targeted_filter.findIndex( tf => tf === e.target.name ) < 0 ? [ ...targeted_filter, e.target.name] : targeted_filter.filter( tf => tf !== value)
    this.setState({
      [filter_group]: filter_update
    })
  }

  handleChangeRadio = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  submitOrderFilter = (e) => {
    const { date_filter, status_filter, payment_filter, course_filter } = this.state
    const filters = {
      date_param: date_filter,
      status_param: status_filter,
      payment_param: payment_filter,
      course_param: course_filter
    }
    const filter_url = this.props.setFilterUrlParams(filters)
    const url_str = `/admin/orders?page=1&limit=10${filter_url}`
    this.props.history.push(url_str)
    this.resetFilter()
  }

  renderDateFilter = () => {
    return (
      <Accordion style={{ boxShadow: 'none', margin: 0 }} square expanded={this.state.panel_date} onChange={() => this.toggleAccordion('panel_date') }>
        <AccordionSummary aria-controls="panel1-content" id="panel1-header" style={{ borderBottom: '1px solid #e0e0e0', height: 60 }} expandIcon={<ExpandMoreIcon />}>
          <div style={{ fontWeight: '500', padding: 0, margin: 0 }} >Date Added</div>
        </AccordionSummary>
        <AccordionDetails style={{ background: '#F9FAFC' }}>
          <FormControl component="fieldset">
            <RadioGroup aria-label="date-filter" name="date-filter" value={this.state.date_filter} onChange={ e => this.handleChangeRadio(e, 'date_filter') }>
              { array_date.map( (adate, i) => {
                return (
                  <FormControlLabel key={i} value={adate.value} control={<Radio />} label={<span style={{ fontSize: '14px' }}>{adate.label}</span>} />
                )
              } ) }
            </RadioGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    )
  }

  renderStatusFilter = () => {
    return (
      <Accordion style={{ boxShadow: 'none', margin: 0 }} square onChange={() => this.toggleAccordion('panel_status') }>
        <AccordionSummary aria-controls="panel2-content" id="panel2-header" style={{ borderBottom: '1px solid #e0e0e0', height: 60 }} expandIcon={<ExpandMoreIcon />}>
          <div style={{ fontWeight: '500', padding: 0, margin: 0 }} >Lead Status</div>
        </AccordionSummary>
        <AccordionDetails style={{ background: '#F9FAFC' }}>
          <FormGroup>
            { array_status.map((ast, i) => {
              return (
                <FormControlLabel
                  key={i}
                  control={<Checkbox checked={ this.state.status_filter.findIndex( status => status === ast ) > -1 } onChange={ e => this.handleChangeCheckbox(e, 'status_filter', ast)} name={ ast } />}
                  label={ <span style={{ fontSize: '14px' }}>{ast}</span> }
                />
              )
            }) }
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    )
  }

  renderPaymentFilter = () => {
    return (
      <Accordion style={{ boxShadow: 'none', margin: 0 }} square onChange={() => this.toggleAccordion('panel_status') }>
        <AccordionSummary aria-controls="panel3-content" id="panel3-header" style={{ borderBottom: '1px solid #e0e0e0', height: 60 }} expandIcon={<ExpandMoreIcon />}>
          <div style={{ fontWeight: '500', padding: 0, margin: 0 }} >Payment Type</div>
        </AccordionSummary>
        <AccordionDetails style={{ background: '#F9FAFC' }}>
          <FormGroup>
            { array_payment.map((ast, i) => {
              return (
                <FormControlLabel
                  key={i}
                  control={<Checkbox checked={ this.state.payment_filter.findIndex( payment => payment === ast ) > -1 } onChange={ e => this.handleChangeCheckbox(e, 'payment_filter', ast)} name={ ast } />}
                  label={ <span style={{ fontSize: '14px', textTransform: 'capitalize' }}>{ast}</span> }
                />
              )
            }) }
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    )
  }

  renderCourseFilter = () => {
    return (
      <Accordion style={{ boxShadow: 'none', margin: 0 }} square onChange={() => this.toggleAccordion('panel_status') }>
        <AccordionSummary aria-controls="panel3-content" id="panel3-header" style={{ borderBottom: '1px solid #e0e0e0', height: 60 }} expandIcon={<ExpandMoreIcon />}>
          <div style={{ fontWeight: '500', padding: 0, margin: 0 }} >Course</div>
        </AccordionSummary>
        <AccordionDetails style={{ background: '#F9FAFC' }}>
          <FormGroup>
            { array_course.map((ast, i) => {
              return (
                <FormControlLabel
                  key={i}
                  control={<Checkbox checked={ this.state.course_filter.findIndex( course => course === ast ) > -1 } onChange={ e => this.handleChangeCheckbox(e, 'course_filter', ast)} name={ ast } />}
                  label={ <span style={{ fontSize: '14px', textTransform: 'capitalize' }}>{ast}</span> }
                />
              )
            }) }
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    )
  }

  render() {
    return (
      <Paper style={{ position: 'relative', zIndex: '999', width: '400px', textAlign: 'left' }} >
        <div style={{ display: 'flex', alignItems: 'center', background: '#fff', padding: '12px 15px', width: 'calc(100% - 24px)', borderBottom: '1px solid #e0e0e0' }} >
          <div style={{ flexBasis: '50%', fontSize: '20px', fontWeight: '500', color: '#616161' }} >Filters</div>
          <div style={{ display: 'flex', flexBasis: '50%', justifyContent: 'flex-end', alignItems: 'center' }} >
            <div onClick={(e) => this.clearFilter(e)} style={{ margin: '6px 18px', color: '#1E90FF', fontSize: '16px', fontWeight: '500', cursor: 'pointer' }}>Clear</div>
            <Button style={{ textTransform: 'none' }} onClick={(e) => this.submitOrderFilter(e)} >Apply</Button>
          </div>
        </div>
        <div style={{ background: '#fff', maxHeight: '450px', overflowY: 'auto' }}>
          { this.renderDateFilter() }
          { this.renderStatusFilter() }
          { this.renderPaymentFilter() }
          { this.renderCourseFilter() }
        </div>
      </Paper>
    )

    // return (
    //   <Slide direction="bottom" mountOnEnter unmountOnExit>
    //     <div style={sidebar_style}>
    //       <div style={sidebar_wrapper}>
    //         <div style={{ fontSize: '1.2rem', fontWeight: '500' }} >{`${selected_trainee ? selected_trainee.first_name : ``} ${selected_trainee ? selected_trainee.last_name : ``}`} </div>
    //         { selected_trainee &&
    //           <div><Link to={`/admin/edit-trainee/${selected_trainee._id}`}>Edit Details</Link></div>
    //         }
    //         <IconButton onClick={(e) => closeTraineeDetail(e)} style={{ color: '#212121', position: 'absolute', right: 10, top: 10 }} >
    //           <CloseIcon />
    //         </IconButton>
    //       </div>
    //       { this.renderProfile(selected_trainee) }
    //       { this.renderAcademic(selected_trainee, arranged_credit) }
    //       { this.renderCourse(arranged_credit) }
    //     </div>
    //   </Slide>      
    // )
  }
}


export default OrderFilter


