import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'

import LeftIcon from '@material-ui/icons/ChevronLeft'
import RightIcon from '@material-ui/icons/ChevronRight'

import CalendarDay from './CalendarDay'
import CalendarCell from './CalendarCell'

import { days_of_the_week } from '../utils/constants.json'

const styles = {
  row: {
    display: 'flex',
    width: '100%'
  },
  calendarContainer: {
    backgroundColor: 'white',
    border: '1px solid #E5E5E5',
    borderRadius: '5px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '12px',
    paddingBottom: '12px',
    marginLeft: '30px'
  },
  prevNextMonthButton: {
    transform: 'translateY(70%)',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  dayOfTheWeek: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '12px'
  }
}

class Calendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // current_month: moment(),
      current_month: moment().set('month', this.props.set_month),
      selected: moment().startOf('day')
    }
  }

  // Sometimes constructor didn't get the props update from parent component (especially in the build version)
  // Add componentDidUpdate to detect and render prop change of current_month in the child component (calendar page)
  componentDidUpdate(prevProps) {
    if (this.props.set_month !== prevProps.set_month) {
      this.setState({
        current_month: moment().set('month', this.props.set_month)
      })
    }
  }
  
  previousMonth = () => {
    const { current_month } = this.state 

    this.setState({ current_month: current_month.subtract(1, 'month') })
  }

  nextMonth = () => {
    const { current_month } = this.state 

    this.setState({ current_month: current_month.add(1, 'month') })
  }

  select = (day) => {
    this.setState({
      selected: day.date,
      month: day.date.clone()
    })
  }

  getCalendarWeeks = () => {
    let calendar_weeks = []
    let temp_days = [] 

    const current_month = this.state.current_month.clone()
    const start_day =  current_month.startOf('month').day()
    const days_in_month = current_month.daysInMonth()
    const max_calendar_slots = days_in_month + start_day - 1

    // Add non existent days before the first day of the month
    for (let i = 0; i <= max_calendar_slots; i++) {
      if (i < start_day)
        temp_days.push(0) 
      else 
        temp_days.push(i - start_day + 1)
    }

    // Break down temp_days array to weeks
    while (temp_days.length) {
      let week = temp_days.splice(0, 7)

      while (week.length !== 7) {
        week.push(0)
      }

      calendar_weeks.push(week)
    }
    
    return calendar_weeks
  }

  courseOnDate = (calendar_date) => {
    // date filter is not applicable for multiple schedules on the same date
    // for (const course of this.props.course_schedule) {
    //   const course_date = new Date(course.start_time)

    //   const course_month = course_date.getMonth()
    //   const course_day = course_date.getDate()

    //   const calendar_date_month = calendar_date.getMonth()
    //   const calendar_date_day = calendar_date.getDate()

    //   if (course_month === calendar_date_month && course_day === calendar_date_day) {
    //     return course
    //   }
    // }

    return this.props.course_schedule.filter(course =>
      new Date(course.start_time).getMonth() === calendar_date.getMonth() &&
      new Date(course.start_time).getDate() === calendar_date.getDate()
    )
  }

  renderCalendarDays = () => {
    const calendar_weeks = this.getCalendarWeeks()

    const now = new Date()

    return calendar_weeks.map((week, i) => {
      return (
        <div key={i} style={styles.row}>
          {week.map((day, index) => {
            const { current_month } = this.state
            const calendar_date = new Date(current_month.year(), current_month.month(), day)
            const course = this.courseOnDate(calendar_date)

            const today = now.getFullYear() === calendar_date.getFullYear() && now.getMonth() === calendar_date.getMonth() && now.getDate() === calendar_date.getDate()

            return <CalendarDay day={day} key={index} scheduled={course} today={today} />
          })}
        </div>
      )
    })
  }

  render() {

    return (
      <>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        
        <div style={styles.calendarContainer}>
          <div style={styles.row}>
              <LeftIcon onClick={() => this.previousMonth()} style={styles.prevNextMonthButton} />
              <h4 style={{ flex: 1, textAlign: 'center' }}>{this.state.current_month.format('MMMM YYYY')}</h4>
              <RightIcon onClick={() => this.nextMonth()} style={styles.prevNextMonthButton} /> 
          </div>
          
          <br/>
          
          <div style={styles.row}>
            {days_of_the_week.map((day, i) => {
              return <CalendarCell key={i} > <span style={styles.dayOfTheWeek}>{day}</span></CalendarCell>
            })}
          </div>

          {this.renderCalendarDays()}
        </div>

        <br/>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    course_schedule: state.course_schedule.filter(cs => cs.deleted_on === null)
  }
}

export default connect(mapStateToProps)(Calendar)

