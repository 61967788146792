import axios from 'axios'

import utils from '../utils'

const API_URL = process.env.REACT_APP_UATO_API_URL

export const enrollUserToCourse = async (scheduled_courses, auth) => {
  // console.log(`
  //   Enrolling ${auth.email} (${auth._id}) to ${scheduled_course.course_name} (${scheduled_course._id}) 
  //   on ${utils.formatTime(scheduled_course.start_time)}  
  // `)

  const payload = {
    trainee_id: auth._id,
    // course_schedule_id: scheduled_course._id
    scheduled_courses
  }
  
  const options = utils.getJWTHeaders()

  const res = await axios.post(API_URL + '/enrol', payload, options)

  console.log('data: ', res.data)

  return res.data
}
