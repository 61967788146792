import { combineReducers } from 'redux'
import { reducer as oidc_reducer } from 'redux-oidc'
import { connectRouter } from 'connected-react-router'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// import { USER_SIGNED_OUT, SESSION_TERMINATED } from 'redux-oidc'

import course_schedule_reducer from './course_schedule_reducer'
import trainee_reducer from './trainee_reducer'
import personnel_reducer from './personnel_reducer'
import auth_reducer from './auth_reducer'
import enrol_reducer from './enrol_reducer'
import error_reducer from './error_reducer'
import {theoryResult} from './upload_reducer'

const persistOidc = {
  key: 'oidc',
  storage
}

const persistAuth = {
  key: 'auth',
  storage
}

const persistOidcState = {
  key: 'oidc_state',
  storage
}

const oidc_state_reducer = (state = null, action) => {
  let oidc_reducer_action = action

  if (action.type && action.type.startsWith('redux-oidc/')) {
    if (action.type.includes('SESSION_TERMINATED')) {
      oidc_reducer_action = { ...action, terminated: true }
    } else if (action.type.includes('USER_FOUND')) {
      oidc_reducer_action = { ...action, terminated: false, user_found: action.payload }
    } else if (action.type.includes('USER_SIGNED_OUT')) {
      oidc_reducer_action = { ...action, terminated: false, user_found: null }
    }
    return {...state, ...oidc_reducer_action}
  } else if (action.type === 'persist/REHYDRATE') {
    return {...state, terminated: false, type: action.type}
  } else if (action.type === 'WAITING_SIGN_OUT_RESULT') {
    return { ...state, waiting_signout: true}
  } else {
    return state
  }
}

const reducer = (history) => combineReducers(
  {
    router: connectRouter(history),
    oidc: persistReducer(persistOidc, oidc_reducer),
    oidc_state: persistReducer(persistOidcState, oidc_state_reducer),
    course_schedule: course_schedule_reducer,
    trainee: trainee_reducer,
    personnel: personnel_reducer,
    auth: persistReducer(persistAuth, auth_reducer),
    enrol: enrol_reducer,
    error: error_reducer,

    theoryResult
  }
)

export default reducer
