import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import api_interface from '../../api_interface'
import utils from '../../utils'

import FlightReportSection from '../sections/FlightReportSection'
import FlightReportHeader from '../sections/FlightReportHeader'
import FlightReportForm from '../sections/FlightReportForm'

// const first_schedule_hardcoded = new Date(Date.UTC(2020, 8, 1, 0, 0))

const styles = {
  grid_sidebar: {
    maxHeight: '524px',
    borderRight: '1px solid #e0e0e0'
  },
  grid_main: {
    minHeight: '524px',
    overflowY: 'auto'
  },
  paperWrapper: {
    padding: '12px 20px'
  },
  autoScrollDiv: {
    overflowY: 'auto'
  },
  contentMaxHeight: {
    maxHeight: '500px'
  }
}

const ListItemSidebar = withStyles({
  root: {
    padding: 0,
    display: 'block',
    '&:hover': {
      backgroundColor: '#eee !important'
    }
  }
})(ListItem)

const CollapseSidebar = withStyles({
  root: {
    '& div': {
      '&:hover': {
        backgroundColor: 'rgba(30, 144, 255, 0.1) !important'
      }
    }
  }
})(Collapse)

class PracticalFlightGradingViewer extends React.Component {
  state = {
    flight_reports: null,
    schedules: null,
    schedules_date_only: null,
    active_schedule: null,
    active_trainee_report: null,
    expanded_flight_report: null,
    anchor_el: null,
    search_trainee: '',
    query_page_dump: null,
    list_end: false,
    load_next: false,
    edit_flight_report: null,
    report_updated: false,
    count_update_form: 0,

    delete_dialog: false,
    delete_grade_id: null,
    delete_flight_processing: false,
    count_delete: 0,

    query_search_result: false,

    width: window.innerWidth,
    height: window.innerHeight
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  async componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
    this.setState({
      flight_reports: null,
      schedules: null,
      schedules_date_only: null
    })
    const { flight_reports, schedules, ended } = await this.getGrades()
    const schedules_date_only = this.setDateOnly(schedules)
    this.setState({ flight_reports, schedules, schedules_date_only, ended })
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if(this.state.report_updated !== prevState.report_updated && this.state.report_updated) {
      const flight_grade_res_data = await api_interface.getFlightGradeById(this.state.edit_flight_report._id)
      const { flight_grade } = flight_grade_res_data
      
      this.setState({
        report_updated: false,
        expanded_flight_report: flight_grade
      })
    }
    if (!this.state.report_updated && this.state.count_update_form !== prevState.count_update_form && this.state.count_update_form === prevState.count_update_form+1 ) {
      await this.reinitializeGradeViewer()
    }
    if (!this.state.delete_flight_processing && this.state.count_delete !== prevState.count_delete && this.state.count_delete === prevState.count_delete+1) {
      await this.reinitializeGradeViewer()
    }
    if (!this.state.query_search_result && this.state.query_search_result !== prevState.query_search_result) {
      const { flight_reports, schedules, ended } = await this.getGrades()
      const schedules_date_only = this.setDateOnly(schedules)
      this.setState({
        expanded_flight_report: null,
        active_trainee_report: null,
        flight_reports, schedules, schedules_date_only, ended
      })
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions)
  }

  reinitializeGradeViewer = async () => {
    const { query_page_dump, search_trainee, active_schedule, expanded_flight_report } = this.state
    const query = query_page_dump ? `?lt=${query_page_dump.lte}${search_trainee && search_trainee !== '' ? `&trainee=${search_trainee}` : ''}` : ''
    
    const { flight_reports, schedules, ended } = await this.getGrades(query)
    const active_trainee_report = flight_reports.filter( report => report.trainee._id === expanded_flight_report.trainee._id && active_schedule.indexOf(report.schedule) > -1 )

    this.setState({
      expanded_flight_report: active_trainee_report && active_trainee_report.length > 0 && active_trainee_report.findIndex(report => report._id === expanded_flight_report._id) > -1 ? active_trainee_report.find(report => report._id === expanded_flight_report._id)
      :
      null,
      active_trainee_report: active_trainee_report.length > 0 ? active_trainee_report : null,
      flight_reports, schedules, ended
    })
  }

  getGrades = async (query) => {
    const query_report = query || ''
    const flight_report_res_data = await api_interface.getGradesForAdmin(query_report)
    // console.log('flight_report_res_data', flight_report_res_data)
    const flight_reports = flight_report_res_data.flight_reports
    const schedules = flight_report_res_data.schedules
    const ended = flight_report_res_data.ended
    return { flight_reports, schedules, ended }
    
  }

  setDateOnly = (schedules, flight_reports) => {
    let schedule_date_array = []
    for (const schedule of schedules) {
      const get_schedule_date = new Date(schedule.start_time).getFullYear() + '/' + new Date(schedule.start_time).getMonth() + '/' + new Date(schedule.start_time).getDate()
      const schedule_index = schedule_date_array.findIndex(sda => sda.format_date === get_schedule_date )
      if ( !(this.state.schedules_date_only && this.state.schedules_date_only.length && this.state.schedules_date_only.findIndex( sdo => sdo.schedule_list === get_schedule_date) < 0) ) {
        if (schedule_date_array.length === 0 || schedule_index < 0) {
          const sda_json = {
            format_date: get_schedule_date,
            format_iso: schedule.start_time,
            schedule_list: [ schedule._id ]
          }
          schedule_date_array.push(sda_json)
        } else {
          const updated_schedule = {
            ...schedule_date_array[schedule_index],
            schedule_list: [ ...schedule_date_array[schedule_index].schedule_list, schedule._id ]
          }
          schedule_date_array[schedule_index] = updated_schedule
        }
      }
    }
    return schedule_date_array
  }

  handleFindTraineeSchedule = async (e) => {
    e.preventDefault()
    const { search_trainee } = this.state
    if (search_trainee.length > 2) {
      const query_page_dump = {
        trainee: search_trainee,
        lte: new Date()
      }
      this.setState({
        flight_reports: null,
        schedules: null,
        schedules_date_only: null,
        load_next: true,
        active_trainee_report: null,
        expanded_flight_report: null,
        anchor_el: null
      })
      const { flight_reports, schedules, ended } = await this.getGrades(`?trainee=${search_trainee}`)
      const schedules_date_only = this.setDateOnly(schedules)
      this.setState({
        load_next: false,
        query_search_result: true,
        flight_reports, schedules, schedules_date_only, ended, query_page_dump })
    } else {
      alert('Min. 3 letters to search')
    }
  }

  handleScheduleClick = (e, schedule_id, report_length) => {
    e.preventDefault()
    if (report_length > 0) {
      this.setState({
        active_schedule: this.state.active_schedule === schedule_id ? null : schedule_id
      })
    }
  }

  handleSelectReport = (e, schedule, trainee) => {
    e.preventDefault()
    const active_trainee_report = this.state.flight_reports.filter( report => report.trainee._id === trainee._id && schedule.schedule_list.indexOf(report.schedule) > -1 )
    this.setState({
      active_trainee_report,
      expanded_flight_report: null
    })
  }

  handleExpandingReport = (e, expanding_report) => {
    e.preventDefault()
    this.setState({
      expanded_flight_report: expanding_report,
      edit_flight_report: null
    })
  }

  handleMenu = (e, name, value) =>{
    this.setState({
      [name]: value
    })
  }

  handleChangeSearch = (e, name) => {
   this.setState({
     [name]: e.target.value
   })
  }

  handleNextSchedule = async (e) => {
    e.preventDefault()
    const { search_trainee, flight_reports, schedules, schedules_date_only } = this.state
    this.setState({ load_next: true })
    const lte = new Date(schedules[schedules.length-1].start_time).toISOString()
    const query_page_dump = {
      trainee: search_trainee,
      lte
    }
    
    const query = `?lt=${query_page_dump.lte}${search_trainee && search_trainee !== '' ? `&trainee=${search_trainee}` : ''}`
    const next_grades = await this.getGrades(query)
    const schedules_date_only_update = this.setDateOnly(next_grades.schedules)
    this.setState({
      flight_reports: [...flight_reports, ...next_grades.flight_reports],
      schedules: [...schedules, ...next_grades.schedules],
      schedules_date_only: [...schedules_date_only, ...schedules_date_only_update],
      ended: !(schedules_date_only_update.length && !next_grades.ended),
      query_page_dump,
      load_next: false
    })
  }

  handleEdit = (e, report) => {
    e.preventDefault()
    this.handleMenu( e, 'anchor_el', null )
    this.setState({
      edit_flight_report: report
    })
  }
  
  handleDelete = (e, grade_id) => {
    e.preventDefault()
    this.setState({
      delete_grade_id: grade_id,
      delete_dialog: true
    })
  }

  deleteGradeReport = async (e) => {
    const { delete_grade_id } = this.state
    if (delete_grade_id) {
      this.setState({
        delete_flight_processing: true,
        anchor_el: null
      })
      const grade_removed = await api_interface.deleteFlightGrade(delete_grade_id)
      if (grade_removed) {
        this.setState({
          edit_flight_report: null,
          active_trainee_report: null,
          delete_grade_id: null,
          delete_dialog: false,
          delete_flight_processing: false,
          count_delete: this.state.count_delete+1
        })
      }
    }
  }

  exitFormReport = (updated) => {
    this.setState({
      report_updated: updated,
      anchor_el: null,
      count_update_form: this.state.count_update_form+1
    })
    if (!updated) {
      this.setState({
        edit_flight_report: null
      })
    }
  }

  restorePreviousSession = (e) => {
    e.preventDefault()
    this.setState({
      query_search_result: false,
      search_trainee: ''
    })
  }

  renderLabel = () => {
    return (
      <div style={{ position: 'relative' }} >
        <br /><br /><br /><br /><br /><br />
        <h2 style={{ position: 'relative', marginTop: '0', marginBottom: '2em' }} >
          Practical Flight Grading
          <div style={{ position: 'absolute', top: 0, right: 0, display: 'flex' }} >
            <Button onClick={ () => this.props.history.push('/admin/create-flight-grade') } >Grade Flight</Button>
          </div> 
        </h2>
      </div>
    )
  }

  renderSearchBar = () => {
    return (
      <div style={{ height: 64 }} >
        <div style={ styles.paperWrapper } >
          <form onSubmit={(e) => this.handleFindTraineeSchedule(e)} style={{ position: 'relative', width: '100%' }} >
            <TextField
              style={{ margin: 0, marginRight: '5px', width: '100%', paddingRight: '40px' }}
              id="standard-full-width"
              placeholder="Search"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={ this.state.query_search_result }
              value={ this.state.search_trainee }
              name='trainee-search'
              onChange={(event) => this.handleChangeSearch(event, 'search_trainee')}
            />
            {
              this.state.query_search_result ?
              <IconButton style={{ position: 'absolute', right: 0, top: 0, padding: '8px' }} onClick={(e) => this.restorePreviousSession(e) } ><CloseIcon /></IconButton>
              :
              <IconButton type="submit" style={{ position: 'absolute', right: 0, top: 0, padding: '8px' }}><SearchIcon /></IconButton>
            }
          </form>
        </div>
        <Divider />
      </div>
    )
  }

  renderFlightList = () => {
    const { schedules_date_only, active_schedule, flight_reports, active_trainee_report, ended, load_next } = this.state

    // console.log('schedules_date_only', schedules_date_only)
    // console.log('flight_reports', flight_reports)

    return (
      <List style={{ ...styles.autoScrollDiv, maxHeight: 'calc(100% - 88px)', padding: 0 }}>
        {
          schedules_date_only.map( (schedule, i) => {
            const raw_related_trainees = flight_reports.filter(report => schedule.schedule_list.indexOf(report.schedule) > -1 ).map( report => { return { _id: report.trainee._id, full_name: report.trainee.full_name, student_id: report.trainee.student_id } } ).filter((v, i, a) => a.indexOf(v) === i)
            const related_trainees = Array.from(new Set(raw_related_trainees.map(JSON.stringify))).map(JSON.parse)
            return (
              <ListItemSidebar key={i} style={{
                borderTop: active_schedule === schedule.schedule_list ? '1px solid #e0e0e0' : 'none',
                borderBottom: active_schedule === schedule.schedule_list ? '1px solid #e0e0e0' : 'none',
              }} >
                <div style={{ ...styles.paperWrapper, width: 'calc(100% - 40px)', cursor: related_trainees.length > 0 ? 'pointer' : 'default', background: active_schedule === schedule.schedule_list ? '#e0e0e0' : 'transparent' }} onClick={ (e) => this.handleScheduleClick(e, schedule.schedule_list, related_trainees.length) } >
                  <div style={{ fontWeight: '500', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                    <div>{ utils.formatTime(schedule.format_iso) }</div>
                    {
                      related_trainees.length > 0 &&
                      <div>{ active_schedule === schedule.schedule_list ? <ExpandLessIcon /> : <ExpandMoreIcon /> }</div>
                    }
                  </div>
                  <div style={{ color: '#757575' }} >{ [ ...new Set(related_trainees.map( trainee => trainee.full_name )) ].join(' - ') }</div>
                </div>
                <CollapseSidebar in={ active_schedule === schedule.schedule_list }>
                  { related_trainees.map( (trainee, j) => {
                    return (
                      <ListItem button onClick={(e) => this.handleSelectReport(e, schedule, trainee)} key={j} style={{ ...styles.paperWrapper,
                        cursor: 'pointer',
                        fontWeight: '500',
                        background:
                          active_trainee_report &&
                          active_trainee_report.findIndex( atr => atr.trainee._id === trainee._id ) > -1 ?
                            'rgba(30, 144, 255, 0.4)'
                            :
                            '#fff'
                      }} >
                        { trainee.full_name } - { trainee.student_id }
                      </ListItem>
                    )
                  })}                  
                </CollapseSidebar>
              </ListItemSidebar>
            )
          })
        }
        { ended ?
            null
            :
            !load_next ?
              <div style={{ padding: '10px 0', textAlign: 'center' }} >
                <Button ref={(el) => {
                  if (el) {
                    el.style.setProperty('border-radius', '75px', 'important')
                  }
                }} style={{ fontSize: '.875em', padding: '4px 30px', textTransform: 'capitalize' }} onClick={(e) => this.handleNextSchedule(e)}>Next</Button>
              </div>
              :
              this.renderNull()
        }
      </List>
    )
  }

  renderFlightReport = () => {
    const { active_trainee_report, expanded_flight_report, edit_flight_report, schedules } = this.state
    const edit_flight_patterns = edit_flight_report !== null ? edit_flight_report.grade_params.reduce((r, obj) => r.concat(obj.flight_patterns), []) : []
    
    const selected_session = edit_flight_report !== null ? {
      ...edit_flight_report.credit,
      course: { ...edit_flight_report.course }
    } : null

    const course_selector = (code) => {
      const split_code = code.split('_')
      if (split_code.length === 2) {
        const course_code = split_code[0]
        return { course: course_code, code }
      } else if (split_code.length === 3) {
        const course_code = split_code[0] + '_' + split_code[1]
        return { course: course_code, code }
      }
    }

    return active_trainee_report.map((active_report, i) => {
      const flight_patterns = active_report.grade_params.reduce((r, obj) => r.concat(obj.flight_patterns), [])
      // console.log('edit_flight_report', edit_flight_report)
      return (
        <div key={i} style={{ position: 'relative', zIndex: 1 }} >
        { expanded_flight_report && expanded_flight_report._id === active_report._id ?
          edit_flight_report !== null ?
            <Paper style={{ margin: i === 0 ? '10px 0 0' : '10px 0' }}>
              <FlightReportForm
                form_id = { edit_flight_report._id }
                grade_params = { edit_flight_report.grade_params }
                flight_patterns = { edit_flight_patterns }
                extend_form = { true }
                remarks = { edit_flight_report.remarks }
                selected_checklist = { JSON.stringify(course_selector(edit_flight_report.grade_code)) }
                selected_sortie = { edit_flight_report.sortie }
                selected_module_code = { JSON.stringify(course_selector(edit_flight_report.module_code)) }
                selected_trainee = { edit_flight_report.trainee }
                session = { selected_session }
                flight_time = { edit_flight_report.flight_time }
                init_grading_checklist = { true }
                exitFormReport={ this.exitFormReport } />
            </Paper>
            :
            <Paper style={{ margin: i === 0 ? '10px 0 0' : '10px 0' }} >
              <div style={{ cursor: 'pointer' }} onClick={ (e) => this.handleExpandingReport(e, null) }>
                <FlightReportSection rendered_report={ { ...active_report, schedule: active_report.schedule ? schedules.find( schedule => schedule._id === active_report.schedule) : null } } flight_patterns={ flight_patterns } />
              </div>
              { this.renderEditOption(active_report) }
            </Paper>
          :
          <Paper style={{ margin: i === 0 ? '10px 0 0' : '10px 0' }}>
            <div key={i} style={{ cursor: 'pointer' }} onClick={ (e) => this.handleExpandingReport(e, active_report) }>
              <FlightReportHeader rendered_report={ { ...active_report, schedule: active_report.schedule ? schedules.find( schedule => schedule._id === active_report.schedule) : null } } />
            </div>
          </Paper>
        }
        </div>
      )
    })  
  }

  renderEditOption = (report) => {
    return (
      <div style={{ position: 'absolute', right: 20, top: 12, zIndex: 999 }} >
        <IconButton onClick={ e => this.handleMenu( e, 'anchor_el', e.currentTarget ) } ><MoreHorizIcon /></IconButton>
        <Menu
          anchorEl={ this.state.anchor_el }
          keepMounted
          open={ Boolean(this.state.anchor_el) }
          onClose={(e) => this.handleMenu( e, 'anchor_el', null )}
        >
          <MenuItem style={{ display: 'flex', alignItems: 'center', color: '#757575' }} onClick={(e) => this.handleEdit(e, report) }><EditIcon style={{ marginRight: '.5em', fontSize: '1.1em' }} />Edit</MenuItem>
          <MenuItem style={{ display: 'flex', alignItems: 'center', color: '#757575' }} onClick={(e) => this.handleDelete(e, report._id) }><DeleteIcon style={{ marginRight: '.5em', fontSize: '1.1em' }} />Delete</MenuItem>
        </Menu>
      </div>
    )
  }

  renderDeleteDialog = () => {
    const { delete_dialog, delete_flight_processing } = this.state
    return (
      <Dialog open={ delete_dialog }
        onClose={() => this.setState({ delete_dialog: false })}
        style={{ margin: '0 auto' }}
      >
        <DialogTitle>Delete Flight Report</DialogTitle>
        <DialogContent>
          <div style={{ maxWidth: '100%', minWidth: '500px' }}>
            Are you sure you want to delete this flight report?
          </div>
        </DialogContent>
        <DialogActions style={{ borderTop: '1px solid #e0e0e0' }}>
          {
            delete_flight_processing ?
            this.renderNull()
            :
            <div>
              <Button onClick={() => this.setState({ delete_dialog: false })} style={{ backgroundColor: 'white', color: '#1E90FF', textTransform: 'none' }}>
                <strong>Cancel</strong>
              </Button>
              <Button disabled={ delete_flight_processing } onClick={(e) => this.deleteGradeReport(e)} style={{ textTransform: 'none' }}>
              <strong>Delete</strong>
              </Button>
            </div>
          }
        </DialogActions>
      </Dialog>
    )
  }

  renderNull = () => {
    return (
      <div style={{ textAlign: 'center', width: 'calc(100% - 4em)', padding: '2em' }} >
        <CircularProgress />
      </div>
    )
  }

  render() {

    const {
      flight_reports,
      schedules,
      schedules_date_only,
      active_trainee_report,
      width,
      // height
    } = this.state

    return(
      <div>
        { this.renderLabel() }
        <Paper>
          <Grid>
            <Grid item md={4} xs={12} style={{ ...styles.grid_sidebar }} >
              { this.renderSearchBar() }
              <div>
                { width < 960 &&
                  <div style={{ padding: '12px 20px' }} >
                    <strong>Flight Schedules</strong>
                  </div>
                }
                <div style={{ overflowY: 'auto', maxHeight: width < 960 ? '400px' : '460px' }} >
                  {
                    schedules !== null && schedules_date_only !== null ?
                    this.renderFlightList()
                    :
                    this.renderNull()
                  }
                </div>
                { width < 960 && <Divider /> }
              </div>
            </Grid>
            <Grid item md={8} xs={12} style={ styles.grid_main }>
              <div style={{ ...styles.paperWrapper, ...styles.autoScrollDiv, ...styles.contentMaxHeight }}>
                {
                  flight_reports === null ?
                  this.renderNull()
                  :
                  <div>
                    { width < 960 &&
                      <div style={{ padding: '12px 0' }} ><strong>Flight Reports</strong></div>
                    }
                    {
                      active_trainee_report ?
                      this.renderFlightReport()
                      :
                      <div>
                        Nothing is selected
                      </div>
                    }
                  </div>
                }
              </div>
            </Grid>
          </Grid>
        </Paper>
        { this.renderDeleteDialog() }
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    )
  }
}

export default PracticalFlightGradingViewer
