import axios from 'axios'

import utils from '../utils'

const API_URL = process.env.REACT_APP_UATO_API_URL

export const processOrder = async (order_details) => {
  try {
    // console.log('process order: ', order_details)
    const res = await axios.post(`${API_URL}/orders`, order_details)
    // console.log('res.data is: ', res.data)
    return res.data
  } catch (e) {
    console.error('Error processOrder', e)
    return e.response.data
  }
}

export const applyCoupon = async (coupon) => {
  const res = await axios.post(`${API_URL}/coupon`, coupon)
  return res.data
}

export const getOrders = async (order_url_str) => {
  const options = utils.getJWTHeaders()

  const res = await axios.get(`${API_URL}/orders?${order_url_str}`, options)

  return res.data
}

export const putOrderStatus = async (order, status) => {
  // console.log(`Changint ${order._id} to ${status}`)
  const url = API_URL + `/orders/${order._id}/status`
  const options = utils.getJWTHeaders()

  const res = await axios.put(url, { status }, options)

  return res.data
}

export const putOrderAdditionalNotes = async (order_id, additional_notes) => {

  const url = API_URL + `/orders/${order_id}/additional_notes`
  const options = utils.getJWTHeaders()

  const res = await axios.put(url, { additional_notes }, options)

  // console.log('res.data is: ', res.data)
  return res.data
}

export const getOrdersByTrainee = async (trainee_id) => {
  const url = API_URL + `/orders/${trainee_id}/trainee`
  const options = utils.getJWTHeaders()

  const res = await axios.get(url, options)

  // console.log('res.data is: ', res.data)
  return res.data
}

export const getCoursesByCatalogOrder = async (order_id) => {
  const url = API_URL + `/catalog-order/${order_id}/order`
  const options = utils.getJWTHeaders()

  const res = await axios.get(url, options)

  // console.log('res.data is: ', res.data)
  return res.data
}
