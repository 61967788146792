import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import constants from '../utils/constants.json'

import { updateAuthUserDetails } from '../actions'

class AdditionalDetails extends React.Component {
  state = {
    nric: '',
    birthday: '',
    address: '',
    city: '',
    state_province: '',
    country: '',
    postal_code: '',
    step: 'additional_details_1',

    exp_flying_drones: false,
    unmanned_flight_hours: '0',
    drone_type_model: [''],
    drone_training_classes: false,

    trainings: [{
      course_title: '',
      provider: '',
    }],

    address_line_1: '',
    address_line_2: '',

    nric_is_valid: true,

    unmanned_flight_hours_is_valid: true,
  }

  componentDidMount() {
    // console.log('props: ', this.props )
  }

  validNric(nric) {
    return nric.match(/^[0-9]{3}[a-zA-Z]$/)
  }

  validateNric() {
    if (this.validNric(this.state.nric)) {
      this.setState({ nric_is_valid: true })
    } else {
      this.setState({ nric_is_valid: false })
    }
  }

  validNumber(number) {
    return number.match(/^[0-9]+$/)
  }

  validateUnmannedFlightHours() {
    if (this.validNumber(this.state.unmanned_flight_hours)) {
      this.setState({ unmanned_flight_hours_is_valid: true })
    } else {
      this.setState({ unmanned_flight_hours_is_valid: false })
    }
  }

  handleTextInput(event) {
    this.setState({[event.target.name]: event.target.value})
    // console.log(event.target.name, event.target.value)
  }

  handleChangeBirthday(date_string) {
    // filter invalid date format
    if (!date_string || date_string === '') {
      return
    }

    const user_birthday = new Date(date_string).toISOString()
    // console.log('string is: ', user_birthday)
    this.setState({ birthday: user_birthday})
    
  }

  handleChangeCountry(event) {
    this.setState({country: event.target.value})
  }

  handleGoToNextPanel(panel_name) {
    // console.log(panel_name)
    this.setState({step: panel_name})
    
    // Combine address line 1 and address line 2 into address on next click
    let ADDRESS = this.state.address_line_1 + this.state.address_line_2
    this.setState({address: ADDRESS})

  }

  renderCountries() {
    return (
      <Select name='country' onChange={(event) => this.handleChangeCountry(event)} style={{ minWidth: '388px', marginRight: '0px' , fontSize: '14px' , height: '39.6px' }} value={this.state.country}>
        {constants.countries.map(c => {
          return <MenuItem value={c.code}>{c.name}</MenuItem>
        })}
      </Select>
    )
  }

  handleExpFlyingDrones(exp_boolean) {
    this.setState({exp_flying_drones: exp_boolean})
  }

  handleTrainingClasses(training_classes_boolean) {
    this.setState({drone_training_classes: training_classes_boolean})
  }

  renderUserTrainings() {
    return this.state.trainings.map((t , index) => {
      if (this.state.trainings.length === 1) {
        return (
          <>
            <Grid container item>
              <Grid item md={5}>
                <TextField
                  name='course_title'
                  style={{ marginBottom: 10 , width: 342.5 }}
                  placeholder="e.g. Professional Multirotor Pilot Course"
                  value={this.state.trainings[index].course_title}
                  onChange={(event) => this.handleUserTrainingChange(event, index)}
                />
              </Grid>
              
              {/* <Grid item md={1}></Grid> */}
  
              <Grid item md={5}>
                <TextField
                  name='provider'
                  style={{ marginBottom: 10 , marginLeft: 10, width: 342.5 }}
                  placeholder="e.g. Garuda Robotics Academy"
                  value={this.state.trainings[index].provider}
                  onChange={(event) => this.handleUserTrainingChange(event, index)}
                />
              </Grid>
  
              {/* <Grid item md={1}></Grid> */}
  
              <Grid item md={2}>
                {/* <DeleteOutlineIcon color='disabled' style={{ marginTop: '5px' , marginLeft: 20 }}>
                  Remove
                </DeleteOutlineIcon> */}
              </Grid>
            </Grid>
          </>
        )
      } else {
          return (
            <>
              <Grid container item>
                <Grid item md={5}>
                  <TextField
                    name='course_title'
                    style={{ marginBottom: 10, width: 342.5 }}
                    placeholder="e.g. Professional Multirotor Pilot Course"
                    fullWidth
                    value={this.state.trainings[index].course_title}
                    onChange={(event) => this.handleUserTrainingChange(event, index)}
                  />
                </Grid>
                
                {/* <Grid item md={1}></Grid> */}
    
                <Grid item md={5}>
                  <TextField
                    name='provider'
                    style={{ marginBottom: 10 , marginLeft: 10, width: 342.5 }}
                    placeholder="e.g. Garuda Robotics Academy"
                    fullWidth
                    value={this.state.trainings[index].provider}
                    onChange={(event) => this.handleUserTrainingChange(event, index)}
                  />
                </Grid>
    
                {/* <Grid item md={1}></Grid> */}
    
                <Grid item md={2}>
                  <DeleteOutlineIcon color="primary" style={{ marginTop: '5px' , marginLeft: 20, cursor: 'pointer' }} onClick={() => this.handleRemoveTraining(index)}>
                    Remove
                  </DeleteOutlineIcon>
                </Grid>
              </Grid>
            </>
          )
      }
    })
  }

  handleUserTrainingChange(event, index) {
    const trainings_copy = [ ...this.state.trainings ]
    trainings_copy[index][event.target.name] = event.target.value
    this.setState({trainings: trainings_copy})
  }

  handleAddTraining() {
    const trainings_copy = [ ...this.state.trainings ]
    trainings_copy.push({
      course_title: '',
      provider: '',
    })
    this.setState({trainings: trainings_copy})
  }

  handleRemoveTraining(index) {
    let trainings_copy = [ ...this.state.trainings ]
    trainings_copy = trainings_copy.filter((trainings, filter_index) => index !== filter_index)
    this.setState({trainings: trainings_copy})
  }

  renderUserPreviousDroneModel() {
    return this.state.drone_type_model.map((m, index) => {
      if (this.state.drone_type_model.length === 1) {
        return (
          <>
            <Grid item md={6}>
              <TextField
                name='drone_type_model'
                style={{ marginTop: 10 , marginBottom: 0 , width: 342.5}}
                placeholder="e.g. DJI Phantom 4 Pro V2.0"
                value={this.state.drone_type_model[index]}
                onChange={(event) => this.handleUserPreviousDroneModelChange(event, index)}
              />
            </Grid>

            {/* <Grid item md={1}></Grid> */}

            <Grid item md={2}>
              {/* <DeleteOutlineIcon color='disabled' style={{ marginLeft: 10, marginTop: '15px' }}>
                Remove
              </DeleteOutlineIcon> */}
            </Grid>

          </>
        )
      } else {
          return (
            <>
              <Grid item md={5}>
                <TextField
                  name='drone_type_model'
                  style={{ marginTop: 10 , marginBottom: 0, width: 342.5 }}
                  placeholder="e.g. DJI Phantom 4 Pro V2.0"
                  value={this.state.drone_type_model[index]}
                  onChange={(event) => this.handleUserPreviousDroneModelChange(event, index)}
                />
              </Grid>

              {/* <Grid item md={1}></Grid> */}

              <Grid item md={2}>
                <DeleteOutlineIcon color="primary" style={{ marginLeft: 10, marginTop: '15px', cursor: 'pointer' }} onClick={() => this.handleRemoveDroneModel(index)}>
                  Remove
                </DeleteOutlineIcon>
              </Grid>

              <Grid item md={5} style={{ width: '100%' }}></Grid>

            </>
          )
      }
    })
  }

  renderFlightHoursAndDroneType() {
    if (this.state.exp_flying_drones === true) {
      return (
        <>
          <Grid container item>
            <Grid item md={12} style={{ fontSize: 13 }}>Total unmanned flight hours</Grid>
            <Grid item md={12}>
              <TextField
                style={{ marginTop: 10 , marginBottom: 30, width: 720 }}
                placeholder=""
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                name='unmanned_flight_hours'
                value={this.state.unmanned_flight_hours}
                onChange={(event) => this.handleTextInput(event)}
                error={!this.state.unmanned_flight_hours_is_valid}
                helperText={this.state.unmanned_flight_hours_is_valid ? '' : 'Invalid unmanned flight hours'}
                onBlur={() => this.validateUnmannedFlightHours()}
                onFocus={() => this.setState({ unmanned_flight_hours_is_valid: true })}
              />
            </Grid>
          </Grid>

          <Grid container item>
            <Grid item md={6} style={{ fontSize: 13 }}>Drone type/make and model</Grid>
            <Grid item md={6}></Grid>
            {this.renderUserPreviousDroneModel()}
          </Grid>

          <Button style={{ width: '140px' , marginTop: '10px' , marginBottom: '30px' , backgroundColor: 'transparent' , color: '#5C677D' , fontWeight: 600 }} onClick={() => this.handleAddDrone()}>
            + Add Drone
          </Button>
        </>
      )
    } else {
      return ''
    }
  }

  renderCourseTitleAndTrainingProvider() {
    if (this.state.drone_training_classes === true) {
      return (
        <>
           <Grid container item>
              <Grid item md={5} style={{ fontSize: 13 }}>Course Title</Grid>
              <Grid item md={5} style={{ fontSize: 13 , marginLeft: 10 }}>Training Provider</Grid>
              {this.renderUserTrainings()}
              
            </Grid>
            <br/>

            <Button style={{ width: '215px', marginBottom: 30 , backgroundColor: 'transparent' , color: '#5C677D' , fontWeight: 600 }} onClick={() => this.handleAddTraining()}>
              + Add Drone Training
            </Button>
        </>
      )
    } else {
      return ''
    }
  }

  handleUserPreviousDroneModelChange(event, index) {
    const model_copy = [...this.state.drone_type_model]
    model_copy[index] = event.target.value
    this.setState({drone_type_model: model_copy})
  }

  handleAddDrone() {
    const model_copy = [ ...this.state.drone_type_model]
    model_copy.push('')
    this.setState({drone_type_model: model_copy})
  }

  handleRemoveDroneModel(index) {
    let model_copy = [ ...this.state.drone_type_model ]
    model_copy = model_copy.filter((model, filter_index) => index !== filter_index)
    this.setState({drone_type_model: model_copy})
  }

  async handleUpdateUserDetails() {
    const { nric, birthday, address, city, country, postal_code, unmanned_flight_hours, drone_type_model, trainings } = this.state
    const payload = {
      nric,
      birthday,
      address,
      city,
      country,
      postal_code,
      unmanned_flight_hours: parseFloat(unmanned_flight_hours),
      flown_drones: drone_type_model,
      trainings,
    }
    // console.log('payload is: ', payload)
    
    const success = await this.props.updateAuthUserDetails(this.props.auth.email, payload)
    // console.log('success: ', success)
    if (success) {
      this.props.push('/schedule')
    }
    
  }

  render() {
    // console.log('birthday is: ', this.state.birthday)
    // console.log('this.props is: ', this.props)
    // console.log('exp flying drones is: ', this.state.exp_flying_drones)
    // console.log('drone training classes is: ', this.state.drone_training_classes)
    if (this.state.step === 'additional_details_1') {
      return (
        <div>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <Container fixed spacing={0}>

            <div style={{ minWidth: '100%' }}>

              <div style={{ textAlign: 'center' , paddingBottom: '30px' , fontSize: '20px'}}>  
                Hi {this.props.auth.full_name}, we'd like to know more about you
              </div>

              <TextField
                label={<strong>Last 4 characters of NRIC</strong>}
                placeholder="e.g. 123A"
                style={{ minWidth: '45%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                name='nric'
                value={this.state.nric}
                onChange={(event) => this.handleTextInput(event)}
                error={!this.state.nric_is_valid}
                helperText={this.state.nric_is_valid ? '' : 'Invalid Format'}
                onBlur={() => this.validateNric()}
                onFocus={() => this.setState({ nric_is_valid: true })}
              />

              <TextField
                id="date"
                label={<strong>Birthday</strong>}
                type="date"
                style={{ minWidth: '50%' , marginLeft: '5%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={this.state.birthday.split('T')[0]}
                onChange={(event) => this.handleChangeBirthday(event.target.value)}
              />

              <br/>

              <TextField
                label={<strong>Address Line 1</strong>}
                style={{ minWidth: '100%' }}
                placeholder=""
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                name='address_line_1'
                value={this.state.address_line_1}
                onChange={(event) => this.handleTextInput(event)}
              />
              <br/>

              <TextField
                label={<><strong>Address Line 2</strong> (Optional)</>}
                style={{ minWidth: '100%' }}
                placeholder=""
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                name='address_line_2'
                value={this.state.address_line_2}
                onChange={(event) => this.handleTextInput(event)}
              />
              <br/>

              <TextField
                label={<strong>City</strong>}
                style={{ minWidth: '45%' }}
                placeholder=""
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                name='city'
                value={this.state.city}
                onChange={(event) => this.handleTextInput(event)}
              />

              <TextField
                label={<><strong>State/Province</strong> (Optional)</>}
                style={{ minWidth: '50%' , marginLeft: '5%' , marginTop: 15 }}
                placeholder=""
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                name='state_province'
                value={this.state.state_province}
                onChange={(event) => this.handleTextInput(event)}
              />
              <br/>

              <FormControl variant='outlined' style={{ marginRight: '8.4%' }}>
                <p style={{ fontSize: 12 , marginBottom: '0px', fontFamily: 'Arial' }}><strong>Country/Region</strong></p>
                  {this.renderCountries()}
              </FormControl>

              <TextField
                label={<strong>Postal Code</strong>}
                style={{ minWidth: '50%' , marginLeft: '5%' , marginTop: 15 }}
                placeholder=""
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                name='postal_code'
                value={this.state.postal_code}
                onChange={(event) => this.handleTextInput(event)}
              />
              <br/>
              <br/>

              {this.renderNextButton()}
            </div>

          </Container>  
        </div>
      )
    } else if (this.state.step === 'additional_details_2') {
      return (
        <Container fixed spacing={0}>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>

          <div style={{ minWidth: '100%' }}>

            <div style={{ textAlign: 'center' , paddingBottom: '30px' , fontSize: '20px' }}>  
              {this.props.auth.full_name}, we'd like to know more about you
            </div>

            <FormControl style={{ minWidth: '100%' }} >
              <FormLabel style={{ fontSize: 14 , color: 'black' }}>Do you have any experience flying drones?</FormLabel>
              
              <Grid container item>

                <div>
                  <Radio style={{ marginLeft: '-9px' }} checked={this.state.exp_flying_drones} onClick={() => this.handleExpFlyingDrones(true)}></Radio>
                  <span style={{ fontSize: 14, fontFamily: 'Arial', cursor: 'pointer' }} onClick={() => this.handleExpFlyingDrones(true)}>Yes</span>
                
                  <Radio style={{ marginLeft: '100px' }} checked={!this.state.exp_flying_drones} onClick={() => this.handleExpFlyingDrones(false)}></Radio>
                  <span style={{ fontSize: 14, fontFamily: 'Arial', cursor: 'pointer' }} onClick={() => this.handleExpFlyingDrones(false)}>No</span>
                </div>


              </Grid>

              {this.renderFlightHoursAndDroneType()}

              <FormLabel style={{ marginTop: 20, fontSize: 14 , color: 'black' }}>Have you attended any drone training classes before?</FormLabel>
              
              <Grid container item style={{ marginBottom: 10 }}>

                <div>
                  <Radio style={{ marginLeft: '-9px' }} checked={this.state.drone_training_classes} onClick={() => this.handleTrainingClasses(true)}></Radio>
                  <span style={{ fontSize: 14, fontFamily: 'Arial', cursor: 'pointer' }} onClick={() => this.handleTrainingClasses(true)}>Yes</span>
              
                  <Radio style={{ marginLeft: '100px' }} checked={!this.state.drone_training_classes} onClick={() => this.handleTrainingClasses(false)}></Radio>
                  <span style={{ fontSize: 14, fontFamily: 'Arial', cursor: 'pointer' }} onClick={() => this.handleTrainingClasses(false)}>No</span>
                </div>

              </Grid>

            {this.renderCourseTitleAndTrainingProvider()}

              <br/>

              <Grid item md={12} style={{ width: 822 }}></Grid>

              <div>
                <Button variant="contained" color="primary" onClick={() => this.handleGoToNextPanel('additional_details_1')} style={{ width: '10%' , marginTop: '10px', backgroundColor: '#1E90FF' }}>
                  Back
                </Button>

                {this.renderSubmitButton()}

                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                

              </div>

              
              

            </FormControl>
          
          </div>

        </Container>
      )
    } else {
        return (
          <div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            Hi
            thisstatestep is:{this.state.step}
          </div>
        )
      }

  }

  renderNextButton() {
    const { nric, birthday, address_line_1, city, country, postal_code } = this.state

    if (!this.validNric(this.state.nric) || nric === '' || birthday === '' || address_line_1 === '' || city === '' || country === '' || postal_code === '') {
      return (
        <Button disabled style={{ float: 'right' }} variant="contained" color="primary" onClick={() => this.handleGoToNextPanel('additional_details_2')}>
          Next
        </Button>
      )
    } else {
      return (
        <Button style={{ float: 'right' , backgroundColor: '#1E90FF' }} variant="contained" color="primary" onClick={() => this.handleGoToNextPanel('additional_details_2')}>
          Next
        </Button>
      )
    }

  }

  renderSubmitButton() {

    let can_continue_exp = true
    let can_conitnue_trainings = true

    for (let i = 0; i < this.state.drone_type_model.length; i++) {

      if (this.state.exp_flying_drones) {

        if (!this.validNumber(this.state.unmanned_flight_hours) || this.state.unmanned_flight_hours === '' || this.state.drone_type_model[i] === '') {
          can_continue_exp = false
        }

      }
    }

    for (let i = 0; i < this.state.trainings.length; i++) {

      if (this.state.drone_training_classes) {

        const { course_title, provider } = this.state.trainings[i]

        if (course_title === '' || provider === '') {
          can_conitnue_trainings = false
        }

      }
    }

    if (!can_continue_exp || !can_conitnue_trainings) {
      return (
        <Button disabled variant="contained" onClick={() => this.handleUpdateUserDetails()} style={{ float: 'right', width: '10%' , marginTop: '10px' }}>
          Submit
        </Button>
      )
    } else {
      return (
        <Button variant="contained" color="primary" onClick={() => this.handleUpdateUserDetails()} style={{ float: 'right', width: '10%' , marginTop: '10px', backgroundColor: '#1E90FF' }}>
          Submit
        </Button>
      )
    }
  }

}

const mapStateToProps = (state) => {
  // console.log('state is: ', state)
  return {
    auth: state.auth
  }
}
export default connect(mapStateToProps, { updateAuthUserDetails, push })(AdditionalDetails)
