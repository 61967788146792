import { useState, useEffect } from 'react'
import api_interface from '../../api_interface'

import StatPanel from './StatPanel'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import {
  ExpandMore,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  grid: {
    width: 'lg',
  },
})

function AboutStudents({ classes, instructor, filter }) {

  const [ flights, setFlights ] = useState([])

  useEffect(() => {
    async function fetchTraineeFlights() {

      // TBD: Still figuring out which data model connects the instructor to the trainee.

      const data = await api_interface.getTraineeFlightsByInstructorPersonnelId(instructor._id)
      console.log('data', data)
      if (!data.success) {
        console.log('Failed to get flights', data.error)
        setFlights([])
      } else {
        setFlights(data.flights)
      }
    }
    if (instructor?._id)
      fetchTraineeFlights()
  }, [instructor._id])

  // trainee's user id => Array of flights
  const traineeAsPilot0 = {} // Executive Officer / Instructor
  const traineeAsPilot1 = {} // Box / Transmitter Pilot
  const traineeAsPilot2 = {} // GCS / BVLOS Pilot

  // Double / Triple count if the student plays multiple roles
  flights.forEach(flight => {
    const p = flight.pilots
    if (!p) return
    if (p[0]?.id) {
      if (traineeAsPilot0[p[0].id])
        traineeAsPilot0[p[0].id].push(flight)
      else
        traineeAsPilot0[p[0].id] = [flight]
    }
    if (p[1]?.id) {
      if (traineeAsPilot1[p[1].id])
        traineeAsPilot1[p[1].id].push(flight)
      else
        traineeAsPilot1[p[1].id] = [flight]
    }
    if (p[2]?.id) {
      if (traineeAsPilot2[p[2].id])
        traineeAsPilot2[p[2].id].push(flight)
      else
        traineeAsPilot2[p[2].id] = [flight]
    }
  })

  const totalTrainees = Object.keys(traineeAsPilot0).length +
    Object.keys(traineeAsPilot1).length + Object.keys(traineeAsPilot2).length

  return <>
    <Typography variant='h6'>About {instructor.name}'s Students</Typography>
    <Typography variant='body2' gutterBottom>
      Currently based on all flights students flown (not just instructed time)
    </Typography>
    <Grid container spacing={2} className={classes.grid}>
      <Grid item>
        <StatPanel
          overtext='Total Students'
          maintext={totalTrainees.toFixed()}
        />
      </Grid>
      <Grid item>
        <StatPanel
          overtext='Total Pass'
          maintext='?'
        />
      </Grid>
      <Grid item>
        <StatPanel
          overtext='Total Fail'
          maintext='?'
        />
      </Grid>
      <Grid item>
        <StatPanel
          overtext='Passing Rate'
          maintext='?'
        />
      </Grid>
    </Grid>
    <br />
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}>
        Expand Student’s Flight Records
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table className={classes.table} size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Pilots</TableCell>
                <TableCell>Drones</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Pass / Fail</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { flights.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.pilots.map(p => p.name).join(', ')}</TableCell>
                  <TableCell>{row.drone.name}</TableCell>
                  <TableCell>{row.location}</TableCell>
                  <TableCell>{new Date(row.date).toLocaleDateString()}</TableCell>
                  <TableCell>{row.time}</TableCell>
                  <TableCell>{
                    row.duration.hours + 'hrs ' +
                    row.duration.minutes + 'min ' +
                    row.duration.seconds + 'sec'
                  }</TableCell>
                  <TableCell>?</TableCell>
                </TableRow>
              )) }
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  </>
}

export default withStyles(styles)(AboutStudents)
