import React from 'react'

import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Select from '@material-ui/core/Select'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'

import Autocomplete from '@material-ui/lab/Autocomplete'

import api_interface from '../../api_interface'
import FlightReportForm from '../sections/FlightReportForm'

const styles = {
  paper_wrapper: {
    display: 'flex',
    position: 'relative',
    padding: '12px 20px'
  },
  report_body_label: {
    color: '#757575',
    fontWeight: '500',
    lineHeight: '25px'
  },
  label_optional: {
    fontWeight: '500',
    fontSize: '.875em',
    color: '#9e9e9e'
  },
  label: {
    fontWeight: '500',
    fontSize: '.875em'
  }
}

class CreateFlightGradeViewer extends React.Component {
  
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      // state to manage autocomplete field
      trainees_selection: [], // add default string
      trainees_selection_object: [],
      selected_trainee: null,
      trainee_input_value: '',
      selected_session: JSON.stringify({}),
      sessions_selection: [],
      load_trainee: false,

      // state to manage module & sortie part
      grading_checklist: {},
      selected_module_code: null,
      selected_sortie: null,
      selected_checklist: null,
      modules_selection: null,
      sorties_selection: null,
      checklist_selection: null,

      // State to manage flight patterns
      flight_patterns: null,

      //State to manage previous flight
      previous_flight: null,
      previous_flights: [],
      prev_flight_dialog: false

    }
  }

  componentDidMount = async () => {
    this._isMounted = true
    if (this._isMounted) {
      await this.initializeForm()
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.selected_trainee !== prevState.selected_trainee) {
      let previous_flight = null,
        sessions = [],
        course_selector = {course: 'P010', grade_code: 'P010_01', sortie: '1'}
      if (this.state.selected_trainee) {
        const { selected_trainee } = this.state
        this.setState({
          load_trainee: true
        })
        const session_res_data = await api_interface.getTraineeFlightGradeCourses(selected_trainee._id)

        sessions = session_res_data.sessions
        previous_flight = session_res_data.previous_flight

        if (sessions.length > 0) {
          const set_grade_code = `${sessions[0].course.code}_${sessions[0].session_number > 9 ? '' : '0' }${sessions[0].session_number}`
          course_selector = { course: sessions[0].course.code, grade_code: set_grade_code, sortie: '1' }
        }
      }
      
      this.setState({
        selected_session: sessions.length > 0 ? JSON.stringify(sessions[0]) : JSON.stringify({}),
        sessions_selection: sessions,
        load_trainee: false,
        previous_flight
      })
      this.setupState(course_selector, this.state.grading_checklist)
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  initializeForm = async () => {
    const grade_res_data = await api_interface.initializeGradeForm({ select: ['trainee'] })

    const grading_checklist = grade_res_data.grading_checklist

    const course_selector = { course: 'P010', grade_code: 'P010_01', sortie: '1' }
    this.setState({
      trainees_selection: grade_res_data.trainees_selection
    })
    this.setupState(course_selector, grading_checklist)
  }

  setupState = (course_selector, grading_checklist) => {
    const selected_module_checklist = grading_checklist[course_selector.course]

    const modules_selection = this.getModuleCodeList(grading_checklist)
    const sorties_selection = this.getSortiesSelection(selected_module_checklist, course_selector.grade_code)
    
    const selected_module_pattern = selected_module_checklist ? selected_module_checklist.length > 1 ? selected_module_checklist.find( mod_list => mod_list.code === course_selector.grade_code ) : selected_module_checklist[0] : null

    const flight_patterns = selected_module_pattern ? this.renderChecklist(selected_module_pattern) : []

    this.setState({
      selected_module_code: JSON.stringify({ course: course_selector.course, code: course_selector.grade_code }),
      selected_sortie: course_selector.sortie,
      selected_checklist: JSON.stringify({ course: course_selector.course, code: course_selector.grade_code }),
      modules_selection: modules_selection,
      checklist_selection: modules_selection,
      sorties_selection,
      grading_checklist,
      flight_patterns
    })
  }

  getModuleCodeList = (grading_checklist) => {
    let code_list = []

    for (const grade in grading_checklist) {
      code_list.push( ...grading_checklist[grade].map(gc => { return { course: grade, code: gc.code } } ))
    }

    return code_list
  }

  getSortiesSelection = (modules_list, module_code) => {
    const selected_module_index = modules_list ? modules_list.length > 1 ? modules_list.findIndex( mod_list => mod_list.code === module_code ) : 0 : -1
    return selected_module_index > -1 ? modules_list[selected_module_index].sorties.map( s => s.sortie ) : null
  }

  loadPreviousFlight = async (e) => {
    e.preventDefault()
    this.setState({ prev_flight_dialog: true })

    const previous_flight_res_data = await api_interface.getTraineeGradeReports( this.state.selected_trainee._id )
    
    const previous_flights = previous_flight_res_data.grade_reports
    this.setState({ previous_flights })
  }

  renderChecklist = (module_pattern, refresh = false) => {
    const grade_params_aggr = module_pattern.sorties.map( s => s.content.map( c => {
      return {
        sortie: s.sortie,
        flight_patterns: c.flight_patterns.map( fp => { return { pattern: fp, grade: false} } ),
        training_phase: c.training_phase
      }
    }) )

    const grade_params = [].concat.apply([], grade_params_aggr)

    return {
      grade_code: module_pattern.code,
      grade_params
    }
  }

  handleChangeAutocomplete = (e, new_selected_trainee, reason) => {
    const confirmation = new_selected_trainee && this.state.selected_trainee ? window.confirm("Checklist form will be re-initialized. Are you sure?") : true
    if (confirmation && new_selected_trainee) {
      this.setState({
        selected_trainee: new_selected_trainee
      })
    } else {
      if (!this.state.selected_trainee && new_selected_trainee) {
        this.setState({
          selected_trainee: null
        })
      }
    }
  }

  handleChangeInput = async (input_value, reason) => {
    const trainees_selection_res_data = await api_interface.getTraineeSelection(input_value)
    const updated_trainees_selection = trainees_selection_res_data.trainees_selection.length > 0 ? trainees_selection_res_data.trainees_selection : this.state.trainees_selection_object
    if (reason === 'reset' && !this.state.selected_trainee) {
      this.setState({
        trainee_input_value: ''
      })
    } else if (reason === 'clear' && this.state.selected_trainee) {
      this.setState({
        trainee_input_value: '',
        selected_trainee: null,
        trainees_selection: updated_trainees_selection
      })
    } else {
      this.setState({
        trainee_input_value: input_value,
        trainees_selection: updated_trainees_selection
      })
    }
  }

  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleChangePlusSortie = (e) => {
    const confirmation = !(this.state.selected_trainee && this.state.selected_session) ? alert('Please select trainee and session first') : this.state.checklist_selection !== e.target.value ? window.confirm("Checklist form will be re-initialized. Are you sure?") : true
    if (confirmation) {
      const parsed_target_value = JSON.parse(e.target.value)
      
      const selected_module_checklist = this.state.grading_checklist[parsed_target_value.course]
      const sorties_selection = this.getSortiesSelection(selected_module_checklist, parsed_target_value.code)

      const selected_module_pattern = selected_module_checklist.find( mod_list => mod_list.code === parsed_target_value.code )

      const flight_patterns = this.renderChecklist(selected_module_pattern)

      this.setState({
        selected_checklist: e.target.value,
        selected_sortie: 1,
        sorties_selection,
        flight_patterns
      })
    }
  }

  handleChangeSession = (e, name) => {
    const { sessions_selection } = this.state

    if (this.state.checklist_selection !== e.target.value) 
      window.confirm("Checklist form will be re-initialized. Are you sure?")

    if (e.target.value !== this.state[name] && true) {
      const parsed_target_value = JSON.parse(e.target.value)

      const matched_credit = sessions_selection.find( session => session.course.code === parsed_target_value.course.code )
      const session_number = parseInt(parsed_target_value.session_number) > parseInt(matched_credit.course.number_of_sessions) ? 1 : parsed_target_value.session_number
      const session_string = `_${session_number > 9 ? '' : '0' }${session_number}`

      const set_grade_code = `${parsed_target_value.course.code}${session_string}`
      const course_selector = { course: parsed_target_value.course.code, grade_code: set_grade_code, sortie: '1' }
      this.setupState(course_selector, this.state.grading_checklist)
    }
    this.setState({
      [name]: e.target.value
    })
  }

  handlePreviousSelection = (e, prev_report) => {
    const confirmation = prev_report ? window.confirm("Checklist form will be re-initialized. Are you sure?") : true
    if (confirmation) {
      const array_grade_code = prev_report.grade_code.split('_')
      
      const session_grade_code = array_grade_code[array_grade_code.length-1].length+1
      const course_code = prev_report.grade_code.slice(0, -session_grade_code )

      const selected_module_checklist = this.state.grading_checklist[course_code]

      const flight_patterns = { grade_code: prev_report.module_code, grade_params: prev_report.grade_params }

      const sorties_selection = this.getSortiesSelection(selected_module_checklist, prev_report.grade_code)

      const selected_sortie = prev_report.sortie.toString()
      // const selected_module_code = JSON.stringify({ course: course_code, code: prev_report.module_code })
      const selected_checklist = JSON.stringify({ course: course_code, code: prev_report.grade_code })

      const credit_json = {
        ...prev_report.credit,
        course: prev_report.course
      }

      const selected_session = JSON.stringify(credit_json)

      this.setState({
        selected_session,
        selected_checklist,
        selected_sortie,
        sorties_selection,
        flight_patterns,
        prev_flight_dialog: false
      })
    }
  }

  renderFormSidebar = () => {
    const {
      trainees_selection,
      selected_trainee,
      trainee_input_value,
      load_trainee,
      selected_session,
      sessions_selection,
      modules_selection,
      sorties_selection,
      checklist_selection,
      previous_flight,
      previous_flights,
      prev_flight_dialog
    } = this.state

    return checklist_selection ?
      (
        <div style={{ width: '100%' }} >
          <div style={{ padding: '12px 20px' }} >
            <div>
              <div><span style={styles.label}>Search trainee by name</span></div>
              <Autocomplete
                id="search-trainee"
                value={ selected_trainee }
                onChange={ (e, new_selected_trainee, reason) => this.handleChangeAutocomplete(e, new_selected_trainee, reason ) }
                inputValue={ trainee_input_value }
                onInputChange={(e, input_value, reason) => this.handleChangeInput(input_value, reason) }
                options={ trainees_selection }
                getOptionLabel={ (trainee) => `${trainee.full_name} - ${trainee.student_id}` }
                getOptionSelected={(option, value) => value && option._id === value._id}
                noOptionsText={'Cannot find the trainee'}
                clearOnEscape
                clearOnBlur
                renderInput={(params) => <TextField {...params} style={{ border: 'none' }} variant="standard" placeholder="e.g. John Smith" />}
              />
            </div>
            <div>
              <div style={{ fontWeight: '700' }}><span style={styles.label}>Session</span></div>
                {
                  load_trainee ?
                  this.renderNull()
                  :
                  <Select value={ selected_session } onChange={ (event) => this.handleChangeSession(event, 'selected_session') }>
                    { sessions_selection.length === 0 && <MenuItem value={ JSON.stringify({}) } >-</MenuItem> }
                    {
                      sessions_selection.map( (ss, i) => {
                        return (
                          <MenuItem value={JSON.stringify(ss)} key={i}>{ `${ss.course.name} - ${ss.session_number}` }</MenuItem>
                        )
                      })
                    }
                  </Select>
                }
            </div>
            {
              previous_flight &&
              <div>
                <div style={{ fontSize: '.875em', color: '#616161', margin: '.5em 0' }} >Previous: { previous_flight.module_code }, sortie { previous_flight.sortie } | { previous_flight.grade_code } checklist</div>
                <div><Button onClick={(e) => this.loadPreviousFlight(e) } style={{ background: 'transparent', color: '#1E90FF', border: '1px solid #1E90FF', textTransform: 'capitalize', width: '100%', textAlign: 'center', padding: '5px 12px' }} >Load Previous Flight</Button></div>
                <Dialog open={ prev_flight_dialog }
                  onClose={() => this.setState({ prev_flight_dialog: false })}
                  style={{ margin: '0 auto' }}
                >
                  <DialogTitle>Previous Flight Report</DialogTitle>
                  <DialogContent>
                    {
                      previous_flights.length > 0 ?
                      <MenuList style={{ border: '1px solid #e0e0e0', padding: 0, marginBottom: '2em' }} >
                        {
                          previous_flights.map((flight, k) => {
                            return (
                              <MenuItem onClick={(e) => this.handlePreviousSelection(e, flight)} style={{ padding: '1em 2em', borderBottom: '1px solid #e0e0e0', fontSize: '1em' }} key={k}>{flight.module_code}, Sortie: { flight.sortie } | { flight.grade_code } checklist</MenuItem>
                            )
                          } )
                        }
                      </MenuList>
                      :
                      'No flight report created'
                    }
                  </DialogContent>
                </Dialog>
              </div>
            }
          </div>
          <Divider />
          <div style={{ padding: '12px 20px' }} >
            <div>
              <div>Currently flying for...</div>
              <div style={{ margin: '.5em 0' }} >
                <div style={{ fontWeight: '700' }}><span style={styles.label}>Module Code</span></div>
                <Select value={ this.state.selected_module_code } onChange={ (event) => this.handleChange(event, 'selected_module_code') }>
                  {
                    modules_selection.map( (ms, i) => {
                      return (
                        <MenuItem value={JSON.stringify(ms)} key={i}>{ ms.code.replace(/_/g, '.') }</MenuItem>
                      )
                    })
                  }
                </Select>
              </div>
              <div style={{ margin: '.5em 0' }} >
                <div style={{ fontWeight: '700' }}><span style={styles.label}>Sortie</span></div>
                <Select value={ this.state.selected_sortie } onChange={ (event) => this.handleChange(event, 'selected_sortie') }>
                  {
                    sorties_selection.map( (ss, i) => {
                      return (
                        <MenuItem value={ss} key={i}>{ ss }</MenuItem>
                      )
                    })
                  }
                </Select>
              </div>
              <div style={{ margin: '.5em 0' }} >
                <div style={{ fontWeight: '700' }}><span style={styles.label}>Checklist</span></div>
                <Select value={ this.state.selected_checklist } onChange={ (event) => this.handleChangePlusSortie(event, 'selected_checklist') }>
                  {
                    checklist_selection.map( (cs, i) => {
                      return (
                        <MenuItem value={JSON.stringify(cs)} key={i}>{ cs.code.replace(/_/g, '.') }</MenuItem>
                      )
                    })
                  }
                </Select>
              </div>
            </div>
          </div>
        </div>
      )
      :
      this.renderNull()
  }

  renderLabel = () => {
    return (
      <div style={{ position: 'relative' }} >
        <br /><br /><br /><br /><br /><br />
        <h2 style={{ position: 'relative', marginTop: '0', marginBottom: '2em' }} >
          Grade Trainee's Flight
        </h2>
      </div>
    )
  }

  renderNull = () => {
    return (
      <div style={{ textAlign: 'center', width: 'calc(100% - 4em)', padding: '2em' }} >
        <CircularProgress />
      </div>
    )
  }

  render() {
    const {
      flight_patterns,
      grading_checklist,
      selected_module_code,
      selected_trainee,
      selected_session,
      selected_checklist,
      selected_sortie
    } = this.state
    
    const flight_time = { mins: 0, secs: 0 }

    return (
      <div style={{ position: 'relative', zIndex: '9' }} >
        { this.renderLabel() }
        <FlightReportForm { ...this.props }
          { ...flight_patterns }
          selected_trainee={ selected_trainee }
          session={ selected_session ? JSON.parse(selected_session) : null }
          selected_checklist={ selected_checklist }
          selected_sortie={ selected_sortie }
          selected_module_code={ selected_module_code }
          flight_time={ flight_time }
          grading_checklist={ grading_checklist }
          top_submit_flight={true}
          renderFormSidebar={ this.renderFormSidebar }
          />
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    )
  }
}

export default CreateFlightGradeViewer

