import React from 'react'

import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'

import api_interface from '../../api_interface'
import utils from '../../utils'

const pseudo_button_style = {
  background: 'transparent',
  border: '2px solid #1E90FF',
  color: '#1E90FF',
  borderRadius: '100px',
  padding: '3px 15px',
  fontWeight: 500,
  cursor: 'pointer',
  display: 'inline-block'
}

class GradeViewer extends React.Component {
  state = {
    grades: null,
    next_active: false
  }

  async componentDidMount() {
    const grades_res_data = await api_interface.getGrades(0)
    const grades = grades_res_data.grades

    // console.log('grades', grades)

    this.setState({ grades })
  }

  renderNull = () => {
    return (
      <div style={{ textAlign: 'center', width: '100%', padding: '2em' }} >
        <br/><br/><br/><br/><br />
        <CircularProgress />
      </div>
    )
  }

  renderScheduleBody = () => {
    const { grades } = this.state
    return grades.map( (g, i) => {
      return (
        <div key={i}>
          <div style={{ display: 'flex', width: 'calc(100% - 40px)', textAlign: 'left', padding: '15px 20px', lineHeight: '1.618' }}>
            <div style={{ flexBasis: '25%' }}>{ `${g.trainee.first_name} ${g.trainee.last_name}` }</div>
            <div style={{ flexBasis: '25%' }}>{ `${g.course.name} - ${g.credit.session_number}` }</div>
            <div style={{ flexBasis: '25%' }}>{ g.evaluated_by.name } </div>
            <div style={{ flexBasis: '25%' }}>{ utils.formatTime(g.created_at) }</div>
          </div>
          <Divider />
        </div>
      )
    } )
  }

  handleNextGrades = async (e) => {
    e.preventDefault()
    this.setState({ next_active: true })

    const grades_res_data = await api_interface.getGrades(parseInt(this.state.grades.length))
    const grades = grades_res_data.grades

    // console.log('grades', grades)

    this.setState({ grades: [ ...this.state.grades , ...grades ], next_active: false })
  }

  render() {

    const { grades, next_active } = this.state
    // console.log('grades', grades)

    return(
      <div style={{ margin: '120px 0 30px', textAlign: 'center' }} >
        <h2>Flight Grades</h2>
        {
          grades === null ?
          this.renderNull()
          :
          grades.length > 0 ?
          <div>
            <Paper style={{ margin: '20px 0' }} >
              <div style={{ display: 'flex', width: 'calc(100% - 40px)', textAlign: 'left', alignItems: 'center', justifyContent: 'center', fontWeight: '500', padding: '20px', background: '#f5f5f5' }} >
                <div style={{ flexBasis: '25%' }}>Trainee</div>
                <div style={{ flexBasis: '25%' }}>Course</div>
                <div style={{ flexBasis: '25%' }}>Evaluated by</div>
                <div style={{ flexBasis: '25%' }}>Created at</div>
              </div>
              <Divider />
              { this.renderScheduleBody() }
            </Paper>
            { next_active && <div style={{ marginTop: '-150px' }} >{this.renderNull()}</div> }
            { grades.length > 0 && grades.length%10 === 0 && !next_active && <div style={ pseudo_button_style } onClick={ (e) => this.handleNextGrades(e) } >Next</div> }
          </div>
          :
          <h1 style={{ fontSize: '2.5em', fontWeight: '300', color: '#9e9e9e', fontStyle: 'italic' }} >No Schedule Available</h1>
        }
      </div>
    )
  }
}

export default GradeViewer


