export const setError = (payload) => async dispatch => {
  dispatch({ 
    type: 'SET_ERROR',
    payload
  })
}

export const clearError = () => async dispatch => {
  dispatch({ type: 'CLEAR_ERROR' })
}

export const setMobileDuplicateError = (payload) => async dispatch => {
  dispatch({
    type: 'SET_MOBILE_DUPLICATE_ERROR',
    payload
  })
}

export const setMobileExistingError = (payload) => async dispatch => {
  dispatch({
    type: 'SET_MOBILE_EXISTING_ERROR',
    payload
  })
}