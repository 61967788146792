import DateFnsUtils from '@date-io/date-fns'
import {
  Avatar,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import {
  ChevronLeft,
} from '@material-ui/icons'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { withStyles } from '@material-ui/core/styles'

const drawerWidth = 360

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  paper: {
    width: drawerWidth,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    justifyContent: 'flex-end',
  },
  text: {
    padding: theme.spacing(1, 2, 0, 2),
  },
  filters: {
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(2),
  },
})

function FilterDrawer({
  classes, open, onClose,
  instructors,
  selectIndex, setSelectIndex,
  products,
  filter,
  setFilter,
}) {

  function set(key, value) {
    setFilter(prevFilter => ({ ...prevFilter,
      [key]: value
    }))
  }

  function setAllTime() {
    setFilter(prevFilter => ({ ...prevFilter,
      startDate: null,
      endDate: null,
    }))
  }

  function setThisMonth() {
    const today = new Date()
    setFilter(prevFilter => ({ ...prevFilter,
      startDate: new Date(today.getFullYear(), today.getMonth(), 1),
      endDate: today,
    }))
  }

  function setThisYear() {
    const today = new Date()
    setFilter(prevFilter => ({ ...prevFilter,
      startDate: new Date(today.getFullYear(), 0, 1),
      endDate: today,
    }))
  }

  function toggle(course) {
    setFilter(prevFilter => {
      if (prevFilter.courses.includes(course))
        return { ...prevFilter, courses: prevFilter.courses.filter(c => c !== course) }
      else
        return { ...prevFilter, courses: [ ...prevFilter.courses, course ] }
    })
  }

  // TODO: Dynamically name company who logged in (and its legal number)
  return <Drawer
    open={open}
    className={classes.drawer}
    variant='temporary'
    classes={{ paper: classes.paper }}>
    <div className={classes.header}>
      <IconButton onClick={onClose}><ChevronLeft /></IconButton>
    </div>
    <Divider />
    <Typography variant='h6' className={classes.text}>
      UATO 0001<br />
      Garuda Robotics
    </Typography>
    <Typography className={classes.text}>Select Personnel</Typography>
    <List>
    {instructors.map((instructor, index) => (
      <ListItem button key={index}
        selected={index === selectIndex}
        onClick={() => setSelectIndex(index)}>
        <ListItemIcon>
          <Avatar>{instructor.type}</Avatar>
        </ListItemIcon>
        <ListItemText
          primary={instructor.name}
          secondary={instructor.position} />
      </ListItem>
    ))}
    </List>
    <Divider />
    <div className={classes.filters}>
      <Typography variant='h6'>Filters</Typography>
      <Typography variant='body1'>Student Results</Typography>
      <ButtonGroup color='primary' fullWidth>
        <Button variant={filter.studentResults === 'all' ? 'contained' : 'outlined'}
          onClick={() => set('studentResults', 'all')}>All</Button>
        <Button variant={filter.studentResults === 'pass' ? 'contained' : 'outlined'}
          onClick={() => set('studentResults', 'pass')}>Pass only</Button>
        <Button variant={filter.studentResults === 'fail' ? 'contained' : 'outlined'}
          onClick={() => set('studentResults', 'fail')}>Fail only</Button>
      </ButtonGroup>
      <br />
      <br />
      <Typography variant='body1'>Flight Incident</Typography>
      <ButtonGroup color='primary' fullWidth>
        <Button variant={filter.flightIncident === 'all' ? 'contained' : 'outlined'}
          onClick={() => set('flightIncident', 'all')}>All</Button>
        <Button variant={filter.flightIncident === 'only' ? 'contained' : 'outlined'}
          onClick={() => set('flightIncident', 'only')}>Incident Only</Button>
        <Button variant={filter.flightIncident === 'exclude' ? 'contained' : 'outlined'}
          onClick={() => set('flightIncident', 'exclude')}>Exclude Incidents</Button>
      </ButtonGroup>
      <br />
      <br />
      <Typography variant='body1'>Date Range</Typography>
      <ButtonGroup color='primary' fullWidth>
        <Button size='small' onClick={setAllTime}>All Time</Button>
        <Button size='small' onClick={setThisMonth}>This Month</Button>
        <Button size='small' onClick={setThisYear}>This Year</Button>
      </ButtonGroup>
      <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ display: 'flex' }}>
        <KeyboardDatePicker
          style={{ width: '48%', marginRight: '4px' }}
          disableToolbar
          variant='inline'
          color='primary'
          format='dd/MM/yyyy'
          id='start-date'
          label='Start Date'
          value={filter.startDate}
          onChange={(date) => set('startDate', date)}
        />
        <KeyboardDatePicker
          style={{ width: '48%', marginLeft: '4px' }}
          disableToolbar
          variant='inline'
          color='primary'
          format='dd/MM/yyyy'
          id='end-date'
          label='End Date'
          value={filter.endDate}
          onChange={(date) => set('endDate', date)}
        />
      </MuiPickersUtilsProvider>
      <br />
      <br />
      <Typography variant='body1'>Products</Typography>
      <FormControl component='fieldset' className={classes.formControl}>
        <FormLabel component='legend'>UATO Courses</FormLabel>
        <FormGroup>
        { products.map((product, index) => {
          return <FormControlLabel key={index}
            control={<Checkbox checked={filter.courses.includes(product.code)}
            onChange={() => toggle(product.code)} name={product.code} />}
            label={`${product.name} (${product.code})`}
          />
        }) }
        </FormGroup>
        <FormHelperText>Uncheck to exclude certain courses</FormHelperText>
      </FormControl>
    </div>
  </Drawer>
}

export default withStyles(styles)(FilterDrawer)
