import axios from 'axios'

import utils from '../utils'

const API_URL = process.env.REACT_APP_UATO_API_URL

export const submitFlightGrade = async (grade_details) => {
  try {
    // console.log('process order: ', grade_details)
    const options = utils.getJWTHeaders()
    const res = await axios.post(`${API_URL}/grade`, grade_details, options)
    // console.log('res.data is: ', res.data)
    return res.data
  } catch (e) {
    console.error('Error submiting flight grade', e)
    return e.response.data
  }
}

export const submitFlightGradeForm = async (data) => {
  try {
    // console.log('data: ', data)
    const options = utils.getJWTHeaders()
    const res = await axios.post(`${API_URL}/update-flight-grade`, data, options)
    // console.log('res.data is: ', res.data)
    return res.data
  } catch (e) {
    console.error('Error submitting flight grade form', e)
    return e.response.data
  }
}

export const getGrades = async (skip) => {
  const url = `${API_URL}/grades/${skip}`
  const options = utils.getJWTHeaders()
  
  const res = await axios.get(url, options)

  return res.data
}

export const getGradesForAdmin = async (query) => {
  // console.log('query', query)
  const url = `${API_URL}/grades-for-admin${query || ''}`
  const options = utils.getJWTHeaders()
  
  const res = await axios.get(url, options)

  return res.data
}

export const getTraineeGradeReports = async (trainee) => {
  try {
    const url = `${API_URL}/trainee-grade-reports/${trainee}`
    const options = utils.getJWTHeaders()
    
    const res = await axios.get(url, {...options, timeout: 60000})

    return res.data
  } catch (e) {
    return {error: e}
  }
}

export const getTraineeSelection = async (trainee) => {
  const query = trainee ? `?trainee=${trainee}` : ''
  const url = `${API_URL}/get-suggested-trainee${query}`
  const options = utils.getJWTHeaders()
  
  const res = await axios.get(url, options)

  return res.data
}

export const getTraineeFlightGradeCourses = async (trainee) => {
  const url = `${API_URL}/get-trainee-flight-grade-courses/${trainee || ''}`
  const options = utils.getJWTHeaders()
  
  const res = await axios.get(url, options)

  return res.data
}

export const getFlightGradeById = async (grade_id) => {
  const url = `${API_URL}/retrieve-flight-grade/${grade_id}`
  const options = utils.getJWTHeaders()
  
  const res = await axios.get(url, options)

  return res.data
}

export const deleteFlightGrade = async (grade_id) => {
  const url = `${API_URL}/delete-flight-grade/${grade_id}`
  const options = utils.getJWTHeaders()
  
  const res = await axios.put(url, grade_id, options)

  return res.data
}

export const initializeGradeForm = async (set_query) => {
  const select = set_query ? set_query.select : null
  let query = ''
  if (select && select.length > 0) {
    let i=0
    for (const s of select) {
      query = `select=${s}&` + query
      i = i+1
    }
    query = query.slice(0, -1)
  }
  const url = `${API_URL}/flight-grade-formload${query ? `?${query}` : ''}`
  const options = utils.getJWTHeaders()
  
  const res = await axios.get(url, options)

  return res.data
}
