import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import utils from '../../utils'

import api_interface from '../../api_interface'

import { addDiscount } from '../../actions'


class EnrolSummary extends React.Component {
  state = {
    coupon_code: '',
    applied_coupon: null,
    processing_coupon: false,
    coupon_error: null
  }

  handleChange = (name, event) => {
    this.setState({
      [name]: event.target.value,
      coupon_error: ''
    })
  }

  insertCouponCode = async() => {
    this.setState({
      processing_coupon: true
    })
    const validate_coupon_res = await api_interface.applyCoupon({ coupon: this.state.coupon_code })
    this.setState({
      coupon_code: ''
    })
    if (validate_coupon_res.success) {
      this.props.addDiscount(validate_coupon_res.applied_coupon)
      this.setState({
        applied_coupon: validate_coupon_res.applied_coupon,
        processing_coupon: false
      })
    } else {
      this.setState({
        coupon_error: validate_coupon_res.error,
        processing_coupon: false
      })
    }
  }

  render() {
    const { selected_catalog_item, trainees } = this.props

    const { applied_coupon, processing_coupon } = this.state

    const total_price = selected_catalog_item.catalog_price.amount * trainees.length
    //const total_price = selected_catalog_item.catalog_price.amount * trainees.length || 0

    let discount_nominal = 0

    if (applied_coupon) {
      discount_nominal = applied_coupon.type === 'percent' ?
        (applied_coupon.discount/100) * total_price
        :
        applied_coupon.discount
    }

    const final_price = discount_nominal > 0 ? total_price - discount_nominal : total_price

    return (
      <Card>
        <CardContent>
          <Typography style={{ fontSize: '20px' }}>Summary</Typography>
          
          <br/>
          <Divider />
          <br/>
          
          <Typography style={{ fontSize: '16px', fontWeight: '500', paddingBottom: '8px' }}>{selected_catalog_item.sku} - {selected_catalog_item.name}</Typography>
          <Typography style={{ fontSize: '12px'}}>Quantity: {trainees.length}</Typography>
          
          <br/>
          { processing_coupon && <div>Processing coupon...</div> }
          {
            !applied_coupon &&
            <div style={{ display: !processing_coupon ? 'block' : 'none' }} >
              <Typography style={{ fontSize: '16px', fontWeight: '500', paddingBottom: '8px' }}>Coupon code <span style={{ color: '#757575' }} >(optional)</span></Typography>
              <br />
              <div>
                <TextField 
                  label="Coupon"
                  onChange={(event) => this.handleChange('coupon_code', event)} 
                  value={this.state.coupon_code} 
                  InputLabelProps={{ shrink: true }} 
                  style={{ minWidth: '90%' }}
                />
              </div>
              { this.state.coupon_error &&
                <Typography style={{ color: 'red',fontSize: '14px' }}>{this.state.coupon_error}</Typography>
              }
              <br />
              <div>
                <Button 
                    style={{ textTransform: 'none', border: '1px solid' }} 
                    onClick={() => this.insertCouponCode()}
                    disabled={ this.state.coupon_code === '' }
                  >
                  Apply
                </Button>
              </div>
            </div>
          }
          <br />
          <Divider />
          <br />

          {applied_coupon && applied_coupon.id &&
            <div>
              <Grid container>
                <Grid item md={6}> <Typography style={{ fontSize: '14px', fontWeight: '400' }}>Subtotal</Typography></Grid>
                <Grid item md={6}> <Typography style={{ fontSize: '14px', fontWeight: '400', float: 'right' }}>{utils.formatPrice({ currency: 'SGD', amount: total_price })}</Typography></Grid>
              </Grid>
              <Grid container>
                <Grid item md={6}> <Typography style={{ fontSize: '14px', fontWeight: '400' }}>Discount</Typography></Grid>
                <Grid item md={6}> <Typography style={{ fontSize: '14px', fontWeight: '400', float: 'right' }}>-{utils.formatPrice({ currency: 'SGD', amount: discount_nominal })}</Typography></Grid>
              </Grid>
            </div>
          }

          <Grid container>
            <Grid item md={6}> <Typography style={{ fontSize: '16px', fontWeight: '500' }}>Total</Typography></Grid>
            <Grid item md={6}> <Typography style={{ fontSize: '16px', fontWeight: '500', float: 'right' }}>{utils.formatPrice({ currency: 'SGD', amount: final_price })}</Typography></Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }
}

const mapStateToProps = state => {
  return {
    selected_catalog_item: state.enrol.selected_catalog_item,
    trainees: state.enrol.trainees
  }
}

const mapDispatchToProps = {
  addDiscount
}

export default connect(mapStateToProps, mapDispatchToProps)(EnrolSummary)