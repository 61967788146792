import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import utils from '../../../utils'
import api_interface from '../../../api_interface'
import { Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { FilePicker } from 'react-file-picker'
import { addTheoryResult } from '../../../actions'

const MAX_PDF_SIZE_MB = process.env.REACT_APP_MAX_PDF_SIZE_MB

const sidebar_style = {
  position: 'fixed',
  right: '0px',
  top: '0px',
  bottom: '0px',
  left: '70%',
  zIndex: '1100',
  background: '#fff',
  boxShadow: '0 1px 3px 2px rgba(0,0,0,.2)',
  overflowY: 'auto'
}

const sidebar_wrapper = {
  padding: '20px',
  borderBottom: '1px solid #e0e0e0',
  position: 'relative'
}

const sub_header_style = {
  fontSize: '1rem',
  fontWeight: '500',
  margin: '0 0 10px'
}

const sidebar_row = {
  margin: '10px 0'
}

const sidebar_label = {
  color: '#757575',
  fontSize: '.875rem',
  fontWeight: 500
}

const sidebar_content = {
  fontSize: '1rem'
}

const pseudo_container = {
  display: 'flex',
  width: '100%'
}

const pseudo_half_grid = {
  flexBasis: '50%'
}

const course_order = {
  'Theory Module 1': 1,
  'Theory Module 2': 2,
  'P010 Practical Session' : 3,
  'P010 Mock Evaluation' : 4,
  'P011 Practical Session' : 5,
  'P011 Mock Evaluation' : 6,
  'P020 Practical Session' : 7,
  'P020 Mock Evaluation' : 8,
  'UAPL Practical Assessment': 9
}

const caas_status = [ 'Unknown', 'Failed', 'Passed' ]

class TraineeSidebar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      credit: null,
      academic_dialog: false,
      arranged_credit: {},
      trainee_status: 'Trainee',
      caas_theory: [{ result: 'Unknown', attempt_date: '' }],
      certificate_id: '',
      new_item: '',
      new_item_file: null,
      new_item_url: '',
      list: [],
      errorDialogOpen: false,
      delete_dialog: false,
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.open_sidebar !== prevProps.open_sidebar || this.props.selected_trainee !== prevProps.selected_trainee) {
      this.setState({ credit: null })
      const credit_res_data = await api_interface.getTraineeCredits(this.props.selected_trainee._id)
      // console.log('credit_res_data', credit_res_data)
      const arranged_credit = this.arrangeCreditsByStatus(credit_res_data.credits)
      if (credit_res_data.success) {
        this.setState({
          caas_theory: this.props.selected_trainee.caas_theory.length === 0 ? [{ result: 'Unknown', attempt_date: '' }] : this.props.selected_trainee.caas_theory.map( caas => {
            const attempt_date = new Date(caas.attempt_date)
            return { result: caas.result, attempt_date: `${attempt_date.getFullYear()}-${parseInt(attempt_date.getMonth()+1) < 10 ? `0${parseInt(attempt_date.getMonth())+1}` : attempt_date.getMonth()+1}-${attempt_date.getDate()}` }
          }),
          certificate_id: this.props.selected_trainee.certificate_id || '',
          credit: credit_res_data.credits,
          arranged_credit,
        })
      }
    }
  }

  handleEnabledTopup = async (e) => {
    const enabled = Boolean(e.target.checked)

    if (enabled !== this.props.selected_trainee.topup_enabled) {
      // const toggle_res_data = await api_interface.toggleTopupCredit(this.props.selected_trainee._id, enabled)
      // console.log('toggle_res_data', toggle_res_data)
      const query = `?select_by=${this.props.filter_trainee}${this.props.search_trainee && this.props.search_trainee !== '' ? `&trainee=${this.props.search_trainee}` : ''}`
      this.props.refreshTraineeDetails(query)
      this.props.closeTraineeDetail('page_refereshed')
    }

  }

  renderProfile = (trainee) => {
    if (trainee === null) {
      return (
        <div style={sidebar_wrapper}>
          <CircularProgress size={24} />
        </div>
      )
    }
    return (
      <div style={sidebar_wrapper}>
        <div style={{ position: 'relative' }} >
          <h3 style={sub_header_style} >Profile Information</h3>
          { trainee &&
            <div style={{ position: 'absolute', zIndex: '10', right: 0, top: 0 }} ><Link to={`/admin/edit-trainee/${trainee._id}`} style={{ textDecoration: 'none', lineHeight: '21px', color: 'rgb(30, 144, 255)' }} >Edit</Link></div>
          }
        </div>
        <div style={sidebar_row}>
          <div style={sidebar_label} >First Name</div>
          <div style={sidebar_content} >{ trainee.first_name }</div>
        </div>
        <div style={sidebar_row}>
          <div style={sidebar_label} >Last Name</div>
          <div style={sidebar_content} >{ trainee.last_name }</div>
        </div>
        <div style={sidebar_row}>
          <div style={sidebar_label} >Email</div>
          <div style={sidebar_content} >{ trainee.email }</div>
        </div>
        <div style={sidebar_row}>
          <div style={sidebar_label} >Mobile Number</div>
          <div style={sidebar_content} >{ trainee.mobile_number }</div>
        </div>
        <div style={sidebar_row}>
          <div style={sidebar_label} >Contact Owner</div>
          <div style={sidebar_content} >{ `${trainee.order_detail.billing_contact.first_name || ''} ${trainee.order_detail.billing_contact.last_name || ''}` }</div>
        </div>
      </div>
    )
  }

  renderAcademic = (trainee, credit) => {
    if (trainee === null || credit === null) {
      return (
        <div style={sidebar_wrapper}>
          <CircularProgress size={24} />
        </div>
      )
    }
    return (
      <div style={sidebar_wrapper}>
        <div style={{ position: 'relative' }} >
          <h3 style={sub_header_style}>Academic Information</h3>
          { trainee &&
            <div style={{ position: 'absolute', zIndex: '10', right: 0, top: 0, color: 'rgb(30, 144, 255)', cursor: 'pointer' }} onClick={ (e) => this.handleDialogOpen(e, 'academic_dialog') } >Edit</div>
          }
        </div>
        <div style={sidebar_row}>
          <div style={sidebar_label} >Course</div>
          <div style={sidebar_content} >{ trainee.active_catalog.sku } - { trainee.active_catalog.name }</div>
        </div>
        <div style={sidebar_row}>
          <div style={sidebar_label} >Status</div>
          <div style={sidebar_content} >{ credit.status }</div>
        </div>
        <div style={sidebar_row}>
          <div style={sidebar_label} >Student ID</div>
          <div style={sidebar_content} >{ trainee.student_id }</div>
        </div>
        <div style={sidebar_row}>
          <div style={sidebar_label} >Date Joined</div>
          <div style={sidebar_content} >{ utils.formatTime(trainee.order_detail.ordered_on) }</div>
        </div>
        <div style={sidebar_row}>
          <div style={sidebar_label} >CAAS Theory Result</div>
          <div style={sidebar_content} >{ trainee.caas_theory.length === 0 ? '-' :
            <div>
              { trainee.caas_theory.map( (caas, index) => {
                return (
                  <div key={index}>
                    { caas.result !== 'Unknown' ? `${ caas.result } on ${ utils.formatTime(caas.attempt_date) }` : 'Unknown' }
                  </div>
                )
              } )}
            </div>
          } </div>
        </div>
        <div style={sidebar_row}>
          <div style={sidebar_label} >Certificate ID</div>
          <div style={sidebar_content} >{ trainee.certificate_id === '' || !trainee.certificate_id ? '-' : trainee.certificate_id}</div>
        </div>
      </div>
    )
  }

  renderCourse = (credit) => {
    if (credit === null || !credit.course) {
      return (
        <div style={sidebar_wrapper}>
          <CircularProgress size={24} />
        </div>
      )
    }
    return (
      <div style={sidebar_wrapper}>
        <h3 style={sub_header_style}>Course Credits</h3>
        <div style={sidebar_row}>
          <div style={sidebar_label} >Used</div>
          {
            credit.course.availability.used.length === 0 ?
              <div style={sidebar_content} >-</div>
              :
              credit.course.availability.used.map( (c, i) => <div key={i} style={sidebar_content} >{c.sessions}x {c.course_name}</div>)
          }
        </div>
        <div style={sidebar_row}>
          <div style={sidebar_label} >Available</div>
          {
            credit.course.availability.available.length === 0 ?
              <div style={sidebar_content} >-</div>
              :
              credit.course.availability.available.map( (c, i) => <div key={i} style={sidebar_content} >{c.sessions}x {c.course_name}</div>)
          }
        </div>
        <div style={sidebar_row} >
          <div style={sidebar_content}>
            <FormControlLabel
              control={
                <Switch checked={this.props.selected_trainee.topup_enabled} onChange={(e) => this.handleEnabledTopup(e)} name='topup_enabled' />
              }
              label={`${this.props.selected_trainee.topup_enabled ? 'Topup Credit Form Active' : 'Topup Credit Form Inactive' }`}
            />
          </div>
        </div>
      </div>
    )
  }

  renderCaasForm = () => this.state.caas_theory.map( (caas, index) => {
    return (
      <div style={{ ...pseudo_container, justifyContent: 'space-between' }} key={index} >
        <div style={{ ...pseudo_half_grid, margin: '10px 10px 10px 0', flexBasis: '45%' }} >
          <div style={ sidebar_label } >Attempt Date</div>
          <TextField
            id="date"
            type="date"
            style={{ width: '100%' }}
            value={ this.state.caas_theory[index].attempt_date }
            required={true}
            onChange={ e => this.handleChangeArray({ e, index, parent_target: 'caas_theory', child_target: 'attempt_date' }) }
          />
        </div>
        <div style={{ ...pseudo_half_grid, margin: '10px 0 10px 10px' }} >
          <div style={ sidebar_label }>Status</div>
          <Select style={{ width: '100%' }} value={ this.state.caas_theory[index].result } onChange={ e => this.handleChangeArray({ e, index, parent_target: 'caas_theory', child_target: 'result'}) } >
            {
              caas_status.map( (status, index) => {
                return (
                  <MenuItem key={index} value={status} >{ status }</MenuItem>
                )
              } )
            }
          </Select>
          { index > 0 &&
            <div style={{ fontSize: '13px', lineHeight: '21px', color: 'rgb(30, 144, 255)', cursor: 'pointer' }} onClick={(e) => this.removeCaas(e, index)} >Remove attempt</div>
          }
        </div>
      </div>
    )
  })

  renderAcademicDialog = () => {
    return (
      <Dialog
        open={ this.state.academic_dialog }
        onClose={ () => this.setState({ academic_dialog: false }) }
        style={{ minWidth: '600px' }}
      >
        <DialogTitle style={{ minWidth: '552px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
            <div>Edit Academic Information</div>
            <div>
              <IconButton style={{ backgroundColor: 'transparent', color: '#616161', textTransform: 'none', fontWeight: 'bold', padding: '0' }} onClick={ () => this.setState({ academic_dialog: false }) }>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div style={ pseudo_container } >
            <div style={ pseudo_half_grid } >
              <div style={{ marginRight: '10px' }} >
                <div style={ sidebar_label }>Status</div>
                <Select value={this.state.trainee_status} >
                  <MenuItem value='Trainee' >Trainee</MenuItem>
                  <MenuItem value='Graduated' >Graduated</MenuItem>
                </Select>
              </div>
            </div>
            <div style={ pseudo_half_grid } >
              <div style={{ marginLeft: '10px' }} >
                <div style={ sidebar_label } >Certificate ID</div>
                <TextField
                  id="certificate_id"
                  type="text"
                  value={ this.state.certificate_id }
                  onChange={ (e) => this.handleChange(e, 'certificate_id') }
                />
              </div>
            </div>
          </div>
          <div style={{ fontWeight: '500', margin: '30px 0 10px' }}>No. of attempts for CAAS Theory Test: { this.state.caas_theory.length } </div>
          { this.renderCaasForm() }
          <div style={{ cursor: 'pointer', fontWeight: '500', margin: '10px 0' }} onClick={ (e) => this.addNewCaas(e) } >+ CAAS Theory Result</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => this.setState({ academic_dialog: false }) } style={{ backgroundColor: 'white', color: '#1E90FF', textTransform: 'none' }}>
            <strong>Close</strong>
          </Button>
          <Button onClick={(e) => this.submitTraineeAcademic(e)} style={{ textTransform: 'none' }}>
            <strong>Submit</strong>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  //------------------Attachment theory result component--------------------

  updateInput = async (e, selected_trainee) => {
    await this.setState({
      new_item_file: e,
      new_item: e.name,
      new_item_url: URL.createObjectURL(e)
    })
    this.addItem(selected_trainee)
  }

  addItem = async (selected_trainee) => {
    const new_item = {
      id: 1 + Math.random(),
      value: this.state.new_item.slice(),
      url: this.state.new_item_url.slice()
    }

    const form_data = new FormData()
    form_data.append('trainee_id', selected_trainee._id)
    form_data.append('file', this.state.new_item_file)

    await this.props.addTheoryResult(form_data, this.props.auth._id)

    const query = `?select_by=${this.props.filter_trainee}${this.props.search_trainee && this.props.search_trainee !== '' ? `&trainee=${this.props.search_trainee}` : ''}`
    await this.props.refreshTraineeDetails(query)
    await this.props.closeTraineeDetail('page_refereshed')

    const list = [...this.state.list]
    list.push(new_item)
    this.setState({
      list,
      new_item: '',
    })
  }

  deleteItem = () => {
    const { id } = this.state
    const list = [...this.state.list]
    const updated_list = list.filter(item => item.id !== id)

    this.setState({
      list: updated_list
    })
  }

  handleAttachmentDialogOpen = (dialog_name, id) => {
    this.setState({
      [dialog_name]: true,
      id: id
    })
  }

  renderErrorDialog = () => {
    return (
      <Dialog
        open={ this.state.errorDialogOpen }
        onClose={ () => this.setState({ errorDialogOpen: false }) }
        style={{ minWidth: '600px' }}
      >
        <DialogTitle style={{ minWidth: '552px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
            <div>Upload Error</div>
            <div>
              <IconButton 
                style={{ backgroundColor: 'transparent', color: '#616161', textTransform: 'none', fontWeight: 'bold', padding: '0' }} 
                onClick={ () => this.setState({ errorDialogOpen: false }) }>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          Upload failed. Please try again later......
        </DialogContent>
      </Dialog>
    )
  }

  renderDeleteDialog = () => {
    return (
      <Dialog
        open={this.state.delete_dialog}
        onClose={() => this.setState({ delete_dialog: false })}
        style={{ minWidth: '600px' }}
      >
        <DialogTitle style={{ minWidth: '552px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
            <div>Delete file</div>
            <div>
              <IconButton style={{ backgroundColor: 'transparent', color: '#616161', textTransform: 'none', fontWeight: 'bold', padding: '0' }} onClick={() => this.setState({ delete_dialog: false })}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          Are you sure you want to delete this file?
      </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ delete_dialog: false })} style={{ backgroundColor: 'white', color: '#1E90FF', textTransform: 'none' }}>
            <strong>Cancel</strong>
          </Button>
          <Button onClick={() => { this.setState({ delete_dialog: false }); this.deleteItem() }} style={{ backgroundColor: 'white', color: '#1E90FF', textTransform: 'none' }}>
            <strong>Confirm</strong>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderAttachment = (selected_trainee) => {
    if (selected_trainee === null) {
      return (
        <div style={sidebar_wrapper}>
          <CircularProgress size={24} />
        </div>
      )
    }

    return (
      <div style={sidebar_wrapper}>
        <div style={{ position: 'relative' }} >
          <h3 style={sub_header_style} >Attachment</h3>
          {
            <div style={{ display: 'flex', position: 'absolute', right: 0, top: 0 }} >
              <FilePicker
                extensions={['pdf']}
                onChange={e=>this.updateInput(e,selected_trainee)}
                onError={() => this.handleAttachmentDialogOpen('attachment_dialog', null)}
                accept='application/pdf'
                maxSize={MAX_PDF_SIZE_MB}
                sizeUnit='MB'
              >
                {
                  (selected_trainee.caas_theory.length <= 4)
                    ? <div style={{ position: 'absolute', zIndex: '10', right: 0, top: 0, color: 'rgb(30, 144, 255)', cursor: 'pointer' }}>Add</div>
                    : <div></div>
                }
              </FilePicker>
            </div>
          }
        </div>
        <div>
        </div>
        <div style={sidebar_row}>
          {selected_trainee.caas_theory.map(item => {
            return <div key={item.file_id} style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', padding: '5px 10px', backgroundColor: '#f7f8fa' }} >
              <Link style={{ textDecoration: 'none', color: 'rgb(30, 144, 255)' }} to={{ pathname: `/caas/${item.filename}`}}>{item.caas_id}</Link>
              {/* <a style={{ textDecoration: 'none', color: 'rgb(30, 144, 255)' }} href={this.convertToBlob(item.filename)} target='_blank'>{item.caas_id}</a> */}
              {/* <IconButton style={{ backgroundColor: 'transparent', color: '#616161', textTransform: 'none', fontWeight: 'bold', padding: '0', margin: '5' }} onClick={() => this.handleAttachmentDialogOpen('delete_dialog', item.id)}>
                 <CloseIcon />
              </IconButton> */}
            </div>
          })}
        </div>
      </div>
    )
  }

  //------------------------------------------------------------------------

  creditWithSession = (credits) => {
    let credit_order = []
    for (const credit of credits) {
      const credit_position_on_list = credit_order.findIndex(co => co.course_id === credit.course_id)
      if (credit_position_on_list < 0) {
        credit_order.push({ course_id: credit.course_id, sessions: 1, course_name: credit.course.name, order: course_order[credit.course.name] })
      } else {
        credit_order[credit_position_on_list].sessions = credit_order[credit_position_on_list].sessions + 1
      }
    }
    return credit_order.sort((a,b) => a.order - b.order)
  }

  arrangeCreditsByStatus = (credits) => {
    if (!credits) {
      return null
    }

    // console.log('credits', credits)

    const passed_status = 'Passed'
    const used_status_list = ['Enrolled', 'Passed', 'Failed']
    const available_status_list = ['Available', 'Cancelled']


    const status = credits.findIndex( c => c.status !== passed_status ) > -1 ? 'Trainee' : credits.length === 0 ? 'Trainee' : 'Graduated'
    const used_credits = credits.filter( c => used_status_list.indexOf(c.status) > -1 )
    const available_credits = credits.filter( c => available_status_list.indexOf(c.status) > -1 )
    const arranged_credit = {
      course: {
        availability: {
          used: this.creditWithSession(used_credits),
          available: this.creditWithSession(available_credits)
        },
      },
      status
    }
    return arranged_credit
  }

  handleDialogOpen = (e, dialog_name) => {
    e.preventDefault()
    this.setState({
      [dialog_name]: true
    })
  }

  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleChangeArray = (payload) => {
    const { e, index, parent_target, child_target } = payload
    let updated_parent = [ ...this.state[parent_target] ]
    updated_parent[index][child_target] = e.target.value
    this.setState({
      [parent_target]: updated_parent
    })
  }

  addNewCaas = (e) => {
    e.preventDefault()
    this.setState({
      caas_theory: [ ...this.state.caas_theory, { result: 'Unknown', attempt_date: '' } ]
    })
  }

  removeCaas = (e, remove_index) => {
    e.preventDefault()
    if (this.state.caas_theory.length > 1) {
      const caas_theory = this.state.caas_theory.filter( (a, i) => i !== remove_index)
      this.setState({ caas_theory })
    }
  }

  submitTraineeAcademic = async (e) => {
    e.preventDefault()
    const { certificate_id, caas_theory } = this.state

    let trainee_details = {
      ...this.props.selected_trainee,
    }
    const submit_result = ['Passed', 'Failed']

    // const test_logic = caas_theory.length === 1 && submit_result.indexOf(caas_theory[0].result) > -1

    if (caas_theory.length > 1 || (caas_theory.length === 1 && submit_result.indexOf(caas_theory[0].result) > -1 )) {
      if ( caas_theory.findIndex( caas => caas.attempt_date ) < 0 ) {
        alert('Please fill the empty attempt date')
        return
      }
      trainee_details.caas_theory = caas_theory.map(caas => {
        return {
          result: caas.result,
          attempt_date: caas.attempt_date
        }
      })
    }

    if (certificate_id && certificate_id !== '') {
      trainee_details.certificate_id = certificate_id
    }

    const update_trainee_details = await api_interface.updateTraineeDetailsById(trainee_details)
    // console.log('update_trainee_details', update_trainee_details)
    if (update_trainee_details.success) {
      const query = `?select_by=${this.props.filter_trainee}${this.props.search_trainee && this.props.search_trainee !== '' ? `&trainee=${this.props.search_trainee}` : ''}`
      this.props.refreshTraineeDetails(query)
      this.props.closeTraineeDetail('page_refereshed')
      this.setState({
        academic_dialog: false
      })
    }
  }

  render() {
    const { closeTraineeDetail, selected_trainee } = this.props
    const { arranged_credit } = this.state

    return (
      <div>
        <Slide direction="left" in={this.props.open_sidebar} mountOnEnter unmountOnExit>
          <div style={sidebar_style}>
            <div style={sidebar_wrapper}>
              <div style={{ fontSize: '1.2rem', fontWeight: '500' }} >{`${selected_trainee ? selected_trainee.first_name : ``} ${selected_trainee ? selected_trainee.last_name : ``}`} </div>
              <IconButton onClick={() => closeTraineeDetail()} style={{ color: '#212121', position: 'absolute', right: 10, top: 10 }} >
                <CloseIcon />
              </IconButton>
            </div>
            { this.renderProfile(selected_trainee) }
            { this.renderAcademic(selected_trainee, arranged_credit) }
            { this.renderCourse(arranged_credit) }
            { this.renderAttachment(selected_trainee)}
          </div>
        </Slide>
        { this.renderAcademicDialog() }
        { this.renderErrorDialog() }
        { this.renderDeleteDialog() }
      </div>
    )
  }
}

const mapStateToProps = (state) => state

export default connect(mapStateToProps, {addTheoryResult})(TraineeSidebar)
