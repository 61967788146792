import React, { useState, useEffect } from 'react'

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  Close,
  Search,
} from '@material-ui/icons'

import { months } from '../../utils/constants.json'
import api_interface from '../../api_interface'

class ModuleGradingViewer extends React.Component {
  state = {
    table_info: null,
    change_status: {},
    open_dialog: false,
    current_query: null,
    active_tab: 'enrolled', // active_tab list: 'enrolled', 'cancelled'
    search_trainee: '',
    disabled_search_form: false,
    instructors: []
  }

  async componentDidMount() {
    const get_parsed_query = this.parseQuery()
    // console.log('get_parsed_query', get_parsed_query)
    await this.getEnrolledSchedules(get_parsed_query)
  }

  componentDidUpdate = async (prevProps) => {
    if (this.props.location.search !== prevProps.location.search) {
      const get_parsed_query = this.parseQuery()
      // console.log('get_parsed_query', get_parsed_query)
      this.getEnrolledSchedules(get_parsed_query)
    }
  }

  parseQuery = () => {
    const now = new Date()
    const query_params = this.props.location.search
    const urlParams = new URLSearchParams(query_params)
    const year_query = urlParams.get('year') && urlParams.get('year') !== '' ? `year=${urlParams.get('year')}` : `year=${now.getFullYear()}`
    const month_query = urlParams.get('month') && urlParams.get('month') !== '' ? `&month=${urlParams.get('month')}` : `&month=${now.getMonth()}`
    const query_by_month = year_query + month_query
    const query_by_trainee = urlParams.get('trainee') && urlParams.get('trainee') !== '' ? `trainee=${urlParams.get('trainee')}` : null
    const return_query = {
      month: parseInt(urlParams.get('month')) || parseInt(urlParams.get('month')) === 0 ? parseInt(urlParams.get('month')) : now.getMonth(),
      year: parseInt(urlParams.get('year')) || now.getFullYear(),
      trainee: urlParams.get('trainee'),
      query_params: query_by_trainee ? [query_by_month, query_by_trainee].join('&') : query_by_month,
      query_by_month,
      query_by_trainee
    }
    return return_query
  }

  getEnrolledSchedules = async (query) => {
    this.setState({ table_info: null })
    const query_params = this.props.location.search
    const urlParams = new URLSearchParams(query_params)
    let enrolled_schedules = null

    if (query.query_by_month) {
      const current_query = { year: parseInt(query.year), month: parseInt(query.month) }
      this.setState({ current_query })
    }
    
    if (query.query_by_trainee) {
      const search_trainee = query.trainee
      this.setState({ search_trainee })
    }

    enrolled_schedules = await api_interface.getMonthlyEnrolledSchedules(`?${query.query_params}`)

    console.log('enrolled_schedules', enrolled_schedules)

    this.setState({
      table_info: enrolled_schedules.schedule,
      instructors: enrolled_schedules.instructors,
      disabled_search_form: urlParams.get('trainee') && urlParams.get('trainee') !== ''
    })
  }

  handleChangeSearch = (e) => {
    this.setState({
      search_trainee: e.target.value
    })
  }

  handleBlurSearch = () => {
    if (this.state.search_trainee === '') {
      const get_parsed_query = this.parseQuery()
      this.props.history.push(`?${get_parsed_query.query_by_month}`)
    }
  }
  
  handleFindTraineeSchedule = async (e) => {
    e.preventDefault()
    
    let set_query = this.state.search_trainee && this.state.search_trainee !== '' ?  `trainee=${this.state.search_trainee}` : null

    const get_parsed_query = this.parseQuery()
    const query_builder = set_query ? [get_parsed_query.query_by_month, set_query].join('&') : get_parsed_query.query_by_month

    const str_query = `?${query_builder}`

    if (str_query !== this.props.location.search) {
      this.setState({
        table_info: null
      })
      this.props.history.push(`?${query_builder}`)
    }

  }

  restoreModule = async () => {
    this.setState({
      disabled_search_form: false,
      search_trainee: ''
    })

    const get_parsed_query = this.parseQuery()
    await this.props.history.push(`?${get_parsed_query.query_by_month}`)
  }

  changeCreditStatus = async ({ trainee_credit_id, trainee_credit_name, status, instructor, credit_info }) => {
    this.setState( prevState => ({
        ...prevState,
        change_status: {
          ...prevState.change_status,
          trainee_credit_id,
          trainee_credit_name,
          status,
          instructor,
          credit_info,
        },
        open_dialog: true
      })
    )
  }

  updateCreditStatus = async (e) => {
    e.preventDefault()
    const { trainee_credit_id, status, instructor } = this.state.change_status

    let data = {
      status: status
    }

    if (instructor && instructor._id)
      data.instructor = instructor._id

    const credit_status_changed = await api_interface.setTraineeCreditGrade(trainee_credit_id, data)
    
    if (credit_status_changed.success) {
      this.setState({ open_dialog: false })
      const get_parsed_query = this.parseQuery()
      await this.getEnrolledSchedules(get_parsed_query)
    } else {
      alert(`Failed to update credit status. ${credit_status_changed.message}`)
    }
  }

  // handleScheduleTab = async (tab_status) => {
  //   this.setState({ active_tab: tab_status, table_info: null })
  //   // const show_status = tab_status === 'cancelled' ? ['Enrolled', 'Conditional Passed', 'Passed', 'Conditional Failed', 'Failed'] : tab_status
  //   const str_query =`include=${tab_status.charAt(0).toUpperCase() + tab_status.slice(1)}`
  //   const all_enrolled_schedules = await api_interface.getEntireEnrolledSchedules(str_query)
  //   if (all_enrolled_schedules.schedule)
  //     this.setState({ table_info: all_enrolled_schedules.schedule })
  // }

  render() {
    const {
      current_query,
      search_trainee,
      disabled_search_form,
      table_info,
      instructors,
      change_status,
      open_dialog,
    } = this.state
    return(
      <div>
        <HeaderControls
          query={current_query}
          history={this.props.history}
          search={search_trainee}
          enableSearch={!disabled_search_form}
          onChangeSearch={(event) => this.handleChangeSearch(event)}
          onBlurSearch={() => this.handleBlurSearch()}
          onRestore={() => this.restoreModule()}
          onSubmit={(e) => this.handleFindTraineeSchedule(e)}
        />
        <Grid container>
          <Grid item style={{ minWidth: '100%', marginBottom: '3em' }}>
            <TableContainer component={Paper}>
              <Table style={{ minWidth: '1000px' }}>
                <GradingTableHeader />
                <GradingTableBody
                  data={table_info}
                  instructors={instructors}
                  onChangeCredit={this.changeCreditStatus}
                />
              </Table>
            </TableContainer>
            { table_info === null &&
              <div style={{ textAlign: 'center', width: '100%', padding: '2em' }} >
                <CircularProgress />
              </div>
            }
            { table_info !== null && table_info.length === 0 &&
              <NoTrainees />
            }
            {
              open_dialog && change_status &&
              <GradingDialog
                open={open_dialog}
                onClose={() => this.setState({ open_dialog: false })}
                changeStatus={change_status}
                onUpdate={(e) => this.updateCreditStatus(e)}
              />
            }
          </Grid>
        </Grid>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    )
  }
}

function GradingTableHeader() {
  return (
    <TableHead>
      <TableRow style={{ background: '#f5f5f5' }} >
        <TableCell style={{ fontSize: 16, fontWeight: 'bold', width: 90 }}>Date</TableCell>
        <TableCell style={{ fontSize: 16, fontWeight: 'bold', width: 30 }}>Course</TableCell>
        <TableCell style={{ fontSize: 16, fontWeight: 'bold', width: 130 }}>Module</TableCell>
        <TableCell style={{ fontSize: 16, fontWeight: 'bold', width: 120 }}>Name</TableCell>
        <TableCell style={{ fontSize: 16, fontWeight: 'bold', width: 30 }}>Student ID</TableCell>
        <TableCell style={{ fontSize: 16, fontWeight: 'bold', width: 80 }}>Instructor</TableCell>
        <TableCell style={{ fontSize: 16, fontWeight: 'bold', width: 130, maxWidth: 130 }}>Status</TableCell>
      </TableRow>
    </TableHead>
  )
}

function GradingTableBody({ data, instructors, onChangeCredit }) {

  function formatDate(date) {
    const obj = new Date(date)
    const year = obj.getFullYear()
    const month = months[obj.getMonth()]
    const month_date = obj.getDate()

    return `${month} ${month_date}, ${year}`
  }
  const status_list = [ 'Passed', 'Conditional Passed', 'Conditional Failed', 'Failed', 'Cancelled' ]

  return (
    <TableBody>
      { data !== null && data.map((info, index) => (
        <TableRow key={index}>
          <TableCell style={{ width: 90 }}>{ formatDate(info.date) }</TableCell>
          <TableCell style={{ width: 30 }}>{ info.course_code }</TableCell>
          <TableCell style={{ width: 130 }}>
            { `${info.module}${info.session_number ? `-${info.session_number}` : ''}` }
          </TableCell>
          <TableCell style={{ width: 120 }}>{ info.name }</TableCell>
          <TableCell style={{ width: 30 }}>{ info.student_id }</TableCell>
          <TableCell style={{ width: 80 }}>
            <Select value={ JSON.stringify(info.instructor || {}) } onChange={(e) => onChangeCredit({
                trainee_credit_id: info.trainee_credit_id,
                trainee_credit_name: info.name,
                status: info.credit_status,
                instructor: JSON.parse(e.target.value),
                credit_info: info,
              }) } >
              <MenuItem value={ JSON.stringify({}) }>-</MenuItem>
              { instructors.map((instructor, j) => (
                <MenuItem key={j} value={ JSON.stringify(instructor) } >
                  { instructor.name }
                </MenuItem>
              )) }
            </Select>
          </TableCell>
          <TableCell style={{ width: 130, maxWidth: 130 }}>
            <Select style={{ maxWidth: 130 }} value={info.credit_status} onChange={
              (event) => onChangeCredit({
                trainee_credit_id: info.trainee_credit_id,
                trainee_credit_name: info.name,
                status: event.target.value,
                instructor: info.instructor || {},
                credit_info: info,
              })
            }>
              <MenuItem value={'Enrolled'}>
                <span style={{ fontSize: '12px', color: 'transparent' }}>hidden</span>
              </MenuItem>
              { status_list.map( (sl, k) => {
                return (
                  <MenuItem key={k} value={sl} >{sl}</MenuItem>
                )
              } ) }
            </Select>
          </TableCell>
        </TableRow>
      )) }
    </TableBody>
  )
}

function HeaderControls({
  query,
  history,
  search,
  enableSearch,
  onChangeSearch,
  onBlurSearch,
  onRestore,
  onSubmit,
}) {

  function setupMonthQuery() {
    const now = new Date()
    const top_limit_count = new Date(now.setMonth(now.getMonth() + 5))
    const bottom_limit_count = new Date(now.setMonth(now.getMonth() - 20))
    const top_query = {
      year: top_limit_count.getFullYear(),
      month: top_limit_count.getMonth()
    }
    const bottom_query = {
      year: bottom_limit_count.getFullYear(),
      month: bottom_limit_count.getMonth()
    }
    let query_list = []
    for (let i = top_query.year; i >= bottom_query.year; i--) {
      if (i === bottom_query.year) {
        for (let j = 11; j >= bottom_query.month; j--) {
          query_list.push({ year: i, month: j })
        }
      } else if (i === top_query.year) {
        for (let j = top_query.month-1; j >= 0; j--) {
          query_list.push({ year: i, month: j })
        }
      } else {
        for (let j = 11; j >= 0; j--) {
          query_list.push({ year: i, month: j })
        }
      }
    }
    return query_list
  }

  function handleChangeQuery(e) {
    const v = JSON.parse(e.target.value)
    const push_url = `?year=${v.year}&month=${v.month}${search ? ('&trainee=' + search) : ''}`
    history.push(push_url)
  }

  return (
    <div style={{ position: 'relative' }}>
      <br /><br /><br /><br /><br /><br />
      <h2 style={{ position: 'relative', marginTop: '0', marginBottom: '2em' }}>
        Module Grading
        <div style={{ position: 'absolute', top: 0, right: 0, display: 'flex' }}>
          <div style={{ display: 'flex', minWidth: '400px', marginRight: '10px' }}>
            <form disabled={!enableSearch}
              onSubmit={onSubmit} style={{ position: 'relative', width: '100%' }}>
              <TextField
                style={{ margin: 0, marginRight: '5px', width: '100%', paddingRight: '40px' }}
                id='standard-full-width'
                placeholder='Search trainee by name, email, or Student ID'
                margin='normal'
                InputLabelProps={{
                  shrink: true
                }}
                disabled={!enableSearch}
                value={search}
                name='trainee-search'
                onChange={onChangeSearch}
                onBlur={onBlurSearch}
              />
              { enableSearch ?
                <IconButton
                  type='submit'
                  style={{ position: 'absolute', right: 0, top: 0, padding: '8px' }}>
                  <Search />
                </IconButton>
                :
                <IconButton
                  style={{ position: 'absolute', right: 0, top: 0, padding: '8px' }}
                  onClick={onRestore}>
                  <Close />
                </IconButton>
              }
            </form>
          </div>
          <div style={{ display: 'flex' }}>
            { query ?
                <Select
                  value={ JSON.stringify(query) }
                  onChange={(e) => handleChangeQuery(e)}>
                  { setupMonthQuery().map( (query, i) => 
                    <MenuItem key={i} value={ JSON.stringify(query) }>
                      {months[query.month]} {query.year}
                    </MenuItem>
                  ) }
                </Select>
              :
                <CircularProgress />
            }
          </div>
        </div>
      </h2>
    </div>
  )
}

function NoTrainees() {
  return (
    <div style={{
      padding: '2em',
      textAlign: 'center',
      fontSize: '2em',
      fontStyle: 'italic',
      color: '#9e9e9e',
      fontWeight: '200'
    }}>
      No Trainees (choose a different month)
    </div>
  )
}

function GradingDialog({ open, onClose, changeStatus, onUpdate }) {

  const [ checked, setChecked ] = useState([0])
  const [ flights, setFlights ] = useState([])

  // Load the flights from Flights API where date is changeStatus.credit_info.date

  useEffect(() => {
    async function fetchTraineeFlights() {
      const data = await api_interface.getFlightsByStudentId(changeStatus.credit_info.student_id)
      console.log('data', data)
      if (!data.success) {
        console.log('Failed to get flights', data.error)
        setFlights([])
      } else {
        setFlights(data.flights)
      }
    }
    if (changeStatus?.credit_info?.student_id)
      fetchTraineeFlights()
  }, [changeStatus.credit_info.student_id])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
      TransitionComponent={Transition}>
      <DialogTitle>Update Grading</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant='body2'>Updating the status for:</Typography>
            <Typography variant='overline'>Name</Typography>
            <Typography variant='h6'>{ changeStatus.trainee_credit_name }</Typography>
            <Typography variant='overline'>Status</Typography>
            <Typography variant='h6'>{ changeStatus.status }</Typography>
            <Typography variant='overline'>Instructor</Typography>
            <Typography variant='h6'>{ changeStatus.instructor.name || '-' }</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='body1'>Attach Flight Logs</Typography>
            <Typography variant='caption'>Found the following flight logs on {changeStatus.credit_info.date}</Typography>
            { (!flights || flights.length === 0) && <>
              <Typography variant='body1'>No Flights Found</Typography>
              <Typography variant='caption'>This normally happens when there's no Garuda Plex identity associated with the student.</Typography>
            </>}
            <List>
              { flights.map((flight, i) => 
              <ListItem key={i} onClick={setChecked(i)}>
                <ListItemIcon>
                  <Checkbox
                    edge='start'
                    checked={checked.indexOf(i) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary='Date Time' secondary='Drone and other details' />
              </ListItem>
              ) }
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>
          Close
        </Button>
        <Button variant='contained' color='primary' onClick={onUpdate}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
})

export default ModuleGradingViewer
