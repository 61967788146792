const INITIAL_STATE = {
  message: '',
  type: '',

  mobile_duplicate_error: '',
}

const ErrorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return { ...state, type: action.payload.type, message: action.payload.message }

    case 'CLEAR_ERROR':
      return INITIAL_STATE

    case 'SET_MOBILE_DUPLICATE_ERROR':
      return { ...state, mobile_duplicate_error: action.payload, type: 'DUPLICATE_KEY_MOBILE_NUMBER', message: `Cannot duplicate mobile number`}

    case 'SET_MOBILE_EXISTING_ERROR':
      return { ...state, mobile_duplicate_error: action.payload, type: 'EXISTING_KEY_MOBILE_NUMBER', message: 'Account with this number exists'}

    default:
      return state
   }
}

export default ErrorReducer