import { createStore, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

// import { loadUser } from 'redux-oidc'
import reducers from './reducers'
// import userManager from './utils/userManager'
import thunk from 'redux-thunk'


export const history = createBrowserHistory()

// // create the middleware with the userManager
// // const oidcMiddleware = createOidcMiddleware(userManager)

// const saveToLocalStorage = (state) => {
//   try {
//     const serialized_state = JSON.stringify(state)
//     localStorage.setItem('state', serialized_state)
//   } catch (error) {
//     console.log('saveToLocalStorage error: ', error)
//   }
// }

// const loadFromLocalStorage = () => {
//   try {
//     const serialized_state = localStorage.getItem('state')
    
//     if (serialized_state === null)
//       return {} 

//     return JSON.parse(serialized_state)
//   } catch (error) {
//     console.log('loadFromLocalStorage error: ', error)
//     return {}
//   }
// }

// const persistedState = loadFromLocalStorage()

// const loggerMiddleware = store => next => action => {
//   console.log('Action type:', action.type)
//   console.log('Action payload:', action.payload)
//   console.log('State before:', store.getState())
//   next(action)
//   console.log('State after:', store.getState())
// }

// const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// const store = createStore(
//   reducers(history),
//   persistedState,
//   storeEnhancers(applyMiddleware(thunk, routerMiddleware(history)))
// )

// store.subscribe(() => saveToLocalStorage(store.getState()))

// loadUser(store, userManager)

// console.log('store: ', store)

// export default store

// --- New Persist ----------

const client_id = process.env.REACT_APP_OIDC_CLIENT_ID
const uri = process.env.REACT_APP_OIDC_AUTHORITY_URI
const persist_key = `oidc.user:${uri}:${client_id}`

const persistConfig = {
  key: persist_key,
  storage: storageSession
}

const persistedReducer = persistReducer(persistConfig, reducers(history))


const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  persistedReducer,
  storeEnhancers(applyMiddleware(thunk, routerMiddleware(history)))
)

const persistor = persistStore(store)

export { store, persistor }
