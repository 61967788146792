import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch, Redirect } from 'react-router'
import ReactGA from 'react-ga'

import Navbar from './components/Navbar'
import EnrolContainer from './components/enrol/EnrolContainer'
import LoginCallback from './components/LoginCallback'
import LogoutCallback from './components/LogoutCallback'
import AdditionalDetails from './components/AdditionalDetails'
import CourseScheduler from './components/CourseScheduler'
import FlightReport from './components/FlightReport'
import EBooklet from './components/EBooklet'
import ServerError from './components/ServerError'
import Dashboard from './components/Dashboard'
import LecturenoteViewer from './components/LecturenoteViewer'
import CaasViewer from './components/CaasViewer'

import AdminProtectedRoute from './components/admin/AdminProtectedRoute'
import OrderViewer from './components/admin/OrderViewer'
import TopupOrderViewer from './components/admin/TopupOrderViewer'
import CourseScheduleViewer from './components/admin/CourseScheduleViewer'
import ModuleGradingViewer from './components/admin/ModuleGradingViewer'
import PracticalFlightGradingViewer from './components/admin/PracticalFlightGradingViewer'
import CreateFlightGradeViewer from './components/admin/CreateFlightGradeViewer'
import TraineeDetailsViewer from './components/admin/TraineeDetailsViewer'
import EditTraineeDetailViewer from './components/admin/EditTraineeDetailViewer'
import CreditManagerViewer from './components/admin/CreditManagerViewer'
import ScheduleManagerViewer from './components/admin/ScheduleManagerViewer'
import GradeViewer from './components/admin/GradeViewer'

import InstructorDashboard from './components/instructor/Dashboard'

import { removeAuthUser, setAuthUser } from './actions'

import { history } from './store'

import Container from '@material-ui/core/Container'
import { ThemeProvider } from '@material-ui/core/styles'
import './index.css'
import theme from './utils/site-theme'

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID

class App extends Component {

  async componentDidMount() {
    ReactGA.initialize(GA_TRACKING_ID)
    const { oidc, auth } = this.props

    if (!oidc.user && auth && auth._id) {
      this.props.removeAuthUser()
    } else {
      if (oidc.user) {
        this.props.setAuthUser(oidc.user)
      }
    }
  }

  render() {
    
    return (
      <ThemeProvider theme={theme}>
        <Navbar />
        <Container maxWidth='lg'>
          <div style={{ maxWidth: '1110px', margin: '0 auto' }} >
            <ConnectedRouter history={history}>
              <Switch>
                <Redirect exact from='/' to='/enrol' />
                <Route exact path='/login' component={LoginCallback} />
                <Route exact path='/logout' component={LogoutCallback} />
                <Route exact path='/callback' component={LoginCallback} />
                <Route exact path='/enrol' component={EnrolContainer} />
                <Route exact path='/user/details' component={AdditionalDetails} />
                <Route exact path='/schedule' component={CourseScheduler} />
                <Route exact path='/flight-report' component={FlightReport} />
                <Route exact path='/e-booklet' component={EBooklet} />
                <Route exact path='/500' component={ServerError} />
                <Route exact path='/dashboard' component={Dashboard} />
                <Route exact path='/lecture/:pdf_id' component={LecturenoteViewer} />
                <Route exact path='/caas/:pdf_id' component={CaasViewer} />

                {/* <Route exact path='/admin/orders' exact component={OrderViewer} />
                <Route exact path='/admin/course-schedule' exact component={CourseScheduleViewer} />
                <Route exact path='/admin/trainees' exact component={TraineeDetailsViewer} /> */}

                <AdminProtectedRoute exact path='/admin/instructor-dashboard'> <Route component={InstructorDashboard} /> </AdminProtectedRoute>
                <AdminProtectedRoute exact path='/admin/orders'> <Route component={OrderViewer} /> </AdminProtectedRoute>
                <AdminProtectedRoute exact path='/admin/topup'> <Route component={TopupOrderViewer} /> </AdminProtectedRoute>
                <AdminProtectedRoute exact path='/admin/course-schedule'> <Route component={CourseScheduleViewer} /> </AdminProtectedRoute>
                <AdminProtectedRoute exact path='/admin/module-grading'> <Route component={ModuleGradingViewer} /> </AdminProtectedRoute>
                <AdminProtectedRoute exact path='/admin/practical-flight-grading'><Route component={PracticalFlightGradingViewer} /></AdminProtectedRoute>
                <AdminProtectedRoute exact path='/admin/create-flight-grade'><Route component={CreateFlightGradeViewer} /></AdminProtectedRoute>
                <AdminProtectedRoute exact path='/admin/trainees'> <Route component={TraineeDetailsViewer} /> </AdminProtectedRoute>
                <AdminProtectedRoute exact path='/admin/edit-trainee/:trainee_id'><Route component={EditTraineeDetailViewer} /></AdminProtectedRoute>
                <AdminProtectedRoute exact path='/admin/credit-manager'> <Route component={CreditManagerViewer} /> </AdminProtectedRoute>
                <AdminProtectedRoute exact path='/admin/schedule-manager'> <Route component={ScheduleManagerViewer} /> </AdminProtectedRoute>
                <AdminProtectedRoute exact path='/admin/grades'><Route component={GradeViewer} /></AdminProtectedRoute>
                
                <Route render={() => <h1>404 Page Not Found</h1>} />    
              </Switch>
            </ConnectedRouter>
          </div>
        </Container>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = ({ auth, oidc }) => {
  return { auth, oidc }
}

export default connect(mapStateToProps, { removeAuthUser, setAuthUser })(App)
