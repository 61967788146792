import React from 'react'
import { connect } from 'react-redux'
import qs from 'query-string'

import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { withStyles } from '@material-ui/core/styles'

import EnrolSummary from './EnrolSummary'
import StepOneNotice from './StepOneNotice'
import StepTwoChooseCatalog from './StepTwoChooseCatalog'
import StepThreeAddTrainees from './StepThreeAddTrainees'
import StepFourPayment from './StepFourPayment'
import StepFiveConfirmation from './StepFiveConfirmation'
import EnrollmentCompletePage from './EnrollmentCompletePage'

import { goToNextStep, goToStep, setError, changePaymentExpandedPanel, clearEnrolReducer, setMobileDuplicateError, setMobileExistingError } from '../../actions'
import utils from '../../utils/index'
import api_interface from '../../api_interface'

import userManager from '../../utils/userManager'

class EnrolContainer extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      processing_order: false
    }
  }

  componentDidMount = async () => {
    const plex_user_local = localStorage.getItem('plex_user')

    if (this.props.auth && plex_user_local) {
      if (!this.props.oidc.user && !this.props.oidc_state.terminated && this.props.oidc_state.user_found ) {
        await userManager.signinRedirect()
        return
      }
    }

    await this.props.clearEnrolReducer()

    // console.log('this.props is: ', this.props)

    //console.log('useQuery is: ', useQuery)

    const query = qs.parse(this.props.location.search)

    // console.log('query is: ', query)

    if (query.course) {
      // console.log('getting for course: ', query.course)

      this.props.goToStep(1)
    }
  }

  useQuery = () => new URLSearchParams(this.props.location.search)


  processOrder = async () => {
    this.setState({ processing_order: true })
    
    const { billing_contact, card_payer_details, coupon, invoicing_details, quotation_details, payment_method, selected_catalog_item, stripe_payment_method, trainees } = this.props.enrol_details

    // console.log('coupon', coupon)
    

    const payload = {
      billing_contact,
      card_payer_details,
      discount: { coupon },
      invoicing_details,
      quotation_details,
      payment_method,
      selected_catalog_item,
      stripe_payment_method,
      trainees
    }
    // console.log('payload is: ', payload)
    const process_order = await api_interface.processOrder(payload)

    // console.log('process order res: ', process_order)
    
    if (process_order.success) {
      this.props.goToNextStep()
    } else {
      // console.log('process_order', process_order)
      // Go to payment page
      if (process_order.error.includes('STRIPE_ERROR')) {
        this.props.goToStep(3)
        this.props.changePaymentExpandedPanel('panel2')
        
        let message = process_order.error.split('STRIPE_ERROR: ')[1]

        if (message === "Your card's security code is incorrect.") {
          message = "Your card's CVC is incorrect."
        } else if (message.includes('You cannot use a Stripe token more than once')) {
          message = 'Something went wrong with Stripe. Please try again.'
        }

        this.props.setError({ type: 'STRIPE_ERROR', message })
      } else if (process_order.error.includes('duplicate')){

        if (process_order.error.includes('duplicate mobile number')) {
          alert('Please enter unique mobile number')
          this.props.goToStep(2)
          this.props.setMobileDuplicateError(process_order.mobile_error)
        }
        
        if (process_order.error.includes('duplicate email address')) {
          alert('Please enter unique email address')
          this.props.goToStep(2)
        }

        if (process_order.error.includes('duplicate key error' && 'mobile_number')) {
          alert('Mobile number has already been used.\nPlease enter a different mobile number or use an email that associated with ' + process_order.mobile_error)
          this.props.goToStep(2)
          this.props.setMobileExistingError(process_order.mobile_error)
        }
        // const message = 'Failed to process the order. Please try again.'
        // this.props.setError({ type: 'SET_ERROR', message })
      } else {
        alert(`Failed to process the order. Please contact our team to resolve the issue.`)
      }

    }

    this.setState({ processing_order: false })
  }

  renderEnrolStepper() {
    return (
      <>
        <Stepper alternativeLabel style={{ marginTop: '100px', backgroundColor: '#f9fafc' }}>

          <Step completed={this.props.step > 0} active={this.props.step === 0} onClick={() => this.props.goToStep(0)}>
            <StepLabel style={{ cursor: 'pointer' }}>Notice</StepLabel>
          </Step>

          <Step completed={this.props.step > 1} active={this.props.step === 1} onClick={() => this.props.goToStep(1)}>
            <StepLabel style={{ cursor: 'pointer' }}>Course</StepLabel>
          </Step>

          <Step completed={this.props.step > 2} active={this.props.step === 2} onClick={() => this.checkStepperValidTrainee()}>
            <StepLabel style={{ cursor: 'pointer' }}>Trainee</StepLabel>
          </Step>

          <Step completed={this.props.step > 3} active={this.props.step === 3} onClick={() => this.checkStepperValidPayment()}>
            <StepLabel style={{ cursor: 'pointer' }}>Payment</StepLabel>
          </Step>

          <Step completed={this.props.step > 4} active={this.props.step === 4} onClick={() => this.checkStepperValidConfirmation()}>
            <StepLabel style={{ cursor: 'pointer' }}>Confirmation</StepLabel>
          </Step>

        </Stepper>
      </>
    )
  }


  checkStepperValidTrainee() {
    if (this.props.enrol_details.selected_catalog_item.name) {
      this.props.goToStep(2)
    }
  }

  checkStepperValidPayment() {
    let can_continue = true

    for (let i = 0; i < this.props.enrol_details.trainees.length; i++) {

      const { first_name, last_name, email, mobile_number } = this.props.enrol_details.trainees[i]

      if (!first_name || !last_name || !utils.validEmail(email) || !utils.validContact(mobile_number)) {
          can_continue = false
      }
    }

    if (can_continue) {
      this.props.goToStep(3)
    }
  }

  checkStepperValidConfirmation() {
    if (this.props.step > 4) {
      this.props.goToStep(4)
    }
  }

  render() {
    const styles = {
      sticky: {
        // Issue with having multiple definition of position: sticky for browser compatibility
        // Todo: try to see if can avoid sticky and fall back on fixed or something
        // position: '-webkit-sticky',
        position: 'sticky',
        top: 100
      }
    }
    // console.log('container props is: ', this.props)
    if (this.props.step === 0) {
      return (
        <>
          {this.renderEnrolStepper()}
         <StepOneNotice />
        </>
      )
    } else if (this.props.step === 1) {
      return (
        <>
          {this.renderEnrolStepper()}
          <Container style={{ marginTop: '20px'}}>
            <h2>Choose Your UA Course</h2>
            <StepTwoChooseCatalog location={this.props.location}/>
          </Container>
        </>
      )
    } else if (this.props.step === 2) {
      return (
        <>
          {this.renderEnrolStepper()}
          <Grid container style={{ marginTop: '20px'}}>
            <Container><h2>Add UA Trainee For Course</h2></Container>
            <Grid item md={9}><StepThreeAddTrainees/></Grid>
            <Grid item md={3}>
              <div style={styles.sticky}>
                <EnrolSummary />
              </div>
            </Grid>
          </Grid>
        </>
      )
    } else if (this.props.step === 3) {
      return (
        <>
          {this.renderEnrolStepper()}
          <Grid container style={{ marginTop: '20px'}}>
            <Container><h2>Add Payment Information</h2></Container>
            <Grid item md={9}><StepFourPayment /></Grid>
            <Grid item md={3}>
              <div style={styles.sticky}>
                <EnrolSummary />
              </div>
            </Grid>
          </Grid>
        </>
      )
    } else if (this.props.step === 4) {
      return (
        <>
          {this.renderEnrolStepper()}
          <Grid container style={{ marginTop: '20px' }}>
            <Container><h2>Confirm Your Purchase</h2></Container>
            <Grid item md={9}><StepFiveConfirmation /></Grid>
            <Grid item md={3}>
              <div style={styles.sticky}>
                <EnrolSummary />
                <br/>
                {this.renderPlaceOrderButton()}
              </div>
            </Grid>
          </Grid>
        </>
      )
    } else if (this.props.step === 5) {
      return <EnrollmentCompletePage />
    }
  }

  renderPlaceOrderButton() {
    if (this.props.place_order_checkbox_first_confirmation && this.props.place_order_checkbox_second_confirmation && !this.state.processing_order) {
      return <Button style={{ minWidth: '100%', textTransform: 'none' }} onClick={() => this.processOrder()}>Place Order</Button>
    } else if (this.state.processing_order) {
      return <Button style={{ minWidth: '100%', textTransform: 'none' }}>Processing...</Button>
    } else {
      return <Button 
      disabled 
      variant='contained' 
      style={{ 
        minWidth: '100%', 
        textTransform: 'none', 
        cursor: 'not-allowed', 
        pointerEvents: 'all', 
        color: 'rgb(170, 170, 170)', 
        padding: '10px', 
        paddingLeft: '20px', 
        paddingRight: '20px', 
        border: 'none' 
      }} 
      onClick={() => this.processOrder()}>Place Order</Button>
    }  
  }

}

const mapStateToProps = state => { 
  return {
    auth: state.auth,
    oidc: state.oidc,
    oidc_state: state.oidc_state,
    step: state.enrol.step,
    selected_catalog_item: state.enrol.selected_catalog_item,
    enrol_details: state.enrol,
    place_order_checkbox_first_confirmation: state.enrol.place_order_checkbox_first_confirmation,
    place_order_checkbox_second_confirmation: state.enrol.place_order_checkbox_second_confirmation,
    error: state.error,
  }
}

const mapDispatchToProps =  { 
  goToNextStep, 
  goToStep, 
  setError, 
  changePaymentExpandedPanel, 
  clearEnrolReducer,
  setMobileDuplicateError,
  setMobileExistingError
}

export default connect(mapStateToProps, mapDispatchToProps)(EnrolContainer)

const Button = withStyles({
  root: {
    backgroundColor: '#1E90FF ',
    color: 'white',
    borderRadius: '4px !important', 
    '&$disabled': {
      border: '1px solid rgb(170, 170, 170)',
      color: 'rgb(170, 170, 170)',
      backgroundColor: 'white'
    },
    '&:hover': {
      backgroundColor: '#3d94f6',
      color: 'white',
    }
  }
})(MuiButton)
