import React, { useState, useEffect } from 'react'

import api_interface from '../../api_interface'
import utils from '../../utils'

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  DeleteOutline,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

const splitMonthYear = (date) => {
  const my_object = date.split('-')
  return { year: parseInt(my_object[1]), month: parseInt(my_object[0]) }
}

const pseudo_button_style = {
  background: 'transparent',
  border: '2px solid #1E90FF',
  color: '#1E90FF',
  borderRadius: '100px',
  padding: '3px 15px',
  fontWeight: 500,
  cursor: 'pointer',
  display: 'inline-block'
}

const default_start_from = { month: 7, year: 2020 }
const default_end_by = { month: new Date().getMonth()+1, year: new Date().getFullYear()+1 }
const end_date = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

class ScheduleManagerViewer extends React.Component {
  state = {
    schedules: null,
    start_filter: 'default',
    end_filter: 'default',
    next_active: false,
    schedule_dialog_open: false,
    delete_dialog_open: false,
    edited_schedule: null,
  }

  async componentDidMount() {
    const schedule_res_data = await api_interface.getCourseScheduleManager(0, 0, 0)
    const schedules = schedule_res_data.schedules

    const course_res_data = await api_interface.getFullCourses()
    const courses = course_res_data.courses

    // console.log('schedule_res_data', schedule_res_data)
    // console.log('course_res_data', course_res_data)

    this.setState({ schedules, courses })
  }

  handleFilterSchedule = async (e) => {
    e.preventDefault()
    const start_object = splitMonthYear(this.state.start_filter)
    const end_object = splitMonthYear(this.state.end_filter)
    
    const start_filter_schedule = new Date(start_object.year, start_object.month-1, 1, 0, 0).toISOString()
    const end_filter_schedule = new Date(end_object.year, end_object.month-1, end_date[end_object.month-1], 23, 59).toISOString()

    this.setState({ schedules: null })

    const schedule_res_data = await api_interface.getCourseScheduleManager(0, start_filter_schedule, end_filter_schedule)
    const schedules = schedule_res_data.schedules

    // console.log('schedules', schedules)

    this.setState({ schedules })
  }

  handleNextSchedules = async (e) => {
    e.preventDefault()
    this.setState({ next_active: true })

    let start_next = 0
    let end_next = 0
    if (this.state.start_filter !== 'default' && this.state.end_filter !== 'default') {
      
      const start_object = splitMonthYear(this.state.start_filter)
      const end_object = splitMonthYear(this.state.end_filter)
      
      start_next = new Date(start_object.year, start_object.month-1, 1, 0, 0).toISOString()
      end_next = new Date(end_object.year, end_object.month-1, end_date[end_object.month-1], 23, 59).toISOString()
    }

    const schedule_res_data = await api_interface.getCourseScheduleManager(this.state.schedules.length, start_next, end_next)

    const next_schedules = schedule_res_data.schedules

    this.setState({ next_active: false, schedules: [ ...this.state.schedules, ...next_schedules ] })
  }

  openAddDialog = (e) => {
    e.preventDefault()
    this.setState({
      edited_schedule: null,
      schedule_dialog_open: true,
    })
  }

  openEditDialog = (e, schedule) => {
    this.setState({
      edited_schedule: schedule,
      schedule_dialog_open: true,
    })
  }

  openDeleteDialog = (e, schedule) => {
    e.preventDefault()
    this.setState({
      edited_schedule: schedule,
      delete_dialog_open: true
    })
  }

  renderScheduleBody = () => {
    const { schedules } = this.state
    return schedules.map( (s, i) => {
      return (
        <div key={i}>
          <div style={{ display: 'flex', width: 'calc(100% - 40px)', textAlign: 'left', padding: '15px 20px', lineHeight: '1.618' }}>
            <div style={{ flexBasis: '20%' }}>{ utils.formatTime(s.start_time) }<br />{ utils.format24HrTime(s.start_time) }</div>
            <div style={{ flexBasis: '20%' }}>{ s.course.name }</div>
            <div style={{ flexBasis: '20%' }}>{ s.max_attendance } trainees</div>
            <div style={{ flexBasis: '30%' }}>
              <div>Enrolled Trainees</div>
              { s.current_credit_schedule.length > 0 ?
                s.current_credit_schedule.map( (ccs, j) => {
                  return (
                    <div key={j} >
                      <strong>{`${ccs.trainee.first_name} ${ccs.trainee.last_name}`}</strong> ({ ccs.status })
                    </div>
                  )
                } )
                :
                '-'
              }
            </div>
            <div style={{ flexBasis: '10%', display: 'flex', alignItems: 'center' }}>
              <div style={{ ...pseudo_button_style, lineHeight: '25px', height: '25px', margin: '0 5px' }}
                onClick={(e) => this.openEditDialog(e, s)}>
                Edit
              </div>
              <div>
                <IconButton onClick={(e) => this.openDeleteDialog(e, s)} >
                  <DeleteOutline />
                </IconButton>
              </div>
            </div>
          </div>
          <Divider />
        </div>
      )
    } )
  }

  render() {

    const {
      courses,
      schedules,
      next_active,
      edited_schedule,
      schedule_dialog_open,
      delete_dialog_open,
      start_filter,
      end_filter,
    } = this.state

    const filterOptions = renderFilterOptions()
    const filterEnabled = start_filter !== 'default' && end_filter !== 'default'

    return(
      <div style={{ margin: '120px 0 30px', textAlign: 'center' }} >
        <h2>Schedule Manager</h2>
        <div style={{ lineHeight: '1.618', display: 'flex', alignItems: 'center', margin: '40px 0px 20px', justifyContent: 'space-between' }} >
          <div style={{ display: 'flex', alignItems: 'center' }} >
            <div style={{ marginRight: '10px' }}>From</div>
            <div style={{ marginRight: '10px' }}>
              <Select
                value={start_filter}
                onChange={(e) => this.setState({ start_filter: e.target.value })}>
                <MenuItem value='default'></MenuItem>
                { filterOptions.map( (option, i) => {
                  return (
                    <MenuItem key={i} value={option.value} >01 - {option.value}</MenuItem>
                  )
                } ) }
              </Select>
            </div>
            <div style={{ marginRight: '10px' }}>to</div>
            <div style={{ marginRight: '10px' }}>
              <Select
                value={end_filter}
                onChange={(e) => this.setState({ end_filter: e.target.value })}>
                <MenuItem value='default'></MenuItem>
                { filterOptions.map( (option, i) => {
                  return (
                    <MenuItem key={i} value={option.value} >{`${end_date[option.month_index - 1]} - ${option.value}`}</MenuItem>
                  )
                } ) }
              </Select>
            </div>
            <div style={{ marginRight: '10px' }}>
              <Button
                onClick={e => this.handleFilterSchedule(e)}
                disabled={!filterEnabled}
                style={{ color: 'white', fontSize: '12px', padding: '6px 10px', background: filterEnabled ? '#1E90FF' : '#616161' }}>
                  Filter Schedule
              </Button>
            </div>
          </div>
          <div sytle={{ display: 'flex' }} >
            <Button onClick={ (e) => this.openAddDialog(e) } style={ pseudo_button_style } >+Schedule</Button>
          </div>
        </div>
        { schedules === null ?
            <NoSchedule />
          :
          schedules.length > 0 ?
          <div>
            <Paper style={{ margin: '20px 0' }} >
              <div style={{ display: 'flex', width: 'calc(100% - 40px)', textAlign: 'left', alignItems: 'center', justifyContent: 'center', fontWeight: '500', padding: '20px', background: '#f5f5f5' }} >
                <div style={{ flexBasis: '20%' }}>Date</div>
                <div style={{ flexBasis: '20%' }}>Course</div>
                <div style={{ flexBasis: '20%' }}>Capacity</div>
                <div style={{ flexBasis: '30%' }}>Enrolled Trainees</div>
                <div style={{ flexBasis: '10%', display: 'flex' }}></div>
              </div>
              <Divider />
              { this.renderScheduleBody() }
            </Paper>
            { next_active &&
              <div style={{ marginTop: '-150px' }}><NoSchedule /></div>
            }
            { schedules.length > 0 && schedules.length % 50 === 0 && !next_active &&
              <div style={ pseudo_button_style } onClick={ (e) => this.handleNextSchedules(e) }>Load More</div>
            }
            { delete_dialog_open &&
              <DeleteScheduleDialog
                open={delete_dialog_open}
                onClose={() => this.setState({ delete_dialog_open: false })}
                schedule={edited_schedule}
              />
            }
          </div>
          :
          <h1 style={{ fontSize: '2.5em', fontWeight: '300', color: '#9e9e9e', fontStyle: 'italic' }} >No Schedule Available</h1>
        }
        { !edited_schedule &&
          <AddScheduleDialog
            open={schedule_dialog_open}
            onClose={() => this.setState({ schedule_dialog_open: false })}
            courses={courses}
          />
        }
        { edited_schedule &&
          <EditScheduleDialog
            open={schedule_dialog_open}
            onClose={() => this.setState({ schedule_dialog_open: false })}
            schedule={edited_schedule}
            courses={courses}
          />
        }
      </div>
    )
  }
}

const renderFilterOptions = () => {
  
  let filter_schedule_options = []
  for (let i=parseInt(default_end_by.year); i>=parseInt(default_start_from.year); i--) {
    const j_limit = i > parseInt(default_start_from.year) ? 0 : parseInt(default_start_from.month)
    for (let j=12; j>j_limit; j--) {
      const schedule_option = `${parseInt(j) < 10 ? `0${parseInt(j)}` : j} - ${i}`
      filter_schedule_options.push({ value: schedule_option, month_index: j })
    }
  }
  return filter_schedule_options
}

function NoSchedule() {
  return (
    <div style={{ textAlign: 'center', width: '100%', padding: '2em' }} >
      <br/><br/><br/><br/><br />
      <CircularProgress />
    </div>
  )
}

const setTime = (() => {
  const mins  = Array.from({ length: 12 }, (x, i) => i * 5)
  const hours = Array.from({ length: 24 }, (x, i) => i)
  return { hours, mins }
})()

const dialogStyles = theme => ({
  input: {
    width: '50px',
    padding: '5px 12px',
    margin: '0 5px'
  },
  action: {
    borderTop: '1px solid #e0e0e0',
  },
  cancel: {
    backgroundColor: 'white',
    color: '#1E90FF',
    textTransform: 'none',
  },
  valid: {

  },
  invalid: {
    backgroundColor: '#606060'
  },
  cellCenter: {
    width: '100%',
    minHeight: '56px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
})

function AddScheduleDialog({ open, onClose, courses }) {
  const classes = makeStyles(dialogStyles)()
  const now = new Date()
  const nextTwoDays = new Array(8)
    .fill(now.getTime())
    .map((time, i) => new Date(time + i * 1000 * 60 * 60 * 6))

  const [ newCourse, setNewCourse ] = useState('default')
  const [ table, setTable ] = useState({
    dates:  nextTwoDays.map(d => d.getDate().toString()),
    months: nextTwoDays.map(d => (d.getMonth() + 1).toString()),
    years:  nextTwoDays.map(d => d.getFullYear().toString()),
    hours:  nextTwoDays.map(d => 9),
    mins:   nextTwoDays.map(d => 0),
    capacities: nextTwoDays.map(d => '2'),
  })

  function set(e, i, field) {
    const newValue = e.target.value
    setTable(prevTable => {
      const newField = prevTable[field]
      newField[i] = newValue
      return {
        ...prevTable,
        [field]: newField
      }
    })
  }

  function handleSubmit(e) {
    e.preventDefault()
    let allSuccess = true

    nextTwoDays.forEach(async (twodays, i) => {
      if (parseInt(table.capacities[i]) > 0) {
        const start_time = Date.UTC(
          parseInt(table.years[i]),
          parseInt(table.months[i]) - 1,
          parseInt(table.dates[i]),
          parseInt(table.hours[i]),
          parseInt(table.mins[i])
        )
        const newSchedule = {
          schedule_id:    null,
          course:         newCourse._id,
          max_attendance: parseInt(table.capacities[i]),
          start_time:     new Date(start_time).toISOString()
        }
        console.log('adding schedule', newSchedule)

        const result = await api_interface.addSchedule(newSchedule)
        console.log('new schedule', i, result)
        if (!result.success)
          allSuccess = false
      }
      if (i === nextTwoDays.length - 1 && allSuccess) {
        window.location.reload()
      } else {
        console.log('At least one error')
      }
    })
  }

  function dayOfWeek(y, m, d) {
    const obj = new Date(parseInt(y), parseInt(m) - 1, parseInt(d))
    const day = obj.getDay()
    if (day >= 0 && day <= 6)
      return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][day]
    else
      return ''
  }

  return (
    <Dialog open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'>
      <DialogTitle>Add Course Schedule(s)</DialogTitle>
      <DialogContent>
        <div style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }}>
          <strong>Class</strong>
          <Select value={newCourse} onChange={(e) => setNewCourse(e.target.value)}
            style={{ display: 'inline-block', minWidth: '300px', margin: '0 10px' }}>
            <MenuItem value='default'></MenuItem>
            { courses?.map((course, k) => (
                <MenuItem value={course} key={k}>{course.name}</MenuItem>
            )) }
          </Select>
        </div>
        <strong>Slots</strong><br />
        <Typography variant='caption'>
          To create less than 8 slots, remove Capacity (or set it to 0)
        </Typography>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>Year</Grid>
          <Grid item xs={1}>Month</Grid>
          <Grid item xs={1}>Date</Grid>
          <Grid item xs={1}>Day</Grid>
          <Grid item xs={2}>Hour</Grid>
          <Grid item xs={2}>Minute</Grid>
          <Grid item xs={2}>Capacity</Grid>
          {
            nextTwoDays.map((twodays, i) => (<React.Fragment key={i}>
              <Grid item xs={1} className={classes.cellCenter}>{i + 1}.</Grid>
              <Grid item xs={2}>
                <TextField variant='outlined' value={table.years[i]} onChange={(e) => set(e, i, 'years')}  />
              </Grid>
              <Grid item xs={1}>
                <TextField variant='outlined' value={table.months[i]} onChange={(e) => set(e, i, 'months')} />
              </Grid>
              <Grid item xs={1}>
                <TextField variant='outlined' value={table.dates[i]} onChange={(e) => set(e, i, 'dates')}  />
              </Grid>
              <Grid item xs={1}>
                <div className={classes.cellCenter}>
                  { dayOfWeek(table.years[i], table.months[i], table.dates[i]) }
                </div>
              </Grid>
              <Grid item xs={2}>
                <TextField variant='outlined' fullWidth value={table.hours[i]} select onChange={(e) => set(e, i, 'hours')} >
                  {setTime.hours.map((st, ih) => <MenuItem value={st} key={ih} >{st < 10 ? `0${st}` : st}</MenuItem>)}
                </TextField>
              </Grid>
              <Grid item xs={2}>
                <TextField variant='outlined' fullWidth value={table.mins[i]} select onChange={(e) => set(e, i, 'mins')}>
                  {setTime.mins.map((st, im) => <MenuItem value={st} key={im}>{st < 10 ? `0${st}` : st}</MenuItem>)}
                </TextField>
              </Grid>
              <Grid item xs={2}>
                <TextField variant='outlined' value={table.capacities[i]}
                  className={parseInt(table.capacities[i]) > 0 ? classes.valid : classes.invalid}
                  onChange={(e) => set(e, i, 'capacities')} />
              </Grid>
            </React.Fragment>))
          }
        </Grid>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button onClick={onClose} className={classes.cancel}>
          <strong>Cancel</strong>
        </Button>
        <Button onClick={handleSubmit}>
          <strong>Submit</strong>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function EditScheduleDialog({ open, onClose, schedule, courses }) {
  const classes = makeStyles(dialogStyles)()
  const now = new Date()
  const [ date,  setDate  ] = useState(now.getDate())
  const [ month, setMonth ] = useState(now.getMonth() + 1)
  const [ year,  setYear  ] = useState(now.getFullYear())
  const [ hour,  setHour  ] = useState(9)
  const [ min,   setMin   ] = useState(0)
  const [ capacity, setCapacity ] = useState(2)

  useEffect(() => {
    if (schedule) {
      const st = new Date(schedule.start_time)
      setDate(st.getDate())
      setMonth(st.getMonth() + 1)
      setYear(st.getFullYear())
      setHour(st.getUTCHours())
      setMin(st.getUTCMinutes())
      setCapacity(parseInt(schedule.max_attendance))
    }
  }, [schedule])

  async function handleSubmit(e) {
    e.preventDefault()
    const start_time = Date.UTC(parseInt(year), parseInt(month) - 1,
      parseInt(date), parseInt(hour), parseInt(min))

    const newSchedule = {
      schedule_id:    schedule._id,
      course:         schedule.course._id,
      max_attendance: capacity,
      start_time:     new Date(start_time).toISOString()
    }

    const result = await api_interface.updateSchedule(newSchedule)
    console.log('updated schedule', result)

    if (result.success)
      window.location.reload()
  }

  return (
    <Dialog open={open}
      onClose={onClose}
      style={{ width: '100%', margin: '0 auto' }}>
      <DialogTitle>Change Course Schedule</DialogTitle>
      <DialogContent>
        <div style={{ color: '#212121' }}>
          <h3 style={{ margin: '10px 0', minWidth: '500px' }}>Current Schedule</h3>
          <div style={{ marginBottom: '20px' }} >
            <div>Date: { utils.formatTime(schedule.start_time) }</div>
            <div>Name: { schedule.course.name }</div>
            <div>Capacity: { schedule.max_attendance } trainee(s)</div>
          </div>
          <Divider />
        </div>
        <div style={{ color: '#212121' }} >
          <h3 style={{ margin: '10px 0', minWidth: '500px' }}>New Schedule</h3>
          <div style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }}>
            <strong>Class:</strong> { schedule.course.name }
          </div>
          <div style={{ margin: '10px 0' }}>
            <strong>Date:</strong>
            <input type='text' label='date'  value={date}  className={classes.input} onChange={(e) => setDate(e.target.value)}  />
            <input type='text' label='month' value={month} className={classes.input} onChange={(e) => setMonth(e.target.value)} />
            <input type='text' label='year'  value={year}  className={classes.input} onChange={(e) => setYear(e.target.value)}  />
          </div>
          <div style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }} >
            <strong>Time:</strong>
            <Select value={hour} style={{ display: 'inline-block', minWidth: '0', margin: '0 10px' }} onChange={(e) => setHour(e.target.value)} >
              {setTime.hours.map((st, ih) => <MenuItem value={st} key={ih} >{st < 10 ? `0${st}` : st}</MenuItem>)}
            </Select>
            <Select value={min} style={{ display: 'inline-block', minWidth: '0', margin: '0 10px' }} onChange={(e) => setMin(e.target.value)}>
              {setTime.mins.map((st, im) => <MenuItem value={st} key={im}>{st < 10 ? `0${st}` : st}</MenuItem>)}
            </Select>
          </div> 
          <div style={{ margin: '10px 0' }}>
            <strong>Capacity:</strong>
            <input type='text' label='capacity' value={capacity} className={classes.input} onChange={(e) => setCapacity(e.target.value)} />
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button onClick={onClose} className={classes.cancel}>
          <strong>Cancel</strong>
        </Button>
        <Button onClick={handleSubmit} style={{ textTransform: 'none' }}>
          <strong>Submit</strong>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function DeleteScheduleDialog({ open, onClose, schedule }) {
  const classes = makeStyles(dialogStyles)()

  async function handleSubmit(e) {
    e.preventDefault()
    const now = new Date()
    const deleted_time = Date.UTC(now.getFullYear(), now.getMonth() - 1,
      now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds())

    // Do delete request API here, use put endpoint
    const payload = {
      ...schedule,
      schedule_id: schedule._id,
      deleted_on: new Date(deleted_time).toISOString()
    }
    console.log('deleting schedule', payload)
    const deleted_schedule = await api_interface.updateSchedule(payload)

    console.log('deleted schedule', deleted_schedule)
    if (deleted_schedule.success)
      window.location.reload()
  }

  return (
    <Dialog open={open}
      onClose={onClose}
      style={{ width: '100%', margin: '0 auto' }}>
      <DialogTitle>Delete Course Schedule</DialogTitle>
      <DialogContent>
        <div>
          <p>Are you sure to delete this schedule?</p>
          <div className={classes.field}>Name: {schedule.course.name}</div>
          <div className={classes.field}>Date: {utils.formatTime(schedule.start_time)}, {utils.format24HrTime(schedule.start_time)}</div>
          <div className={classes.field}>Current Attendance:
            <div>{ schedule.current_credit_schedule.length > 0 ?
              schedule.current_credit_schedule.map((ccs, j) => (
                <div key={j}>
                  <strong>{`${ccs.trainee.first_name} ${ccs.trainee.last_name}`}</strong> ({ ccs.status })
                </div>
              ))
            : '-'
            }</div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button onClick={onClose} className={classes.cancel}>
          <strong>Cancel</strong>
        </Button>
        <Button onClick={handleSubmit} style={{ textTransform: 'none', background: 'red', color: 'white' }}>
          <strong>Yes, Delete</strong>
        </Button>
      </DialogActions>
    </Dialog>
  )

}

export default ScheduleManagerViewer
