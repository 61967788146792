import api_interface from '../api_interface'
import utils from '../utils'

export const setAuthUser = (plex_user) => async (dispatch) => {
  localStorage.setItem('plex_user', JSON.stringify(plex_user))

  const client_id = process.env.REACT_APP_OIDC_CLIENT_ID
  const uri = process.env.REACT_APP_OIDC_AUTHORITY_URI
  const session_key = `oidc.user:${uri}:${client_id}`

  const persist_session = localStorage.getItem(`persist:oidc`)
  const oidc_session = sessionStorage.getItem(session_key)

  if (!oidc_session && utils.checkStringifiedObject(persist_session)) {
    const user_session = JSON.parse(persist_session)

    sessionStorage.setItem(session_key, JSON.stringify(JSON.parse(user_session.user)))
  }

  try {
    // console.log('in setAuthUser')
    // const oidc_access_token = state.oidc.user.access_token
    // console.log('oidc_access_token: ', oidc_access_token)
    let { success, auth_user } = await api_interface.getUATOAuthUser()

    if (!success)
      return { success: false }
    
    if (auth_user.uato_personnel) {
      // console.log('is personnel')
      await dispatch({ 
        type: 'SET_AUTH_USER',
        payload: auth_user
      })

      return { success: true, uato_personnel: true }
    } else if (!auth_user.uato_personnel) {
      // console.log('is trainee')
      let return_obj = {}

      if (auth_user.plex.user_id && auth_user.plex.company_id) {
        // console.log('go to schedule')
        return_obj = { success: true, uato_personnel: false, update_trainee_details: false }
      } else {
        // console.log('update trainee plex details')
        const update_trainee_plex_details = await api_interface.updateTraineePlexDetails(plex_user)
        // console.log('update_trainee_plex_details: ', update_trainee_plex_details)

        if (update_trainee_plex_details.success) {
          auth_user = update_trainee_plex_details.trainee
          
          return_obj = { success: true, uato_personnel: false, update_trainee_details: true }
        }     
      }   

      await dispatch({ 
        type: 'SET_AUTH_USER',
        payload: auth_user
      })
      
      return return_obj
    }
  } catch (error) {
    return { success: true, error: `Couldn't get ${plex_user.profile.email} data` }
  }
}

export const removeAuthUser = () => async dispatch => {
  await dispatch({ type: 'REMOVE_AUTH_USER' })
}

export const getAuthUserCredits = () => async (dispatch, getState) => {
  const state = getState()
  const trainee_id = state.auth._id
  const auth_user_credits_res = await api_interface.getTraineeCredits(trainee_id) 

  // console.log('auth_user_credits_res: ', auth_user_credits_res)

  if (auth_user_credits_res.success) {
    dispatch({
      type: 'GET_AUTH_USER_CREDITS',
      payload: auth_user_credits_res.credits
    })
  }
}

export const updateAuthUserDetails = (email, payload) => async dispatch => {
  const updated = await api_interface.updateTraineeAdditionalDetails(email, payload)

  // console.log('update trainee action: ', updated)

  if (updated.success) {
    dispatch({
      type: 'UPDATE_AUTH_USER_DETAILS_SUCCESS',
      payload: updated.trainee
    })
  }

  return updated.success
}
