 const CourseScheduleReducer = (state = [], action) => {
  switch (action.type) {
    case 'GET_ENTIRE_COURSE_SCHEDULE':
      return [ ...action.payload ]
    
    case 'GET_COURSE_SCHEDULE_FOR_TRAINEE':
      return [ ...action.payload ]
    
    default:
      return state
   }
}

export default CourseScheduleReducer
