const initState = {
  theory_result: ''
}

export const theoryResult = (state = initState.theory_result, action) => {
  switch (action.type) {
    case 'ADD_THEORY_RESULT':
      return action.payload
    default:
      return state
  }
}

