import axios from 'axios'

import utils from '../utils'

const API_URL = process.env.REACT_APP_UATO_API_URL

export const getProducts = async () => {

  const url = `${API_URL}/products`
  const options = utils.getJWTHeaders()

  const res = await axios.get(url, options)

  console.log('getProducts: ', res.data)

  return res.data
}
