import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FilePicker } from 'react-file-picker'

import api_interface from '../api_interface'
import { addTheoryResult } from '../actions'

import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  SvgIcon,
} from '@material-ui/core'
import {
  Close,
  OpenInNew,
} from '@material-ui/icons'

const MAX_PDF_SIZE_MB = process.env.REACT_APP_MAX_PDF_SIZE_MB

const sub_header_style = {
  fontWeight: '500',
  margin: '0 0 10px'
}

const sidebar_row = {
  margin: '10px 0'
}

class Dashboard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      new_item: '', //from file picker (as string name )
      new_item_file: null, //from file picker (as file object)
      new_item_url: '', //from file picker (converted to blob url)
      list: [], //list of added theory_result pdf
      // newList: [],
      buffer_array: [],//lecture pdf fetch from backend (converted to buffer_array)

      errorDialogOpen: false,
      deleteDialogOpen: false,

      url: '', //lecture pdf fetch from backend (converted to blob url)
      lecture_note_list: [],
      caas_theory: [],
    }

  }

  async componentDidMount() {
    const res_auth = await api_interface.getUATOAuthUser()
    this.setState({
      first_name: res_auth.auth_user.first_name,
      trainee_id: res_auth.auth_user._id,
      uato_personnel: res_auth.auth_user.uato_personnel,
      caas_theory: res_auth.auth_user.caas_theory
    })

    const res_lecture = await api_interface.getLectureFiles(this.props.auth._id)
    this.setState({ lecture_note_list: res_lecture.lectures })
  }

  handleCaasUpdate = async () => {
    const res_auth = await api_interface.getUATOAuthUser()
    this.setState({
      first_name: res_auth.auth_user.first_name,
      trainee_id: res_auth.auth_user._id,
      uato_personnel: res_auth.auth_user.uato_personnel,
      caas_theory: res_auth.auth_user.caas_theory
    })
  }

  handleLectureUpdate = async () => {
    const res_lecture = await api_interface.getLectureFiles(this.props.auth._id)
    this.setState({ lecture_note_list: res_lecture.lectures })
  }

  updateInput = async (e) => {
    await this.setState({
      new_item_file: e,
      new_item: e.name,
      new_item_url: URL.createObjectURL(e)
    })
    this.addItem()
  }

  addItem = async () => {
    const new_item = {
      id: this.state.trainee_id,
      value: this.state.new_item.slice(),
      url: this.state.new_item_url.slice()
    }

    //convert from file object to form data
    const form_data = new FormData()
    form_data.append('trainee_id', this.state.trainee_id)
    form_data.append('file', this.state.new_item_file)

    // for (var fd of form_data) {
    //   console.log(fd);
    // }

    await this.props.addTheoryResult(form_data, this.props.auth._id)
    this.handleCaasUpdate()

    const list = [...this.state.list]
    list.push(new_item)
    this.setState({
      list,
      new_item: '',
    })
  }

  deleteItem = () => {
    const { id } = this.state
    const list = [...this.state.list]
    const updated_list = list.filter(item => item.id !== id)
    this.setState({
      list: updated_list
    })
  }

  handleDialogOpen = (dialog_name, id) => {
    this.setState({
      [dialog_name]: true,
      id: id
    })
  }

  renderErrorDialog = () => {
    return (
      <Dialog
        open={this.state.errorDialogOpen}
        onClose={() => this.setState({ errorDialogOpen: false })}
        style={{ minWidth: '600px' }}
      >
        <DialogTitle style={{ minWidth: '552px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
            <div>Upload Error</div>
            <div>
              <IconButton
                style={{ backgroundColor: 'transparent', color: '#616161', textTransform: 'none', fontWeight: 'bold', padding: '0' }}
                onClick={() => this.setState({ errorDialogOpen: false })}>
                <Close />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          Upload failed. Please try again later.
      </DialogContent>
      </Dialog>
    )
  }

  renderDeleteDialog = () => {
    return (
      <Dialog
        open={this.state.deleteDialogOpen}
        onClose={() => this.setState({ deleteDialogOpen: false })}
        style={{ minWidth: '600px' }}
      >
        <DialogTitle style={{ minWidth: '552px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
            <div>Delete file</div>
            <div>
              <IconButton
                style={{ backgroundColor: 'transparent', color: '#616161', textTransform: 'none', fontWeight: 'bold', padding: '0' }}
                onClick={() => this.setState({ deleteDialogOpen: false })}>
                <Close />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          Are you sure you want to delete this file?
      </DialogContent>
        <DialogActions>
          <Button
            onClick={() => { this.setState({ deleteDialogOpen: false }) }}
            style={{ backgroundColor: 'white', color: '#1E90FF', textTransform: 'none' }}>
            <strong>Cancel</strong>
          </Button>
          <Button
            onClick={() => { this.setState({ deleteDialogOpen: false }); this.deleteItem() }}
            style={{ backgroundColor: 'white', color: '#1E90FF', textTransform: 'none' }}>
            <strong>Confirm</strong>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderAttachment = (caas_theory) => {
    return (
      <Card style={{ width: '44vh', height: '45vh', padding: '3vh', marginLeft: '2vh', overflowY: 'auto' }}>
        <div style={{ position: 'relative' }} >
          <h3 style={sub_header_style} >UAPL Theory Test Result</h3>
          {
            <div style={{ display: 'flex', position: 'absolute', right: 0, top: 0 }} >
              <FilePicker
                extensions={['pdf']}
                onChange={this.updateInput}
                onError={() => this.handleDialogOpen('errorDialogOpen', null)}
                accept='application/pdf'
                maxSize={MAX_PDF_SIZE_MB}
                sizeUnit='MB'>
                {
                  (caas_theory.length <= 4)
                    ? <div style={{ position: 'absolute', zIndex: '10', right: 0, top: 0, color: 'rgb(30, 144, 255)', cursor: 'pointer' }}>Add</div>
                    : <div></div>
                }
              </FilePicker>
            </div>
          }
        </div>
        <div style={{ margin: '3vh 0' }}>
        </div>
        <div style={sidebar_row}>
          {caas_theory.map((item) => {
            return <div key={item.file_id} style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }} >
              <Link style={{ textDecoration: 'none', color: 'rgb(30, 144, 255)' }} to={{ pathname: `/caas/${item.filename}`}}>{item.caas_id}</Link>
              {/* <a style={{ textDecoration: 'none', color: 'rgb(30, 144, 255)' }} onClick={this.convertToBlob(item.filename)}  target='_blank'>{item.caas_id}</a> */}
              {/* <IconButton style={{ backgroundColor: 'transparent', color: '#616161', textTransform: 'none', fontWeight: 'bold', padding: '0', margin: '5' }} onClick={() => this.handleDialogOpen('deleteDialogOpen', item.id)} >
                <DeleteIcon />
              </IconButton> */}
            </div>
          })}
        </div>
      </Card>
    )
  }

  updateInputUpload = async (e) => {
    await this.setState({
      new_item_file_lecture: e,
      new_item_lecture: e.name,
      new_item_url_lecture: URL.createObjectURL(e)
    })
    this.handleUploadLecture()
  }

  handleUploadLecture = async () => {

    const form_data_lecture = new FormData()
    form_data_lecture.append('file', this.state.new_item_file_lecture)

    const res = await api_interface.uploadLectureFile(form_data_lecture)
    if (res.success)
      this.handleLectureUpdate()
    else
      this.setState({ errorDialogOpen: true })
  }

  //Training Course/View Cert to be done on next sprint

  // renderTrainingCourse = () => {
  //   return (
  //     <Card style={{ display: 'flex', height: '20vh', margin: '3vh 0', }}>
  //       <CardContent >
  //         <Typography color='textSecondary' gutterBottom>Training course</Typography>
  //       </CardContent>
  //     </Card>
  //   )
  // }

  render() {
    const { first_name, uato_personnel, caas_theory, lecture_note_list } = this.state
    return (
      <div>
        <br />
        <br />
        <br />

        <h1 style={{ textTransform: 'capitalize' }}>{(first_name) ? `Welcome, ${first_name}` : ''}</h1>

        <div>
          <div>
            {/* {this.renderTrainingCourse()} */}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <LectureNoteList
              lecture_note_list={lecture_note_list}
              uato_personnel={this.state.uato_personnel}
            />
            {/*this.renderLectureNote(lecture_note_list)*/}
            {(!uato_personnel) ? this.renderAttachment(caas_theory) : <div></div>}
            {/* {this.renderAttachment(caas_theory)} */}
          </div>
          {this.renderDeleteDialog()}
          {this.renderErrorDialog()}
        </div>
      </div>
    )
  }

}

function LectureNoteList({ lecture_note_list, uato_personnel }) {

  return (
    <Card style={{ width: '80vh', height: '45vh', padding: '3vh', overflowY: 'auto' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <h3 style={sub_header_style}>Lecture Notes & Training Manual</h3>
        </div>
        <div>
          { uato_personnel ?
            <FilePicker
              extensions={['pdf']}
              onChange={this.updateInputUpload}
              onError={() => this.handleDialogOpen('errorDialogOpen', null)}
              accept='application/pdf'
              maxSize={MAX_PDF_SIZE_MB}
              sizeUnit='MB'>
              <div style={{ color: 'rgb(30, 144, 255)', cursor: 'pointer' }}>Add</div>
            </FilePicker>
            :
            <div></div>
          }
        </div>
      </div>
      <div style={{ margin: '3vh 0' }}>
      </div>
      <div style={sidebar_row}>
        {lecture_note_list.map(item => {
          return (
            <div key={item.fileid}>
              <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', paddingLeft: '15px' }}>
                <div style={{ flexBasis: '75%', display: 'inline-flex' }}>
                  <SvgIcon>
                    <svg x='0px' y='0px' viewBox='0 0 309.267 309.267' style={{ enableBackground: 'new 0 0 309.267 309.267' }}>
                      <g>
                       <path style={{ fill: '#E2574C' }} d='M38.658,0h164.23l87.049,86.711v203.227c0,10.679-8.659,19.329-19.329,19.329H38.658 c-10.67,0-19.329-8.65-19.329-19.329V19.329C19.329,8.65,27.989,0,38.658,0z' />
                       <path style={{ fill: '#B53629' }} d='M289.658,86.981h-67.372c-10.67,0-19.329-8.659-19.329-19.329V0.193L289.658,86.981z' />
                       <path style={{ fill: '#FFFFFF' }} d='M217.434,146.544c3.238,0,4.823-2.822,4.823-5.557c0-2.832-1.653-5.567-4.823-5.567h-18.44 c-3.605,0-5.615,2.986-5.615,6.282v45.317c0,4.04,2.3,6.282,5.412,6.282c3.093,0,5.403-2.242,5.403-6.282v-12.438h11.153 c3.46,0,5.19-2.832,5.19-5.644c0-2.754-1.73-5.49-5.19-5.49h-11.153v-16.903C204.194,146.544,217.434,146.544,217.434,146.544z M155.107,135.42h-13.492c-3.663,0-6.263,2.513-6.263,6.243v45.395c0,4.629,3.74,6.079,6.417,6.079h14.159 c16.758,0,27.824-11.027,27.824-28.047C183.743,147.095,173.325,135.42,155.107,135.42z M155.755,181.946h-8.225v-35.334h7.413 c11.221,0,16.101,7.529,16.101,17.918C171.044,174.253,166.25,181.946,155.755,181.946z M106.33,135.42H92.964 c-3.779,0-5.886,2.493-5.886,6.282v45.317c0,4.04,2.416,6.282,5.663,6.282s5.663-2.242,5.663-6.282v-13.231h8.379 c10.341,0,18.875-7.326,18.875-19.107C125.659,143.152,117.425,135.42,106.33,135.42z M106.108,163.158h-7.703v-17.097h7.703 c4.755,0,7.78,3.711,7.78,8.553C113.878,159.447,110.863,163.158,106.108,163.158z' />
                      </g>
                    </svg>
                  </SvgIcon>
                  <div>{item.originalname}</div>
                </div>
                <div>
                  {/* <IconButton style={{ padding: '0', margin: '5' }} onClick={() => window.open(item.url)}>
                    <OpenInNew />
                  </IconButton> */}
                  <IconButton style={{ padding: '0', margin: '5' }} >
                    <Link style={{ textDecoration: 'none', color: 'rgb(30, 144, 255)' }} to={{ pathname: `/lecture/${item.filename}`}}><OpenInNew /></Link>
                  </IconButton>
                </div>
              </div>
              <Divider />
            </div>
          )
        })}
      </div>
    </Card >
  )
}


const mapStateToProps = (state) => state

export default connect(mapStateToProps, { addTheoryResult })(Dashboard)