import React from 'react'
import { connect } from 'react-redux'

import MuiButton from '@material-ui/core/Button'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'

import { goToNextStep, goToPreviousStep, handleAllTraineesInfo, setBillingContact, addOneTrainee, removeOneTrainee, clearError, setBillingContactIndexCheckbox } from '../../actions'
import utils from '../../utils/index'


class StepThreeAddTrainees extends React.Component {

  state = {
    trainees: [
      {
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
      }
    ],

    billing_info_index: -1,

    email_is_valid: [
      {
        valid: true
      }
    ],

    mobile_is_valid: [
      {
        valid: true
      }
    ],
  }

  componentDidMount = async () => {
    // console.log('props 3 is: ', this.props)
    if (this.props.enrol_details.trainees.length > 0 ) {

      let email_is_valid_array = new Array(this.props.enrol_details.trainees.length).fill({ valid: true })

      let mobile_is_valid_array = new Array(this.props.enrol_details.trainees.length).fill({ valid: true })

      // console.log('mobile_is_valid_array', mobile_is_valid_array)

      await this.setState({ 
        trainees: this.props.enrol_details.trainees, 
        email_is_valid: email_is_valid_array, 
        mobile_is_valid: mobile_is_valid_array 
      })

    }

    // console.log('props dupe mobile num ', this.props.error.mobile_duplicate_error)

    if (this.props.error.type === 'EXISTING_KEY_MOBILE_NUMBER') {
      const valid_number_array = this.props.enrol_details.trainees.map(trainee => ({ valid: trainee.mobile_number !== this.props.error.mobile_duplicate_error}))

      // console.log('valid_number_array', valid_number_array)

      this.setState({ mobile_is_valid: valid_number_array })

    }

  }

  onNextClick() {
    this.props.goToNextStep()
    this.props.handleAllTraineesInfo(this.state.trainees)

    if (this.props.enrol_details.set_billing_contact_index_checkbox > -1) {

      if (
        this.state.trainees[this.props.enrol_details.set_billing_contact_index_checkbox].email !== this.props.enrol_details.billing_contact.email ||
        this.state.trainees[this.props.enrol_details.set_billing_contact_index_checkbox].mobile_number !== this.props.enrol_details.billing_contact.mobile_number ||
        this.state.trainees[this.props.enrol_details.set_billing_contact_index_checkbox].first_name !== this.props.enrol_details.billing_contact.first_name ||
        this.state.trainees[this.props.enrol_details.set_billing_contact_index_checkbox].last_name !== this.props.enrol_details.billing_contact.last
        ) {
  
        let new_billing_contact = {...this.state.trainees[this.props.enrol_details.set_billing_contact_index_checkbox]}
        new_billing_contact.company_name = this.props.enrol_details.billing_contact.company_name
        // console.log('new_billing_contact', new_billing_contact)
  
        this.props.setBillingContact(new_billing_contact)
      }
    }
  }

  renderRemoveButton(index) {
    return (
      this.state.trainees.length > 1 ? 
        <span>
          <br/> 
          <HoverButton variant='outlined' style={{ marginTop: 30, marginBottom: 20, color: '#1E90FF', backgroundColor: 'white', width: '100%' }} onClick={() => this.handleRemoveTrainee(index)}>Remove</HoverButton> 
        </span> : ''
    )
  }

  renderSingleTrainee() {
    return this.state.trainees.map((t, index) => {
      return (
        <Paper key={'trainee' + index} style={{ marginBottom: '24px' }} >
          <Container fixed spacing={0}>
            <TextField
              label="First Name"
              placeholder=""
              style={{ minWidth: '45%' , marginTop: 30 }}
              InputLabelProps={{
                shrink: true,
              }}
              name='first_name'
              value={this.state.trainees[index].first_name}
              onChange={(event) => this.handleTextInput(event, index)}
            />
            <TextField
              id="date"
              label="Last Name"
              placeholder=""
              style={{ minWidth: '50%' , marginLeft: '5%' , marginTop: 30 }}
              InputLabelProps={{
                shrink: true,
              }}
              name='last_name'
              value={this.state.trainees[index].last_name}
              onChange={(event) => this.handleTextInput(event, index)}
            />
            <br/>
            <TextField
              label="Email"
              style={{ minWidth: '45%' , marginTop: 30, color: 'blue !important' }}
              placeholder=""
              margin="normal" 
              InputLabelProps={{
                shrink: true,
              }}
              error={!this.state.email_is_valid[index].valid}
              helperText={this.state.email_is_valid[index].valid ? '' : 'Invalid email or unsupported Hotmail address'}
              name='email'
              value={this.state.trainees[index].email}
              onChange={(event) => this.handleTextInput(event, index)}
              onBlur={() => this.validateEmail(index)}
              onFocus={() => this.focusEmail(index)}
            />
            <TextField
              label="Mobile Number"
              style={{ minWidth: '50%' , marginLeft: '5%' , marginTop: 30 }}
              placeholder=""
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              error={!this.state.mobile_is_valid[index].valid}
              helperText={this.checkDisplayMobileHelperText(index)}
              name='mobile_number'
              value={this.state.trainees[index].mobile_number}
              onChange={(event) => this.handleTextInput(event, index)}
              onBlur={() => this.validateMobile(index)}
              onFocus={() => this.focusMobile(index)}
            />
            <br/>
            <br/>

            <FormControlLabel
                control={
                  <Checkbox checked={this.props.enrol_details.set_billing_contact_index_checkbox === index} onChange={() => this.handleSetBillingInfo(index)}/>
                }
                label={
                  <span style={{ fontSize: 14 }}>This is my billing contact information.</span>
                }
              />
            
            {this.renderRemoveButton(index)}

          </Container>  
        </Paper>
      )
    })
  }


  validateEmail(index) {
    const { email } = this.state.trainees[index]

    if (email.length === 0) {
      let email_is_valid_copy = [ ...this.state.email_is_valid]
      email_is_valid_copy[index].valid = true
      this.setState({ email_is_valid: email_is_valid_copy })
    } else if (email.toLowerCase().includes("hotmail")){
      let email_is_valid_copy = [ ...this.state.email_is_valid]
      email_is_valid_copy[index].valid = false 
      this.setState({ email_is_valid: email_is_valid_copy })
      
      //this.setState({ email_is_valid: true })
    } else if (email.length > 0 && !utils.validEmail(email)) {  
      
      let email_is_valid_copy = [ ...this.state.email_is_valid]
      email_is_valid_copy[index].valid = false
      this.setState({ email_is_valid: email_is_valid_copy })
      //this.setState({ email_is_valid: false })
    } else {

      let email_is_valid_copy = [ ...this.state.email_is_valid]
      email_is_valid_copy[index].valid = true
      this.setState({ email_is_valid: email_is_valid_copy })
      //this.setState({ email_is_valid: true })
    }
  }

  focusEmail(index) {

    let email_is_valid_copy = [ ...this.state.email_is_valid]
    email_is_valid_copy[index].valid = true
    this.setState({ email_is_valid: email_is_valid_copy })
    //this.setState({ email_is_valid: true })
  }

  validateMobile(index) {
    const { mobile_number } = this.state.trainees[index]

    // console.log('mobile number', mobile_number)
    // console.log('index is', index)

    if (mobile_number.length === 0) {

      let mobile_is_valid_copy = [ ...this.state.mobile_is_valid]
      mobile_is_valid_copy[index].valid = true
      this.setState({ mobile_is_valid: mobile_is_valid_copy })
      //this.setState({ mobile_is_valid: true })
    } else if (this.props.error.type === 'EXISTING_KEY_MOBILE_NUMBER') {
      let mobile_is_valid_copy = [ ...this.state.mobile_is_valid]
      mobile_is_valid_copy[index].valid = false
      this.setState({ mobile_is_valid: mobile_is_valid_copy })
      //this.setState({ mobile_is_valid: false })
    } else if (mobile_number.length > 0 && utils.validContact(mobile_number)) {
      let mobile_is_valid_copy = [ ...this.state.mobile_is_valid]
      mobile_is_valid_copy[index].valid = true
      this.setState({ mobile_is_valid: mobile_is_valid_copy })
      //this.setState({ mobile_is_valid: true })
    }  else {
      let mobile_is_valid_copy = [ ...this.state.mobile_is_valid]
      mobile_is_valid_copy[index].valid = false
      this.setState({ mobile_is_valid: mobile_is_valid_copy })
      //this.setState({ mobile_is_valid: false })
    }
  }

  focusMobile(index) {

    let mobile_is_valid_copy = [ ...this.state.mobile_is_valid]
    mobile_is_valid_copy[index].valid = true
    this.setState({ mobile_is_valid: mobile_is_valid_copy })
    //this.setState({ mobile_is_valid: true })
  }

  checkDisplayMobileHelperText(index) {
    const { mobile_number } = this.state.trainees[index]

    if (this.state.mobile_is_valid[index].valid) {
      return ''
    } else if (mobile_number.length && !utils.validContact(mobile_number)) {
      return 'Invalid mobile number'
    } else if (this.props.error.type === 'DUPLICATE_KEY_MOBILE_NUMBER' || this.props.error.type === 'EXISTING_KEY_MOBILE_NUMBER') {
      return this.props.error.message
    }
  }

  handleTextInput(event, index) {
    const trainees_copy = [ ...this.state.trainees ]
    trainees_copy[index][event.target.name] = event.target.value.trim().replace(/\s\s+/g, ' ')
    this.setState({trainees: trainees_copy})

    if (event.target.name === 'mobile_number' && this.props.error.type === 'EXISTING_KEY_MOBILE_NUMBER') {
      this.props.clearError()
    }
    //console.log('trainees_copy is: ', trainees_copy)
  }

  handleAddTrainee() {
    const trainees_copy = [ ...this.state.trainees ]
    trainees_copy.push({
      first_name: '',
      last_name: '',
      email: '',
      mobile_number: '',
    })
    this.setState({ trainees: trainees_copy })

    let email_is_valid_copy = [ ...this.state.email_is_valid]
    email_is_valid_copy.push({
      valid: true,
    })
    this.setState({ email_is_valid: email_is_valid_copy })

    let mobile_is_valid_copy = [ ...this.state.mobile_is_valid]
    mobile_is_valid_copy.push({
      valid: true
    })
    this.setState({ mobile_is_valid: mobile_is_valid_copy })

    this.props.addOneTrainee()
  }

  handleRemoveTrainee(index) {
    let trainees_copy = [ ...this.state.trainees ]
    trainees_copy = trainees_copy.filter((trainee, filter_index) => index !== filter_index )
    this.setState({ trainees: trainees_copy })

    let email_is_valid_copy = [ ...this.state.email_is_valid]
    email_is_valid_copy = email_is_valid_copy.filter((v, filter_index) => index !== filter_index)
    this.setState({ email_is_valid: email_is_valid_copy })

    let mobile_is_valid_copy = [ ...this.state.mobile_is_valid]
    mobile_is_valid_copy = mobile_is_valid_copy.filter((v, filter_index) => index !== filter_index)
    this.setState({ mobile_is_valid: mobile_is_valid_copy })

    this.props.removeOneTrainee()
  }

  handleSetBillingInfo(index) {
    // console.log('the index is: ', index)
    if (this.props.enrol_details.set_billing_contact_index_checkbox === index) {
      this.props.setBillingContactIndexCheckbox(-1)
    } else {
      this.props.setBillingContactIndexCheckbox(index)
    }

    //console.log('billing_info index is: ', index)

  }

  renderNextButton() {

    let can_continue = true

    for (let i = 0; i < this.state.trainees.length; i++) {

      const { first_name, last_name, email, mobile_number } = this.state.trainees[i]

      if (!first_name || !last_name || !utils.validEmail(email) || !utils.validContact(mobile_number)) {
          can_continue = false
      }
    }

    if (can_continue) {
      return <HoverButton style={{ float: 'right', textTransform: 'none' }} onClick={ () => this.onNextClick()}>Continue</HoverButton>
    } else {
      return <Button variant='contained' style={{ float: 'right', textTransform: 'none', cursor: 'not-allowed', pointerEvents: 'all', padding: '10px', paddingLeft: '20px', paddingRight: '20px', border: 'none' }} disabled onClick={ () => this.onNextClick()}>Continue</Button>
    }

  }


  
  render() {
    // console.log('the billing_info_index is: ', this.state.billing_info_index)
    // console.log('this.state.mobile_is_valid', this.state.mobile_is_valid)
    return (
      <Container>
        {this.renderSingleTrainee()}

        <HoverButton style={{ color: '#1E90FF', backgroundColor: 'white', border: 'dotted', width: '100%', textTransform: 'none' }} onClick={() => this.handleAddTrainee()}>+ Add Another UA Trainee</HoverButton>

        <br/>
        <br/>
        <HoverButton style={{ textTransform: 'none', backgroundColor: 'white', color: '#1E90FF', border: '1px solid' }} onClick={ () => this.props.goToPreviousStep()}>Back to Course</HoverButton>

        {this.renderNextButton()}
        <br/>
        <span style={{ fontSize: 12, float: 'right', marginTop: 10 }}>All fields are mandatory.</span>

        <br/><br/><br/><br/>
      </Container>
    )
  }
}

const styles = () => {
  return {
    textField: {
      color: 'blue !important' 
    },
  }
  
}

const mapStateToProps = state => {
  return {
    enrol_details: state.enrol,
    error: state.error
  }
}

const mapDispatchToProps = { 
  goToNextStep, 
  goToPreviousStep, 
  handleAllTraineesInfo, 
  setBillingContact, 
  addOneTrainee, 
  removeOneTrainee,
  setBillingContactIndexCheckbox, 
  clearError 
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StepThreeAddTrainees))

const HoverButton = withStyles({
  root: {
    backgroundColor: '#1E90FF ',
    color: 'white',
    borderRadius: '4px !important', 
    '&$disabled': {
      border: '1px solid rgb(170, 170, 170)',
      color: 'rgb(170, 170, 170) !important',
      backgroundColor: 'white !important'
    },
    '&:hover': {
      backgroundColor: '#3d94f6 !important',
      color: 'white !important',
    }
  }
})(MuiButton)