import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

// const CREDIT_DEBIT_CARD_METHOD = 'Credit / Debit Card'
const REQUEST_QUOTATION_OR_INVOICE_METHOD = 'Request Quotation / Invoice'
// const PAYNOW_METHOD = 'PayNow'
// const BANK_TRANSFER_METHOD = 'Bank Transfer'

const CheckCircle = () => (
  <div style={{ fill: '#5AA700'}}>
    <svg xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 0 24 24" width="50"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"/></svg>
  </div>
)

const EnrollmentCompletePage = ({ billing_contact, payment_method }) => (
  <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh', textAlign: 'center' }}>
    <Grid item xs={8}>
      <CheckCircle />
      <h2>Thank you, your order has been placed!</h2>
      
      { payment_method !== REQUEST_QUOTATION_OR_INVOICE_METHOD && <p>An email with your order confirmation and receipt will be sent shortly to <strong>{billing_contact.email}</strong>. If the email hasn’t arrived, please check your spam folder to see if the email was routed there.</p> }

      { payment_method === REQUEST_QUOTATION_OR_INVOICE_METHOD && <p>We will send you an invoice within 1-2 working days at {billing_contact.email}</p> }
    </Grid>   
  </Grid> 
)

const mapStateToProps = state => {
  return {
    billing_contact: state.enrol.billing_contact,
    payment_method: state.enrol.payment_method
  }
}

export default connect(mapStateToProps)(EnrollmentCompletePage)
