import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
// import Button from '@material-ui/core/Button'

import MuiButton from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

import { goToPreviousStep, goToStep, togglePlaceOrderCheckboxFirst, togglePlaceOrderCheckboxSecond } from '../../actions'

const CREDIT_DEBIT_CARD_METHOD = 'Credit / Debit Card'
const REQUEST_QUOTATION_OR_INVOICE_METHOD = 'Request Quotation / Invoice'

class StepFiveConfirmation extends React.Component {
  state = {
    first_confirmation: false,
    second_confirmation: false 
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  renderAllTraineesInfo() {
    return this.props.all_trainees.map((trainee, index) => {
      return (
          <Grid key={'trainee' + index} item md={6}>
            <Typography style={{ paddingBottom: '8px' }}><strong>UA Trainee {index + 1}</strong></Typography>
            <Typography>
              {trainee.first_name} {trainee.last_name} <br/>
              {trainee.email} <br/>
              {trainee.mobile_number} <br/>
            </Typography>
          </Grid>
      )
    })
  }
  
  render() {
    const { payment_method, billing_contact, card_payer_details, invoicing_details, quotation_details } = this.props 
    return (
      <Container>
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary>
            <span style={styles.numberCircle}>1</span> UA Trainees
            <strong style={{ marginLeft: 560, marginTop: 3, fontSize: 14, color: '#1E90FF' }} onClick={() => this.props.goToStep(2)} >Edit</strong>
          </ExpansionPanelSummary>
          
          <ExpansionPanelDetails>
            <Container>
              <Grid container>
                {this.renderAllTraineesInfo()}
              </Grid>
              <br/>
            </Container>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary>
            <span style={styles.numberCircle}>2</span> Payment
            <strong style={{ marginLeft: 588, marginTop: 3, fontSize: 14, color: '#1E90FF' }} onClick={() => this.props.goToStep(3)} >Edit</strong>
          </ExpansionPanelSummary>
          
          <ExpansionPanelDetails>
            <Container>
              <Grid container>
                <Grid item md={6}>
                  <Typography style={{ paddingBottom: '8px' }}><strong>Billing Contact</strong></Typography>
                  <Typography>{billing_contact.first_name} {billing_contact.last_name}</Typography>
                  <Typography>{billing_contact.email}</Typography>
                  <Typography>{billing_contact.mobile_number}</Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography style={{ paddingBottom: '8px' }}><strong>Payment Method</strong></Typography>
                  <Typography>{payment_method}</Typography>
                </Grid>
              </Grid>
              
              <br/><br/>
              
              { payment_method === CREDIT_DEBIT_CARD_METHOD ? 
                <Grid item md={6}>
                  <Typography style={{ paddingBottom: '8px' }}><strong>Billing Address</strong></Typography>
                  <Typography>{card_payer_details.first_name} {card_payer_details.last_name}</Typography>
                  <Typography>{card_payer_details.address}</Typography>
                  <Typography>{card_payer_details.city} {card_payer_details.postal_code}</Typography>
                  <Typography>{card_payer_details.country.toUpperCase()}</Typography>
                </Grid>
                :
                ''
              }

              { payment_method === REQUEST_QUOTATION_OR_INVOICE_METHOD ? 
                <Grid container>
                  <Grid item md={6}>
                    <Typography style={{ paddingBottom: '8px' }}><strong>Invoicing Instructions</strong></Typography>
                    <Typography>{invoicing_details.recipient_name}</Typography>
                    <Typography>{invoicing_details.email}</Typography>
                    <Typography>{invoicing_details.company_name}</Typography>
                    <Typography>{invoicing_details.uen}</Typography>
                    <Typography>{invoicing_details.address}</Typography>
                    <Typography>{invoicing_details.city} {invoicing_details.postal_code}</Typography>
                    <Typography>{invoicing_details.country.toUpperCase()}</Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography style={{ paddingBottom: '8px' }}><strong>Quotation Instructions</strong></Typography>   
                    <Typography>{quotation_details.recipient_name || 'NIL'}</Typography>
                    <Typography>{quotation_details.email}</Typography>
                    <Typography>{quotation_details.company_name}</Typography>
                    <Typography>{quotation_details.uen}</Typography>
                    <Typography>{quotation_details.address}</Typography>
                    <Typography>{quotation_details.city} {quotation_details.postal_code}</Typography>
                    <Typography>{quotation_details.country.toUpperCase()}</Typography>
                  </Grid>
                </Grid>
                :
                ''
              }
              <br/>
            </Container>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel expanded={true} style={{ border: '1px solid rgba(0, 0, 0, 0.125)' }}>
          <ExpansionPanelSummary>
            <span style={styles.numberCircle}>3</span> Order Review
          </ExpansionPanelSummary>
          
          <ExpansionPanelDetails>
            <Container>
              <Grid container>

                <Grid container>
                  <Grid item md={12}>
                    <Checkbox
                        checked={this.props.place_order_checkbox_first_confirmation}
                        onChange={() => this.props.togglePlaceOrderCheckboxFirst()}
                        color="primary"
                      />
                    <Typography style={{ fontSize: '14px', marginLeft: '53px', marginTop: '-33px', cursor: 'pointer' }} onClick={() => this.props.togglePlaceOrderCheckboxFirst()}>
                    I hereby declare that the information provided is complete, true and accurate.
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item md={12}>
                    <Checkbox
                        checked={this.props.place_order_checkbox_second_confirmation}
                        onChange={() => this.props.togglePlaceOrderCheckboxSecond()}
                        color="primary"
                      />
                    <Typography style={{ fontSize: '14px', marginLeft: '53px', marginTop: '-33px', cursor: 'pointer' }} onClick={() => this.props.togglePlaceOrderCheckboxSecond()}>
                      I have read and agree to the website <a href="https://uato.sg/terms/" target="_blank" rel="noopener noreferrer">Terms</a>; and I agree to let Garuda Robotics 
                      store and handle my personal data in order to manage my account, in accordance 
                      with Garuda Robotics' <a href="https://garuda.io/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                    </Typography>
                  </Grid>
                </Grid>

              </Grid>
            </Container>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <br/><br/>

        <BackButton style={{ textTransform: 'none', backgroundColor: 'white', color: '#1E90FF', border: '1px solid' }} onClick={ () => this.props.goToPreviousStep()}>Back to Payment</BackButton>

        <br/><br/><br/><br/><br/>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    payment_method: state.enrol.payment_method,
    billing_contact: state.enrol.billing_contact,
    card_payer_details: state.enrol.card_payer_details,
    invoicing_details: state.enrol.invoicing_details,
    quotation_details: state.enrol.quotation_details,
    all_trainees: state.enrol.trainees,
    place_order_checkbox_first_confirmation: state.enrol.place_order_checkbox_first_confirmation,
    place_order_checkbox_second_confirmation: state.enrol.place_order_checkbox_second_confirmation,
  }
}

export default connect(mapStateToProps, { goToPreviousStep, goToStep, togglePlaceOrderCheckboxFirst, togglePlaceOrderCheckboxSecond })(StepFiveConfirmation)



const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion)

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: '#EBEEF0',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    fontSize: '20px',
    color: '#373649',
    fontWeight: '500',
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(AccordionSummary)

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(AccordionDetails)

const styles = {
  numberCircle: {
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    padding: '4px',
    background: '#1E90FF',
    color: 'white',
    textAlign: 'center',
    fontSize: '12px',
    marginRight: '10px',
    marginTop: '3px'
  }
}

const BackButton = withStyles({
  root: {
    backgroundColor: '#1E90FF ',
    color: 'white',
    borderRadius: '4px !important', 
    '&$disabled': {
      border: '1px solid rgb(170, 170, 170)',
      color: 'rgb(170, 170, 170) !important',
      backgroundColor: 'white !important'
    },
    '&:hover': {
      backgroundColor: '#3d94f6 !important',
      color: 'white !important',
    }
  }
})(MuiButton)

/*

  MuiButton: {
      root: {
        backgroundColor: '#1E90FF ',
        color: 'white',
        borderRadius: '4px !important', 
        '&$disabled': {
          border: '1px solid rgb(170, 170, 170)',
          color: 'rgb(170, 170, 170) !important',
          backgroundColor: 'white !important'
        },
        '&:hover': {
          backgroundColor: '#3d94f6 !important',
          color: 'white !important',
        }
      },


*/