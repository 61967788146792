import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'

import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'

import api_interface from '../../api_interface'
import utils from '../../utils'

const button_enabled = { marginRight: 10, backgroundColor: 'white', color: '#1E90FF', textTransform: 'none', fontWeight: 'bold', border: '1px solid', fontSize: '14px', padding: '4px 10px' }

const get_order_query = (query_params) => {
  let query = {}

  const query_selection = ['page', 'limit']
  for (const param of query_params) {
    const split_param = param.split('=')
    if (query_selection.indexOf(split_param[0]) > -1) {
      query = {
        ...query,
        [split_param[0]]: parseInt(split_param[1])
      }
    }
  }
  return query
}

class TopupOrderViewer extends Component {
  state = {
    topup_orders: null,

    dialog_box_index: -1,
    dialog_box_is_open: false,
    order_id_add_note: '',

    edited_additional_notes: '',
    
    page_param: 1,
    limit_param: 10,
    total_orders: 1
  }

  componentDidMount = async () => {
    await this.setUpdateTopup()
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.props.location.search !== prevProps.location.search) {
      this.setState({
        topup_orders: null
      })
      await this.setUpdateTopup()
    }
  }

  setUpdateTopup = async () => {

    const { total_orders, topup_orders } = await api_interface.getTopupOrders(this.props.location.search.substr(1))

    const query_params = this.props.location.search.substr(1).split('&')
    const order_params = get_order_query(query_params)
    
    this.setState({
      page_param: order_params.page || 1,
      limit_param: order_params.limit || 10,
      topup_orders,
      total_orders
    })
  }

  changeTopupStatus = async (e, topup) => {
    const update_topup_res = await api_interface.putTopupStatus(topup, e.target.value)

    if (update_topup_res.success) {
      this.setUpdateTopup()
    }
    
  }

  openNoteDialog = (order, i) => {
    // console.log('order', order)
    this.setState({
      dialog_box_is_open: true,
      dialog_box_index: i,
      order_id_add_note: order._id,
      edited_additional_notes: order.additional_notes
    })
  }
  
  submitDialogBox = async (e) => {
    e.preventDefault()

    const submit_additional_notes = await api_interface.putTopupAdditionalNotes(this.state.order_id_add_note, this.state.edited_additional_notes)

    if (submit_additional_notes.success) {
      this.setUpdateTopup()
      
      this.setState({ dialog_box_is_open: false, order_id_add_note: '', edited_additional_notes: '' })
    }
  }
  
  cancelDialogBox = (e) => {
    e.preventDefault()
    this.setState({ dialog_box_is_open: false, order_id_add_note: '', edited_additional_notes: '' })
  }

  handleOrderLimit = (e) => {
    const filter_url = this.setFilterUrlParams(this.state)
    const str_url = `/admin/topup?page=1&limit=${e.target.value}${filter_url}`
    this.props.history.push(str_url)
    this.setState({
      limit_param: e.target.value
    })
  }

  handleOrderPage = (e, page) => {
    const filter_url = this.setFilterUrlParams(this.state)
    const str_url = `/admin/topup?page=${page}&limit=${this.state.limit_param}${filter_url}`
    this.props.history.push(str_url)
    this.setState({
      page_param: page
    })
  }

  setFilterUrlParams = (filters) => {
    const { date_param, status_param, payment_param, course_param } = filters
    const date_url_str = date_param && date_param.length > 0 ? `&date_limit=${date_param}` : ''
    const status_url_str = status_param && status_param.length > 0 ? `&status[]=${status_param.join('&status[]=')}` : ''
    const payment_url_str = payment_param && payment_param.length > 0 ? `&payment[]=${payment_param.join('&payment[]=')}` : ''
    const course_url_str = course_param && course_param.length > 0 ? `&course[]=${course_param.join('&course[]=')}` : ''

    return date_url_str + status_url_str + payment_url_str + course_url_str
  }

  renderDialogBox() {
    return (
      <Dialog open={this.state.dialog_box_is_open}>
        <DialogTitle>Add a New Note</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Additional Notes for: <strong>{this.state.topup_orders[this.state.dialog_box_index].trainee.email}</strong>
          </DialogContentText>

          <TextField
            id="standard-full-width"
            multiline
            rows={6}
            label=""
            placeholder=""
            style={{ width: 500 }}
            InputLabelProps={{
              shrink: true
            }}
            name='additional_notes'
            value={this.state.edited_additional_notes}
            onChange={(event) => this.setState({ edited_additional_notes: event.target.value })}
          />

        </DialogContent>

        <DialogActions>
          <Button style={{ textTransform: 'none' }} onClick={(e) => this.submitDialogBox(e)}>Submit</Button>
          <Button style={{ textTransform: 'none' }} onClick={(e) => this.cancelDialogBox(e)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderTableBody = () => {
    const { topup_orders } = this.state
    // console.log('topup_orders', topup_orders)
    return topup_orders.map( (order, i) => {
      return (
        <TableRow key={i} >
          <TableCell>
            { utils.formatTime(order.requested_at) },<br />
            { utils.formatSgMinsAndSecs(order.requested_at) }
          </TableCell>
          <TableCell>
            { order.trainee.first_name } { order.trainee.last_name }<br />
            { order.coupon_code && <span><strong>Coupon:</strong> { order.coupon_code }</span> }
          </TableCell>
          <TableCell style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }} >
            { order.trainee.email }
          </TableCell>
          <TableCell>
            { order.catalog.sku }
          </TableCell>
          <TableCell>
            { order.credits.map( (c, i) => <span key={i} style={{ display: 'block' }} >{ c.course.name } - {c.session_number}</span> ) }
          </TableCell>
          <TableCell>
            { utils.formatPrice({ amount: parseInt(order.total_amount) }) }
          </TableCell>
          <TableCell>
            <Select value={order.lead_status} onChange={(event) => this.changeTopupStatus(event, order)}>
              <MenuItem value={'Engaging'}><span style={{ fontSize: 20, color: 'orange' }}>■</span> Engaging</MenuItem>
              <MenuItem value={'Paid and Confirmed'}><span style={{ fontSize: 20, color: '#0cb304' }}>■</span> Paid</MenuItem>
              <MenuItem value={'No Response'}><span style={{ fontSize: 20, color: 'red' }}>■</span> No Response</MenuItem>
              <MenuItem value={'Cancelled'}><span style={{ fontSize: 20, color: '#8B0000' }}>■</span> Cancelled</MenuItem>
            </Select>
          </TableCell>
          <TableCell>
            <div style={{ display: 'flex', alignItems: 'center' }} >
              <div>
                <Button
                  style={{ ...button_enabled, minWidth: '125px' }}
                  onClick={() => this.openNoteDialog(order, i)}
                >
                  +Add Note
                </Button>
                { this.state.dialog_box_index > -1 && this.renderDialogBox() }
              </div>
              <div style={{ flexBasis: '60%' }} >
                <span>
                  { order.additional_notes }
                </span>
              </div>
            </div>
          </TableCell>
        </TableRow>
      )
    })
  }

  renderNull = () => {
    return (
      <TableBody style={{ textAlign: 'center' }}>
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <span><CircularProgress /></span>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableBody>
    )
  }

  render() {
    const { page_param, limit_param, total_orders } = this.state

    // console.log('page_param', page_param)
    // console.log('limit_param', limit_param)
    // console.log('total_orders', total_orders)
    // console.log('(parseInt(total_orders/limit_param))*limit_param+1', (parseInt(total_orders/limit_param))*limit_param+1)
    const start_items = page_param*limit_param >= total_orders ? parseInt(total_orders/limit_param)*limit_param+1 : (page_param-1)*limit_param+1
    const end_items = page_param*limit_param >= total_orders ? total_orders : page_param*limit_param
    const page_selections = Array.from({ length: total_orders ? parseInt((total_orders-1)/limit_param)+1 : 1 }, (_, i) => i)

    return (
      <div>
        <br/><br/><br/><br/><br /><br />
        <div style={{ position: 'relative' }} >
          <h2 style={{ marginTop: '0', marginBottom: '2em' }} >Top-up Sessions</h2>
        </div>
        {
          <Paper >

            <TableContainer component={Paper} style={{ marginTop: 0, maxWidth: 4000, borderRadius: '0', overflowX: 'auto' }}>
              <Table style={{ minWidth: 1500, tableLayout: 'fixed' }} >
                <TableHead>
                  <TableRow style={{ background: '#f5f5f5' }} >
                    <TableCell style={{ fontSize: 14, fontWeight: 'bold', width: 120, wordWrap: 'break-word', overflowWrap: 'break-word' }}>Date/Time</TableCell>
                    <TableCell style={{ fontSize: 14, fontWeight: 'bold', width: 150, wordWrap: 'break-word', overflowWrap: 'break-word' }}>Name</TableCell>
                    <TableCell style={{ fontSize: 14, fontWeight: 'bold', width: 175, wordWrap: 'break-word', overflowWrap: 'break-word' }}>Email</TableCell>
                    <TableCell style={{ fontSize: 14, fontWeight: 'bold', width: 60, wordWrap: 'break-word', overflowWrap: 'break-word' }}>Course</TableCell>
                    <TableCell style={{ fontSize: 14, fontWeight: 'bold', width: 175, wordWrap: 'break-word', overflowWrap: 'break-word' }}>Order/Details</TableCell>
                    <TableCell style={{ fontSize: 14, fontWeight: 'bold', width: 75, wordWrap: 'break-word', overflowWrap: 'break-word' }}>Total Fee</TableCell>
                    <TableCell style={{ fontSize: 14, fontWeight: 'bold', width: 125, wordWrap: 'break-word', overflowWrap: 'break-word' }}>Lead Status</TableCell>
                    <TableCell style={{ fontSize: 14, fontWeight: 'bold' }}>Additional Notes</TableCell>
                  </TableRow>
                </TableHead>
                {
                  this.state.topup_orders === null ?
                    this.renderNull()
                    :
                    <TableBody>
                    { this.renderTableBody() }
                    </TableBody>
                }
              </Table>
            </TableContainer>
            <div style={{ padding: '12px 20px', fontSize: '14px', background: '#fff', borderRadius: '0 0 4px 4px', boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }} >
              <div style={{ display: 'flex' }} >
                <div style={{ flexBasis: '50%', color: '#212121' }}>
                  Items per page
                  <Select style={{ minWidth: '50px', margin: '0 10px' }} value={this.state.limit_param} onChange={(e) => this.handleOrderLimit(e)} >
                    <MenuItem value={10} >10</MenuItem>
                    { total_orders > 10 && <MenuItem value={20} >20</MenuItem> }
                    { total_orders > 20 && <MenuItem value={50} >50</MenuItem> }
                  </Select>
                  { start_items } - { end_items } of { total_orders } items
                </div>
                <div style={{ flexBasis: '50%', textAlign: 'right', color: '#212121' }}>
                  {page_param} of { page_selections.length } pages
                  <IconButton disabled={page_param === 1} onClick={e => this.handleOrderPage(e, page_param-1)} ><NavigateBeforeIcon /></IconButton>
                  <Select style={{ minWidth: '50px', margin: '0 10px' }} value={this.state.page_param} onChange={(e) => this.handleOrderPage(e, e.target.value)} >
                    {
                      page_selections.map((page, i) => <MenuItem key={i} value={page+1} >{page+1}</MenuItem>)
                    }
                  </Select>
                  <IconButton disabled={page_param === page_selections.length} onClick={e => this.handleOrderPage(e, page_param+1)}><NavigateNextIcon /></IconButton>
                </div>
              </div>
            </div>
          </Paper>
        }
        <br/>
        <br/>
        <br/>
      </div>
    )
  }
}

export default TopupOrderViewer 
