import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'

import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'

import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import FilterListIcon from '@material-ui/icons/FilterList'

import api_interface from '../../api_interface'
import OrderFilter from './dropdown/OrderFilter'
import { months } from '../../utils/constants.json'

const filter_selection_str = [ 'date[]', 'status[]', 'payment[]', 'course[]' ]

const get_order_query = (query_params) => {
  let query = {}

  const query_selection = ['page', 'limit']
  for (const param of query_params) {
    const split_param = param.split('=')
    if (query_selection.indexOf(split_param[0]) > -1) {
      query = {
        ...query,
        [split_param[0]]: parseInt(split_param[1])
      }
    } else if (split_param[0] === 'date_limit') {
      query = {
        ...query,
        date: [split_param[1]]
      }
    } else if (filter_selection_str.indexOf(split_param[0]) > -1) {
      const filter_key = split_param[0].replace('[]', '')
      const query_param_checker = {...query}[filter_key]
      
      const add_filters = query_param_checker && query_param_checker.length > 0 ? [...query_param_checker, decodeURI(split_param[1]) ] : [ decodeURI(split_param[1]) ]
      query = {
        ...query,
        [filter_key]: add_filters
      }
    }
  }
  return query
}

class OrderViewer extends Component {
  _isMounted = false

  state = {
    orders: null,

    orders_user_editing: [],

    dialog_box_index: -1,

    dialog_box_is_open: false,

    order_id_add_note: '',

    additional_notes: '',

    page_param: 1,
    limit_param: 10,
    total_orders: 1,

    date_param: null,
    status_param: null,
    payment_param: null,
    course_param: null,
    
    open_filter: false
  }

  async componentDidMount() {
    this._isMounted = true
    if (this._isMounted) {
      await this.setUpdateOrders()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.props.location.search !== prevProps.location.search) {
      this.setState({
        orders: null,
        orders_user_editing: [],
      })
      await this.setUpdateOrders()
    }
  }

  setUpdateOrders = async () => {
    const { orders } = await api_interface.getOrders(this.props.location.search.substr(1))

    const query_params = this.props.location.search.substr(1).split('&')
    const order_params = get_order_query(query_params)
    
    this.setState({
      total_orders: orders.total,
      orders_user_editing: orders.order_object,
      page_param: order_params.page || 1,
      limit_param: order_params.limit || 10,
      date_param: order_params.date || [],
      status_param: order_params.status || [],
      payment_param: order_params.payment || [],
      course_param: order_params.course || [],
      open_filter: false,
      orders: orders.order_object
    })
  }

  changeOrderStatus = async (order, status) => {
    const update_order_res = await api_interface.putOrderStatus(order, status)
    // console.log('update order res is ', update_order_res)
    if (update_order_res.success) {
      this.setUpdateOrders()
    }
  }

  formatDate = (date) => {
    const date_str = new Date(date)
    const year = date_str.getFullYear()
    const month = months[date_str.getMonth()]
    const month_date = date_str.getDate()

    return `${month} ${month_date}, ${year}`
  }

  handleNextOrders = async (e) => {
    e.preventDefault()
    this.setState({ next_active: true })
    const trainee_res_data = this.state.search_trainee.length > 2 ? await api_interface.searchTraineeByNameOrEmail(this.state.search_trainee, this.state.trainees.length) : await api_interface.getLatestTraineeCredits(this.state.trainees.length)
    const next_trainees = trainee_res_data.trainees

    this.setState({ next_active: false, trainees: [ ...this.state.trainees, ...next_trainees ], recent_trainees: this.state.search_trainee.length > 2 ? this.state.recent_trainees : [ ...this.state.recent_trainees, ...next_trainees ] })
  }

  submitDialogBox = async () => {
    // console.log('order id is: ', this.state.order_id_add_note)
    // console.log('additional notes: ', this.state.additional_notes)

    const submit_additional_notes = await api_interface.putOrderAdditionalNotes(this.state.order_id_add_note, this.state.additional_notes)

    if (submit_additional_notes.success) {
      this.setUpdateOrders()
      
      this.setState({ dialog_box_is_open: false, order_id_add_note: '', additional_notes: '' })
    }
  }

  cancelDialogBox() {
    this.setState({ dialog_box_is_open: false, order_id_add_note: '', additional_notes: '' })

  }

  renderDialogBox() {
    return (
      <Dialog open={this.state.dialog_box_is_open}>
        <DialogTitle>Add a New Note</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Additional Notes for index number {this.state.dialog_box_index}
          </DialogContentText>

          <TextField
            id="standard-full-width"
            multiline
            rows={6}
            label=""
            placeholder=""
            style={{ width: 500 }}
            InputLabelProps={{
              shrink: true,
            }}
            name='additional_notes'
            value={this.state.additional_notes}
            onChange={(event) => this.setState({ additional_notes: event.target.value })}
          />

        </DialogContent>

        <DialogActions>
          <Button style={{ textTransform: 'none' }} onClick={() => this.submitDialogBox()}>Submit</Button>
          <Button style={{ textTransform: 'none' }} onClick={() => this.cancelDialogBox()}>Cancel</Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderOrderRows() {
    const { orders } = this.state

    // let orders_copy = [ ...orders ]

    // const orders_reversed = orders_copy.reverse()
    // console.log('orders_reversed: ', orders_reversed)

    // console.log('orders', orders)

    return orders.map((order, index) => {
      // console.log('each order: ', order)
      // console.log('type: ', typeof order.additional_notes)
      // console.log('order.trainees: ', order.trainees)
      // console.log('order status: ', order.status)

      const trainees_full_name = order.trainees.map((t, i) => <li key={i}>{t.full_name}</li>)
      const trainees_email = order.trainees.map((t, i) => <li key={i}>{t.email}</li>)
      const trainees_mobile_number = order.trainees.map((t, i) => <li key={i}>{t.mobile_number}</li>)

      return (
        <TableRow style={{ minWidth: 3000 }} key={index} >
          <TableCell align="left" style={{ minWidth: 250 }}><ol>{trainees_full_name}</ol></TableCell>
          <TableCell align="left" style={{ minWidth: 250 }}><ol>{trainees_email}</ol></TableCell>
          <TableCell align="left" style={{ width: 150 }}><ol>{trainees_mobile_number}</ol></TableCell>
          <TableCell align="center">
              <Select value={order.status} onChange={(event) => this.changeOrderStatus(order, event.target.value)}>
                <MenuItem value={'Requested Payment'}><span style={{ fontSize: 20, color: '#5e5e5e' }}>■</span> Requested Payment</MenuItem>
                <MenuItem value={'Engaged'}><span style={{ fontSize: 20, color: 'orange' }}>■</span> Engaging</MenuItem>
                <MenuItem value={'Payment Expected'}><span style={{ fontSize: 20, color: '#1E90FF' }}>■</span> Payment Expected</MenuItem>
                <MenuItem value={'Paid and Confirmed'}><span style={{ fontSize: 20, color: '#0cb304' }}>■</span> Paid and Confirmed</MenuItem>
                <MenuItem value={'No Response'}><span style={{ fontSize: 20, color: 'red' }}>■</span> No Response</MenuItem>
                <MenuItem value={'Cancelled'}><span style={{ fontSize: 20, color: '#8B0000' }}>■</span> Cancelled</MenuItem>
              </Select>
          </TableCell>
          <TableCell align="left">({order.catalog_item.sku}) {order.catalog_item.name}</TableCell>
          <TableCell align="center">{this.formatDate(order.ordered_on)}</TableCell>
          <TableCell align="center">{order.payment_type}</TableCell>
          <TableCell align="center">{order.stripe_payment_details ? order.stripe_payment_details.id : ''}</TableCell>
          <TableCell align="center"><strong>{order.billing_contact.email}</strong></TableCell>
          <TableCell align="center"><strong>{order.billing_contact.mobile_number}</strong></TableCell>
          <TableCell align="center">
            {this.renderInvoicingDetails(order)}
          </TableCell>
          <TableCell align="center">
            {this.renderQuotationDetails(order)}
          </TableCell>
          <TableCell>
            <div>
              <Button 
                style={{ textTransform: 'none', width: 130 }} 
                onClick={() => this.setState({ dialog_box_is_open: true, order_id_add_note: order._id, additional_notes: order.additional_notes })}
              >
                + Add Note
              </Button>

              {this.renderDialogBox()}

            </div>
          </TableCell>
          <TableCell style={{ minWidth: 200, whiteSpace: 'normal', wordWrap: 'break-word' }}>
            {order.additional_notes}
          </TableCell>
          
        </TableRow>
      )
    })
  }

  renderInvoicingDetails(order) {
    const { recipient_name, email, company_name, uen, address, city, postal_code, country } = order.invoicing_details

    if (order.payment_type === 'invoice') {
      return (
        <>
          <TableRow>
            <TableCell>Recipient Name:</TableCell>
            <TableCell>{recipient_name}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>{email}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Company Name</TableCell>
            <TableCell>{company_name}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>UEN</TableCell>
            <TableCell>{uen}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{address}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>City</TableCell>
            <TableCell>{city}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Postal Code</TableCell>
            <TableCell>{postal_code}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Country</TableCell>
            <TableCell>{country}</TableCell>
          </TableRow>
        </>
      )
    } else {
      return 'Not Applicable'
    }
  }

  renderQuotationDetails(order) {
    const { recipient_name, email, company_name, uen, address, city, postal_code, country } = order.quotation_details

    if (order.payment_type === 'invoice' && recipient_name) {
      return (
        <>
          <TableRow>
            <TableCell>Recipient Name:</TableCell>
            <TableCell>{recipient_name}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>{email}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Company Name</TableCell>
            <TableCell>{company_name}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>UEN</TableCell>
            <TableCell>{uen}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{address}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>City</TableCell>
            <TableCell>{city}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Postal Code</TableCell>
            <TableCell>{postal_code}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Country</TableCell>
            <TableCell>{country}</TableCell>
          </TableRow>
        </>
      )
    } else {
      return 'Not Applicable'
    }
  }

  renderNull = () => {
    return (
      <TableRow>
        <TableCell>
          <div style={{ textAlign: 'center', width: '100%', height: '100px' }} >
            <div style={{ position: 'absolute', padding: '2em', width: 'calc(100% - 19em)' }} >
              <CircularProgress />
            </div>
          </div>
        </TableCell>
      </TableRow>
    )
  }

  handleTextInputChange = (key, value) => this.setState({ [key]: value })

  setFilterUrlParams = (filters) => {
    const { date_param, status_param, payment_param, course_param } = filters
    const date_url_str = date_param && date_param.length > 0 ? `&date_limit=${date_param}` : ''
    const status_url_str = status_param && status_param.length > 0 ? `&status[]=${status_param.join('&status[]=')}` : ''
    const payment_url_str = payment_param && payment_param.length > 0 ? `&payment[]=${payment_param.join('&payment[]=')}` : ''
    const course_url_str = course_param && course_param.length > 0 ? `&course[]=${course_param.join('&course[]=')}` : ''

    return date_url_str + status_url_str + payment_url_str + course_url_str
  }

  handleOrderLimit = (e) => {
    const filter_url = this.setFilterUrlParams(this.state)
    const str_url = `/admin/orders?page=1&limit=${e.target.value}${filter_url}`
    this.props.history.push(str_url)
    this.setState({
      limit_param: e.target.value
    })
  }

  handleOrderPage = (e, page) => {
    const filter_url = this.setFilterUrlParams(this.state)
    const str_url = `/admin/orders?page=${page}&limit=${this.state.limit_param}${filter_url}`
    this.props.history.push(str_url)
    this.setState({
      page_param: page
    })
  }

  renderLabel = () => {
    const { date_param, status_param, payment_param, course_param } = this.state
    const filters = { date_param, status_param, payment_param, course_param }
    // console.log('date_param', date_param)
    return (
      <div style={{ position: 'relative' }} >
        <h2 style={{ marginTop: '0', marginBottom: '2em' }} >Order</h2>
        <div style={{ position: 'absolute', right: 0, top: 0, textAlign: 'right' }} >
          <Button onClick={() => this.setState({ open_filter: !this.state.open_filter })} style={{ background: this.state.open_filter ? 'transparent' : '#1E90FF', color: this.state.open_filter ? '#1E90FF' : '#fff', border: '1px solid #1E90FF' }} ><FilterListIcon />Filter</Button>
          { this.state.open_filter &&
          <OrderFilter history={ this.props.history } setFilterUrlParams={ this.setFilterUrlParams } filters={ filters } />
          }
        </div>
      </div>
    )
  }

  render() {
    const { page_param, limit_param, total_orders } = this.state
    // console.log('page_param', page_param)
    // console.log('limit_param', limit_param)
    // console.log('total_orders', total_orders)
    // console.log('(parseInt(total_orders/limit_param))*limit_param+1', (parseInt(total_orders/limit_param))*limit_param+1)
    const start_items = page_param*limit_param >= total_orders ? (parseInt(total_orders/limit_param))*limit_param+1 : (page_param-1)*limit_param+1
    const end_items = page_param*limit_param >= total_orders ? total_orders : page_param*limit_param
    const page_selections = Array.from({ length: total_orders ? parseInt((total_orders-1)/limit_param)+1 : 1 }, (_, i) => i)

    // console.log('start_items', start_items)

    return (
      <div>
        <Grid style={{ marginTop: '120px', marginLeft: '0px' }}>
          { this.renderLabel() }
          {
            total_orders > 0 ?
            <div>
              <TableContainer component={Paper} style={{ marginRight: 0 }}>
                <Table>
                  <TableHead style={{ minWidth: this.state.orders === null ? 0 : 3000, width: this.state.orders === null ? '100%' : 'auto' }}>
                    <TableRow>
                      <TableCell align="center" style={{ minWidth: 250 }}>Name</TableCell>
                      <TableCell align="center" style={{ minWidth: 250 }} >Email</TableCell>
                      <TableCell align="center" style={{ width: 150 }} >Mobile Number</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Catalog Item</TableCell>
                      <TableCell align="center">Date Added</TableCell>
                      <TableCell align="center">Payment Type</TableCell>
                      <TableCell align="center">Stripe Payment ID</TableCell>
                      <TableCell align="center"><strong>Billing Contact Email</strong></TableCell>
                      <TableCell align="center"><strong>Billing Contact Number</strong></TableCell>
                      <TableCell align="center" style={{ }}>Invoicing Details (If Applicable)</TableCell>
                      <TableCell align="center" style={{ }}>Quotation Details (If Applicable)</TableCell>
                      <TableCell align="center">Additional Notes Button</TableCell>
                      <TableCell align="center" style={{ minWidth: 200 }}>Additional Notes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ minWidth: this.state.orders === null ? 0 : 3000, width: this.state.orders === null ? '100%' : 'auto', position: 'relative', minHeight: '300px' }}>
                    {
                      this.state.orders === null ?
                      this.renderNull()
                      :
                      this.renderOrderRows()
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ padding: '12px 20px', fontSize: '14px', background: '#fff', borderRadius: '0 0 4px 4px', boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }} >
                <div style={{ display: 'flex' }} >
                  <div style={{ flexBasis: '50%', color: '#212121' }}>
                    Items per page
                    <Select style={{ minWidth: '50px', margin: '0 10px' }} value={this.state.limit_param} onChange={(e) => this.handleOrderLimit(e)} >
                      <MenuItem value={10} >10</MenuItem>
                      { total_orders > 10 && <MenuItem value={20} >20</MenuItem> }
                      { total_orders > 20 && <MenuItem value={50} >50</MenuItem> }
                    </Select>
                    { start_items } - { end_items } of { total_orders } items
                  </div>
                  <div style={{ flexBasis: '50%', textAlign: 'right', color: '#212121' }}>
                    {page_param} of { page_selections.length } pages
                    <IconButton disabled={page_param === 1} onClick={e => this.handleOrderPage(e, page_param-1)} ><NavigateBeforeIcon /></IconButton>
                    <Select style={{ minWidth: '50px', margin: '0 10px' }} value={this.state.page_param} onChange={(e) => this.handleOrderPage(e, e.target.value)} >
                      {
                        page_selections.map((page, i) => <MenuItem key={i} value={page+1} >{page+1}</MenuItem>)
                      }
                    </Select>
                    <IconButton disabled={page_param === page_selections.length} onClick={e => this.handleOrderPage(e, page_param+1)}><NavigateNextIcon /></IconButton>
                  </div>
                </div>
              </div>
            </div>
            :
            <h2 style={{ fontSize: '2.4em', fontStyle: 'italic', fontWeight: '300', color: '#9e9e9e', textAlign: 'center' }} >No Order Available</h2>
          }
          <br/>
          <br/>
          <br/>
        </Grid>
      </div>
    )
  }
}

export default OrderViewer 
