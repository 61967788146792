import React, { Component } from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import CircularProgress from '@material-ui/core/CircularProgress'

import Calendar from './Calendar'
import TopupCreditSection from './TopupCreditSection'

import utils from '../utils'
import api_interface from '../api_interface'

import { 
  getEntireCourseSchedule, 
  getCourseScheduleForTrainee, 
  getAuthUserCredits 
} from '../actions'

const styles = {
  divider: {
    marginTop: '12px',
    marginBottom: '12px',
    width: '100%',
    backgroundColor: '#8C9BA5'
  }
}

const passed_status = ['Passed', 'Conditional Passed']
const available_status = ['Available']

const practical_session = 'Practical Session Series'
const theory_module = 'Theory Module'

class CourseScheduler extends Component {
  state = {
    new_enrollments: [],
    dialog_open: false,
    topup_active: false,
    open_topup_dialog: false,
    topup_order: [],
    triggered_topup: false
  }

  async componentDidMount() {
    const { auth, getEntireCourseSchedule, getCourseScheduleForTrainee, getAuthUserCredits } = this.props
    
    if (!auth.plex) {
      this.props.history.push('/login')
      return
    }

    if (!auth.nric) {
      alert('Please complete your user details to enrol course')
      this.props.history.push('/user/details')
      return
    }

    if (auth.uato_personnel) {
      getEntireCourseSchedule()
    } else if (!auth.uato_personnel) {
      getCourseScheduleForTrainee()
      getAuthUserCredits()

      const trainee_topup_order = await api_interface.getTopupOrder(this.props.auth.email)
      // console.log('trainee_topup_order', trainee_topup_order)
      this.setState({ topup_order: trainee_topup_order })
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.topup_active !== prevState.topup_active && prevState.topup_active) {
      await this.props.getAuthUserCredits()
      const trainee_topup_order = await api_interface.getTopupOrder(this.props.auth.email)
      // console.log('trainee_topup_order', trainee_topup_order)
      this.setState({ topup_order: trainee_topup_order })
    }
  }

  handleCourseSelect = (course, session_number) => {
    // console.log('course', course)
    if (course.remove)
      this.removeCourse(course.course_name, session_number)
    else 
      this.enrollToCourse({ ...course, session: session_number }) 
  }

  enrollToCourse = (c) => {
    const new_enrollments_copy = [ ...this.state.new_enrollments ]

    let filtered_new_enrollments = []

    for (const new_enrol of new_enrollments_copy) {
      if (new_enrol.course._id !== c.course._id) {
        filtered_new_enrollments.push(new_enrol)
      } else {
        if (c.course.number_of_sessions > 1 && new_enrol.session !== c.session ) {
          filtered_new_enrollments.push(new_enrol)
        }
      }
    }

    filtered_new_enrollments.push(c)

    if (c.course.name.includes(theory_module)) {
      const all_tm_enrol = this.props.course_schedule.filter( cs => new Date(cs.start_time).getDate() === new Date(c.start_time).getDate() && new Date(cs.start_time).getMonth() === new Date(c.start_time).getMonth() && cs.course.name.includes(theory_module) ).map( tm_enrol => {
        return {
          ...tm_enrol,
          session: c.session
        }
      })

      filtered_new_enrollments = [...filtered_new_enrollments].filter(new_enrol => !(new_enrol.ref_course === c.course.name && new_enrol.start_time < c.start_time))
      filtered_new_enrollments = [
        ...filtered_new_enrollments.filter( ne => all_tm_enrol.findIndex( ate => ate.course._id === ne.course._id) < 0 ),
        ...all_tm_enrol
      ]
    }

    this.setState({
      new_enrollments: [ ...filtered_new_enrollments ]
    })
  }

  removeCourse = (course_name, session_number) => {
    const new_enrollments_copy = [ ...this.state.new_enrollments ]

    const filtered_new_enrollments = new_enrollments_copy.filter(new_enrollments_course => !new_enrollments_course.course.name.includes(course_name) && new_enrollments_course.session !== session_number)

    this.setState({ new_enrollments: filtered_new_enrollments })
  }

  isBeforeTheoryModule = (course) => {
    const { new_enrollments } = this.state
    
    const { auth } = this.props
    const user_credit_record = auth.credits

    const is_enrol_theory_module = user_credit_record ? user_credit_record.find( ucr => ucr.course.name === course.ref_course && ucr.status !== 'Available' ) : null

    if (is_enrol_theory_module) {
      return is_enrol_theory_module && course.start_time < is_enrol_theory_module.course_schedule.start_time
    } else {
      const theory_for_practical = new_enrollments.find( enrol => enrol.course.name === course.ref_course )
      // practical schedule is disabled if scheduled before theory
      return new_enrollments.length > 0 && typeof theory_for_practical !== 'undefined' && course.start_time < theory_for_practical.start_time
    }
  }

  renderCourseTimeSelect = (course_name, course_schedule, session_number) => {
    const { new_enrollments } = this.state
    const { auth } = this.props
    const user_credit_record = auth && auth.credits ? auth.credits : []

    const new_enrolled_schedule = new_enrollments.find(ne => course_schedule.findIndex(cs => cs.start_time === ne.start_time) > -1 && ( ne.session === session_number || ne.course.name === course_name ) )
    const selected_index = new_enrolled_schedule ? course_schedule.findIndex( cs => cs.start_time === new_enrolled_schedule.start_time && cs.course.name === new_enrolled_schedule.course.name ) : -1

    const now = new Date().getTime()
    
    const filtered_course_schedule = course_schedule.filter(cs => cs.deleted_on === null)

    return (
      <Select style={{ border: 'none !important' }} value={selected_index > -1 ? course_schedule[selected_index] : '' } onChange={e => this.handleCourseSelect(e.target.value, session_number)}>
        <MenuItem value={{ remove: true, course_name: course_name }}>
          <span style={{ fontSize: '12px', color: 'transparent' }}>u cant c me</span>
        </MenuItem>
        { filtered_course_schedule.map((c, i_1) => {
          // MenuItem is disabled if:
          // - menu/course started at the same time with schedules in user auth credit, or
          // - menu/course is selected by trainee, or
          // - current attendance >= max_attendance or schedule already included in the new enrollments, or
          // - start_time-1 day <= now
          // if not, disable when practical scheduled before theory module
          const start_time_date = new Date(c.start_time).getTime()

          let is_disable = (user_credit_record.findIndex(ucr => ucr.course_schedule && ucr.course_schedule.start_time === c.start_time ) > -1 || new_enrollments.findIndex( ne => ne._id === c._id) > -1 || c.current_attendance >= c.max_attendance || start_time_date-(24*3600*1000) <= now ) ? true : this.isBeforeTheoryModule(c) ? true : false

          if (c.course.code === 'TM_01') {
            is_disable = this.props.course_schedule.findIndex( cs => cs.course.code === 'TM_02' && utils.formatTime(cs.start_time) === utils.formatTime(c.start_time) && cs.current_attendance < cs.max_attendance ) < 0
          }

          // console.log('is_disable', is_disable)

          if (c.start_time) {
            return (
              <MenuItem key={i_1} value={c} disabled={ is_disable } >
                {
                  <>
                    <span style={{ fontSize: '12px'}}>
                      {utils.formatTime(c.start_time)}, {utils.formatSgMinsAndSecs(c.start_time)} <b>(Slots left: {c.max_attendance - c.current_attendance})</b>
                    </span>
                  </>
                }
              </MenuItem>
            )
          } else {
            return null
          }
        })}
      </Select>
    )
  }

  checkIfCourseIsEnrollable = (course_id, session_number) => {
    // This function determines if the currently authenticated
    // trainee can enroll in a class.
    // It is enrollable if the credit's status is "Available"
    // or "Failed". If it is "Passed" or "Enrolled", then
    // we render as such.

    const authenticated_user_credits = this.props.auth.credits 

    if (!authenticated_user_credits) 
      return false

    let is_enrolled = ''

    for (const credit of authenticated_user_credits) {

      if (credit.course_id === course_id && credit.session_number === session_number) {

        // const date_enrolled = this.props.course_schedule.filter(scheduled => scheduled._id === credit.course_schedule_id)

        const rendered_status = [...passed_status, 'Conditional Failed', 'Failed']

        // If trainee has passed once, return passed
        if (rendered_status.indexOf(credit.status) > -1)
          return { course_id: course_id, status: credit.status, time: utils.formatTime(credit.course_schedule.start_time), session_number }

        // Keep checking if credit is 'Enrolled'
        if (credit.status === 'Enrolled') {
          // Date when this user is enrolled to course_id
          is_enrolled = credit.course_schedule.start_time
        }
      }
    }

    if (is_enrolled)
      return { course_id: course_id, status: 'Enrolled', time: utils.formatTime(is_enrolled), session_number }
  }

  convertCredits = (e, value) => {
    e.preventDefault()
    this.setState({
      open_topup_dialog: value
    })
  }

  renderCourseAndTimePicker = () => {
    const { schedule_by_course_name, auth } = this.props

    const auth_credits = auth.credits ? [...auth.credits] : []

    const course_names_map = Object.keys(schedule_by_course_name)

    const render_status = [...passed_status, 'Conditional Failed', 'Failed', 'Enrolled']

    const rendered_course_names = [...course_names_map, 'Current_enrolled_checkpoint']

    if (rendered_course_names.length) {
      // record the iteration times, classified by course_id
      let record_schedule_temp = {}
      let enrollable_temp = []
      return rendered_course_names.map((course_name_front_raw, i) => {
        let course_name_front = course_name_front_raw
        const course_schedule = schedule_by_course_name[course_name_front]
        const course_name_array = course_name_front.split(' - ')
        const course_name = course_name_array[0]
        const course_name_order = course_name_array.length > 1 ? course_name_array[course_name_array.length - 1] : 1

        const course_schedule_ref = course_schedule && course_schedule.length && course_schedule[0].course ? course_schedule[0].course._id : null

        const course_in_credit = course_schedule_ref ? auth_credits.filter(credit => credit.course._id === course_schedule_ref ) : auth_credits.filter(credit => credit.course.name === course_name_front || credit.course.name.includes(course_name) )

        let calendar_color = course_in_credit.length ? course_in_credit[0].course.calendar_color : course_schedule && course_schedule[0] ? course_schedule[0].course.calendar_color : ''

        if (course_in_credit.length > 0) {
          
          const course_sessions = course_in_credit.filter(credit => available_status.indexOf(credit.status) < 0 )
          const multiple_sessions = course_sessions.length > 0 && course_sessions[0].course.number_of_sessions > 1
          const course_session = Boolean(multiple_sessions || course_sessions.length) ? course_in_credit.find(cs => cs.session_number === parseInt(course_name_order)) : course_sessions[0]

          if (course_session) {
            if (available_status.indexOf(course_session.status) < 0 ) {
              // const sessions_in_credit = auth_credits.filter(credit => credit.course_id === course_session.course_id)
              record_schedule_temp[course_session.course_id] = record_schedule_temp[course_session.course_id] ? record_schedule_temp[course_session.course_id]+1 : 1
              return (
                <Grid container key={i}>
                  <Grid item md={6} style={{ fontSize: '12px', marginBottom: '24px', paddingTop: '12px' }}>
                    <span style={{ padding: '0px 8px', borderRadius: '2px', backgroundColor: `${calendar_color}`, margin: '0px 10px'}}></span>  
                    { course_name_front }
                  </Grid>
                  <Grid item md={6} style={{ fontSize: '12px', paddingTop: '12px', width: '60%', marginBottom: '24px'}}>{ course_session.status } on { utils.formatTime(course_session.course_schedule.start_time) }, { utils.formatSgMinsAndSecs(course_session.course_schedule.start_time) }</Grid>
                </Grid>
              )
            }
          }
        }

        // Render the unenrolled credits
        
        // course_id, eligible, and calendar color 
        // will always the same for the course
        const course_id = course_schedule && course_schedule[0] ? course_schedule[0].course._id : ''
        const eligible = course_schedule && course_schedule[0] ? course_schedule[0].eligible : ''
        // const sessions = course_schedule && course_schedule[0] ? course_schedule[0].course.number_of_sessions : ''

        record_schedule_temp[course_id] = record_schedule_temp[course_id] ? record_schedule_temp[course_id]+1 : 1

        const course_enrollable = this.checkIfCourseIsEnrollable(course_id, record_schedule_temp[course_id])
        if (course_enrollable && passed_status.indexOf(course_enrollable.status) > -1) {
          enrollable_temp.push(course_enrollable)
        }

        // const enrolled_course_sessions = auth_credits.filter(ac => ac.course_id === course_id)
        // const enrolled_cs_by_name = enrolled_course_sessions.filter(ecs => ecs.course_schedule_id !== null)

        if (course_name === 'Current_enrolled_checkpoint') {
          // if (this.state.topup_order.length === 0) {
          //   return (
          //     <div key={i} style={{ textAlign: 'center', width: '100%' }} >
          //       <div onClick={(e) => this.convertCredits(e, true)} style={{ cursor: 'pointer', fontWeight: '700', color: 'rgb(30, 144, 255)' }} >+Add Class</div>
          //     </div>
          //   )
          // }
          return undefined
        } else if (course_enrollable && course_enrollable.hold) {
          return (
            <Grid container key={i}>
              <Grid item md={6} style={{ fontSize: '12px', marginBottom: '24px', paddingTop: '12px' }}>
                <span style={{ padding: '0px 8px', borderRadius: '2px', backgroundColor: `${calendar_color}`, margin: '0px 10px' }}></span>  
                { course_name }
              </Grid>
              <Grid item md={6} style={{ fontSize: '12px', paddingTop: '12px', width: '60%', marginBottom: '24px' }}>
                - Not enough credit -
              </Grid>
            </Grid>
          )
        } else if (!eligible) {
          return (
            <Grid container key={i}>
              <Grid item md={6} style={{ fontSize: '12px', marginBottom: '24px', paddingTop: '12px' }}>
                <span style={{ padding: '0px 8px', borderRadius: '2px', backgroundColor: `${calendar_color}`, margin: '0px 10px'}}></span>  
                {course_name_front}
              </Grid>
              <Grid item md={6} style={{ fontSize: '12px', paddingTop: '12px', width: '60%', marginBottom: '24px'}}>
                { course_name.includes('Theory Module 2') && course_enrollable && course_enrollable.status && available_status.indexOf(course_enrollable.status) < 0 ?
                  `${course_enrollable.status} on ${course_enrollable.time}`
                  :
                  '- Not Eligible -'  
                }
              </Grid>
            </Grid>
          )
        } else if (course_name === 'UAPL Practical Assessment' && ( !this.props.auth.caas_theory || this.props.auth.caas_theory.length < 1 || this.props.auth.caas_theory[0].result !== 'Passed')) {
          // Remove this case in the future when 
          // we figure out how to check trainee's passing
          // of CAAS exam 
          
          return (
            <Grid container key={i}>
              <Grid item md={6} style={{ fontSize: '12px', marginBottom: '24px', paddingTop: '12px' }}>
                <span style={{ padding: '0px 8px', borderRadius: '2px', backgroundColor: `${calendar_color}`, margin: '0px 10px'}}></span>  
                {course_name_front}
              </Grid>
              <Grid item md={6} style={{ fontSize: '12px', paddingTop: '12px', width: '60%', marginBottom: '24px'}}>- Appointment Only -</Grid>
            </Grid>
          )
        } else if (course_enrollable && (render_status.indexOf(course_enrollable.status) > -1)) {
          return (
            <Grid container key={i}>
              <Grid item md={6} style={{ fontSize: '12px', marginBottom: '24px', paddingTop: '12px' }}>
                <span style={{ padding: '0px 8px', borderRadius: '2px', backgroundColor: `${calendar_color}`, margin: '0px 10px'}}></span>  
                {course_name_front}
              </Grid>
              <Grid item md={6} style={{ fontSize: '12px', paddingTop: '12px', width: '60%', marginBottom: '24px' }}>
                {`${course_enrollable.status} on ${course_enrollable.time}`}
              </Grid>
            </Grid>
          )
        } else {
          // User's credit is available or they have failed
          // so we render a dropdown
          return (
            <Grid container key={i}>
              <Grid item md={6} style={{ fontSize: '12px', marginBottom: '24px', paddingTop: '12px', display: 'flex', alignItems: 'center' }}>
                <span style={{ padding: '0px 8px', height: '18px', borderRadius: '2px', backgroundColor: `${calendar_color}`, margin: '0px 10px' }}></span>  
                { course_name_front }
              </Grid>
              <Grid item md={6} style={{ fontSize: '12px', paddingTop: '12px', width: '60%', marginBottom: '24px' }}>
                { this.renderCourseTimeSelect(course_name_front, course_schedule, record_schedule_temp[course_id]) } 
                {/* { record_schedule_temp[course_id] === enrollable_temp.filter(et => et.course_id === course_id).length+1 ? this.renderCourseTimeSelect(course_name, course_schedule, record_schedule_temp[course_id]) : '- Not Eligible -' }  */}
              </Grid>
            </Grid>
          )
        }

      })
    } 
  }

  processEnrollments = async () => {
    const { new_enrollments } = this.state 
    const { auth } = this.props

    // for (const scheduled_course of new_enrollments) {
    //   await api_interface.enrollUserToCourse(new_enrollments, auth)
    // }

    this.setState({
      dialog_open: false,
      new_enrollments: []
    })

    const eutc = await api_interface.enrollUserToCourse(new_enrollments, auth)

    if (!eutc.success) {
      // console.log('e', eutc)
      alert(`Cannot book the schedule\nReason: ${eutc.error}`)
    } else {
      await this.props.getAuthUserCredits()
    }

    this.setState({
      dialog_open: false,
      new_enrollments: []
    })
  }

  changeTopupStatus = (value) => {
    this.setState({
      topup_active: value
    })
  }

  render() {
    const now = new Date()
    let first_course_month

    const first_course = Array.isArray(this.props.course_schedule) ? this.props.course_schedule[0] : null
    if (first_course && first_course.start_time && now.toISOString() < first_course.start_time) {
      first_course_month = new Date(first_course.start_time).getMonth()
    } else {
      first_course_month = now.getMonth()
    }
    
    return (
      <div>
        <Grid container  style={styles.classAndTimePicker}>
          <Grid item md={5}>
            <div style={{ paddingRight: '2em' }} >
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <h4 style={{ fontWeight: 500 }}>Select date for classes</h4>
              <br/>
              <Grid container>
                <Grid item md={6}><strong style={{ fontSize: '12px', fontWeight: 600 }}>Class</strong></Grid>
                <Grid item md={6}><strong style={{ fontSize: '12px', fontWeight: 600 }}>Date</strong></Grid>
                
                <Divider style={styles.divider} />

                {
                  this.props.auth && this.props.auth.credits ?
                  this.renderCourseAndTimePicker()
                  :
                  <CircularProgress />
                }
              </Grid>
            </div>
          </Grid>
          <Grid item md={7}>
            <Calendar set_month={ first_course_month } />
            <TopupCreditSection credits={ this.props.auth.credits || [] } auth={ this.props.auth } topup_active={ this.state.topup_active } change_topup_status={ this.changeTopupStatus } open_topup_dialog={ this.state.open_topup_dialog } convertCredits={ this.convertCredits } topup_order={ this.state.topup_order } triggerTopupOrder={ this.triggerTopupOrder } />
            <br /><br />
            <Button style={{ float: 'right' }} onClick={() => this.setState({ dialog_open: true })} disabled={ this.state.new_enrollments.length === 0 } >Enrol To Courses</Button>
          </Grid>
          






          {/* CONFIRM ENROLLMENT DIALOG */}
          <Dialog
            open={this.state.dialog_open}
            onClose={() => this.setState({ dialog_open: false })}
            TransitionComponent={Transition}
          >
            <DialogTitle>Confirm Enrollment</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {this.renderCourseEnrolmentDialog()}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ dialog_open: false })} style={{ backgroundColor: 'white', color: '#1E90FF', textTransform: 'none' }}>
                <strong>Cancel</strong>
              </Button>
              <Button onClick={() => this.processEnrollments()} style={{ textTransform: 'none' }}>
              <strong>Confirm</strong>
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    )
  }



  renderCourseEnrolmentDialog() {

    // Sort new enrollments by date, earliest on top.
    // this.state.new_enrollments remains unchanged

    let new_enrollments_copy = [ ...this.state.new_enrollments ]

    let sorted_new_enrollments = new_enrollments_copy.sort((a,b) => (a.start_time > b.start_time) ? 1 : -1)

    return (
      <div>
        <div>Classes cannot be rescheduled. Are you sure you want to enrol to the following classes?</div>

        {sorted_new_enrollments.findIndex(sne => sne.course.number_of_sessions > 1) > -1 ?
          <div style={{ fontSize: '12px', color: '#ff0000', margin: '10px 0' }} >* Session will be reordered by date</div>
          :
          ``
        }
        
        <br/>
        
        <TableContainer style={{ border: '1px solid rgba(0, 0, 0, 0.125)' }}>
          <Table>
            <TableHead style={{ backgroundColor: '#ebebeb' }}>
              <TableRow>
                <TableCell><strong>Class</strong></TableCell>
                <TableCell><strong>Date</strong></TableCell>
                <TableCell><strong>Time</strong></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              
              {sorted_new_enrollments.map(ne => {

                return (
                  <TableRow>
                    <TableCell>{`${ne.course.name}${ `${ne.course.number_of_sessions > 1 ? ` – ${ne.session}` : ''
                      }` }` }</TableCell>
                    <TableCell>{utils.formatTime(ne.start_time)}</TableCell>
                    <TableCell>{utils.formatSgMinsAndSecs(ne.start_time)}</TableCell>
                  </TableRow>
                )

              })}

                
              
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }
}



// Transform array of scheduled classes
// to an object where the key is the class name,
// and the value is the list of the scheduled classes
// of that class
// ex:
// from: [{ _id: 0, name: "PS 101", time: "10:45 AM"}, { _id: 1, name: "PS 101", time: "2:45 PM"}]
// to: { "PS 101": [{ _id: 0, name: "PS 101", time: "10:45 AM"}, { _id: 1, name: "PS 101", time: "2:45 PM"}]}

const formatCourseScheduleByName = (course_names, schedule) => {
  let schedule_format = {}

  for (const name of course_names) {
    schedule_format[name] = []
  } 

  // {
  //   "Theory Module 1": [],
  //   "Theory Module 2": [],
  //   "Practical Session Series 1-1": [],
  //   "Practical Session Series 1-2": [],
  //   "Practical Session Series 1-3": [],
  // }

  // console.log('schedule_format: ', schedule_format)

  const sorted_schedule = schedule.sort((a,b) => new Date(a.start_time) - new Date(b.start_time))

  for (const scheduled_course of sorted_schedule) {
    const course_name = scheduled_course.course.name
    
    // Check if key exists
      // Add current scheduled class to key's array
      // If not, create key and add it

    for (const key of Object.keys(schedule_format)) {
      // console.log('checking key: ', key)
      if (key.includes(course_name)) {
        let add_scheduled_course = scheduled_course
        if(course_name.includes(practical_session)) {
          const serie = course_name.split(practical_session)
          add_scheduled_course = { ...scheduled_course, ref_course: theory_module + serie[serie.length-1] }
        }
        schedule_format[key].push(add_scheduled_course)
      }
    }
  }

  // console.log('schedule_format: ', schedule_format)

  return schedule_format
}

const sortCoursesByCode = (courses) => {
  const courses_order = utils.getCourseOrder()
  let sorted_courses = []
  for (const course_order of courses_order) {
    const selected_course = courses.find(course => course.code === course_order.code)
    if (selected_course) {
      sorted_courses.push(selected_course)
    }
  }
  return sorted_courses
}

const generateCourseNames = (schedule, credits) => {
  // console.log('in generateCourseNames')
  // console.log('schedule', schedule)

  let all_courses = []
  // console.log('credits', credits)
  if (credits) {
    // First we get all the courses and their info
    for (const c of credits) {
      const exists = all_courses.findIndex(course => course.name === c.course.name) > -1

      if (!exists) {
        all_courses.push(c.course) 
      }
    }

    // console.log('all_courses: ', all_courses)

  }

  let course_names = []

  const all_courses_sorted = sortCoursesByCode(all_courses)
 
  for (const course of all_courses_sorted) {
    const course_number_of_sessions = credits ? credits.filter( credit => credit.course_id === course._id) : []
    if (course_number_of_sessions.length > 1) {
      for (let i = 0; i < course_number_of_sessions.length; i++) {
        if (course_number_of_sessions[i].status !== 'Requested') {
          course_names.push(`${course.name} - ${i+1}`)
        }
      }
    } else {
      course_names.push(course.name)
    }
  }

  // console.log('COURSE_NAMES: ', course_names)

  return course_names
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
})

const mapStateToProps = state => {
  // const course_info = getCourseInfo(state.course_schedul)
  const filtered_schedule = state.course_schedule.filter(cs => cs.deleted_on === null)
  // filter all deleted courses in generate course names
  // console.log('state.auth', state.auth)
  const course_names = generateCourseNames(filtered_schedule, state.auth.credits)
  // console.log('course schedule: ', state.course_schedule)
  const schedule_by_course_name = formatCourseScheduleByName(course_names, state.course_schedule)
  // console.log('schedule_by_course_name: ', schedule_by_course_name)

  return { 
    auth: {
      ...state.auth,
      caas_theory: state.auth && state.auth.caas_theory ? state.auth.caas_theory.sort((a,b) => new Date(b.attempt_date) - new Date(a.attempt_date)) : null
    },
    course_schedule: state.course_schedule.sort((a,b) => new Date(a.start_time) - new Date(b.start_time)), // sort schedule by date
    schedule_by_course_name
  }
}

const mapDispatchToProps = {
  getEntireCourseSchedule, 
  getCourseScheduleForTrainee, 
  getAuthUserCredits
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseScheduler)
