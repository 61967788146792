import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import qs from 'query-string'

import MuiButton from '@material-ui/core/Button'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

import Accordion        from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

import Radio from '@material-ui/core/Radio'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import { goToNextStep, goToPreviousStep, handleSelectCatalogItem } from '../../actions'

const API_URL = process.env.REACT_APP_UATO_API_URL

class StepTwoChooseCatalog extends React.Component {

  state = {
    expanded: {
      panel1: false,
      panel2: false,
      panel3: false,
      panel4: false,
      panel5: false,
      panel6: false,

      panel7: false,
      panel8: false,
      panel9: false,
      panel10: false,
      panel11: false,
      panel12: false
    },

    catalog: [],

    selected_catalog_item: {
      _id: '',
      name: '',
    },
  }

  componentDidMount = async () => {
    const catalog_res = await axios.get(API_URL + '/catalog')
    // console.log('catalog_res is: ', catalog_res)

    // console.log('catalog_res.data.catalogs is: ', catalog_res.data.catalogs)

    this.setState({ catalog: catalog_res.data.catalogs })

    if (this.props.selected_catalog_item) {
      this.setState({ selected_catalog_item: this.props.selected_catalog_item })
    }

    const query = qs.parse(this.props.location.search)
    // console.log('query is: ', query)

    if (query.course) {
      const selected_catalog_item = catalog_res.data.catalogs.find(c => c.sku === query.course)

      // console.log('selected_catalog_item is; ', selected_catalog_item)

      this.setState({ selected_catalog_item })
    }
  }

  onNextClick() {
    this.props.goToNextStep()
    this.props.handleSelectCatalogItem(this.state.selected_catalog_item)
  }

  renderMoreCourseDetails(sku) {
    let expanded_copy = {...this.state.expanded}
    if (sku === 'UT01') {
      expanded_copy.panel1 = !expanded_copy.panel1
    } else if (sku === 'UT02') {
      expanded_copy.panel2 = !expanded_copy.panel2
    } else if (sku === 'UT03') {
      expanded_copy.panel3 = !expanded_copy.panel3
    } 
    // else if (sku === 'UT03B') {
    //   expanded_copy.panel4 = !expanded_copy.panel4
    // } 
    else if (sku === 'UT04') {
      expanded_copy.panel4 = !expanded_copy.panel4
    } else if (sku === 'UT05') {
      expanded_copy.panel5 = !expanded_copy.panel5
    } else if (sku === 'UA01') {
      expanded_copy.panel6 = !expanded_copy.panel6
    } else if (sku === 'UA01A') {
      expanded_copy.panel7 = !expanded_copy.panel7
    } else if (sku === 'UA02') {
      expanded_copy.panel8 = !expanded_copy.panel8
    } else if (sku === 'UA03') {
      expanded_copy.panel9 = !expanded_copy.panel9
    } else if (sku === 'UA04') {
      expanded_copy.panel10 = !expanded_copy.panel10
    } else if (sku === 'UA05') {
      expanded_copy.panel11 = !expanded_copy.panel11
    } else if (sku === 'UA06') {
      expanded_copy.panel12 = !expanded_copy.panel12
    }
    // console.log('expanded_copy: ', expanded_copy)
    this.setState({ expanded: expanded_copy })
  }

  handleSelectCatalog(catalog_item) {
    this.setState({ selected_catalog_item: catalog_item })
    this.props.handleSelectCatalogItem(catalog_item)
  }

  renderShortCourseDescription(catalog_item) {
    if (catalog_item.sku === 'UT01') {
      return (
        <span style={{ fontSize: 14 }}>
          This course prepares trainees with no prior experience in UA operation to attain the Class A (Rotorcraft) UAPL limited to multi-rotor up to 25kg only.
        </span>
      )
    } else if (catalog_item.sku === 'UT02') {
      return (
        <span style={{ fontSize: 14 }}>
          This course prepares existing holders of a Class A (Rotorcraft) UAPL, limited to multi-rotor only to operate a helicopter UA safely and remove their multi-rotor limitations.
        </span>
      )
    } else if (catalog_item.sku === 'UT03') {
      return (
        <span style={{ fontSize: 14 }}>
          This course prepares existing holders of a Class A (Rotorcraft) UAPL, limited to multi-rotor up to 7kg only, to remove their weight limitations.
        </span>
      )
    } 
    // else if (catalog_item.sku === 'UT03B') {
    //   return (
    //     <span style={{ fontSize: 14 }}>
    //       This course prepares existing UOP holders who fall under the restricted UOP to attain their Class A (Rotorcraft) UAPL limited to multi-rotor up to 25kg only.
    //     </span>
    //   )
    // } 
    else if (catalog_item.sku === 'UT04') {
      return (
        <span style={{ fontSize: 14 }}>
          This course prepares trainees for the UAPL Theory Test.
        </span>
      )
    } else if (catalog_item.sku === 'UT05') {
      return (
        <span style={{ fontSize: 14 }}>
          This course prepares trainees with no prior experience in UA operation to attain the Class A (Rotorcraft) UAPL limited to multi-rotor up to 7kg only.
        </span>
      )
    } else if (catalog_item.sku === 'UA01') {
      return (
        <span style={{ fontSize: 14 }}>
          The flight assessment is the final assessment in the process of attaining the UAPL. 
          Candidates must demonstrate sufficient flight proficiency in the operation of rotorcraft UAs up to 25kg in order to pass this flight assessment. 
          This flight assessment lasts 1.5 hours.
        </span>
      )
    } else if (catalog_item.sku === 'UA01A') {
      return (
        <span style={{ fontSize: 14 }}>
          The flight assessment is the final assessment in the process of attaining the UAPL. 
          Candidates must demonstrate sufficient proficiency in the operation of small multi-rotor UAs up to 7kg in order to pass this flight assessment. 
          This flight assessment lasts 1 hour.
        </span>
      )
    } else if (catalog_item.sku === 'UA02') {
      return (
        <span style={{ fontSize: 14 }}>
          Proficiency check is required once every 4 years for all UA pilots to ensure that they are still competent in the operations of their rotorcraft before they are allowed to renew their UAPL. Candidates must demonstrate sufficient flight proficiency in the operation of small and/or large multi-rotor UAs up to 25kg in order to pass this flight assessment. This proficiency check lasts 1 hour.
        </span>
      )
    } else if (catalog_item.sku === 'UA03') {
      return (
        <span style={{ fontSize: 14 }}>
          Proficiency check  is required once every 4 years for all UA pilots to ensure that they are still competent in the operations of their rotorcraft. 
          Candidates must demonstrate sufficient flight proficiency in the operation of rotorcraft UAs up to 25kg in order to pass this proficiency check. 
          This proficiency check lasts 1 and a half hours.
        </span>
      )
    } else if (catalog_item.sku === 'UA04') {
      return (
        <span style={{ fontSize: 14 }}>
          UA04 proficiency check is meant for existing UA pilots who wish to remove their multi-rotor limitation on their Class A (Rotorcraft) UAPL, with multi-rotor limitation only.
          UA pilots must demonstrate sufficient flight proficiency in the operation of helicopter UAs up to in order to pass this proficiency check. 
          This proficiency check lasts 1 hour.
        </span>
      )
    } else if (catalog_item.sku === 'UA05') {
      return (
        <span style={{ fontSize: 14 }}>
          UA05 proficiency check is meant for existing UA pilots who wish to remove their weight limitation on their Class A (Rotorcraft) UAPL, limited to UA up to 7kg only.
          Candidates must demonstrate sufficient flight proficiency in the operation of multi-rotor UAs up to 25kg in order to pass this proficiency check. 
          This proficiency check lasts 1 hour.
        </span>
      )
    } else if (catalog_item.sku === 'UA06') {
        return (
          <span style={{ fontSize: 14 }}>
            The practical flight evaluation is a 3-hour mock evaluation. The instructor will go through all required flight profiles, assess the competency of the candidate and will recommend the next course of action.
            The practical flight assessment is the final assessment in the process of attaining the UAPL. Candidates must demonstrate sufficient proficiency in the operation of small and large multi-rotor UAs up to 7kg in order to pass this flight assessment.
          </span>
        )
    }
    
  }

  renderCatalogDescription() {
    const { catalog } = this.state

    const training_catalog_items_without_UT03B = catalog.filter(catalog_item => catalog_item.sku.includes('UT')).filter(catalog_item => {return catalog_item.sku !== 'UT03B'})

    return training_catalog_items_without_UT03B.map((catalog_item, index) => {
      let panel_is_expanded = false

      panel_is_expanded = this.state.expanded[`panel${index + 1}`]

      // console.log('panel_is_expanded is: ', panel_is_expanded)
      // console.log('index is: ', index)

      return (
        <Accordion key={'catalog' + index} expanded={panel_is_expanded}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Radio
              style={{ marginBottom: 50, height: 24 }}
              onClick={() => this.handleSelectCatalog(catalog_item)}
              checked={catalog_item._id === this.state.selected_catalog_item._id} />
            <Container>
              <Grid container item>
                <Grid item md={10} onClick={() => this.handleSelectCatalog(catalog_item)}>
                  <strong>{catalog_item.sku} - {catalog_item.name}</strong>
                  <br/> 
                  {this.renderShortCourseDescription(catalog_item)}
                  <br/>
                </Grid>
                <Grid item md={1} style={{ marginLeft: 60 }}>
                  S${catalog_item.catalog_price.amount / 100}
                </Grid>
              </Grid>
              <Grid container item>
                {this.renderShowHideDetailsButton(catalog_item)}
              </Grid>
            </Container>
          </AccordionSummary>
          <AccordionDetails>
            <span style={{ fontSize: 14, marginLeft: 65, fontFamily: '' }}>
              {this.renderCatalogDetailedDescription(catalog_item)}
            </span>
          </AccordionDetails>
        </Accordion>
      )
      
    })
  }

  renderAssessments() {
    const { catalog } = this.state

    const assessment_catalog_items = catalog.filter(catalog_item => catalog_item.sku.includes('UA'))

    // Sort assessment catalog array by sku name
    const assessment_catalog_items_sorted = assessment_catalog_items.sort(function(a, b) {
      if (a.sku < b.sku) { return -1; }
      if (a.sku > b.sku) { return 1;  }
      return 0;
    })
    // console.log('assessment_catalog_items is ', assessment_catalog_items)

    return assessment_catalog_items_sorted.map((assessment_item, index) => {
      let panel_is_expanded = false

      const training_catalog_items_without_UT03B = catalog.filter(catalog_item => catalog_item.sku.includes('UT')).filter(catalog_item => {return catalog_item.sku !== 'UT03B'})

      panel_is_expanded = this.state.expanded[`panel${index + 1 + training_catalog_items_without_UT03B.length}`]

      // console.log('panel_is_expanded', panel_is_expanded)

      return (
        <Accordion key={'assessment' + index} expanded={panel_is_expanded}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Radio
              style={{ marginBottom: 50, height: 24 }}
              onClick={() => this.handleSelectCatalog(assessment_item)}
              checked={assessment_item._id === this.state.selected_catalog_item._id} />
            <Container>
              <Grid container item>
                <Grid item md={10} onClick={() => this.handleSelectCatalog(assessment_item)}>
                  <strong>{assessment_item.sku} - {assessment_item.name}</strong>
                  <br/>
                  {this.renderShortCourseDescription(assessment_item)}
                  <br/>
                </Grid>
                <Grid item md={1} style={{ marginLeft: 60 }}>
                  S${assessment_item.catalog_price.amount/100}
                </Grid>
              </Grid>
              <Grid container item>
                {this.renderShowHideDetailsButton(assessment_item)}
              </Grid>
            </Container>
          </AccordionSummary>
          <AccordionDetails>
            <span style={{ fontSize: 14, marginLeft: 65, fontFamily: '' }}>
              { this.renderCatalogDetailedDescription(assessment_item) }
            </span>
          </AccordionDetails>
        </Accordion>
      )

    })

  }

  // Text displayed when user clicks 'Show Details'
  renderCatalogDetailedDescription(catalog_item) {

    const list_style = { fontFamily: '', listStyle: 'none', width: '100%' }
    const dash_style = { display: 'inline-block', marginRight: '5px', verticalAlign: 'top' }
    const desc_style = { display: 'inline-block', maxWidth: '90%', width: '800px' }

    if (catalog_item.sku === 'UT01') {
      return (
        <span>
          Trainees will also be exposed to simple helicopter familiarisation.<br /><br />
          The course comprises 3 parts: <br/><br/>

          <strong>Theory Training</strong><br/>
          <ul>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[0]}</div>
            </li>
          </ul>
          
          <strong>Practical Training with a total of 8 modules</strong>
          <ul>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[1]}</div>
            </li>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[2]}</div>
            </li>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[3]}</div>
            </li>
          </ul>

          <strong>UAPL Practical Assessment</strong>
          <ul>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[4]}</div>
            </li>
          </ul>

          Trainees completing this practical training program can expect to log 6 flight hours.

        </span>
      )
    } else if (catalog_item.sku === 'UT02') {
      return (
        <span>
          The course comprises 2 parts: <br/><br/>

          <strong>Practical Training with a total of 11 modules</strong>
          <ul>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[0]}</div>
            </li>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[1]}</div>
            </li>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[2]}</div>
            </li>
          </ul>

          <strong>UAPL Proficiency Check</strong>
          <ul>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[3]}</div>
            </li>
          </ul>

          Trainees completing this practical training program can expect to log 5.5 flight hours. <br/><br/>
          Note: Trainees who wish to enrol must ensure that they have at least a valid Class A (Rotorcraft) UAPL, limited to multi-rotor only.


        </span>
      )
    } else if (catalog_item.sku === 'UT03') {
      return (
        <span>
          The course comprises 2 parts: <br/><br/>

          <strong>Practical Training with a total of 2 modules</strong>
          <ul>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[0]}</div>
            </li>
          </ul>

        <strong>UAPL Proficiency Check</strong>
          <ul>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[1]}</div>
            </li>
          </ul>

          Trainees completing this practical training program can expect to log 1.25 flight hours. <br/><br/>
          Note: Trainees who wish to enrol must ensure that they have a valid Class A (Rotorcraft) UAPL, limited to UA up to 7kg only. For trainees who do not have a valid Class A (Rotorcraft) UAPL, limited to UA up to 7kg, should consider UT03B.

        </span>
      )
    }
    //  else if (catalog_item.sku === 'UT03B') {
    //   return (
    //     <span>
    //       The course comprises 3 parts: <br/><br/>

    //       <strong>Theory Training</strong>
    //       <ul>
    //         <li style={list_style}>
    //           <div style={dash_style}>-</div>
    //           <div style={desc_style}>{catalog_item.description[0]}</div>
    //         </li>
    //       </ul>

    //       <strong>Practical Training with a total of 2 modules</strong>
    //       <ul>
    //         <li style={list_style}>
    //           <div style={dash_style}>-</div>
    //           <div style={desc_style}>{catalog_item.description[1]}</div>
    //         </li>
    //       </ul>

    //       <strong>UAPL Practical Assessment</strong>
    //       <ul>
    //         <li style={list_style}>
    //           <div style={dash_style}>-</div>
    //           <div style={desc_style}>{catalog_item.description[2]}</div>
    //         </li>
    //       </ul>

    //       Trainees completing this practical training program can expect to log 1.25 flight hours. <br/><br/>
    //       *Restricted UOP holders refers to UA pilots who do not have at least one large rotorcraft (weighing at least 15kg) that is approved within their existing UOP. <br/><br/>
    //       Note: Trainees who wish to enrol must ensure that they do not have any sort of Class A (Rotorcraft) UAPL. For trainees who have a valid Class A (Rotorcraft) UAPL, should consider UT03A.

    //     </span>
    //   )
    // } 
    else if (catalog_item.sku === 'UT04') {
      return (
        <span>
          The course comprises: <br/><br/>

          <strong>Theory Training</strong>
          <ul>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[0]}</div>
            </li>
          </ul>

        </span>
      )
    } else if (catalog_item.sku === 'UT05') {
      return (
        <span>
          <strong>Theory Training</strong><br/>
          <ul>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[0]}</div>
            </li>
          </ul>
          
          <strong>Practical Training with a total of 6 modules</strong>
          <ul>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[1]}</div>
            </li>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[2]}</div>
            </li>
          </ul>

          <strong>UAPL Practical Assessment</strong>
          <ul>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[3]}</div>
            </li>
          </ul>

          Trainees completing this practical training program can expect to log 4.5 flight hours.

        </span>
      )
    } else if (catalog_item.sku === 'UA01') {
      return (
        <span>
          The UAPL Practical Assessment comprises: <br/><br/>

          <strong>UAPL Practical Assessment</strong>
          <ul>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[0]}</div>
            </li>
          </ul>

        </span>
      )
    } else if (catalog_item.sku === 'UA01A') {
      return (
        <span>
          The UAPL Practical Assessment comprises: <br/><br/>

          <strong>UAPL Practical Assessment</strong>
          <ul>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[0]}</div>
            </li>
          </ul>

        </span>
      )
    } else if (catalog_item.sku === 'UA02') {
      return (
        <span>
          The UAPL Proficiency Check comprises: <br/><br/>

          <strong>UAPL Proficiency Check</strong>
          <ul>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[0]}</div>
            </li>
          </ul>

          Note: UA Pilots with Class A (Rotorcraft) UAPL with no limitation should take UA03.

        </span>
      )
    } else if (catalog_item.sku === 'UA03') {
      return (
        <span>
          The UAPL Proficiency Check comprises: <br/><br/>

          <strong>UAPL Proficiency Check</strong>
          <ul>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[0]}</div>
            </li>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[1]}</div>
            </li>
          </ul>

          Note: UA Pilots with Class A (Rotorcraft) UAPL limited to multi-rotor only, should take UA02.

        </span>
      )
    } else if (catalog_item.sku === 'UA04') {
      return (
        <span>
          The UAPL Proficiency Check comprises: <br/><br/>

          <strong>UAPL Proficiency Check</strong>
          <ul>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[0]}</div>
            </li>
          </ul>

        </span>
      )
    } else if (catalog_item.sku === 'UA05') {
      return (
        <span>
          The UAPL Proficiency Check comprises: <br/><br/>

          <strong>UAPL Proficiency Check</strong>
          <ul>
            <li style={list_style}>
              <div style={dash_style}>-</div>
              <div style={desc_style}>{catalog_item.description[0]}</div>
            </li>
          </ul>

        </span>
      )
    } else if (catalog_item.sku === 'UA06') {
        return (
          <span>
            The UAPL Assessment Familiarisation consists of: <br/><br/>
  
            <strong>Practical Flight Examination</strong>
            <ul>
              <li style={list_style}>
                <div style={dash_style}>-</div>
                <div style={desc_style}>{catalog_item.description[1]}</div>
              </li>
            </ul>

            <strong>UAPL Practical Assessment</strong>
            <ul>
              <li style={list_style}>
                <div style={dash_style}>-</div>
                <div style={desc_style}>{catalog_item.description[0]}</div>
              </li>
            </ul>
  
          </span>
        )
      } else {
      return ''
    }
  }

  renderShowHideDetailsButton(catalog_item) {
    if (catalog_item.sku === 'UT01') {
      return <Button onClick={() => this.renderMoreCourseDetails(catalog_item.sku)} style={{ fontSize: 13, textTransform: 'none', backgroundColor: 'transparent' , color: '#1E90FF', paddingLeft: 0, paddingRight: 0 }}><strong>{this.renderShowOrHideButtonText('panel1')}</strong></Button>
    } else if (catalog_item.sku === 'UT02') {
      return <Button onClick={() => this.renderMoreCourseDetails(catalog_item.sku)} style={{ fontSize: 13, textTransform: 'none', backgroundColor: 'transparent' , color: '#1E90FF', paddingLeft: 0, paddingRight: 0 }}><strong>{this.renderShowOrHideButtonText('panel2')}</strong></Button>
    } else if (catalog_item.sku === 'UT03') {
      return <Button onClick={() => this.renderMoreCourseDetails(catalog_item.sku)} style={{ fontSize: 13, textTransform: 'none', backgroundColor: 'transparent' , color: '#1E90FF', paddingLeft: 0, paddingRight: 0 }}><strong>{this.renderShowOrHideButtonText('panel3')}</strong></Button>
    }
    //  else if (catalog_item.sku === 'UT03B') {
    //   return <Button onClick={() => this.renderMoreCourseDetails(catalog_item.sku)} style={{ fontSize: 13, textTransform: 'none', backgroundColor: 'transparent' , color: '#1E90FF', paddingLeft: 0, paddingRight: 0 }}><strong>{this.renderShowOrHideButtonText('panel4')}</strong></Button>
    // }
     else if (catalog_item.sku === 'UT04') {
      return <Button onClick={() => this.renderMoreCourseDetails(catalog_item.sku)} style={{ fontSize: 13, textTransform: 'none', backgroundColor: 'transparent' , color: '#1E90FF', paddingLeft: 0, paddingRight: 0 }}><strong>{this.renderShowOrHideButtonText('panel4')}</strong></Button>
    } else if (catalog_item.sku === 'UT05') {
      return <Button onClick={() => this.renderMoreCourseDetails(catalog_item.sku)} style={{ fontSize: 13, textTransform: 'none', backgroundColor: 'transparent' , color: '#1E90FF', paddingLeft: 0, paddingRight: 0 }}><strong>{this.renderShowOrHideButtonText('panel5')}</strong></Button>
    }
     else if (catalog_item.sku === 'UA01') {
      return <Button onClick={() => this.renderMoreCourseDetails(catalog_item.sku)} style={{ fontSize: 13, textTransform: 'none', backgroundColor: 'transparent' , color: '#1E90FF', paddingLeft: 0, paddingRight: 0 }}><strong>{this.renderShowOrHideButtonText('panel6')}</strong></Button>
    }
     else if (catalog_item.sku === 'UA01A') {
      return <Button onClick={() => this.renderMoreCourseDetails(catalog_item.sku)} style={{ fontSize: 13, textTransform: 'none', backgroundColor: 'transparent' , color: '#1E90FF', paddingLeft: 0, paddingRight: 0 }}><strong>{this.renderShowOrHideButtonText('panel7')}</strong></Button>
    }
     else if (catalog_item.sku === 'UA02') {
      return <Button onClick={() => this.renderMoreCourseDetails(catalog_item.sku)} style={{ fontSize: 13, textTransform: 'none', backgroundColor: 'transparent' , color: '#1E90FF', paddingLeft: 0, paddingRight: 0 }}><strong>{this.renderShowOrHideButtonText('panel8')}</strong></Button>
    } else if (catalog_item.sku === 'UA03') {
      return <Button onClick={() => this.renderMoreCourseDetails(catalog_item.sku)} style={{ fontSize: 13, textTransform: 'none', backgroundColor: 'transparent' , color: '#1E90FF', paddingLeft: 0, paddingRight: 0 }}><strong>{this.renderShowOrHideButtonText('panel9')}</strong></Button>
    } else if (catalog_item.sku === 'UA04') {
      return <Button onClick={() => this.renderMoreCourseDetails(catalog_item.sku)} style={{ fontSize: 13, textTransform: 'none', backgroundColor: 'transparent' , color: '#1E90FF', paddingLeft: 0, paddingRight: 0 }}><strong>{this.renderShowOrHideButtonText('panel10')}</strong></Button>
    } else if (catalog_item.sku === 'UA05') {
      return <Button onClick={() => this.renderMoreCourseDetails(catalog_item.sku)} style={{ fontSize: 13, textTransform: 'none', backgroundColor: 'transparent' , color: '#1E90FF', paddingLeft: 0, paddingRight: 0 }}><strong>{this.renderShowOrHideButtonText('panel11')}</strong></Button>
    } else if (catalog_item.sku === 'UA06') {
      return <Button onClick={() => this.renderMoreCourseDetails(catalog_item.sku)} style={{ fontSize: 13, textTransform: 'none', backgroundColor: 'transparent' , color: '#1E90FF', paddingLeft: 0, paddingRight: 0 }}><strong>{this.renderShowOrHideButtonText('panel12')}</strong></Button>
    } else {
      return ''
    }
  }

  renderShowOrHideButtonText(panel_num) {
    if (this.state.expanded.panel1 && panel_num === 'panel1') {
      return 'Hide Details'
    } else if (!this.state.expanded.panel1 && panel_num === 'panel1') {
      return 'Show Details'
    }

    else if (this.state.expanded.panel2 && panel_num === 'panel2') {
      return 'Hide Details'
    } else if (!this.state.expanded.panel2 && panel_num === 'panel2') {
      return 'Show Details'
    }

    else if (this.state.expanded.panel3 && panel_num === 'panel3') {
      return 'Hide Details'
    } else if (!this.state.expanded.panel3 && panel_num === 'panel3') {
      return 'Show Details'
    }

    else if (this.state.expanded.panel4 && panel_num === 'panel4') {
      return 'Hide Details'
    } else if (!this.state.expanded.panel4 && panel_num === 'panel4') {
      return 'Show Details'
    }

    else if (this.state.expanded.panel5 && panel_num === 'panel5') {
      return 'Hide Details'
    } else if (!this.state.expanded.panel5 && panel_num === 'panel5') {
      return 'Show Details'
    }

    else if (this.state.expanded.panel6 && panel_num === 'panel6') {
      return 'Hide Details'
    } else if (!this.state.expanded.panel6 && panel_num === 'panel6') {
      return 'Show Details'
    }

    else if (this.state.expanded.panel7 && panel_num === 'panel7') {
      return 'Hide Details'
    } else if (!this.state.expanded.panel7 && panel_num === 'panel7') {
      return 'Show Details'
    }

    else if (this.state.expanded.panel8 && panel_num === 'panel8') {
      return 'Hide Details'
    } else if (!this.state.expanded.panel8 && panel_num === 'panel8') {
      return 'Show Details'
    }

    else if (this.state.expanded.panel9 && panel_num === 'panel9') {
      return 'Hide Details'
    } else if (!this.state.expanded.panel9 && panel_num === 'panel9') {
      return 'Show Details'
    }

    else if (this.state.expanded.panel10 && panel_num === 'panel10') {
      return 'Hide Details'
    } else if (!this.state.expanded.panel10 && panel_num === 'panel10') {
      return 'Show Details'
    }

    else if (this.state.expanded.panel11 && panel_num === 'panel11') {
      return 'Hide Details'
    } else if (!this.state.expanded.panel11 && panel_num === 'panel11') {
      return 'Show Details'
    }

    else if (this.state.expanded.panel12 && panel_num === 'panel12') {
      return 'Hide Details'
    } else if (!this.state.expanded.panel12 && panel_num === 'panel12') {
      return 'Show Details'
    }
  }

  renderNextButton() {
    if (this.state.selected_catalog_item.name !== '') {
      return (
        <HoverButton
          style={{ float: 'right', textTransform: 'none' }}
          onClick={ () => this.onNextClick()}>
          Continue
        </HoverButton>
      )
    } else {
      return (
        <Button
          variant='contained'
          style={{ float: 'right', textTransform: 'none', cursor: 'not-allowed', pointerEvents: 'all', padding: '10px', paddingLeft: '20px', paddingRight: '20px', border: 'none' }}
          disabled
          onClick={() => this.onNextClick()}>
          Continue
        </Button>
      )
    }
  }

  render() {
    // console.log(this.state.selected_catalog_item)
    return (
      <div>
        <AccordionSummary style={{ backgroundColor: '#EBEEF0' }}>
          UA Training
        </AccordionSummary>
        {this.renderCatalogDescription()}

        <AccordionSummary style={{ backgroundColor: '#EBEEF0' }}>
          UA Assessment
        </AccordionSummary>
        {this.renderAssessments()}

        <br/>
        <HoverButton style={{ textTransform: 'none', backgroundColor: 'white', color: '#1E90FF', border: '1px solid' }} onClick={ () => this.props.goToPreviousStep()}>Back to Notice</HoverButton>
        
        {this.renderNextButton()}
        
        <br/><br/><br/><br/><br/>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    selected_catalog_item: state.enrol.selected_catalog_item
  }
}

export default connect(mapStateToProps, { goToNextStep, goToPreviousStep, handleSelectCatalogItem })(StepTwoChooseCatalog)

const HoverButton = withStyles({
  root: {
    backgroundColor: '#1E90FF ',
    color: 'white',
    borderRadius: '4px !important', 
    '&$disabled': {
      border: '1px solid rgb(170, 170, 170)',
      color: 'rgb(170, 170, 170) !important',
      backgroundColor: 'white !important'
    },
    '&:hover': {
      backgroundColor: '#3d94f6 !important',
      color: 'white !important',
    }
  }
})(MuiButton)